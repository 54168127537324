import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    
};

const ACTION = {
    GET_DIRECTION_TELCO: 'direction_telco/getDirectionTelco',
    CREATE_DIRECTION_TELCO: 'direction_telco/createDirectionTelco',
    EDIT_DIRECTION_TELCO: 'direction_telco/editDirectionTelco',
    DELETE_DIRECTION_TELCO: 'direction_telco/deleteDirectionTelco',

};

export const getDirectionTelco = createAsyncThunk(ACTION.GET_DIRECTION_TELCO, async body => {
   
    return axiosInstance.get('/api/direction-telcos/', {params:body});
});

export const createDirectionTelco = createAsyncThunk(ACTION.CREATE_DIRECTION_TELCO, async body => {
    
    return axiosInstance.post('/api/direction-telcos/', body);
});

export const editDirectionTelco = createAsyncThunk(ACTION.EDIT_DIRECTION_TELCO, async body => {
    return axiosInstance.put(`/api/direction-telcos/${body.id}/`,body);
});

export const deleteDirectionTelco = createAsyncThunk(ACTION.DELETE_DIRECTION_TELCO, async ({ id }) => {
    return axiosInstance.delete(`/api/direction-telcos/${id}/`);
});



const directionTelcoSlice = createSlice({
    name: 'direction-telcos',
    initialState: initialState,
    extraReducers: {
        [getDirectionTelco.pending.toString()]: state => {
            state.loading = true;
        },
        [getDirectionTelco.rejected.toString()]: state => {
            state.loading = false;
        },
        [getDirectionTelco.fulfilled.toString()]: (state, action) => {
            state.loading = false;
          
            state.list = action.payload;
        },
        ///////////create assetdocument_type
        [createDirectionTelco.pending.toString()]: state => {
            state.success = false;
            
        },
        [createDirectionTelco.rejected.toString()]: state => {
            state.success = false;
        },
        [createDirectionTelco.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },
        // edit assetdocument_type
        [editDirectionTelco.pending.toString()]: state => { 
            state.success = false;
            
        },
        [editDirectionTelco.rejected.toString()]: state => {
            state.success = false;
        },
        [editDirectionTelco.fulfilled.toString()]: state => {
            state.success = true;
        },
        //////////delete assetdocument_type
        [deleteDirectionTelco.pending.toString()]: state => {state.success = false; },
        [deleteDirectionTelco.rejected.toString()]: state => {
            state.success = false;
        },
        [deleteDirectionTelco.fulfilled.toString()]: state => {
            state.success = true;
        },

    }
});
const { reducer: directionTelcoReducer } = directionTelcoSlice;
export default directionTelcoReducer;
