import React, { useState } from 'react';
import Modal, { ModalContent } from '../../../components/common/Modal';
import DialogPreviewNew from './dialog-preview';

const PreviewDsNew = (props) => {
  const [modalPreview, setModalPreview] = useState(false);

  return (
    <>
      <button className="btn" style={{minWidth:'inherit',width:250, marginLeft:10}} onClick={() => setModalPreview(true)}>
      Xem trước đối soát OneConnect
      </button>
      <Modal changeActive={setModalPreview} active={modalPreview}>
        <ModalContent
          className="pu-review-ds"
          onClose={() => {
            setModalPreview(false);
          }}>
          <DialogPreviewNew />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewDsNew;
