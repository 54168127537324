import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    
};

const ACTION = {
    GET_ASSETDOCUMENT_TYPE: 'assetdocument_type/getAssetDocumentType',
    CREATE_ASSETDOCUMENT_TYPE: 'assetdocument_type/createAssetDocumentType',
    EDIT_ASSETDOCUMENT_TYPE: 'assetdocument_type/editAssetDocumentType',
    DELETE_ASSETDOCUMENT_TYPE: 'assetdocument_type/deleteAssetDocumentType',

};

export const getAssetDocumentType = createAsyncThunk(ACTION.GET_ASSETDOCUMENT_TYPE, async body => {
   
    return axiosInstance.get('/api/type-documents/', {params:body});
});

export const createAssetDocumentType = createAsyncThunk(ACTION.CREATE_ASSETDOCUMENT_TYPE, async body => {
    
    return axiosInstance.post('/api/type-documents/', body);
});

export const editAssetDocumentType = createAsyncThunk(ACTION.EDIT_ASSETDOCUMENT_TYPE, async body => {
    return axiosInstance.put(`/api/type-documents/${body.id}/`,body);
});

export const deleteAssetDocumentType = createAsyncThunk(ACTION.DELETE_ASSETDOCUMENT_TYPE, async ({ id }) => {
    return axiosInstance.delete(`/api/type-documents/${id}/`);
});



const assetDocumentTypeSlice = createSlice({
    name: 'assetdocument_type',
    initialState: initialState,
    extraReducers: {
        [getAssetDocumentType.pending.toString()]: state => {
            state.loading = true;
        },
        [getAssetDocumentType.rejected.toString()]: state => {
            state.loading = false;
        },
        [getAssetDocumentType.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            state.list = action.payload;
        },
        ///////////create assetdocument_type
        [createAssetDocumentType.pending.toString()]: state => {
        },
        [createAssetDocumentType.rejected.toString()]: state => {
           state.error = true;
        },
        [createAssetDocumentType.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },
        // edit assetdocument_type
        [editAssetDocumentType.pending.toString()]: state => { },
        [editAssetDocumentType.rejected.toString()]: state => {
        },
        [editAssetDocumentType.fulfilled.toString()]: state => {
        },
        //////////delete assetdocument_type
        [deleteAssetDocumentType.pending.toString()]: state => { },
        [deleteAssetDocumentType.rejected.toString()]: state => {
        },
        [deleteAssetDocumentType.fulfilled.toString()]: state => {
        },

    }
});
const { reducer: assetDocumentTypeReducer } = assetDocumentTypeSlice;
export default assetDocumentTypeReducer;
