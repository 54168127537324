import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, FormInput } from 'shards-react';
import { usePaginationState } from '../../hooks/use-pagination';
import { delService, getServices, selectStatus } from '../redux/accountantService';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import { Tooltip } from 'react-tippy';
import Modal, { ModalContent } from '../../components/common/Modal';
import DialogAsk from '../../components/common/DialogAsk';
const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../components/common/Empty'));
const DialogAccountantService = React.lazy(() =>
  import('../../components/accountant/DialogAccountantService')
);
const AccountantService = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [modalAsk, setModalAsk] = useState(false);
  const [serviceEdit, setServiceEdit] = useState(null);

  const serviceList = useSelector((state) => state.accountantService.data);
  const status = useSelector(selectStatus);

  const servicesLoading = useSelector((state) => state.accountantService.loading);

  const pagination = usePaginationState();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(
      getServices({
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage,
        search: search && search.trim()
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getServices({
          limit: pagination.perPage,
          offset: !search ? pagination.perPage * pagination.page - pagination.perPage : 0,
          search: search && search.trim()
        })
      );
    }
  }, [status.success]);

  const handleDel = () => {
    dispatch(delService(serviceEdit.id))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
        setModalAsk(false);
        setServiceEdit(null);
      })
      .catch(() => {
        dispatch(showMessageError());
      });
  };

  return (
    <React.Suspense>
      <Container fluid className="main-content-container px-4">
        <Modal active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
              setServiceEdit(null);
            }}>
            <DialogAsk
              handleCanel={setModalAsk}
              dataEdit={serviceEdit}
              text={`Bạn có muốn xoá sản phẩm ${serviceEdit && serviceEdit.name}`}
              handleConfirm={handleDel}
            />
          </ModalContent>
        </Modal>
        <Modal active={openModal}>
          <ModalContent
            onClose={() => {
              setOpenModal(false);
              setServiceEdit(null);
            }}
            heading={serviceEdit ? 'Sửa' : 'Tạo'}>
            <DialogAccountantService
              setUDO={setOpenModal}
              serviceEdit={serviceEdit}
              setData={setServiceEdit}
            />
          </ModalContent>
        </Modal>
        <h1 className="heading">
          Danh sách sản phẩm
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm sản phẩm
          </span>
        </h1>
        <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              disabled={servicesLoading}
              type="text"
              name="name"
              placeholder="Search theo tên"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getServices({
                      offset: pagination.perPage * pagination.page - pagination.perPage,
                      limit: pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  
                  dispatch(
                    getServices({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      search: search && search.trim()
                    })
                  );
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            />

            <span
              className="clear-search"
              onClick={() => {
                setSearch();
              }}>
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>

        <Row>
          <Col>
            {servicesLoading ? (
              <Spinner loading={servicesLoading} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Mã sản phẩm
                      </th>
                      <th scope="col" className="border-0">
                        Tên sản phẩm
                      </th>
                      <th scope="col" className="border-0">
                        Loại sản phẩm
                      </th>

                      <th scope="col" className="border-0" style={{ width: 120 }}>
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceList &&
                      serviceList.results &&
                      serviceList.results.map((service) => {
                        return (
                          <tr key={service.id}>
                            <td>{service.code}</td>
                            <td>{service.name}</td>
                            <td>{service.category ? service.category.name : 'Chưa có dữ liệu'}</td>

                            <td>
                              <Tooltip
                                position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-edit"
                                  onClick={() => {
                                    setOpenModal(true);
                                    setServiceEdit(service);
                                  }}>
                                  <i className="fa fa-edit"></i>
                                </span>
                              </Tooltip>

                              <Tooltip
                                position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-del"
                                  onClick={() => {
                                    setModalAsk(true);
                                    setServiceEdit(service);
                                  }}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {serviceList && serviceList.results && serviceList.results.length === 0 && (
                  <Empty description="Không có dữ liệu" />
                )}
                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    Math.ceil((serviceList ? serviceList.count : 0) / pagination.perPage),
                    Math.ceil((serviceList ? serviceList.count : 0) / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(
                      Math.ceil((serviceList ? serviceList.count : 0) / pagination.perPage)
                    )
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default AccountantService;
