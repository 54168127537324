import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    
};

const ACTION = {
    GET_ASSETDOCUMENT_STATUS: 'assetdocument_status/getAssetDocumentStatus',
    CREATE_ASSETDOCUMENT_STATUS: 'assetdocument_status/createAssetDocumentStatus',
    EDIT_ASSETDOCUMENT_STATUS: 'assetdocument_status/editAssetDocumentStatus',
    DELETE_ASSETDOCUMENT_STATUS: 'assetdocument_status/deleteAssetDocumentStatus',

};

export const getAssetDocumentStatus = createAsyncThunk(ACTION.GET_ASSETDOCUMENT_STATUS, async body => {
   
    return axiosInstance.get('/api/status-documents/', {params:body});
});

export const createAssetDocumentStatus = createAsyncThunk(ACTION.CREATE_ASSETDOCUMENT_STATUS, async body => {
    
    return axiosInstance.post('/api/status-documents/', body);
});

export const editAssetDocumentStatus = createAsyncThunk(ACTION.EDIT_ASSETDOCUMENT_STATUS, async body => {
    return axiosInstance.put(`/api/status-documents/${body.id}/`,body);
});

export const deleteAssetDocumentStatus = createAsyncThunk(ACTION.DELETE_ASSETDOCUMENT_STATUS, async ({ id }) => {
    return axiosInstance.delete(`/api/accountant/status-documents/${id}/`);
});



const assetDocumentStatusSlice = createSlice({
    name: 'assetdocument_status',
    initialState: initialState,
    extraReducers: {
        [getAssetDocumentStatus.pending.toString()]: state => {
            state.loading = true;
        },
        [getAssetDocumentStatus.rejected.toString()]: state => {
            state.loading = false;
        },
        [getAssetDocumentStatus.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            state.list = action.payload;
        },
        ///////////create assetdocument_status
        [createAssetDocumentStatus.pending.toString()]: state => {
        },
        [createAssetDocumentStatus.rejected.toString()]: state => {
           state.error = true;
        },
        [createAssetDocumentStatus.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },
        // edit assetdocument_status
        [editAssetDocumentStatus.pending.toString()]: state => { },
        [editAssetDocumentStatus.rejected.toString()]: state => {
        },
        [editAssetDocumentStatus.fulfilled.toString()]: state => {
        },
        //////////delete assetdocument_status
        [deleteAssetDocumentStatus.pending.toString()]: state => { },
        [deleteAssetDocumentStatus.rejected.toString()]: state => {
        },
        [deleteAssetDocumentStatus.fulfilled.toString()]: state => {
        },

    }
});
const { reducer: assetDocumentStatusReducer } = assetDocumentStatusSlice;
export default assetDocumentStatusReducer;
