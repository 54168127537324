import React, { useEffect, useState, Suspense } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountList } from '../redux/account';
import { OPTION_FILTER } from '../../components/common/Filter';
import { usePaginationState } from '../../hooks/use-pagination';
import { showMessageError } from '../redux/alert-message';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Filter = React.lazy(() => import('../../components/common/Filter'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));

const Account = () => {
  const dispatch = useDispatch();
  const isDownloading = useSelector(state => state.detail.downloading);
  const accountList = useSelector(state => state.accounts.accountList);
  const isLoading = useSelector(state => state.accounts.loading);
  const [filter, setFilter] = useState({});

  const handleSearch = filter => {
    setFilter({ ...filter, page: pagination.page, perPage: pagination.perPage });
    dispatch(getAccountList({ ...filter, page: pagination.page, perPage: pagination.perPage }))
      .unwrap()
      .then(() => {})
      .catch(() => {
        dispatch(showMessageError('Chưa chọn mã'));
      });
  };

  const pagination = usePaginationState();

  useEffect(() => {
    if (filter.acc) handleSearch({ ...filter, page: pagination.page, perPage: pagination.perPage });
  }, [pagination]);

  return (
    <Suspense fallback={<></>}>
      <Container fluid className="main-content-container px-4">
        {filter.acc && <Spinner loading={isLoading} />}
        <h1 className="heading">Thống Kê</h1>
        <Row>
          <Col>
            <Filter
              onSearch={handleSearch}
              optionFilter={[
                OPTION_FILTER.ACC_CODE_SELECT_SEARCH,
                OPTION_FILTER.DATE_RANGE,
                accountList.list ? OPTION_FILTER.DOWNLOAD_ACCOUNT : ''
              ]}
              isDownloading={isDownloading}
            />

            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="border-0">
                      Account
                    </th>
                    <th scope="col" className="border-0">
                      Ngày
                    </th>
                    <th scope="col" className="border-0">
                      Viettel (sec)
                    </th>
                    <th scope="col" className="border-0">
                      Vinaphone (sec)
                    </th>
                    <th scope="col" className="border-0">
                      Mobifone (sec)
                    </th>
                    <th scope="col" className="border-0">
                      Other (sec)
                    </th>
                    <th scope="col" className="border-0">
                      All (sec)
                    </th>
                  </tr>
                </thead>
                {accountList.list && (
                  <tbody>
                    <tr key={0}>
                      <td className="font-bold">Tổng</td>
                      <td></td>
                      <td className="font-bold">{accountList.total.tvt}</td>
                      <td className="font-bold">{accountList.total.tvn}</td>
                      <td className="font-bold">{accountList.total.tmb}</td>
                      <td className="font-bold">{accountList.total.tother}</td>
                      <td className="font-bold">{accountList.total.tal}</td>
                    </tr>
                    {accountList.list.map((acc, idx) => {
                      return (
                        <tr key={idx + 1}>
                          <td>{acc.account}</td>
                          <td>{acc.start}</td>
                          <td>{acc.viettel}</td>
                          <td>{acc.vinaphone}</td>
                          <td>{acc.mobifone}</td>
                          <td>{acc.other}</td>
                          <td>{acc.total}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <Pagination
                currentPage={pagination.page}
                pageSize={pagination.perPage}
                lastPage={Math.min(
                  pagination.page + 5,
                  Math.ceil(accountList.total / pagination.perPage)
                )}
                onChangePage={pagination.setPage}
                onChangePageSize={pagination.setPerPage}
                onGoToLast={() =>
                  pagination.setPage(Math.ceil(accountList.total / pagination.perPage))
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default Account;
