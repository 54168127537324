import React from 'react';
import { Container } from 'shards-react';

const Table = React.lazy(() => import('./Table'));

const AssetDocument = () => {
  return (
    <React.Suspense>
      <Container fluid className="main-content-container px-4">
        <Table />
      </Container>
    </React.Suspense>
  );
};

export default AssetDocument;
