import React from 'react';
import SidebarNavItem from './SidebarNavItem';

const SidebarLeft = ({ item, setIsHovered, setSelected, selected, isHovered }) => {
  return (
    <div className="nav-item-parent">
      <div
        className="nav-item-hold"
        onMouseOver={() => {
          setIsHovered(true);
          setSelected(item.title);
        }}>
        {item.htmlBefore && (
          <div className="nav-icon" dangerouslySetInnerHTML={{ __html: item.htmlBefore }} />
        )}

        <div className="nav-text">{item.title && <span>{item.title}</span>}</div>
      </div>
      <SidebarNavItem 

                item={item}
                selected={selected}
                setIsHovered={setIsHovered}
                isHovered={isHovered}
      />
    </div>
  );
};

export default SidebarLeft;
