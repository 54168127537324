import React, { } from 'react';

import TableTelco from './TableTelco';
const CustomerTelco = ({ groupId,dataTelco }) => {


  return (
    <div className="box-form-general style1">
      <h1 className="heading">Danh sách gói cước</h1>
        <TableTelco  dataTelco={dataTelco} groupId={groupId} />

    


      
    </div>
  );
};

export default CustomerTelco;
