import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  assetList:{},
  assetTypeList:{},

};

const ACTION = {
  GET_ASSET: 'asset/getAsset',
  CREATE_ASSET:'asset/createAsset',

  GET_ASSET_ID:'asset/getAssetId',
  UPDATE_ASSET_ID:'asset/updateAssetId',
  DELETE_ASSET_ID:'asset/deleteAssetId',

  GET_ASSET_TYPE: 'asset/getAssetType',
  CREATE_ASSET_TYPE:'asset/createAssetType'
};

export const getAsset = createAsyncThunk(ACTION.GET_ASSET, async body => {
  return axiosInstance.get('api/assets/', { params: body });
});

export const createAsset = createAsyncThunk(ACTION.CREATE_ASSET, async body => {
  return axiosInstance.post('api/assets/', body);
});


export const getAssetId = createAsyncThunk(ACTION.GET_ASSET_ID, async body => {
  return axiosInstance.get(`api/assets/${body.id}`, body);
});

export const updateAssetId = createAsyncThunk(ACTION.UPDATE_ASSET_ID, async body => {
  return axiosInstance.put(`api/assets/${body.id}/`, body);
});

export const deleteAssetId = createAsyncThunk(ACTION.DELETE_ASSET_ID, async body => {
  return axiosInstance.delete(`api/assets/${body.id}/`);
});

export const getAssetType = createAsyncThunk(ACTION.GET_ASSET_TYPE, async body => {
  return axiosInstance.get('api/type-assets/', { params: body });
});

export const createAssetType = createAsyncThunk(ACTION.CREATE_ASSET_TYPE, async body => {
  return axiosInstance.post('api/type-assets/', body);
});

const assetSlice = createSlice({
  name: 'asset',
  initialState: initialState,
  extraReducers: {
    [getAsset.pending.toString()]: state => {
      state.loading = true;
    },
    [getAsset.rejected.toString()]: state => {
      state.loading = false;
    },
    [getAsset.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.createError = false;
      state.createSuccess = false;
      state.delSuccess = false;
      state.delError = false;
      state.updateSuccess = false;
      state.updateError = false;
      state.assetList = action.payload;
    },

    [getAssetType.pending.toString()]: state => {
      state.loadingAssetType = true;
    },
    [getAssetType.rejected.toString()]: state => {
      state.loadingAssetType = false;
    },
    [getAssetType.fulfilled.toString()]: (state, action) => {
      state.loadingAssetType = false;
      state.assetTypeList = action.payload;
    },

    [createAsset.pending.toString()]: (state) => {
    },
    [createAsset.rejected.toString()]: (state) => {
      state.createError = true;
    },
    [createAsset.fulfilled.toString()]: (state) => {
      state.createSuccess = true;
    },

    [updateAssetId.pending.toString()]: (state) => {
    },
    [updateAssetId.rejected.toString()]: (state) => {
      state.updateError = true;
    },
    [updateAssetId.fulfilled.toString()]: (state) => {
      state.updateSuccess = true;
    },

    [deleteAssetId.pending.toString()]: (state) => {
    },
    [deleteAssetId.rejected.toString()]: (state) => {
      state.delError = true;
    },
    [deleteAssetId.fulfilled.toString()]: (state) => {
      state.delSuccess = true;
    }

  }
});

export const selectAssetList = (state => state.asset.assetList)
export const selectAssetTypeList = (state => state.asset.assetTypeList)
export const selectStatus = (state => state.asset)

const { reducer: assetReducer } = assetSlice;
export default assetReducer;
