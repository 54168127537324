import React, { useEffect, useState } from 'react';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';
import Modal, { ModalContent } from '../../../components/common/Modal';
import { Tooltip } from 'react-tippy';

import { showMessageError, showMessageSuccess } from '../../redux/alert-message';
import { delSetupTelco, getSetupTelco } from '../../redux/setup-telco';
import DialogAsk from '../../../components/common/DialogAsk';
const Dialog = React.lazy(() => import('./DialogSetupTelco'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));

const SetupTelco = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [modalAsk, setModalAsk] = useState(false);
  const [data, setData] = useState('');


  const list = useSelector(state => state.setupTelco.list);
  const state = useSelector(state => state.setupTelco);
  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getSetupTelco({
        offset: pagination.perPage * pagination.page - pagination.perPage,
        limit: pagination.perPage,
      })
    );
  }, [pagination]);


  useEffect(() => {
    if (state.success === true) {
      dispatch(
        getSetupTelco({
          offset: pagination.perPage * pagination.page - pagination.perPage,
          limit: pagination.perPage
        })
      );
    }
  }, [state.success]);
  // close modal

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  const handleDel = () => {
    if(data){

      dispatch(delSetupTelco({ id: data.id }))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
        setModalAsk(false);
        setData('');
      })
      .catch(() => {
        dispatch(showMessageError());
      });

    }

  };

  return (
    <React.Suspense>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Các gói cước giới hạn
          <div className="btn-g">

          </div>
          <span
            className="btn"
            style={{ marginLeft: 5, height: 36, lineHeight: '36px' }}
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm gói cước
          </span>
        </h1>
       

        <Modal changeActive={setOpenModal} active={openModal} id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent heading={!data ? 'Thêm' : 'Sửa'} onClose={handleCloseModal}>
            <Dialog setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>

        <Modal active={modalAsk}>
        <ModalContent
          className="modal-warning"
          onClose={() => {
            setModalAsk(false);

          }}
        >
          <DialogAsk
            handleCanel={setModalAsk}
            dataEdit={data}
            text={`Bạn có muốn xoá ${data && data.telco}`}
            handleConfirm={handleDel}
            isDisabled={state.success}
          />
        </ModalContent>
      </Modal>

        {state.loading ? (
          <Spinner loading={state.loading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0" style={{ width: '40px' }}>
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Tên nhà mạng
                  </th>
                  <th scope="col" className="border-0">
                    Số phút
                  </th>
                 

                  <th scope="col" className="border-0">
                    Hành động
                  </th>
                </tr>
              </thead>

              <tbody>
                {list && list.results &&
                  list.results.length > 0 &&
                  list.results.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.telco}</td>
                        <td>{item.minutes}</td>
                        

                        <td>
                          <Tooltip position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                            <button
                              onClick={() => {
                                setOpenModal(true);
                                setData(item);
                              }}
                              className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </button>
                          </Tooltip>
                          <Tooltip  position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                            <span
                              className="btn btn-del"
                              onClick={async () => {
                                setModalAsk(true);
                                setData(item);
                              }}
                              >
                              <i className="fa fa-trash"></i>
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {list && list.results && list.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((list ? list.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(Math.ceil((list ? list.count : 0) / pagination.perPage))
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default SetupTelco;
