export const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});

export const splitLastOccurrence = (str, substring) => {
    const arr = str.split(substring);
  
    const after = arr.pop();
  
    const before = arr.join(substring);
  
    return [before, after];
  }

  export const toCap = (text) => {
    return text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  export const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

  export const getYearArr = (range,year) => {
    const arr = [] 
    for(let i= year - range; i <= year; i++){
      arr.push(i)
    }    
    return arr
  }
