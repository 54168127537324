import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormSelect
} from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncAutoSummary, getSummary } from '../redux/summary';
import { OPTION_FILTER } from '../../components/common/Filter';
import { usePaginationState } from '../../hooks/use-pagination';
import { getDirectionTelco } from '../redux/direction-telco';
import { MONTH } from '../../constants';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import moment from 'moment';
import { getYearArr } from '../helps/help';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Filter = React.lazy(() => import('../../components/common/Filter'));

const Summary = () => {
  const dispatch = useDispatch();
  const accountList = useSelector((state) => state.summary.summary);
  const isLoading = useSelector((state) => state.summary.loading);
  const isDownloading = useSelector((state) => state.summary.downloading);
  const statusSummary = useSelector((state) => state.summary);
  const [filter, setFilter] = useState({});
  const telcos = useSelector((state) => state.directionTelco.list.results);
  const [telcoList, setTelcoList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const year = moment().year();

  const YEAR = getYearArr(5, year);

  const [selectCal, setSelectCal] = useState({
    month: parseInt(MONTH[0]),
    year: parseInt(YEAR[0])
  });

  const handleSearch = (filter) => {
    setFilter({ ...filter, page: pagination.page, perPage: pagination.perPage });
    if (filter['groups'] && filter['groups'].includes('All')) {
      dispatch(
        getSummary({ ...filter, groups: 'all', page: pagination.page, perPage: pagination.perPage })
      );
    } else {
      dispatch(getSummary({ ...filter, page: pagination.page, perPage: pagination.perPage }));
    }
  };

  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(getDirectionTelco());
  }, []);

  useEffect(() => {
    if (telcos && telcos.length) {
      let tmpArray = [];
      telcos.map((item) => {
        let name = item.name[0].toUpperCase() + item.name.substring(1).toLowerCase();
        if (!tmpArray.includes(name)) {
          return tmpArray.push(name);
        } else {
          return item;
        }
      });
      setTelcoList(tmpArray);
    }
  }, [telcos]);

  useEffect(() => {
    if (filter.accs || filter.groups) {
      handleSearch({ ...filter, page: pagination.page, perPage: pagination.perPage });
    }
  }, [pagination]);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Tổng hợp</h1>
        <Spinner loading={isDownloading} top="30%" />
        <Row>
          <Col>
            <Filter
              onSearch={handleSearch}
              optionFilter={[
                OPTION_FILTER.GROUP_MULTI,
                OPTION_FILTER.GROUP_MULTI_HAVE_ALL,
                OPTION_FILTER.DATE_RANGE,
                OPTION_FILTER.ACC_CODE_MULTI,
                accountList.data ? OPTION_FILTER.DOWNLOAD_SUMMARY : ''
              ]}
              isDownloading={isDownloading}
              openModalAsyncAll={setOpenModal}
            />
            {(filter.accs || filter.groups) && <Spinner loading={isLoading} top="150%" />}

            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0" style={{ width: '201px' }}>
                      Account
                    </th>
                    <th scope="col" className="border-0" style={{ width: 100 }}>
                      Ngày
                    </th>
                    {telcoList.map((telco) => (
                      <th key={telco} scope="col" className="border-0 text-right">
                        {telco} (sec)
                      </th>
                    ))}
                  </tr>
                </thead>
                {accountList.data && (
                  <tbody>
                    <tr key={0}>
                      <td className="font-bold">Tổng</td>
                      <td></td>
                      {telcoList.map((telco) => {
                        return (
                          <td key={telco} className="font-bold text-right">
                            {accountList.totals[`${telco.toLowerCase()}`]}
                          </td>
                        );
                      })}
                    </tr>
                    {accountList.data.list.map((acc, idx) => {
                      return (
                        <tr key={idx + 1}>
                          <td className="text-truncate" style={{ width: '200px' }}>
                            {acc.account.toString()}
                          </td>
                          <td>{acc.start}</td>
                          {telcoList.map((telco) => (
                            <td className="text-right" key={telco}>
                              {acc[telco.toLowerCase()]}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {accountList.data && accountList.data.list && (
                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    pagination.page + 5,
                    Math.ceil(accountList.total / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(Math.ceil(accountList.total / pagination.perPage))
                  }
                />
              )}
            </div>
          </Col>
        </Row>
        <Modal
          open={openModal}
          toggle={() => {
            setOpenModal(!openModal);
          }}>
          <ModalHeader className="font-bold">Tính toán lại</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="">Chọn tháng</label>
              <FormSelect
                id="feInputState"
                onChange={(e) => {
                  selectCal['month'] = parseInt(e.target.value);
                  setSelectCal({ ...selectCal });
                }}>
                {MONTH.map((item, idx) => {
                  return <option key={idx}>{item}</option>;
                })}
              </FormSelect>
            </div>
            <div className="form-group">
              <label htmlFor="">Chọn năm</label>
              <FormSelect
                id="feInputState"
                onChange={(e) => {
                  selectCal['year'] = parseInt(e.target.value);
                  setSelectCal({ ...selectCal });
                }}>
                {YEAR.map((item, idx) => {
                  return <option key={idx}>{item}</option>;
                })}
              </FormSelect>
            </div>
            <div className="form-group">
              <div className="text-right">
                <Button
                  className={`form-control ${statusSummary.success === false ? 'disabled' : ''}`}
                  onClick={() => {
                    dispatch(asyncAutoSummary(selectCal))
                      .unwrap()
                      .then(() => {
                        dispatch(showMessageSuccess());
                        setOpenModal(false);
                        setSelectCal({
                          month: parseInt(MONTH[0]),
                          year: parseInt(YEAR[0])
                        });
                      })
                      .catch(() => {
                        dispatch(showMessageError());
                        setOpenModal(false);
                        setSelectCal({
                          month: parseInt(MONTH[0]),
                          year: parseInt(YEAR[0])
                        });
                      });
                  }}>
                  Tính lại
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </React.Suspense>
  );
};

export default Summary;
