import React, { useEffect, useState } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerId } from '../../redux/customer';
import {  saveCustomerId } from '../../redux/contract';
const FormBack = React.lazy(() => import('../../../components/common/FormBack'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Contract = React.lazy(() => import('../contracts/Contract'));
const DialogCustomer = React.lazy(() => import('../../../components/customer/DialogCustomer'));

const CustomerForm = () => {
  let { id } = useParams();
  let history = useHistory();
  const url = history.location.pathname
  const dispatch = useDispatch();
  const [dataContract] = useState();
  const [customerDetail,setCustomerDetail] = useState()
  const customerId = useSelector(state => state.contracts.customerId);
  const state = useSelector(state => state.customers);

  useEffect(() => {
    if (id) {
      dispatch(saveCustomerId(parseInt(id)));
      dispatch(getCustomerId({ id })).unwrap().then((res)=>{
        setCustomerDetail(res)
      })
    } 
  }, [id]);

  useEffect(() => {
    if(state.success === true && id){
      dispatch(getCustomerId({ id })).unwrap().then((res)=>{
        setCustomerDetail(res)
      })
    }
  }, [state.success, id])
  
  


  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
    <Container fluid className="main-content-container px-4">
    <Spinner loading={state.loadingDetail} top={150} />
      <FormBack />
      {!id ? (
        <DialogCustomer dataContract={dataContract} />
      ) : (
        <DialogCustomer customerDetail={customerDetail} />
      )}

  
      {customerId && <Contract url={url}/>}
    </Container>
    </React.Suspense>
  );
};

export default CustomerForm;
