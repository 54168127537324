import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  departments: {},
  loading: false,
};

const ACTION = {
  GET_DEPARTMENTS: 'department/getDepartments',
  CREATE_DEPARTMENT: 'department/createDepartment',
  UPDATE_DEPARTMENT: 'department/updateDepartment'
};

export const getDepartments = createAsyncThunk(ACTION.GET_DEPARTMENTS, async body => {
  return axiosInstance.get('/api/departments/', { params: body });
});

export const updateDepartment = createAsyncThunk(ACTION.UPDATE_DEPARTMENT, async body => {
  return axiosInstance.put(`/api/departments/${body.id}/`, body);
});

export const createDepartment = createAsyncThunk(ACTION.CREATE_DEPARTMENT, async body => {
  return axiosInstance.post('/api/departments/', body);
});

const departmentSlice = createSlice({
  name: 'department',
  initialState: initialState,
  extraReducers: {
    [getDepartments.pending.toString()]: state => {
      state.loading = true;
    },
    [getDepartments.rejected.toString()]: state => {
      state.loading = false;
    },
    [getDepartments.fulfilled.toString()]: (state, action) => {
      state.loading = false;
     
      state.departments = action.payload;
    },
    [createDepartment.pending.toString()]: state => {
      state.success = false;
    },
    [createDepartment.rejected.toString()]: state => {
      state.success = true;
    },
    [createDepartment.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [updateDepartment.pending.toString()]: state => {
      state.success = false;
    },
    [updateDepartment.rejected.toString()]: state => {
      state.success = true;
    },
    [updateDepartment.fulfilled.toString()]: (state, action) => {
      state.success = true;
    }
  }
});
export const selectStatus = (state => state.department)
const { reducer: departmentReducer } = departmentSlice;
export default departmentReducer;
