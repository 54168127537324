import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],

};

const ACTION = {
    GET_ANNEX_CONTRACTS: 'annex_contracts/getAnnexContracts',
    CREATE_ANNEX_CONTRACTS: 'annex_contracts/createAnnexContracts',
    

};

export const getAnnexContracts = createAsyncThunk(ACTION.GET_ANNEX_CONTRACTS, async body => {
   
    return axiosInstance.get('/api/contracts/annexts/', {params:body});
});



export const createAnnexContracts = createAsyncThunk(ACTION.CREATE_ANNEX_CONTRACTS, async body => {
    
    return axiosInstance.post('/api/contracts/annexts/', body);
});




const annextContractsSlice = createSlice({
    name: 'annex_contracts',
    initialState: initialState,
    extraReducers: {
        [getAnnexContracts.pending.toString()]: state => {
            state.loading = true;
        },
        [getAnnexContracts.rejected.toString()]: state => {
            state.loading = false;
        },
        [getAnnexContracts.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            
            state.list = action.payload;
        },

        [createAnnexContracts.pending.toString()]: state => {
            state.success = false;
            
        },
        [createAnnexContracts.rejected.toString()]: state => {
            state.success = false;
        },
        [createAnnexContracts.fulfilled.toString()]: (state, action) => {
           state.success = true;
        },


    }
});
const { reducer: annextContractsReducer } = annextContractsSlice;
export default annextContractsReducer;
