import React, { useEffect, useState } from 'react';
import { Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAssetId, getAsset, selectAssetList, selectStatus } from '../../redux/asset';
import { ModalContent } from '../../../components/common/Modal';
import { usePaginationState } from '../../../hooks/use-pagination';
import { toast_type } from '../../../constants';
import moment from 'moment';
import { formatMoney } from '../../helps/convertMoney';
import { Tooltip } from 'react-tippy';

const Modal = React.lazy(() => import('../../../components/common/Modal'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const DialogUCAsset = React.lazy(() => import('./DialogUCAsset'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));

const Asset = () => {
  const dispatch = useDispatch();
  const assetList = useSelector(selectAssetList);
  const status = useSelector(selectStatus);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState('');

  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getAsset({
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage
      })
    );
  }, []);

  useEffect(() => {
    if (status.createSuccess === true) {
      dispatch(
        getAsset({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage
        })
      );
    }
  }, [status.createSuccess]);

  useEffect(() => {
    if (status.delSuccess === true) {
      dispatch(
        getAsset({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage
        })
      );
    }
  }, [status.delSuccess]);

  useEffect(() => {
    if (status.updateSuccess === true) {
      dispatch(
        getAsset({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage
        })
      );
    }
  }, [status.updateSuccess]);

  // modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        

        <h1 className="heading">
          Quản lý tài sản
          <Tooltip title={'Tạo mới'}
           position="right"
                                size={'small'}
                                animation={'shift'}>
            <span
              className="btn-create"
              onClick={() => {
                setOpenModal(true);
                setData('');
              }}>
              <i className="fa fa-plus"></i>
            </span>
          </Tooltip>
        </h1>
        <Modal active={openModal} id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent heading={!data ? 'Tạo' : 'Sửa'} onClose={handleCloseModal}>
            <DialogUCAsset setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>
        {status.loading ? (
          <Spinner loading={status.loading} top={100} />
        ) : (
          <>
            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      STT
                    </th>
                    <th scope="col" className="border-0">
                      Tên
                    </th>
                    <th scope="col" className="border-0">
                      Mã sản phẩm
                    </th>
                    <th scope="col" className="border-0">
                      Giá
                    </th>
                    <th scope="col" className="border-0">
                      Đơn vị
                    </th>

                    <th scope="col" className="border-0">
                      Ngày nhập
                    </th>
                    <th scope="col" className="border-0 text-center">
                      Ngày đưa vào sử dụng
                    </th>
                    <th scope="col" className="border-0 text-center">
                      Nhân viên sử dụng
                    </th>
                    <th scope="col" className="border-0 text-center">
                      Thuê từ
                    </th>
                    <th scope="col" className="border-0" style={{ width: '120px' }}>
                      Hành động
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assetList &&
                    assetList.results &&
                    assetList.results.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.serial_number}</td>
                          <td>{formatMoney(item.price)}</td>
                          <td>{item.unit}</td>
                          <td>{moment(item.date_added).format('YYYY-MM-DD')}</td>
                          <td className="text-center">
                            {item.date_used
                              ? moment(item.date_used).format('YYYY-MM-DD')
                              : 'Tồn kho'}
                          </td>
                          <td className="text-center">
                            {item.employee
                              ? `${item.employee.employee.first_name} ${item.employee.employee.last_name}`
                              : 'Không'}
                          </td>
                          <td className="text-center">
                            {item.supplier ? item.supplier.name : 'Không'}
                          </td>
                          <td>
                            <Tooltip position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                              <span
                                className="btn btn-edit"
                                onClick={() => {
                                  setOpenModal(true);
                                  setData(item);
                                }}>
                                <i className="fa fa-edit"></i>
                              </span>
                            </Tooltip>
                            <Tooltip  position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                              <span
                                className="btn btn-del"
                                onClick={async () =>
                                  await dispatch(deleteAssetId({ id: item.id }))
                                }>
                                <i className="fa fa-trash"></i>
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {assetList && assetList.results && assetList.results.length === 0 && (
                <Empty description="Không có dữ liệu" />
              )}
              <Pagination
                currentPage={pagination.page}
                pageSize={pagination.perPage}
                lastPage={Math.ceil((assetList ? assetList.count : 0) / pagination.perPage)}
                onChangePage={pagination.setPage}
                onChangePageSize={pagination.setPerPage}
                onGoToLast={() =>
                  pagination.setPage(
                    Math.ceil((assetList ? assetList.count : 0) / pagination.perPage)
                  )
                }
              />
            </div>
          </>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Asset;
