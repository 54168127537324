import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'shards-react';
import { usePaginationState } from '../../../hooks/use-pagination';
import { getEmployees, selectStatus } from '../../redux/employee';
import { ModalContent } from '../../../components/common/Modal';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Modal = React.lazy(() => import('../../../components/common/Modal'));
const DialogEmployee = React.lazy(() => import('../../../components/admin/DialogEmployee'));

const Employee = () => {
  const dispatch = useDispatch();
  const employees = useSelector(state => state.employees.employees);
  const status = useSelector(selectStatus);
  const [employeeUpdate, setEmployeeUpdate] = useState();
  const isLoading = useSelector(state => state.employees.loading);
  const [openModal, setOpenModal] = useState(false);

  const [filter, setFilter] = useState({});

  const handleSearch = filter => {
    setFilter({ ...filter });
    dispatch(getEmployees({ ...filter }));
  };

  const pagination = usePaginationState();

  useEffect(() => {
    handleSearch({ ...filter });
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      handleSearch({ ...filter });
    }
  }, [status.success]);


  const handleCloseModal = () => {
    setOpenModal(false);
    setEmployeeUpdate();
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Danh sách nhân viên
          <span
            className="btn"
            onClick={() => {
              setEmployeeUpdate(null)
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm nhân viên
          </span>
        </h1>

        <Modal changeActive={setOpenModal} active={openModal} id={`${!employeeUpdate ? '' : `modal_${employeeUpdate.id}`}`}>
          <ModalContent
            heading={!employeeUpdate ? 'Thêm mới nhân viên' : 'Cập nhật nhân viên'}
            onClose={handleCloseModal}>
            <DialogEmployee
              setUDO={setOpenModal}
              employee={employeeUpdate}
              setData={setEmployeeUpdate}
            />
          </ModalContent>
        </Modal>
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Họ và tên
                  </th>
                  <th scope="col" className="border-0">
                    Số điện thoại
                  </th>
                  <th scope="col" className="border-0">
                    Email
                  </th>
                  <th scope="col" className="border-0">
                    Vị trí
                  </th>
                  <th scope="col" className="border-0">
                    Phòng ban
                  </th>
                  <th scope="col" className="border-0">
                    Địa chỉ
                  </th>
                  <th scope="col" className="border-0">
                    Hành động
                  </th>
                </tr>
              </thead>
              {employees && employees.results && (
                <tbody>
                  {employees.results.map((employee, idx) => {
                    return (
                      <tr key={idx + 1}>
                        <td>{idx + 1}</td>
                        <td>
                          {employee.first_name} {employee.middle_name} {employee.last_name}
                        </td>
                        <td>{employee.phone}</td>
                        <td>{employee.email}</td>
                        <td>{employee.position && employee.position.name}</td>
                        <td>{employee.department && employee.department.name}</td>
                        <td>{employee.ward ? employee.ward.path_with_type : 'Chưa cập nhật'}</td>
                        <td>
                          <button
                            onClick={() => {
                              setOpenModal(true);
                              setEmployeeUpdate(employee);
                            }}
                            className="btn btn-buy mr-2">
                            Cập nhật
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {/* <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    pagination.page + 5,
                    Math.ceil(accountList.total / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(Math.ceil(accountList.total / pagination.perPage))
                  }
                /> */}
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Employee;
