import React, { useEffect, useState } from 'react';
import { Container ,Button} from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../hooks/use-pagination';
import { deleteAgency, getAgency, selectStatus } from '../redux/agency';
import { ModalContent } from '../../components/common/Modal';
import DialogAsk from '../../components/common/DialogAsk';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import logo from '../../images/sales-overview/no-product-image.jpg'
const DialogCreateAgency = React.lazy(() => import('../../components/agency/DialogCreateAgency'));
const Modal = React.lazy(() => import('../../components/common/Modal'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));

const Agency = () => {
  const dispatch = useDispatch();
  const agencyList = useSelector(state => state.agency.agencyList);
  const status = useSelector(selectStatus);
  const [filter, setFilter] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [agencyUpdate, setAgencyUpdate] = useState();
  const [modalAsk, setModalAsk] = useState(false);
  const [agencyDelete, setAgencyDelete] = useState(null);

  const handleSearch = filter => {
    setFilter({ ...filter });
    dispatch(getAgency({ ...filter }));
  };

  const pagination = usePaginationState();

  useEffect(() => {
    handleSearch({ ...filter });
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      handleSearch({ ...filter });
    }
  }, [status.success]);



  const handleCloseModal = () => {
    setOpenModal(false);
    setAgencyUpdate();
  };

  const handleDeleteAgency = agency => {
    setModalAsk(true);
    setAgencyDelete(agency);
  };

  const deleteAgencyFn = () => {
     dispatch(deleteAgency({ id: agencyDelete.id })).unwrap()
       .then(() => {
         setAgencyDelete(null);
         setModalAsk(false);
         dispatch(showMessageSuccess());
       })
       .catch(() => {
         dispatch(showMessageError())
       });
  }

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <Modal changeActive={setModalAsk} active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
            }}>
            <DialogAsk
              text={`Bạn có muốn xoá đại lý ${agencyDelete && agencyDelete.name} ?`}
              handleCanel={setModalAsk}
              handleConfirm={deleteAgencyFn}
              textConfirm='Xóa'
              textCanel='Hủy'
              isDisabled={status.success}
            />
          </ModalContent>
        </Modal>
        <h1 className="heading">
          Quản lý đại lý
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm đại lý
          </span>
        </h1>

        <Modal active={openModal} changeActive={setOpenModal} id={`${!agencyUpdate ? '' : `modal_${agencyUpdate.id}`}`}>
          <ModalContent
            heading={!agencyUpdate ? 'Thêm mới đại lý' : 'Cập nhật đại lý'}
            onClose={handleCloseModal}>
            <DialogCreateAgency setAgencyUpdate={setAgencyUpdate} setUDO={setOpenModal} agencyDetail={agencyUpdate} />
          </ModalContent>
        </Modal>

        {status.loading ? (
          <Spinner loading={status.loading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                <th scope="col" className="border-0">
                    Logo
                  </th>
                  <th scope="col" className="border-0">
                    Tên
                  </th>
                  <th scope="col" className="border-0">
                    Sdt
                  </th>
                  <th scope="col" className="border-0">
                    Email
                  </th>
                  <th scope="col" className="border-0">
                   Ngân hàng
                  </th>
                  <th scope="col" className="border-0 text-center">
                    Hành động
                  </th>
                </tr>
              </thead>
              {agencyList && agencyList.list && (
                <tbody>
                  {agencyList.list.map((agency, idx) => {
                    return (
                      <tr key={idx + 1}>
                      <td><img className='rounded-circle logo' src={`${process.env.REACT_APP_BASE_API}${agency.image}`} 
alt='avatar'
onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = logo;
            }}

                      /></td>
                        <td>{agency.name}</td>
                        <td>{agency.phone}</td>
                        <td>{agency.email}</td>
                        <td>
                          
                          <p>
                            Tên ngân hàng: {agency.bank_name}
                          </p>
                          <p>
                            Tên tài khoản: {agency.owner}
                          </p>
                          <p>
                            Số tài khoản: {agency.account_number}
                          </p>
                         
                        </td>
                        <td className='text-center'>
                          <button
                            onClick={() => {
                              setOpenModal(true);
                              setAgencyUpdate(agency);
                            }}
                            className="btn btn-buy mr-2">
                            Cập nhật
                          </button>
                          <Button onClick={() => handleDeleteAgency(agency)} active theme="danger">
                            Xóa
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Agency;
