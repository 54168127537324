import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadF88PhoneDetail, getF88ByCode } from '../../redux/f88';
import moment from 'moment';
import { formatMoney } from '../../helps/convertMoney';
import { useHistory, useLocation } from 'react-router';
import { toast_type } from '../../../constants';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));

const F88Detail = () => {
  const dispatch = useDispatch();
  const f88Detail = useSelector(state => state.f88.f88Detail);
  const isLoading = useSelector(state => state.f88.loading);
  const [filter, setFilter] = useState({ caller: '' });
  const location = useLocation();
  const history = useHistory();

  const [showError, setShowError] = useState(false);
  const [rows, setRowsFilter] = useState([]);

  useEffect(() => {
    dispatch(
      getF88ByCode({
        month: location.state.month,
        code: location.state.company,
        year: moment().year()
      })
    );
  }, []);

  useEffect(() => {
    if (f88Detail && f88Detail.data) {
      setRowsFilter(
        f88Detail.data && f88Detail.data.length > 0 && f88Detail.data.map(item => item)
      );
      if (filter.caller) {
        setRowsFilter(
          f88Detail.data &&
            f88Detail.data.length > 0 &&
            f88Detail.data.filter(item => item.caller === filter.caller).map(item => item)
        );
      }
    }
  }, [f88Detail, filter]);

  const [activeClear, setActiveClear] = useState(false);
  const onFocus = e => {
    setActiveClear(true);
  };

  function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    link.click();
  }

  const downloadFile = async item => {
    const body = {
      code: location.state.company,
      phone: item.caller,
      month: location.state.month,
      group: 'F88',
      year: moment().year()
    };

    await dispatch(downloadF88PhoneDetail(body))
      .then(response => {
        if (response.payload) {
          let csv = '\ufeff' + response.payload;
          let blob = new Blob([csv], { type: 'text/csv;charset=UTF-8' });
          let url = window.URL.createObjectURL(blob);
          downloadURI(url, item.caller + '_T' + location.state.month + '.csv');
          window.URL.revokeObjectURL(url);
        } else {
          setShowError(true);
        }
      })
      .finally(() => {});
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">F88 detail </h1>
        {<Spinner loading={isLoading} top="30%" />}
        <Row>
          <Col>
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">{`F88/${location.state.company}`}</h6>
              </div>
            </div>
            <div className="text-right ml-auto">
              <div className="form-search">
                <FormInput
                  type="text"
                  name="caller"
                  placeholder="Search theo SĐT"
                  value={filter.caller}
                  onChange={e => setFilter({ [e.target.name]: e.target.value })}
                  onFocus={onFocus}
                />
                {activeClear && (
                  <span onClick={() => setFilter({ caller: '' })} className="clear-search">
                    <i className="fa fa-times"></i>
                  </span>
                )}
              </div>
            </div>

            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      SĐT
                    </th>
                    <th scope="col" className="border-0">
                      Cước
                    </th>
                    <th scope="col" className="border-0">
                      Phút
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.length > 0 &&
                    rows.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.caller}</td>
                          <td>{formatMoney(item.price)}</td>
                          <td>{formatMoney(item.billsec, '')}</td>
                          <td className="">
                            <Button
                              className="form-control w-1/3 bg-yellow"
                              onClick={() => {
                                history.push(
                                  `/f88-detail-phone?company=${location.state.company}&phone=${item.caller}`,
                                  {
                                    company: location.state.company,
                                    phone: item.caller,
                                    month: location.state.month
                                  }
                                );
                              }}>
                              Chi tiết
                            </Button>
                            <Button
                              className="form-control w-1/3 ml-2"
                              onClick={async () => {
                                await downloadFile(item);
                              }}>
                              Tải xuống
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/* <Pagination
                    currentPage={pagination.page}
                    pageSize={pagination.perPage}
                    lastPage={Math.min(
                      pagination.page + 5,
                      Math.ceil(accountList.total / pagination.perPage)
                    )}
                    onChangePage={pagination.setPage}
                    onChangePageSize={pagination.setPerPage}
                    onGoToLast={() =>
                      pagination.setPage(Math.ceil(accountList.total / pagination.perPage))
                    }
                  /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default F88Detail;
