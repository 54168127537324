import React, { useState, useEffect } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { simLockInforTabs } from '../../../constants';
import { getLockSimDetail, selectLockSimDetail } from '../../redux/sim';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const DialogCreateSimLock = React.lazy(() => import('../../../components/sim/DialogCreateSimLock'));
const DialogCreateAgency = React.lazy(() =>
  import('../../../components/agency/DialogCreateAgency')
);
const DialogCustomer = React.lazy(() => import('../../../components/customer/DialogCustomer'));
const SimLockList = React.lazy(() => import('./SimLockList'));

const Wrapper = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  let history = useHistory();

  const detail = useSelector(selectLockSimDetail);
  const createLockSimSuccess = useSelector(state => state.sims.createLockSimSuccess);
  const updateLockSimSuccess = useSelector(state => state.sims.updateLockSimSuccess);
  const [data, setData] = useState([]);
  // Tab
  const [activeTab, setActiveTab] = useState('');
  const dataInformation = [
    <DialogCreateAgency />,
    <DialogCustomer />,
    <SimLockList data={data} setData={setData} dataSim={detail} />
  ];

  useEffect(() => {
    if (id) {
      dispatch(getLockSimDetail({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (createLockSimSuccess === true) {
      dispatch(getLockSimDetail({ id }));
    }
  }, [createLockSimSuccess]);

  useEffect(() => {
    if (updateLockSimSuccess === true) {
      dispatch(getLockSimDetail({ id }));
    }
  }, [updateLockSimSuccess]);
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
    <Container fluid className="main-content-container px-4">
    {/* <Spinner loading={status.loading} top={200} /> */}
    <span
      className="btn"
      onClick={() => history.goBack()}
      style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        minWidth: 'inherit',
        width: 40,
        height: 40,
        lineHeight: 40,
        marginTop: 20
      }}>
      <i className="fa fa-chevron-left"></i>
    </span>
   
    {id ? <DialogCreateSimLock simLockInfoByDay={detail} dataPhone={data} /> : <DialogCreateSimLock  dataPhone={data} />}
    <div className="tabs-list">
        {simLockInforTabs.map((v, i) => (
          <div
            key={i}
            className={`tab-item ${activeTab === v.key ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(v.key);
            }}>
            <p> <span className='fa fa-plus-circle'></span> {v.name.toUpperCase()}</p>
          </div>
        ))}
      </div>
      <div className='box-form-general'>
      {dataInformation[activeTab]}
      </div>
      
  </Container>
  </React.Suspense>
  )
}

export default Wrapper;
