import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  user: {},
  allUser: {},
  userSim: [],
  loadinghistory: null,
};

const ACTION = {
  GET_USER: 'user/getUser',
  GET_ALL_USER: 'user/getAllUser',
  GET_HISTORYUSERSIM: 'sim/getHistoryUserSim',
  CREATE_USER: 'user/createUser',
  UPDATE_USER: 'user/updateUser'
};

export const getUser = createAsyncThunk(ACTION.GET_USER, async () => {
  let boolean = 'False';
  return axiosInstance.get(`/restapi/users/?is_staff=${boolean}`);
});

export const getAllUser = createAsyncThunk(ACTION.GET_ALL_USER, async body => {
  return axiosInstance.get(`/restapi/users`, { params: body });
});

//History sim user
export const getHistoryUserSim = createAsyncThunk(ACTION.GET_HISTORYUSERSIM, async username => {
  return axiosInstance.get(`/restapi/users/history-order/?username=${username}`);
});

// Create user

export const createUser = createAsyncThunk(ACTION.CREATE_USER, async body => {
  return axiosInstance.post('/restapi/users/', body);
});

// update user

export const updateUser = createAsyncThunk(ACTION.UPDATE_USER, async ({ id, body }) => {
  return axiosInstance.put(`/restapi/users/detail/${id}/`, body);
});

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  extraReducers: {
    [getUser.pending.toString()]: state => {
      state.loading = true;
    },
    [getUser.rejected.toString()]: state => {
      state.loading = false;
    },
    [getUser.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.loadinghistory = null;
      state.user = action.payload;
    },
    [getAllUser.pending.toString()]: state => {
      state.loading = true;
    },
    [getAllUser.rejected.toString()]: state => {
      state.loading = false;
    },
    [getAllUser.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.loadinghistory = null;
     
      state.allUser = action.payload;
    },

    [getHistoryUserSim.pending.toString()]: state => {
      state.loadinghistory = true;
    },
    [getHistoryUserSim.rejected.toString()]: state => {
      state.loadinghistory = false;
    },
    [getHistoryUserSim.fulfilled.toString()]: (state, action) => {
      state.userSim = action.payload;
      state.loadinghistory = false;
    },
    [createUser.pending.toString()]: state => {state.success = false;},
    [createUser.rejected.toString()]: state => {
      state.success = true;
    },
    [createUser.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [updateUser.pending.toString()]: state => {state.success = false;},
    [updateUser.rejected.toString()]: state => {
      state.success = true;
    },
    [updateUser.fulfilled.toString()]: (state, action) => {
      state.success = true;
    }
  }
});
export const selectStatus = (state => state.user)
const { reducer: userReducer } = userSlice;
export default userReducer;
