import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../hooks/use-pagination';
import { getAllUser, selectStatus } from '../redux/user';
import moment from 'moment';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const DialogCreateUser = React.lazy(() => import('../../components/user/DialogCreateUser'));

const User = () => {
  const dispatch = useDispatch();
  const userList = useSelector(state => state.user.allUser);
  const isLoading = useSelector(state => state.user.loading);
  const status = useSelector(selectStatus);
  const [filter, setFilter] = useState({});
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [userUpdate, setUserUpdate] = useState();
  const wrapperRef = useRef(null);
  const wrapperRefUpdate = useRef(null);

  const handleSearch = filter => {
    setFilter({ ...filter, page: pagination.page, per_page: pagination.perPage });
    dispatch(getAllUser({ ...filter, page: pagination.page, per_page: pagination.perPage, }));
  };

  const pagination = usePaginationState();

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleKeyDown = e => {
    if (openModalUpdate && e.keyCode == 27) {
      setOpenModalUpdate(false);
      setUserUpdate(null)
    }
  };

  useEffect(() => {
    handleSearch({ ...filter, page: pagination.page, per_page: pagination.perPage });
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      handleSearch({ ...filter, page: pagination.page, per_page: pagination.perPage });
    }
  }, [status.success]);



  const closeModal = e => {
    if (e.target === wrapperRefUpdate.current) setOpenModalUpdate(false);
  };

  const handleCloseModalUpdate = () => {
    setOpenModalUpdate(false);
    setUserUpdate('');
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        {filter.acc && <Spinner loading={isLoading} />}
        <h1 className="heading">
          Quản lý User
          <span
            className="btn"
            onClick={() => {
              setOpenModalUpdate(true);
              setUserUpdate(null);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm User
          </span>
        </h1>

        {openModalUpdate && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">
                  {!userUpdate ? 'Tạo' : 'Cập nhật tài khoản người dùng'}
                </h3>
                <div className="popup-close" onClick={() => handleCloseModalUpdate()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogCreateUser
                  setUDO={setOpenModalUpdate}
                  userUpdate={userUpdate}
                  setUserUpdate={setUserUpdate}
                />
              </div>
            </div>
          </>
        )}
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Tài khoản
                  </th>
                  <th scope="col" className="border-0">
                    Email
                  </th>
                  <th scope="col" className="border-0">
                    Tên
                  </th>
                  <th scope="col" className="border-0">
                    Đang hoạt động
                  </th>

                  <th scope="col" className="border-0">
                    Ngày tham gia
                  </th>
                  <th scope="col" className="border-0">
                    Hành động
                  </th>
                </tr>
              </thead>
              {userList && userList.list && (
                <tbody>
                  {userList.list.map((user, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.first_name + user.first_name ? ', ' : '' + user.last_name}</td>
                        <td>{user.is_active === true ? 'Hoạt động' : 'Bị khoá'}</td>

                        <td>{moment(user.date_joined).format('DD/MM/YYYY')}</td>
                        <td>
                          <button
                            onClick={() => {
                              setOpenModalUpdate(true);
                              setUserUpdate(user);
                            }}
                            className="btn btn-buy mr-2">
                            Cập nhật
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.min(
                pagination.page + 5,
                Math.ceil(userList.total / pagination.perPage)
              )}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() => pagination.setPage(Math.ceil(userList.total / pagination.perPage))}
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default User;
