import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Modal = props => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () =>{
      document.removeEventListener('keydown', handleKeyDown);
    }
  });

  const handleKeyDown = e => {
    if (active && e.keyCode == 27) {
      setActive(false);
      if (props.changeActive) {
        props.changeActive(null)
      }
    }
  };

  return (
    active && (
      <>
        <div className="modal-overlay"></div>
        <div id={props.id} className={`modal-popup_style2 ${active ? 'open' : ''}`}>
          {props.children}
        </div>
      </>
    )
  );
};

Modal.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string
};

export const ModalContent = props => {
  const contentRef = useRef(null);

  const closeModal = () => {
    contentRef.current.parentNode.classList.remove('active');
    if (props.onClose) props.onClose();
  };

  return (
    <div ref={contentRef} className={`popup-container pu-general ${props.className}`} style={props.style}>
      {props.heading && <h3 className='pu-heading'>{props.heading}</h3>}

      <div className="popup-close" onClick={closeModal}>
        <span className="fa fa-times"></span>
      </div>
      {props.children}
    </div>
  );
};

ModalContent.propTypes = {
  onClose: PropTypes.func
};

export default Modal;
