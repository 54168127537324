import React, { useEffect } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContractsDetail } from '../../redux/contract';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const AnnextContract = React.lazy(() => import('./AnnextContract'));
const UcContract = React.lazy(() => import('./UcContract'));

const ContractForm = ({ customer, setUDO }) => {
  let { id,uuid } = useParams();
  let history = useHistory();

  const dispatch = useDispatch();
  const contractDetail = useSelector(state => state.contracts.detail);
  const state = useSelector(state => state.contracts);

  useEffect(() => {
    if(state.success === true && uuid){
      dispatch(getContractsDetail(uuid));
    }
  }, [state.success, uuid])
  


  useEffect(() => {
    if (uuid) {
      dispatch(getContractsDetail(uuid));
    }
  }, [uuid]);

  const handleBack = () => {
    if (customer) {
      setUDO(false);
    } else {
      history.goBack();
    }
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className={!customer ? `main-content-container px-4` : `px-0`}>
        <span className="btn-outline br-theme" onClick={() => handleBack()}>
          <i className="fa fa-chevron-left"></i> Quay lại
        </span>
        {!uuid ? (
          <UcContract customer={customer} setUDO={setUDO} />
        ) : (
          <UcContract dataEdit={contractDetail} setUDO={setUDO} customer={customer} id={id}/>
        )}
        {uuid && contractDetail && <AnnextContract id={contractDetail.unique_id} />}
      </Container>
    </React.Suspense>
  );
};

export default ContractForm;
