import React, { useState, useEffect } from 'react';
import { FormSelect, Button, FormRadio, FormCheckbox, InputGroup } from 'shards-react';
import { getAccount } from '../../erp/redux/account';
import { getGroup } from '../../erp/redux/group';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {  downloadSummary } from '../../erp/redux/summary';
import { downloadDetail } from '../../erp/redux/detail';
import {
  downloadControl,
  getInvoice,
  savePosControl
} from '../../erp/redux/control';
import { getCustomer } from '../../erp/redux/customer';
import useDebounce from '../../hooks/use-debounce';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import ControlExcel from '../../erp/view/control/excel';
import { showMessageSuccess } from '../../erp/redux/alert-message';
import DialogPreview from '../../erp/view/control/PreviewDs';
import { getAgency } from '../../erp/redux/agency';
import ConfigControl from '../../erp/view/control/ConfigControl';
import ControlExcelNewCustomer from '../../erp/view/control-v2/excel-customer-new';
import DialogPreviewNew from '../../erp/view/control-v2/dialog-preview';
import PreviewDsNew from '../../erp/view/control-v2/preview-ds-new';
export const OPTION_FILTER = {
  ACC_CODE: 'acc_code',
  ACC_CODE_MULTI: 'acc_code_multi',
  ACC_CODE_SELECT_SEARCH: 'acc_code_select_search',
  GROUP: 'group',
  GROUP_SELECT_SEARCH: 'group_select_search',
  GROUP_SELECT_SEARCH_CONTROL: 'group_select_search_control',
  GROUP_MULTI_HAVE_ALL: 'group_multi_have_all',
  GROUP_MULTI: 'group_multi',
  DATE_RANGE: 'date-range',
  TYPE_EXPORT: 'typeExport',
  DOWNLOAD_ACCOUNT: 'downloadAccount',
  DOWNLOAD_SUMMARY: 'downloadSummary',
  DOWNLOAD_DETAIL: 'downloadDetail',
  DOWNLOAD_CONTROL: 'downloadControl',

  ACCOUNTANT_MINUS: 'acc_minus'
};

export const OPTION_TIME = {
  MIN: 'minus',
  CUSTOM: 'product',
  ALL: 'all',
  VOICE_BRANCH: 'voiceBranch'
};

export const type_ds = [
  {
    value: 1,
    label: 'Mặc định'
  },
  {
    value: 2,
    label: 'Ẩn cột chiết khấu'
  }
];

const Filter = (props) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 32,
      height:
        props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI) ||
        props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI_HAVE_ALL)
          ? 'inherit'
          : 32,
      borderColor: '#e1e5eb'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height:
        props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI) ||
        props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI_HAVE_ALL)
          ? 'inherit'
          : 30
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: (state) => ({
      display: 'none'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height:
        props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI) ||
        props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI_HAVE_ALL)
          ? 'inherit'
          : 30
    })
  };

  const dispatch = useDispatch();
  var today = new Date();
  const [isToday, setIsToday] = useState(false)
  const [isNewControl, setIsNewControl] = useState(false)
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState(moment().toDate());
  const [groupNameOptions, setGroupNameOptions] = useState([]);

  const [groupSelectSearchControl, setGroupSelectSearchControl] = useState([]);

  const [accountSelectSearch, setAccountSelectSearch] = useState([]);
  const [filter, setFilter] = useState({ page: 1, perPage: 15 });

  const loadingGroupByCustomer = useSelector((state) => state.customers.loading);
  const loadingGroup = useSelector((state) => state.groups.loading);
  const statusControl = useSelector((state) => state.control);
  const classes = classNames('', 'd-flex', 'my-auto', 'date-range');
  const [timeSelected, setTimeSelected] = useState(OPTION_TIME.ALL);
  const [urlDownload, setUrlDownload] = useState('');

  const [selectType, setSelectType] = useState(1);

  const customerName1 = 'NGÂN HÀNG TMCP BẢN VIỆT';
  const dataInvoice = useSelector((state) => state.control.invoice);
  useEffect(() => {
    if (props.optionFilter.includes(OPTION_FILTER.TYPE_EXPORT)) {
      filter['typeExport'] = timeSelected;
    }
  }, []);

  const [accCode, setAccCode] = useState([]);
  const [groupMultiple, setGroups] = useState([]);

  const handleChangeOption = (option, field) => {
    switch (field) {
      case 'accs':
        setAccCode([...option]);
        break;
      case 'groups':
        setGroups([...option]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (accCode) {
      setFilter({ ...filter, accs: accCode.map((item) => item.value) });
    }
  }, [accCode]);

  useEffect(() => {
    if (groupMultiple) {
      setFilter({ ...filter, groups: groupMultiple.map((item) => item.value) });
    }
  }, [groupMultiple]);

  useEffect(() => {
    if (props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_ACCOUNT)) {
      setUrlDownload(
        `${process.env.REACT_APP_BASE_API}/xls-total/?acc=${filter['acc']}&from=${moment(
          filter['from']
        ).format('DD/MM/YYYY')}&to=${moment(filter['to']).format('DD/MM/YYYY')}`
      );
    }
    if (props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_SUMMARY)) {
    }
  }, [filter, props.optionFilter]);

  useEffect(() => {
    filter['from'] = moment(startDate).format('YYYY-MM-DD');
    filter['to'] = moment(endDate).format('YYYY-MM-DD');
    setFilter({ ...filter });
  }, [startDate, endDate]);

  const [page, setPage] = useState(0);
  const products = useSelector((state) => state.control.productDownload);
  const data = useSelector((state) => state.control.invoice);
  const [pageGroup, setPageGroup] = useState(0);
  const [totalPageGroup, setTotalPageGroup] = useState(1);

  const [pageCustomerGroup, setPageCustomerGroup] = useState(0);
  const [totalPageCustomerGroup, setTotalPageCustomerGroup] = useState(1);

  const [search, setSearch] = useState();
  const [searchGroup, setSearchGroup] = useState();
  const [searchCustomerGroup, setSearchCustomerGroup] = useState();
  const searchDebounce = useDebounce(search, 500);
  const searchGroupDebouce = useDebounce(searchGroup, 500);

  const searchCustomerDebouce = useDebounce(searchCustomerGroup, 500);

  const loadingAccount = useSelector((state) => state.accounts.loading);
  const addnewOption = () => {
    setPage(page + 1);
  };
  const addnewOption2 = () => {
    setPageGroup(pageGroup + 1);
  };

  const addnewOption3 = () => {
    setPageCustomerGroup(pageCustomerGroup + 1);
  };
  const baseOption = {
    label: 'Chọn',
    value: ''
  };

  useEffect(() => {
    const fetchDataCustomer = async () => {
      var arr = [...groupSelectSearchControl, baseOption];

      const isSearch = searchCustomerGroup
        ? { name: searchCustomerGroup, group: 1 }
        : {
            limit: 15,
            offset: pageCustomerGroup === 0 ? 0 : (pageCustomerGroup - 1 + 1) * 15,
            group: 1
          };
      dispatch(getCustomer(isSearch))
        .unwrap()
        .then((res) => {
          if (searchCustomerGroup) {
            setTotalPageCustomerGroup((prev) => prev);
          } else {
            const totalpage = Math.round(res.count / 15);
            setTotalPageCustomerGroup(totalpage);
          }

          res &&
            res.results &&
            res.results.map((acc) => {
              return arr.push({
                value: acc.name,
                label: acc.name + ` - (${acc.group && acc.group.name})`,
                nameCustomer: acc.contract_name || acc.name,
                id: acc.id,
              });
            });
          if (res.results && res.results.length > 0) {
            const unique = [...new Map(arr.map((item) => [item['id'], item])).values()];
            setGroupSelectSearchControl(unique);
          }
        });
    };
    if (
      pageCustomerGroup <= totalPageCustomerGroup &&
      props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH_CONTROL)
    ) {
      fetchDataCustomer();
    }
  }, [pageCustomerGroup, searchCustomerDebouce]);
  useEffect(() => {
    const fetDataAccount = async () => {
      var arr = [...accountSelectSearch, baseOption];

      const isSearch = search
        ? { name: search }
        : { limit: 15, offset: page === 0 ? 0 : (page - 1 + 1) * 15 };
      dispatch(getAccount(isSearch))
        .unwrap()
        .then((res) => {
          res &&
            res.results.map((item) =>
              arr.push({
                value: item.code,
                label: item.code
              })
            );
          if (res.results && res.results.length > 0) {
            const unique = [...new Map(arr.map((item) => [item['value'], item])).values()];
            setAccountSelectSearch(unique);
          }
        });
    };
    if (
      props.optionFilter.includes(OPTION_FILTER.ACC_CODE) ||
      props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI) ||
      props.optionFilter.includes(OPTION_FILTER.ACC_CODE_SELECT_SEARCH)
    ) {
      fetDataAccount();
    }
  }, [page, searchDebounce]);

  useEffect(() => {
    var arrGroup = [];
    const all = { label: 'All', value: 'All' };
    if (props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI_HAVE_ALL)) {
      arrGroup = [...groupNameOptions, all];
    } else {
      arrGroup = [...groupNameOptions, baseOption];
    }

    const isSearch = searchGroup
      ? { name: searchGroup }
      : { limit: 15, offset: pageGroup === 0 ? 0 : (pageGroup - 1 + 1) * 15 };

    const fetchDataGroup = async () => {
      dispatch(getGroup(isSearch))
        .unwrap()
        .then((res) => {
          if (searchGroup) {
            setTotalPageGroup((prev) => prev);
          } else {
            const totalpage = Math.round(res.count / 15);
            setTotalPageGroup(totalpage);
          }

          res &&
            res.results.map((item) =>
              arrGroup.push({
                value: item.name,
                label: item.name
              })
            );
          if (res.results && res.results.length > 0) {
            const unique = [...new Map(arrGroup.map((item) => [item['value'], item])).values()];

            setGroupNameOptions(unique);
          }
        });
    };

    if (
      pageGroup <= totalPageGroup &&
      (props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH) ||
        props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI) ||
        props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI_HAVE_ALL))
    ) {
      fetchDataGroup();
    }
  }, [searchGroupDebouce, pageGroup]);

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  const handleSearch = () => {
    props.onSearch(filter);
  };
// tinh toan lai
  const handleSearch2 = () => {
    let values = {
      ...filter,
      recal:true,
    }
    props.onSearch(values);
  };

  const onChangeTypeExport = (time) => {
    filter['typeExport'] = time;
    setFilter({ ...filter });
    setTimeSelected(time);
  };

  function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    link.click();
  }

  const getFileName = (p1, p2, to, typeExport) => {
    let fileName = 'Default';
    if (filter[p2] || (Array.isArray(filter[p2]) && filter[p2].length > 0)) {
      fileName = filter[p2];
    } else if (filter[p1]) {
      fileName = filter[p1];
    }
    fileName = fileName + '_T' + to.format('M');
    if (typeExport) {
      fileName = fileName + '_' + typeExport;
    }
    return fileName;
  };

  const downloadFile = async () => {
    if (props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_SUMMARY)) {
      const body = {
        from: moment(filter['from']).format('YYYY-MM-DD'),
        to: moment(filter['to']).format('YYYY-MM-DD')
      };
      if (filter['accs']) {
        body['accs'] = filter['accs'];
      }
      if (filter['groups']) {
        body['groups'] = filter['groups'];
      }
      await dispatch(downloadSummary(body))
        .then((response) => {
          let blob = new Blob([response.payload], { type: 'application/octet-stream' });
          let url = window.URL.createObjectURL(blob);
          downloadURI(
            url,
            'Tong_hop_' + getFileName('accs', 'groups', moment(filter['to'])) + '.xls'
          );
          window.URL.revokeObjectURL(url);
        })
        .finally(() => {});
    }

    if (props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_DETAIL)) {
      const body = {
        from: moment(filter['from']).format('DD/MM/YYYY'),
        to: moment(filter['to']).format('DD/MM/YYYY')
      };

      if (filter['acc']) {
        body['acc'] = filter['acc'];
      }
      if (filter['group']) {
        body['group'] = filter['group'];
      }
      if (filter['over']) {
        body['over'] = filter['over'];
      }
      await dispatch(downloadDetail(body))
        .then((response) => {
          let blob = new Blob([response.payload], { type: 'application/octet-stream' });
          let url = window.URL.createObjectURL(blob);
          downloadURI(
            url,
            'Chi_tiet_' + getFileName('acc', 'group', moment(filter['to'])) + '.zip'
          );
          window.URL.revokeObjectURL(url);
        })
        .finally(() => {});
    }
    if (props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_CONTROL)) {
      const body = {
        from: moment(filter['from']).format('YYYY-MM-DD'),
        to: moment(filter['to']).format('YYYY-MM-DD'),
        showMinusCol: filter['showMinusCol']
      };
      if (filter['customer']) {
        body['group'] = props.groupName;
      }
      body['products'] = products;
      body['typeExport'] = filter['typeExport'];
      await dispatch(downloadControl(body))
        .then((response) => {
          let blob = new Blob([response.payload], { type: 'application/octet-stream' });
          let url = window.URL.createObjectURL(blob);
          downloadURI(
            url,
            'Xuat_doi_soat_' +
              getFileName('acc', 'customer', moment(filter['to']), filter['typeExport']) +
              '.xls'
          );
          window.URL.revokeObjectURL(url);
        })
        .finally(() => {});
    }
    if (props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_ACCOUNT)) {
      const body = {
        from: moment(filter['from']).format('DD/MM/YYYY'),
        to: moment(filter['to']).format('DD/MM/YYYY')
      };
      if (filter['group']) {
        body['group'] = filter['group'];
      }
      await dispatch(downloadDetail(body))
        .then((response) => {
          let blob = new Blob([response.payload], { type: 'application/octet-stream' });
          let url = window.URL.createObjectURL(blob);
          downloadURI(
            url,
            'Tai_khoan_' + getFileName('acc', 'group', moment(filter['to'])) + '.xls'
          );
          window.URL.revokeObjectURL(url);
        })
        .finally(() => {});
    }
  };

  // xuat doi soat

  const handleOptionChangeGroupMulti = (value) => {
    if (props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH_CONTROL)) {
      filter['customer'] = value.value;
      if (value.value === '') {
        delete filter['customer'];
        setFilter({ ...filter });
      } else {
        setFilter({ ...filter });
      }
    } else {
      filter['group'] = value.value;
      if (value.value === '') {
        delete filter['group'];
        setFilter({ ...filter });
      } else {
        setFilter({ ...filter });
      }
    }

    if (props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH_CONTROL)) {
      const submit = {
        ...value,
        isToday:isToday,
      }
      dispatch(getInvoice(submit));
    }
  };
  // ma tai khoan

  const handleOptionChangeAccount = (value) => {
    filter['acc'] = value.value;
    if (value.value === '') {
      delete filter['acc'];
      setFilter({ ...filter });
    } else {
      setFilter({ ...filter });
    }
  };
  // bu tru
  const [minus, setMinus] = useState(false);
  useEffect(() => {
    filter['showMinusCol'] = minus;
    setFilter({ ...filter });
  }, [minus]);

  //vuot phut
  const [over, setOver] = useState(false);

  const contractList = dataInvoice && dataInvoice.annexContract;
  const renderDs = () => {
    if (dataInvoice && dataInvoice.agency === customerName1) {
      return (
        contractList &&
        contractList.map((v,idx) => {
          return (
            <>
              <DialogPreview marginLeft= {idx > 0 ? 10 : 0}
               filter={filter} type={selectType} contractName={v.name} />
            </>
          );
        })
      );
    } else {
      return (
        <>
          <DialogPreview filter={filter} type={selectType} />
        </>
      );
    }
  };
  
  return (
    <>
      <div className="card card-small mb-4">
        <div className="card-header border-bottom">
          <div className="d-flex items-center">
            <span className="m-0 font-bold">CDR</span>

            <div className="d-flex ml-auto align-items-center">
              {props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_CONTROL) && (
                <>
                  <FormCheckbox
                    className="mb-1"
                    id="acc_minus"
                    name="acc_minus"
                    checked={minus}
                    onChange={(e) => {
                      setMinus(e.currentTarget.checked);
                    }}>
                    Hiển thị cột bù trừ
                  </FormCheckbox>
                  <button
                    onClick={() => props.setOpenDebtModal(true)}
                    className="btn"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    disable={props.isDownloading ? true : undefined}>
                    Tạo công nợ
                  </button>
                  <button
                    onClick={() => {
                      props.setModalInvoice(true)
                      dispatch(getAgency());
                      }}
                    className="btn"
                    style={{ marginRight: 10 }}
                    disable={props.isDownloading ? true : undefined}>
                    Giấy đề nghị thanh toán (PDF)
                  </button>
                  <button
                    className={`btn ${statusControl.successPos === false ? 'disabled' : ''}`}
                    onClick={() => {
                      const newProducts = products && products.map((v) => v.name);
                      dispatch(
                        savePosControl({
                          id: props.groupId,
                          products: newProducts
                        })
                      )
                        .unwrap()
                        .then((res) => {
                          if (!res.error) {
                            dispatch(showMessageSuccess());
                          }
                        });
                    }}>
                    Lưu vị trí
                  </button>
                </>
              )}
              <div className="d-flex ml-auto">
                {props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_DETAIL) && (
                  <FormCheckbox
                    className="mb-1"
                    id="over"
                    name="over"
                    checked={over}
                    onChange={(e) => {
                      setOver(e.currentTarget.checked);
                      setFilter({ ...filter, ['over']: e.currentTarget.checked });
                    }}>
                    Vượt phút
                  </FormCheckbox>
                )}
              </div>
              {props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_CONTROL) && (dataInvoice && dataInvoice.agency !== customerName1) && (
               <>
               <ControlExcel filter={filter} type={selectType} />
                <ControlExcelNewCustomer filter={filter}/>
               </>

              )}
              {(props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_SUMMARY) ||
                props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_DETAIL) 
                ) && (
                <div
                  className="cursor-pointer btn"
                  onClick={() => downloadFile()}
                  disable={props.isDownloading ? true : undefined}
                  style={{ minWidth: 'inherit', width: 30, marginLeft: 10 }}>
                  {/* <a href="" className="btn" style={{ minWidth: 'inherit', width: 30 }}> */}

                  {!props.isDownloading ? (
                    <i className="fa fa-download" style={{ marginLeft: -2 }}></i>
                  ) : (
                    'Downloading...'
                  )}
                  {/* </a> */}
                </div>
              )}
            </div>

            {props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_ACCOUNT) && (
              <div className="ml-auto cursor-pointer">
                <a href={urlDownload} className="btn" style={{ minWidth: 'inherit', width: 30 }}>
                  <i className="fa fa-download" style={{ marginLeft: '-1px' }}></i>
                </a>
              </div>
            )}
          </div>
          {props.optionFilter.includes(OPTION_FILTER.DOWNLOAD_CONTROL) && (
            <div className="d-flex items-center ml-auto" style={{ 
              width:'100%', 
              marginTop: 10, justifyContent:'flex-end' }}>
              
              {renderDs()}
              <PreviewDsNew />
              <button style={{ marginLeft: 10, minWidth:120 }} className='btn' onClick={() => props.setModalCompare(true)}>So sánh dữ liệu</button>

              <button style={{ marginLeft: 10 }} className='btn' onClick={() => props.setModalUpload(true)}>Upload file đối soát</button>
              <button style={{ marginLeft: 10,with:30,minWidth:'inherit' }} className='btn' onClick={() => props.setOpenModalConfig(true)}>
                <i className="fa fa-cog" style={{marginLeft:'-2px'}}></i>
              </button>
              <ConfigControl
              setOpenModalConfig={props.setOpenModalConfig}
              openModalConfig={props.openModalConfig}
              >
                <label htmlFor="type_ds">Cột hiện thị trên đối soát: </label>
                <FormSelect
                  style={{ marginBottom: 10 }}
                  id="type_ds"
                  value={selectType}
                  onChange={(e) => {
                    setSelectType(parseInt(e.target.value));
                  }}>
                  {type_ds.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </FormSelect>
                <FormCheckbox
                    className="mb-1"
                    id="end_user_status"
                    name="timetoday"
                    checked={isToday}
                    onChange={e => {
                      setIsToday(e.currentTarget.checked)
                      dispatch(getInvoice({...dataInvoice,isToday: e.currentTarget.checked}));
                    }}>
                    Ngày hôm nay là ngày xuất đối soát
                  </FormCheckbox>
              </ConfigControl>
            </div>
          )}
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item p-3">
            <div className="row">
              <div className="col">
                <div className="form-row">
                  {props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI) && (
                    <div className="form-group col-md-3">
                      <label htmlFor="">Mã tài khoản</label>
                      <Select
                        onMenuScrollToBottom={(e) => addnewOption(e)}
                        isMulti
                        isSearchable={true}
                        loadOptions={accountSelectSearch}
                        options={accountSelectSearch}
                        cacheOptions
                        type="select"
                        id="accs"
                        name="accs"
                        placeholder={<div>Chọn</div>}
                        onChange={(e) => handleChangeOption(e, 'accs')}
                        onInputChange={(value) => setSearch(value)}
                        isLoading={loadingAccount}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  {props.optionFilter.includes(OPTION_FILTER.ACC_CODE) && (
                    <div className="form-group col-md-3">
                      <label htmlFor="">Mã tài khoản</label>
                      <Select
                        onMenuScrollToBottom={(e) => addnewOption(e)}
                        isSearchable={true}
                        loadOptions={accountSelectSearch}
                        options={accountSelectSearch}
                        cacheOptions
                        type="select"
                        id="accs"
                        name="accs"
                        placeholder={<div>Chọn</div>}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            ['accs']: e.value
                          });
                        }}
                        onInputChange={(value) => setSearch(value)}
                        isLoading={loadingAccount}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  {props.optionFilter.includes(OPTION_FILTER.ACC_CODE_SELECT_SEARCH) && (
                    <div className="form-group col-md-3">
                      <label htmlFor="">Mã tài khoản</label>
                      <Select
                        onMenuScrollToBottom={(e) => addnewOption(e)}
                        isSearchable={true}
                        loadOptions={accountSelectSearch}
                        options={accountSelectSearch}
                        cacheOptions
                        type="select"
                        id="name"
                        name="name"
                        placeholder={<div>Chọn</div>}
                        onChange={handleOptionChangeAccount}
                        onInputChange={(value) => setSearch(value)}
                        isLoading={loadingAccount}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  {props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI) && (
                    <div className="form-group col-md-3">
                      <label htmlFor="">Nhóm mã</label>
                      <Select
                        onMenuScrollToBottom={(e) => addnewOption2(e)}
                        isSearchable={true}
                        isMulti
                        loadOptions={groupNameOptions}
                        options={groupNameOptions}
                        cacheOptions
                        type="select"
                        id="groups"
                        name="groups"
                        placeholder={<div>Chọn</div>}
                        onChange={(e) => handleChangeOption(e, 'groups')}
                        onInputChange={(value) => setSearchGroup(value)}
                        isLoading={loadingGroup}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  {props.optionFilter.includes(OPTION_FILTER.GROUP) && (
                    <div className="form-group col-md-3">
                      <label htmlFor="">Mã KH</label>
                      <FormSelect
                        id="feInputState"
                        onChange={(e) => {
                          filter['group'] = e.target.value;
                          setFilter({ ...filter });
                        }}>
                        {groupNameOptions.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </FormSelect>
                    </div>
                  )}
                  {props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH) && (
                    <div className="form-group col-md-3">
                      <label htmlFor="">Nhóm mã</label>

                      <Select
                        onMenuScrollToBottom={(e) => addnewOption2(e)}
                        isSearchable={true}
                        loadOptions={groupNameOptions}
                        options={groupNameOptions}
                        cacheOptions
                        type="select"
                        id="name"
                        name="group"
                        placeholder={<div>Chọn</div>}
                        onChange={handleOptionChangeGroupMulti}
                        onInputChange={(value) => setSearchGroup(value)}
                        isLoading={loadingGroup}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  {props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH_CONTROL) && (
                    <div className="form-group col-lg-3" style={{ zIndex: 10 }}>
                      <label htmlFor="">Khách hàng</label>

                      <Select
                        onMenuScrollToBottom={(e) => addnewOption3(e)}
                        isSearchable={true}
                        loadOptions={groupSelectSearchControl}
                        options={groupSelectSearchControl}
                        cacheOptions
                        type="select"
                        id="name"
                        name="customer"
                        placeholder={<div>Chọn</div>}
                        onChange={handleOptionChangeGroupMulti}
                        onInputChange={(value) => setSearchCustomerGroup(value)}
                        isLoading={loadingGroupByCustomer}
                        styles={customStyles}
                      />
                    </div>
                  )}
                  <div className="form-group mr-2">
                    <label htmlFor="">Ngày tháng</label>
                    <InputGroup className={classes}>
                      <DatePicker
                        className={`${
                          props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI)
                            ? 'custom-datepicker'
                            : 'custom-datepicker style2'
                        }`}
                        value={startDate}
                        onChange={handleStartDateChange}
                        format="d/MM/y"
                        clearIcon={null}
                        calendarIcon={<i className="material-icons">&#xE916;</i>}
                      />
                      <DatePicker
                        className={`${
                          props.optionFilter.includes(OPTION_FILTER.ACC_CODE_MULTI)
                            ? 'custom-datepicker'
                            : 'custom-datepicker style2'
                        }`}
                        value={endDate}
                        onChange={handleEndDateChange}
                        format="d/MM/y"
                        clearIcon={null}
                        calendarIcon={<i className="material-icons">&#xE916;</i>}
                      />
                    </InputGroup>
                  </div>
                  {props.optionFilter.includes(OPTION_FILTER.TYPE_EXPORT) && (
                    <fieldset className="flex items-center">
                      <div className="mr-2 mt-4">
                        <FormRadio
                          className="mr-4"
                          checked={timeSelected === OPTION_TIME.VOICE_BRANCH}
                          onChange={() => {
                            onChangeTypeExport(OPTION_TIME.VOICE_BRANCH);
                          }}>
                          Voice Brand
                        </FormRadio>
                      </div>
                      <div className="mr-2 mt-4">
                        <FormRadio
                          className="mr-4"
                          checked={timeSelected === OPTION_TIME.CUSTOM}
                          onChange={() => {
                            onChangeTypeExport(OPTION_TIME.CUSTOM);
                          }}>
                          Sản phẩm
                        </FormRadio>
                      </div>
                      <div className="mr-2 mt-4">
                        <FormRadio
                          className="mr-4"
                          checked={timeSelected === OPTION_TIME.ALL}
                          onChange={() => {
                            onChangeTypeExport(OPTION_TIME.ALL);
                          }}>
                          Theo phút
                        </FormRadio>
                      </div>
                    </fieldset>
                  )}
                  <div className="form-group col-md-1">
                    {' '}
                    <label htmlFor="">&nbsp;</label>
                    <div className="flex">
                    <Button type="submit" className="btn form-control" onClick={handleSearch} style={{marginRight:10}}>
                      Tìm kiếm
                    </Button>
                    {props.optionFilter.includes(OPTION_FILTER.GROUP_SELECT_SEARCH_CONTROL) && (
                    <Button type="submit" className="btn form-control" onClick={handleSearch2}>
                      Tính toán lại
                    </Button>
                    )}
                    {props.optionFilter.includes(OPTION_FILTER.GROUP_MULTI) && (
                    <Button type="submit" className="btn form-control" onClick={()=>{
                      props.openModalAsyncAll(true)
                    }}>
                      Tính toán lại
                    </Button>
                    )}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Filter;
