import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import routes from '../src/routes/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './shards-dashboard/styles/shards-dashboards.1.1.0.min.css';
import 'react-tippy/dist/tippy.css';
const App = props => {
  return (
    <React.Suspense fallback={<></>}>
      <BrowserRouter>
        <>
          <Switch>
            {Object.keys(routes).map((key, index) => {
              const route = routes[key];
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={() => {
                    return (
                      <route.layout {...props}>
                        <route.route key={route.path} {...route} />
                      </route.layout>
                    );
                  }}></Route>
              );
            })}
          </Switch>
        </>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
