import cx from 'classnames';
import * as React from 'react';

export function HelpText(props) {
  const { status } = props;
  return (
    <span
      {...props}
      className={cx(
        'text-xs font-normal leading-4',
        status ? colorByStatus[status] : 'text-mediumgrey',
        props.className
      )}
    />
  );
}

const colorByStatus = {
  error: 'text-error-500',
  warning: 'text-yellow-800',
  success: 'text-success-800'
};
