import React, { useEffect, useState } from 'react';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';

import Modal, { ModalContent } from '../../../components/common/Modal';
import { Tooltip } from 'react-tippy';

import { delAccountBank, getAccountBank } from '../../redux/accountBank';
import DialogAccBank from './DialogAccBank';
import logo from '../../../images/sales-overview/no-product-image.jpg';
import DialogAsk from '../../../components/common/DialogAsk';
import { showMessageError, showMessageSuccess } from '../../redux/alert-message';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const AccBank = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState('');
  const [search, setSearch] = useState('');
  const [activeClear, setActiveClear] = useState(false);
  const accountBank = useSelector((state) => state.accountBank.accountbank);
  const isLoading = useSelector((state) => state.accountBank.loading);
  const status = useSelector((state) => state.accountBank);
  const pagination = usePaginationState();
  const [modalAsk, setModalAsk] = useState(false);
  // search

  useEffect(() => {
    dispatch(
      getAccountBank({
        offset: pagination.perPage * pagination.page - pagination.perPage,
        limit: pagination.perPage
        // name: search && search.trim()
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getAccountBank({
          offset: pagination.perPage * pagination.page - pagination.perPage,
          limit: pagination.perPage
          // name: search && search.trim()
        })
      );
    }
  }, [status.success]);

  // close modal

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  const deleteAgencyFn = () => {
    dispatch(delAccountBank({ id: data.id })).unwrap()
      .then(() => {
        setData('');
        setModalAsk(false);
        dispatch(showMessageSuccess());
      })
      .catch(() => {
        dispatch(showMessageError())
      });
 }


  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý tài khoản ngân hàng
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm
          </span>
        </h1>
        {/* <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              type="text"
              name="name"
              placeholder="Search theo tên"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getAccountBank({
                      offset: pagination.perPage * pagination.page - pagination.perPage,
                      limit: pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  e.preventDefault();
                  dispatch(
                    getAccountBank({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      name: search && search.trim()
                    })
                  );
                }
              }}
            />
            {activeClear && (
              <span onClick={() => setSearch('')} className="clear-search">
                <i className="fa fa-times"></i>
              </span>
            )}
          </div>
        </div> */}
        <Modal changeActive={setModalAsk} active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
            }}>
            <DialogAsk
              text={`Bạn có muốn xoá tài khoản ${data && data.account_number} - ${data && data.account_owner} - ${data && data.bank_name} ?`}
              handleCanel={setModalAsk}
              handleConfirm={deleteAgencyFn}
              textConfirm='Xóa'
              textCanel='Hủy'
              isDisabled={status.success}
            />
          </ModalContent>
        </Modal>
        <Modal
          changeActive={setOpenModal}
          active={openModal}
          id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent
            style={{ maxWidth: 500 }}
            heading={!data ? 'Thêm tài khoản ngân hàng' : 'Sửa'}
            onClose={handleCloseModal}>
            <DialogAccBank setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0">
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Tên ngân hàng
                  </th>
                  <th scope="col" className="border-0">
                    STK
                  </th>
                  <th scope="col" className="border-0">
                    Tên TK
                  </th>

                  <th scope="col" className="border-0">
                    QR Code
                  </th>

                  <th scope="col" className="border-0" style={{ width: '180px' }}>
                    Hành động
                  </th>
                </tr>
              </thead>

              <tbody>
                {accountBank &&
                  accountBank.results &&
                  accountBank.results.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.bank_name}</td>
                        <td>{item.account_number}</td>
                        <td>{item.account_owner}</td>
                        <td>
                        {item.qr_code && (
                          <img
                            className="rounded-circle logo"
                            src={item.qr_code}
                            alt="avatar"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = logo;
                            }}
                          />
                        ) }
                          
                        </td>
                        <td>
                          <Tooltip
                            position="top"
                            title={'Cập nhập'}
                            size={'small'}
                            animation={'shift'}>
                            <button
                              onClick={() => {
                                setOpenModal(true);
                                setData(item);
                              }}
                              className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </button>
                          </Tooltip>
                          <Tooltip
                                position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-del"
                                  onClick={() => {
                                    setModalAsk(true);
                            setData(item);
                                  }}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </Tooltip>
                          
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {accountBank && accountBank.results && accountBank.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((accountBank ? accountBank.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(
                  Math.ceil((accountBank ? accountBank.count : 0) / pagination.perPage)
                )
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default AccBank;
