import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'shards-react';
import moment from 'moment';
import { getHistoryUserSim, getUser } from '../redux/user';
import { Tooltip } from 'react-tippy';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const DialogHistoryUserSim = React.lazy(() => import('../../components/sim/DialogHistoryUserSim'));

const HistoryUserSim = () => {
  const dispatch = useDispatch();

  const { user, loading, loadinghistory, userSim } = useSelector(state => state.user);

  const [openModal, setOpenModal] = useState(false);

  const wrapperRef = useRef(null);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const closeModal = e => {
    if (e.target === wrapperRef.current) setOpenModal(false);
  };

  useEffect(() => {
    dispatch(getUser({}));
  }, []);

  const handleGetDetailOrder = username => {
    setOpenModal(true);
    dispatch(getHistoryUserSim(username));
  };

  return (
    <React.Suspense fallback={<></>}>
      <Container fluid className="main-content-container px-4">
        {openModal && user && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general pu-simhistory">
                <h3 className="pu-heading">Lịch sử</h3>
                <div className="popup-close" onClick={() => handleCloseModal()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogHistoryUserSim
                  setUDO={setOpenModal}
                  data={userSim}
                  loading={loadinghistory}
                />
              </div>
            </div>
          </>
        )}
        <h1 className="heading">Danh sách</h1>
        <Row>
          <Col>
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        Tên
                      </th>
                      <th scope="col" className="border-0">
                        Email
                      </th>
                      <th scope="col" className="border-0">
                        Ngày tạo
                      </th>
                      <th scope="col" className="border-0 text-center">
                        Đang hoạt động
                      </th>
                      <th scope="col" className="border-0 text-center">
                        Staff
                      </th>

                      <th scope="col" className="border-0 text-center">
                        SuperUser
                      </th>
                      <th scope="col" className="border-0 text-center">
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {user &&
                      user.list &&
                      user.list.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.username}</td>
                            <td>{item.email}</td>
                            <td>{moment(item.date_joined).format('YYYY-MM-DD')}</td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.is_active === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.is_staff === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.is_superuser === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                            <td className="text-center">
                              <Tooltip title={'Xem chi tiết'} position="top"
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-edit btn-theme"
                                  onClick={() => handleGetDetailOrder(item.username)}>
                                  <i className="fa fa-eye"></i>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default HistoryUserSim;
