import React, { useState, useEffect } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDirectionTelco } from '../../redux/direction-telco';
import { getGroupId } from '../../redux/group';
import CustomerTelco from '../customer/CustomerTelco';
const FormBack = React.lazy(() => import('../../../components/common/FormBack'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const UcGroup = React.lazy(() => import('./UcGroup'));
const TelcoDetail = React.lazy(() => import('./TelcoDetail'));

const GroupForm = () => {
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [dataTelco, setDataTelco] = useState()

  const [rowsTelco, setRowsTelco] = useState([]);
  const [rows] = useState([]);
  const telcoList = useSelector(state => state.directionTelco.list);
  const groupDetail = useSelector(state => state.groups.detail);
  const status = useSelector(state => state.groups);
  const statusSetupTelco = useSelector(state => state.setupTelco);
  const [telcoDetail, setTelcoDetail] = useState([])
  // get telco
  useEffect(() => {
    dispatch(getDirectionTelco());
    
  }, []);

  useEffect(() => {
    if (id) { 
      
      dispatch(getGroupId({id}))
      .unwrap()
      .then((res)=>{
        setTelcoDetail(res && res.telcos)
      })
    }
  }, [id]);

  

  useEffect(() => {
    if(groupDetail){
      setDataTelco([
        {...groupDetail.viettel, name:'Viettel'},
        {...groupDetail.mobifone, name:'Mobifone'},
        {...groupDetail.vinaphone, name:'Vinaphone'}
      ])
    }
  }, [groupDetail])


  useEffect(() => {
    if (status.success === true && id) {
      dispatch(getGroupId({ id }));
    }
  }, [status.success, id]);


  useEffect(() => {
    if (statusSetupTelco.success === true) {
      dispatch(getGroupId({ id }));
    }
  }, [statusSetupTelco.success]);

  const handleBack = () => {
    history.goBack()
  }

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <Spinner loading={status.loadingDetail} top={200} />

        <FormBack />

        {!id ? (
          <UcGroup detailTelco={rowsTelco} detail={rows} handleBack={handleBack} />
        ) : (
          <UcGroup detailTelco={rowsTelco} detail={rows} groupDetail={groupDetail} />
        )}
         {/* setup telco */}
        {id && dataTelco &&  <CustomerTelco groupId = {id} dataTelco={dataTelco} />}
        {/* telcos */}
        <h1 className="heading">Nhà mạng</h1>
        {!id ? (
          <TelcoDetail telcoList={telcoList} rows={rowsTelco} setRows={setRowsTelco} />
        ) : (
          <TelcoDetail
            telcoList={telcoList}
            rows={rowsTelco}
            setRows={setRowsTelco}
            dataEdit={groupDetail}
            telcoDetail={telcoDetail}
          />
        )}
      </Container>
    </React.Suspense>
  );
};

export default GroupForm;
