import React from 'react';
import FormLabel from '../../../components/common/FormLabel';
import { FormInput } from 'shards-react';
import DropFileInput from '../../../components/common/DropFileInput';
import { Link } from 'react-router-dom';
const Note = ({ rows, setRows }) => {
  // add note
  const columnsItem = ['description', 'file'];

  const handleChangeNote = (idx) => (e) => {
    const { name, value, type } = e.target;

    if (type === 'number') {
      rows[idx] = { ...rows[idx], [name]: parseInt(value) };
    } else {
      rows[idx] = { ...rows[idx], [name]: value };
    }

    setRows([...rows]);
  };

  const onFileChange = (idx, name, value) => {
    rows[idx] = { ...rows[idx], [name]: value };
    setRows([...rows]);
  };

  const handleAddRow = () => {
    let item = {};
    columnsItem.map((key) => (item = { ...item, [key]: key }));

    columnsItem.map((key) => {
      return (item[key] = '');
    });

    setRows([...rows, item]);
  };

  const handleRemoveRow = (idx) => {
    const filtered = rows.filter((el, index) => index !== idx);
    setRows(filtered);
  };

  return (
    <>
      <button onClick={() => handleAddRow()} className="btn mt-2">
        <span className="fa fa-plus-circle"></span> Thêm ghi chú
      </button>
      {rows && rows.length > 0 && (
        <div className="note-wrapper">
          {rows.map((v, idx) => {
            return (
              <div className="note-item">
                <div className="inside">
                  <FormLabel label="Nhập ghi chú">
                    <button
                      style={{ minWidth: 'inherit', width: 36 }}
                      className="btn btn-del"
                      onClick={() => handleRemoveRow(idx)}>
                      <i className="fa fa-times-circle"></i>
                    </button>
                  </FormLabel>

                  <FormInput
                    min={1}
                    type="text"
                    name="description"
                    value={rows[idx].description}
                    onChange={handleChangeNote(idx)}
                    className="form-control"
                    placeholder="Nhập ghi chú"
                  />

                  <DropFileInput
                    uploadType={'single'}
                    name="file"
                    type="file"
                    onFileChange={(files) => onFileChange(idx, 'file', files)}
                  />
                  {rows[idx].file && typeof rows[idx].file !== 'object' && (
                    <Link
                      to={{ pathname: `${process.env.REACT_APP_BASE_API}${rows[idx].file}` }}
                      className="btn btn-edit bg-theme"
                      style={{ minWidth: 'inherit', width: 36, marginTop: 10, marginLeft: 0 }}
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fa fa-file"></i>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Note;
