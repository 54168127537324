import React, { useEffect, useState } from 'react';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';
import Modal, { ModalContent } from '../../../components/common/Modal';
import { Tooltip } from 'react-tippy';
import { delCmcPhone, getCmcPhone } from '../../redux/cmc-phone';
import { formatMoney } from '../../helps/convertMoney';
import { showMessageError, showMessageSuccess } from '../../redux/alert-message';
import DialogAsk from '../../../components/common/DialogAsk';
const DialogCmcPhone = React.lazy(() => import('./DialogCmcPhone'));
const DialogCmcUpload = React.lazy(() => import('./DialogCmcUpload'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));

const CmcPhone = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [modalAsk, setModalAsk] = useState(false);
  const [data, setData] = useState('');

  const [search, setSearch] = useState(null);

  const cmcList = useSelector((state) => state.cmcphone.list);
  const cmcState = useSelector((state) => state.cmcphone);
  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getCmcPhone({
        offset: pagination.perPage * pagination.page - pagination.perPage,
        limit: pagination.perPage,
        phone: search && search.trim()
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (cmcState.success === true) {
      dispatch(
        getCmcPhone({
          offset: !search ? pagination.perPage * pagination.page - pagination.perPage : 0,
          limit: pagination.perPage,
          phone: search && search.trim()
        })
      );
    }
  }, [cmcState.success]);
  // close modal

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  // upload

  const [openModalUpload, setOpenModalUpload] = useState(false);

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  const handleDel = () => {
    dispatch(delCmcPhone({ id: data.id }))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
        setModalAsk(false);
        setData();
      })
      .catch(() => {
        dispatch(showMessageError());
      });
  };

  return (
    <React.Suspense>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý đầu số 1900
          <div className="btn-g">
            <Tooltip title={'Tải lên'} position="bottom" size={'small'} animation={'shift'}>
              <span className="btn" onClick={() => setOpenModalUpload(true)} style={{ top: -3 }}>
                <i className="fa fa-upload"></i>
              </span>
            </Tooltip>
          </div>
          <span
            className="btn"
            style={{ marginLeft: 5, height: 36, lineHeight: '36px' }}
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm Cmc
          </span>
        </h1>
        <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              disabled={cmcState.loading}
              type="text"
              name="name"
              placeholder="Search theo tên"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getCmcPhone({
                      offset: pagination.perPage * pagination.page - pagination.perPage,
                      limit: pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  e.preventDefault();
                  dispatch(
                    getCmcPhone({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      phone: search && search.trim()
                    })
                  );
                }
              }}
            />

            <span
              onClick={() => {
                setSearch();
              }}
              className="clear-search">
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>

        <Modal
          changeActive={setOpenModal}
          active={openModal}
          id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent heading={!data ? 'Thêm' : 'Sửa'} onClose={handleCloseModal}>
            <DialogCmcPhone setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>

        <Modal active={openModalUpload} id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent
            style={{ maxWidth: 500 }}
            heading={!data ? 'Thêm' : 'Sửa'}
            onClose={handleCloseModalUpload}>
            <DialogCmcUpload setUDO={setOpenModalUpload} />
          </ModalContent>
        </Modal>
        <Modal active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
              setData('');
            }}>
            <DialogAsk
              handleCanel={setModalAsk}
              dataEdit={data}
              text={`Bạn có muốn xoá ${data && data.phone}`}
              handleConfirm={handleDel}
              isDisabled={cmcState.success}
            />
          </ModalContent>
        </Modal>
        {cmcState.loading ? (
          <Spinner loading={cmcState.loading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0" style={{ width: '40px' }}>
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    SĐT
                  </th>
                  <th scope="col" className="border-0">
                    Giá
                  </th>

                  <th scope="col" className="border-0">
                    Hành động
                  </th>
                </tr>
              </thead>

              <tbody>
                {cmcList.results &&
                  cmcList.results.length > 0 &&
                  cmcList.results.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.phone}</td>
                        <td>{formatMoney(item.price)}</td>
                        <td>
                          <Tooltip
                            position="top"
                            title={'Cập nhập'}
                            size={'small'}
                            animation={'shift'}>
                            <button
                              onClick={() => {
                                setOpenModal(true);
                                setData(item);
                              }}
                              className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </button>
                          </Tooltip>
                          <Tooltip position="top" title={'Xoá'} size={'small'} animation={'shift'}>
                            <span
                              className="btn btn-del"
                              onClick={async () => {
                                setModalAsk(true);
                                setData(item);
                              }}>
                              <i className="fa fa-trash"></i>
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {cmcList && cmcList.results && cmcList.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((cmcList ? cmcList.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(Math.ceil((cmcList ? cmcList.count : 0) / pagination.perPage))
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default CmcPhone;
