import React, { useEffect, useState } from 'react';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';
import { Link } from 'react-router-dom';
import Modal, { ModalContent } from '../../../components/common/Modal';
import { Tooltip } from 'react-tippy';

import { delAccountBank } from '../../redux/accountBank';

import DialogAsk from '../../../components/common/DialogAsk';
import { showMessageError, showMessageSuccess } from '../../redux/alert-message';
import { delDebt, getDebt } from '../../redux/deb';
import DialogDebt from './DialogDebt';
import moment from 'moment';
import { formatMoney } from '../../helps/convertMoney';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const Debt = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState('');
  const [search, setSearch] = useState('');
  const [activeClear, setActiveClear] = useState(false);
  const list = useSelector((state) => state.debt.debts);
  const isLoading = useSelector((state) => state.debt.loading);
  const status = useSelector((state) => state.debt);
  const profile = useSelector((state) => state.auth.profile);
  const pagination = usePaginationState();
  const [modalAsk, setModalAsk] = useState(false);

  // search

  useEffect(() => {
    dispatch(
      getDebt({
        offset: pagination.perPage * pagination.page - pagination.perPage,
        limit: pagination.perPage,
        search: search && search.trim()
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getDebt({
          offset: pagination.perPage * pagination.page - pagination.perPage,
          limit: pagination.perPage,
          search: search && search.trim()
        })
      );
    }
  }, [status.success]);

  // close modal

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  const deleteAgencyFn = () => {
    dispatch(delDebt({ id: data.id }))
      .unwrap()
      .then(() => {
        setData('');
        setModalAsk(false);
        dispatch(showMessageSuccess());
      })
      .catch(() => {
        dispatch(showMessageError());
      });
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý công nợ
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm công nợ
          </span>
        </h1>
        <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              disabled={isLoading}
              type="text"
              name="name"
              placeholder="Search theo code"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getDebt({
                      offset: pagination.perPage * pagination.page - pagination.perPage,
                      limit: pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  e.preventDefault();
                  dispatch(
                    getDebt({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      search: search && search.trim()
                    })
                  );
                }
              }}
            />
            {activeClear && (
              <span onClick={() => setSearch('')} className="clear-search">
                <i className="fa fa-times"></i>
              </span>
            )}
          </div>
        </div>
        <Modal changeActive={setModalAsk} active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
            }}>
            <DialogAsk
              text={`Bạn có muốn xoá công nợ ${data && data.code}`}
              handleCanel={setModalAsk}
              handleConfirm={deleteAgencyFn}
              textConfirm="Xóa"
              textCanel="Hủy"
              isDisabled={status.success}
            />
          </ModalContent>
        </Modal>
        <Modal
          changeActive={setOpenModal}
          active={openModal}
          id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent
            className="pu-debt"
            heading={!data ? 'Thêm công nợ' : 'Sửa'}
            onClose={handleCloseModal}>
            <DialogDebt setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0">
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Mã công nợ
                  </th>
                  <th scope="col" className="border-0">
                    Tên khách hàng
                  </th>
                  <th scope="col" className="border-0">
                    Người tạo
                  </th>
                  <th scope="col" className="border-0">
                    Ngày tạo công nợ
                  </th>
                  <th scope="col" className="border-0">
                    Ngày nhắc nợ
                  </th>
                  <th scope="col" className="border-0 text-right">
                    Tổng số tiền
                  </th>

                  <th scope="col" className="border-0">
                    TK Thanh toán
                  </th>
                  <th scope="col" className="border-0" style={{ width: '120px' }}>
                    Ghi chú
                  </th>

                  <th scope="col" className="border-0 text-center" style={{ width: '120px' }}>
                    Trạng thái công nợ
                  </th>

                  <th scope="col" className="border-0 text-center" style={{ width: '180px' }}>
                    Hành động
                  </th>
                </tr>
              </thead>

              <tbody>
                {list &&
                  list.results &&
                  list.results.map((item, idx) => {
                    const followerArr = item.followers.map((v) => v.username);

                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.code}</td>
                        <td>{item.customer && item.customer.name}</td>
                        <td>{item.created_by && item.created_by.username}</td>
                        <td>
                          {item.created_date && moment(item.created_date).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          {item.reminder_day && moment(item.reminder_day).format('DD/MM/YYYY')}
                        </td>
                        <td className='text-right'>{item.total && formatMoney(item.total)}</td>

                        <td>
                          {item.transaction_account && (
                            <>
                              <div className="dflex">
                                <label className="font-bold">STK:</label>{' '}
                                {`${
                                  item.transaction_account.account_number !== null
                                    ? item.transaction_account.account_number
                                    : ''
                                }`}
                              </div>

                              <div className="dflex">
                                <label className="font-bold">Tên TK:</label>{' '}
                                {`${
                                  item.transaction_account.account_owner !== null
                                    ? item.transaction_account.account_owner
                                    : ''
                                }`}
                              </div>

                              <div className="dflex">
                                <label className="font-bold">Ngân hàng:</label>{' '}
                                {`${
                                  item.transaction_account.bank_name !== null
                                    ? item.transaction_account.bank_name
                                    : ''
                                }`}
                              </div>
                            </>
                          )}
                        </td>
                        <td>
                          {item.attachments &&
                            item.attachments.map((attach, idx) => {
                              return (
                                <>
                                  <div>
                                    <span style={{ fontStyle: 'italic' }}>Ghi chú: </span>
                                    {attach.description}
                                  </div>
                                  <Tooltip
                                    key={idx}
                                    title={attach.file}
                                    position="top"
                                    size={'small'}
                                    animation={'shift'}>
                                    <Link
                                      to={{
                                        pathname: `${process.env.REACT_APP_BASE_API}${attach.file}`
                                      }}
                                      className="btn btn-edit bg-theme"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      <i className="fa fa-file"></i>
                                    </Link>
                                  </Tooltip>
                                </>
                              );
                            })}
                        </td>
                        <td className="text-center">
                        {item.status ? 'Đã thanh toán' :'Chưa thanh toán'}
                        </td>
                        <td className="text-center">
                          {(profile.username === (item.created_by && item.created_by.username) ||
                            profile.username.includes(followerArr)) && (
                            <>
                              <Tooltip
                                title={'Xem chi tiết'}
                                position="top"
                                size={'small'}
                                animation={'shift'}>
                                <Link to={`/accountant/debt/${item.id}`} className="btn btn-edit">
                                  <i className="fa fa-edit"></i>
                                </Link>
                              </Tooltip>

                              <Tooltip
                                position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-del"
                                  onClick={() => {
                                    setModalAsk(true);
                                    setData(item);
                                  }}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {list && list.results && list.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((list ? list.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(Math.ceil((list ? list.count : 0) / pagination.perPage))
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Debt;
