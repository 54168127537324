const DASHBOARD = '/';
const SIGN_IN = '/login';
export const routeConstants = {
  DASHBOARD,
  SIGN_IN
};

export const MONTH = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', 12];
export const YEAR = [2021,2022,2023, 2024]
export const TIMEOUT = 50000;
export const toast_type = {
  error: 'error',
  success: 'success'
};

export const trangthaiList = [
  { id: 1, name: 'Đang chờ' },
  { id: 2, name: 'Đang xử lý' },
  { id: 3, name: 'Đã duyệt' },
  { id: 4, name: 'Từ chối' },
  { id: 5, name: 'Đã đóng' }
];

export const priorityList = [
  { id: 1, name: 'Khẩn cấp' },
  { id: 2, name: 'Cao' },
  { id: 3, name: 'Trung bình' },
  { id: 4, name: 'Thấp' }
];

export const hotkeyList = [
  '081*',
  '082*',
  '083*',
  '086*',
  '096*',
  '032*',
  '033*',
  '034*',
  '035*',
  '089*',
  '070*',
  '092*',
  '056*',
  '*666',
  '*123'
];
export const phoneRegExp = /([84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;
export const expenseTabs = [
  {
    name: 'Danh sách',
    key: 0,
  },

  {
    name: 'Báo cáo chi phí',
    key: 1,
  },
]

export const salesTabs = [
  {
    name: 'Danh sách',
    key: 0,
  },

  {
    name: 'Báo cáo doanh thu',
    key: 1,
  },
]

export const saleOrderTabs = [
  {
    name: 'Thêm danh sách sản phẩm',
    key: 0,
  },
]

export const expenseOrderTabs = [
  {
    name: 'Chi tiết đơn hàng',
    key: 0,
  },
]

export const simLockTabs = [
  {
    name: 'Danh sách',
    key: 0,
  },

  {
    name: 'Thống kê',
    key: 1,
  },
]

export const simLockInforTabs = [
  {
    name: 'Đại lý',
    key: 0,
  },

  {
    name: 'Khách hàng',
    key: 1,
  },
  {
    name: 'Danh sách số',
    key: 2,
  },
]

export const assetDocumentTabs = [
  {
    name: 'Loại tài sản',
    key: 0,
  },
  {
    name: 'Trạng thái tài sản',
    key: 1,
  },

]

export const errorMessage = {
  ACCODE_NOT_IN_GROUP :'AccCode chưa gán nhóm mã',
  GROUP_HAVE_NO_ACCOUNT:'Nhóm mã chưa gán accCode',
  IN_VALID_GROUP_PARAM:'Nhóm mã không hợp lệ',
  ACCOUNT_GROUP_REQUIRED:'Nhóm hoặc mã không hợp lệ'
}
