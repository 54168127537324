import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';;
import to_vietnamese from '../../helps/transNumText';
import { formatMoney } from '../../helps/convertMoney';
import ControlExcelNewCustomer from './excel-customer-new';
const DialogPreviewNew = (props) => {
    const [totalCost, setTotalCost] = useState(0);
    const [totalVat, setTotalVat] = useState(0);


    const data = useSelector((state) => state.control.invoice);
    const control = useSelector((state) => state.control.control);

    const fromDate = data && data.date && moment(data.date).format('DD/MM/YYYY')
    const toDate = data && data.date && moment(data.date).add(5, 'days').format('DD/MM/YYYY')
    const signInvoice = data.signInvoice
    const contractName = props.contractName || data && data.annexContract && data.annexContract.length > 0 && data.annexContract.map((v) => v.name) || ''

    const invoiceName =
        (data && data.nameInvoice) || (control && control.group && control.group.contract) || '';
    const type_of_control = (data && data.type_of_control) ? data.type_of_control : 'Contact Center'
    const customerName = data && (data.nameCustomer || data.value);
    const products = data && data.productControl;


    const line1 = 'THÔNG BÁO PHÍ SỬ DỤNG DỊCH VỤ THÁNG …';

    const line2 = `Dịch vụ: ${type_of_control}`;
    // skipeed line 3 + 4
    const line5 = 'CÔNG TY CỔ PHẦN VIỄN THÔNG TIN HỌC VIỆT NAM'

    const line6 = 'Địa chỉ: Số 74 ngõ 297 Đường Trần Cung, Cổ Nhuế 1, Bắc Từ Liêm, Hà Nội';

    const line7 = 'Sô tài khoản: 0581161668888 Tại: CN Đống Đa – Ngân hàng TMCP Quân Đội';
    // skipped line 8
    const line9 = `Kính gửi: ${customerName}`;
    const line10 = 'Địa chỉ: Số …';

    const line11 = `Số hợp đồng : ${contractName ? `PL số ${contractName} - ` : ''} HĐ: ${invoiceName}         Ngày ký hợp đồng : ${signInvoice ? signInvoice : ''}`
    const line12 = 'Thời điểm chốt công nợ :';
    const line13 = `Từ: ${fromDate}`;
    const line14 = `Đến ngày: ${toDate}`
    const line15 = 'Các khoản chi tiết như sau:';
    //skipped line 16

    const lastLine = [
        'Lưu ý :', 'Thông báo này không được coi là thông báo chứng tỏ Quý khách đã thanh toán cước',
        'Thông báo cước được gửi tới khách hàng qua email, sau 2 ngày kể từ ngày VNTEL gửi TBC nếu khách hàng không có khiếu nại, thì đây sẽ là căn cứ để VNTEL xuất hóa đơn cho khách hàng.', 'Quý khách vui lòng thanh toán đầy đủ số tiền cước để đảm bảo liên lạc được thông suốt.',
        'Để biết chi tiết cước sử dụng, xin vui lòng liên hệ hot line: 19000102 .'
    ]



    // product base
    const dataProduct = () => {
        return (
            data &&
            data.productControl &&
            data.productControl.filter(
                (m) => m.qty !== 0 && m.price !== 0)
        )
    };



    const calculateTotal = (qty, price, ck) => {
        if (!(qty && price)) {
            return 0;
        }
        let total = price * qty * ((100 - ck) / 100);
        // if (v.minus) {
        //   return Math.abs(total) * -1
        // }
        return total;
    };




    useEffect(() => {
        if (products) {
            let total = 0;
            products.map((item) => {
                let cost = Math.round(item.price * Math.round(item.qty) * ((100 - item.ck) / 100));
                if (Boolean(item.hasOwnProperty('minus') && item.minus)) {
                    cost = -Math.abs(cost);
                }
                return (total += cost);
            });

            setTotalCost(total);
        } else {
            setTotalCost(0);
            setTotalVat(0);
        }
    }, [products]);

    useEffect(() => {
        if (control && control.vat) {
            setTotalVat(Math.round(totalCost * (control && control.vat / 100)));
        }
    }, [totalCost, control]);

    return (

        <div className="content">
        <ControlExcelNewCustomer />

            <div className="text-center fbold">{line1}</div>
            <div className="text-center">{line2}</div>

            <div className="fbold">{line5}</div>
            <div className="">{line6}</div>
            <div>{line7}</div>
            <div className='fbold'>{line9}</div>
            <div>{line10}</div>
            <div>{line11}</div>
            <div>{line12}</div>
            <div>{line13}</div>
            <div>{line14}</div>
            <div className='fbold'>{line15}</div>
            <div className="bg-header">
                <div className="view w-17 p-4-8 textCenter">
                    <div className="fbold">STT</div>
                </div>
                <div className="view w-48 p-4-8 textCenter">
                    <div className="fbold">Diễn giải</div>
                </div>
                <div className="view w-20 p-4-8 textCenter">
                    <div className="fbold">Đơn giá</div>
                </div>
                <div className="view w-20 p-4-8 textCenter">
                    <div className="fbold">Số lượng</div>
                </div>
                <div className="view w-20 p-4-8 textCenter">
                    <div className="fbold">Thành tiền</div>
                </div>
            </div>
            {dataProduct().length > 0 &&
                dataProduct().map((v, idx) => {
                    return (
                        <div className="bg-header rowChild">
                            <div className="view w-17 p-4-8 textCenter noneBrTop">{idx + 1}</div>

                            <div className="view w-48 p-4-8 noneBrTop">{v.name}</div>
                            <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
                            <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>

                            <div className="view w-20 p-4-8 textRight noneBrTop">
                                {formatMoney(calculateTotal(v.qty, v.price, v.ck), '')}
                            </div>
                        </div>
                    );
                })}
            <div className="bg-header rowChild">
                <div className="view w-17 p-4-8 textCenter noneBrTop">
                    {dataProduct(props.type).length + 1}
                </div>

                <div className="view w-48 p-4-8 noneBrTop view-flex" style={{ width: '104%' }}>Cộng cước dịch vụ</div>
                <div className="view w-20 p-4-8 textRight noneBrTop">
                    {formatMoney(totalCost, '')}
                </div>

            </div>
            <div className="bg-header rowChild">
                <div className="view w-17 p-4-8 textCenter noneBrTop">
                    {dataProduct(props.type).length + 2}
                </div>

                <div className="view w-48 p-4-8 noneBrTop view-flex" style={{ width: '104%' }}>Thuế GTGT ({control && control.vat}%)</div>
                <div className="view w-20 p-4-8 textRight noneBrTop">
                    {formatMoney(totalVat, '')}
                </div>

            </div>
            <div className="bg-header rowChild">
                <div className="view w-17 p-4-8 textCenter noneBrTop">
                    {dataProduct(props.type).length + 3}
                </div>

                <div className="view w-48 p-4-8 noneBrTop view-flex" style={{ width: '104%' }}>Tổng cước</div>
                <div className="view w-20 p-4-8 textRight noneBrTop">
                    {formatMoney(totalCost + totalVat, '')}
                </div>

            </div>

            <div className="bg-header rowChild">

                <div className="view w-17 p-4-8 noneBrTop view-flex" style={{ width: '44%' }}>Số tiền bằng chữ	</div>
                <div className="view w-20 p-4-8 textCenter noneBrTop" style={{ width: '56%' }}>
                    {data && data.totalControl && to_vietnamese(data.totalControl)}
                </div>

            </div>

            <div className="bg-header rowChild">

                <div className="view w-17 p-4-8 noneBrTop view-flex" style={{ width: '44%' }}>Số tiền nạp phát sinh trong tháng				</div>
                <div className="view w-20 p-4-8 textCenter noneBrTop" style={{ width: '56%' }}>
                </div>

            </div>


            <div className="bg-header rowChild">

                <div className="view w-17 p-4-8 noneBrTop view-flex" style={{ width: '44%' }}>Số tiền còn lại cuối kỳ			</div>
                <div className="view w-20 p-4-8 textCenter noneBrTop" style={{ width: '56%' }}>
                </div>

            </div>

            <div className="bg-header rowChild">

                <div className="view w-17 p-4-8 noneBrTop view-flex" style={{ width: '100%' }}>Đề nghị quý khách thanh toán trước ngày: …					</div>


            </div>

            {
                lastLine.map(v => {
                    return (
                        <div className=''>{v}</div>
                    )
                })
            }
            <div className="bg-header rowChild">
                <div className="w-50 textCenter fbold"></div>
                <div className="w-50 textCenter fbold">{line5}</div>
            </div>
        </div>

    );
};

export default DialogPreviewNew;
