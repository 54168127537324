import React from 'react'
import useVideocall from './useVideocall'
import {
    Container,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DatePicker,
    Button,

} from 'shards-react';
import moment from 'moment';
import classNames from 'classnames';
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));

const Report = () => {
    const {
        success, loading, list, pagination, results, filterChart, setFilterChart, getReport, getExcelAll,myInputId,
    } = useVideocall()
    
    const classes = classNames('', 'd-flex', 'my-auto', 'date-range');

    return (
        <React.Suspense fallback={<Spinner></Spinner>}>
            <input style={{display:'none'}}  id={myInputId} value={`DanhSachRar_${filterChart.from && moment(filterChart.from).format('YYYY-MM-DD')}_${filterChart.to && moment(filterChart.to).format('YYYY-MM-DD')}`} />
            <Container fluid className="main-content-container border-t">
                <h1 className="heading">
                    Thống kê cuộc gọi RAR

                </h1>
                <div className="flex filter-chart">

                    <div className="form-group">
                        <label htmlFor="">Ngày tháng</label>
                        <InputGroup className={classes}>
                            <DatePicker
                                size="sm"
                                selected={filterChart.from}
                                onChange={e => {
                                    filterChart.from = e,
                                        setFilterChart({ ...filterChart })
                                }}
                                placeholderText="Ngày bắt đầu"
                                dropdownMode="select"
                                className="text-center"
                            />
                            <DatePicker
                                size="sm"
                                selected={filterChart.to}
                                onChange={e => {
                                    filterChart.to = e,
                                        setFilterChart({ ...filterChart })
                                }}
                                placeholderText="Ngày kết thúc"
                                dropdownMode="select"
                                className="text-center"
                            />
                            <InputGroupAddon type="append">
                                <InputGroupText>
                                    <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div style={{ marginTop: '30px', marginLeft: 5 }}>
                        <Button type="submit" className="btn" onClick={() => getReport()} >
                            Tìm kiếm
                        </Button>
                    </div>
                    <div style={{ marginTop: '30px', marginLeft: 5 }}>
                        <Button type="submit" className={`btn ${success === false ? 'disabled' : ''}`} onClick={() => getExcelAll()} >
                            Tải file
                        </Button>
                    </div>
                </div>
                {loading ? (
                    <Spinner loading={loading} top={100} />
                ) : (

                    <div className="table-wrapper">
                        <table className="table mb-0">
                            <thead className="bg-white">
                                <tr>
                                    <th scope="col" className="border-0" style={{ width: 150 }}>
                                        Tài khoản
                                    </th>
                                    <th scope="col" className="border-0 text-right">
                                        Kết quả xác thực
                                    </th>
                                    <th scope="col" className="border-0 text-center">
                                        Có hình ảnh
                                    </th>
                                    <th scope="col" className="border-0">
                                        Ngày gọi
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {results &&
                                    results.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{item.user}</td>
                                                <td className='text-right'>
                                                    {item.accuracy}
                                                </td>
                                                <td className="text-center">
                                                    <span
                                                        className={`fa ${item.include_image === true ? 'fa-check' : 'fa-times'
                                                            }`}></span>
                                                </td>
                                                <td>{item.created_at && moment(item.created_at).format('DD/MM/YYYY')}</td>


                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {results && results.length === 0 && (
                            <Empty description="Không có dữ liệu" />
                        )}
                        <Pagination
                            currentPage={pagination.page}
                            pageSize={pagination.perPage}
                            lastPage={Math.ceil((list ? list.count : 0) / pagination.perPage)}
                            onChangePage={pagination.setPage}
                            onChangePageSize={pagination.setPerPage}
                            onGoToLast={() =>
                                pagination.setPage(
                                    Math.ceil((list ? list.count : 0) / pagination.perPage)
                                )
                            }
                        />
                    </div>

                )}
            </Container>
        </React.Suspense>
    )
}

export default Report