import React from 'react'
import { Modal,ModalHeader,ModalBody } from 'shards-react';

const ConfigControl = ({...props}) => {
  return (
    <Modal
    open={props.openModalConfig}
    toggle={() => {
      props.setOpenModalConfig(!props.openModalConfig);
    }}>
    <ModalHeader className="font-bold">Cấu hình đối soát</ModalHeader>
    <ModalBody>
      {props.children}
      
    </ModalBody>
  </Modal>
  )
}

export default ConfigControl