import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { OPTION_FILTER } from '../../components/common/Filter';
import { getDetail } from '../redux/detail';
import { getDirectionTelco } from '../redux/direction-telco';
import { showMessageError } from '../redux/alert-message';
import { errorMessage } from '../../constants';

const Filter = React.lazy(() => import('../../components/common/Filter'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));

const Detail = () => {
  const dispatch = useDispatch();
  const detail = useSelector(state => state.detail.detail);
  const isLoading = useSelector(state => state.detail.loading);
  const [telcoList, setTelcoList] = useState([]);
  const isDownloading = useSelector(state => state.detail.downloading);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    dispatch(getDirectionTelco());
  }, []);

  useEffect(() => {
    if (detail && detail.totals) {
      let tmpArray = [];
      Object.keys(detail.totals).map(key => tmpArray.push({ key, value: detail.totals[key] }))
      setTelcoList(tmpArray)
    }
  }, [detail]);

  const handleSearch = filter => {
    setFilter(filter);
    dispatch(getDetail(filter))
      .then((res) => {
        if (res.payload.error) {
          dispatch(showMessageError(errorMessage[res.payload.error_type]))
        }
      })
  };

  const toCapitalizerCase = (txt) => {
    if (!txt || !txt.length) {
      return ''
    }
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  }


  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Chi tiết</h1>
        <Spinner loading={isDownloading} top="30%" />
        <Row>
          <Col>
            <Filter
              onSearch={handleSearch}
              optionFilter={[
                OPTION_FILTER.GROUP_SELECT_SEARCH,
                OPTION_FILTER.DATE_RANGE,
                detail.totals ? OPTION_FILTER.DOWNLOAD_DETAIL : ''
              ]}
              isNotDefault={true}
              isDownloading={isDownloading}
            />
            {(filter.acc || filter.group) && <Spinner loading={isLoading} top="150%" />}
            <div className="table-wrapper">
              {detail.totals && (<table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Nhà mạng
                    </th>
                    {telcoList.map(telco => (
                      <th key={telco.key} scope="col" className="border-0">
                        {toCapitalizerCase(telco.key)}
                      </th>
                    ))}
                  </tr>
                </thead>
                {detail.totals && (
                  <tbody>
                    <tr>
                      <td>Thời gian</td>
                      {telcoList.map(telco => {
                        return <td key={telco.key}>{detail.totals[`${telco.key.toLowerCase()}`]} (s)</td>;
                      })}
                    </tr>
                  </tbody>
                )}
              </table>)}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default Detail;
