import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  
  loading: false
};

const ACTION = {
  GET_ACCOUNT_BANK: 'account-bank/getAccountBank',
  PUT_ACCOUNT_BANK: 'account-bank/putAccountBank',
  POST_ACCOUNT_BANK: 'account-bank/postAccountBank',
  DEL_ACCOUNT_BANK: 'account-bank/delAccountBank',
  
};

export const getAccountBank = createAsyncThunk(ACTION.GET_ACCOUNT_BANK, async body => {
  return axiosInstance.get('/api/account-bank/', { params: body });
});

export const postAccountBank = createAsyncThunk(
    ACTION.POST_ACCOUNT_BANK,
    async (body, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`/api/account-bank/`, body,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const putAccountBank = createAsyncThunk(
    ACTION.PUT_ACCOUNT_BANK,
    async ({ id, body, rejectWithValue }) => {
      try {
        const response = await axiosInstance.put(`/api/account-bank/${id}/`, body,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );
  export const delAccountBank = createAsyncThunk(
    ACTION.DEL_ACCOUNT_BANK,
    async (body) => {
      return axiosInstance.delete(`/api/account-bank/${body.id}/`);
    }
  );
const accountBankSlice = createSlice({
  name: 'account-bank',
  initialState: initialState,

  extraReducers: {
    [getAccountBank.pending.toString()]: state => {
      state.loading = true;
    },
    [getAccountBank.rejected.toString()]: state => {
      state.loading = false;
    },
    [getAccountBank.fulfilled.toString()]: (state, action) => {
      state.loading = false;
    
      state.accountbank = action.payload;
    },
    [putAccountBank.pending.toString()]: state => {
        state.success = false;
      },
      [putAccountBank.rejected.toString()]: state => {
        state.success = true;
      },
      [putAccountBank.fulfilled.toString()]: (state, action) => {
        state.success = true;
      },
      [delAccountBank.pending.toString()]: state => {
        state.success = false;
      },
      [delAccountBank.rejected.toString()]: state => {
        state.success = true;
      },
      [delAccountBank.fulfilled.toString()]: (state, action) => {
        state.success = true;
      },
      [postAccountBank.pending.toString()]: state => {
        state.success = false;
      },
      [postAccountBank.rejected.toString()]: state => {
        state.success = true;
      },
      [postAccountBank.fulfilled.toString()]: (state, action) => {
        state.success = true;
      },
    
  }
});
const { reducer: accountBankReducer } = accountBankSlice;
export default accountBankReducer;
