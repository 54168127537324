import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  positionList: {},
  loading: false,
};

const ACTION = {
  GET_POSITIONS: 'position/getPositions',
  CREATE_POSITION: 'position/createPosition',
  UPDATE_POSITION: 'position/updatePosition'
};

export const getPositions = createAsyncThunk(ACTION.GET_POSITIONS, async body => {
  return axiosInstance.get('/api/positions/', { params: body });
});

export const updatePosition = createAsyncThunk(ACTION.UPDATE_POSITION, async body => {
  return axiosInstance.put(`/api/positions/${body.id}/`, body);
});

export const createPosition = createAsyncThunk(ACTION.CREATE_POSITION, async body => {
  return axiosInstance.post('/api/positions/', body);
});

const positionSlice = createSlice({
  name: 'position',
  initialState: initialState,
  extraReducers: {
    [getPositions.pending.toString()]: state => {
      state.loading = true;
    },
    [getPositions.rejected.toString()]: state => {
      state.loading = false;
    },
    [getPositions.fulfilled.toString()]: (state, action) => {
      state.loading = false;
     
      state.positionList = action.payload;
    },
    [createPosition.pending.toString()]: state => {
      state.success = false;
    },
    [createPosition.rejected.toString()]: state => {
      state.success = true;
    },
    [createPosition.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [updatePosition.pending.toString()]: state => {
      state.success = false;
      
    },
    [updatePosition.rejected.toString()]: state => {
      state.success = true;
    },
    [updatePosition.fulfilled.toString()]: (state, action) => {
      state.success = true;
      // state.customerList = action.payload;
    }
  }
});
export const selectStatus = (state => state.position)
const { reducer: positionReducer } = positionSlice;
export default positionReducer;
