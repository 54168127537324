import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  ratePerDay: {}
};

const ACTION = {
  GET_RATE_PER_DAY: 'ratePerDay/getRatePerDay'
};

export const getRatePerDay = createAsyncThunk(ACTION.GET_RATE_PER_DAY, async body => {
  return axiosInstance.get('/restapi/export/rate-per-day', {
    params: body
  });
});

const ratePerDaySlice = createSlice({
  name: 'ratePerDay',
  initialState: initialState,
  extraReducers: {
    [getRatePerDay.pending.toString()]: state => {
      state.loading = true;
    },
    [getRatePerDay.rejected.toString()]: state => {
      state.loading = false;
    },
    [getRatePerDay.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.ratePerDay = action.payload;
    }
  }
});

const { reducer: ratePerDayReducer } = ratePerDaySlice;
export default ratePerDayReducer;
