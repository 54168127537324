import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  summary: {},
  file: undefined,
  downloading: false
  // loading: false
};

const ACTION = {
  GET_SUMMARY: 'summary/getSummary',
  ASYNC_AUTO: 'summary/async-auto',
  DOWNLOAD_SUMMARY: 'summary/downloadSummary'
};

export const asyncAutoSummary = createAsyncThunk(ACTION.ASYNC_AUTO, async body => {
  return axiosInstance.post(`/restapi/async-auto/`, body);
});


export const getSummary = createAsyncThunk(ACTION.GET_SUMMARY, async body => {
  return axiosInstance.post('/restapi/export/total', body);
});

export const downloadSummary = createAsyncThunk(ACTION.DOWNLOAD_SUMMARY, async body => {
  return await axiosInstance.post('/restapi/export/total-download', body, {
    responseType: 'blob',
    onDownloadProgress: progressEvent => Math.round((progressEvent.loaded * 100) / progressEvent.total)
  });
});

const summarySlice = createSlice({
  name: 'summary',
  initialState: initialState,
  reducers: {
    setDownloading: (state, action) => {
      state.percentCompleted = action.payload;
    }
  },
  extraReducers: {
    [getSummary.pending.toString()]: state => {
      state.loading = true;
    },
    [getSummary.rejected.toString()]: state => {
      state.loading = false;
    },
    [getSummary.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.summary = action.payload;
    },

    [downloadSummary.pending.toString()]: state => {
      state.downloading = true;
    },
    [downloadSummary.rejected.toString()]: state => {
      state.downloading = false;
    },
    [downloadSummary.fulfilled.toString()]: (state, action) => {
      state.downloading = false;
      state.file = action.payload;
    },
    [asyncAutoSummary.pending.toString()]: state => {
      state.success = false;
    },
    [asyncAutoSummary.rejected.toString()]: state => {
      state.success = true;
    },
    [asyncAutoSummary.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
  }
});

const { reducer: summaryReducer } = summarySlice;
export default summaryReducer;
