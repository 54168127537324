import React from 'react';
import { FormInput, Row, Col } from 'shards-react';
import { HelpText } from '../../../components/components-overview/Help-text';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch,useSelector } from 'react-redux';
import { showMessageSuccess, showMessageError } from '../../redux/alert-message';
import { postAccountBank, putAccountBank } from '../../redux/accountBank';
import CustomPhotoUpload from '../../../components/common/CustomPhotoUpload';
import logo from '../../../images/sales-overview/no-product-image.jpg';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const FormLabel = React.lazy(() => import('../../../components/common/FormLabel'));
const DialogAccBank = ({ dataEdit = null, setUDO, setData }) => {
  const status = useSelector((state) => state.accountBank);
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = React.useState();


    

  function clean(obj) {
    for (var propName in obj) {
      delete obj['qr_code'];
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }
  const convertToFormData = () => {
    const formData = new FormData();

    if (selectedImage) {
      for (const [key, value] of Object.entries(values)) {
        formData.append(key, value);
      }

      return formData;
    } else {
      clean(values);
      return values;
    }
  };

  const submitForm = (values) => {
    if (!dataEdit) {
      dispatch(postAccountBank(convertToFormData()))
        .unwrap()
        .then(() => {
          dispatch(showMessageSuccess());
        })
        .catch(() => {
          dispatch(showMessageError());
        });
    } else {
        const submitValues = {
            body: convertToFormData(),
            id: dataEdit.id
          };
           dispatch(putAccountBank(submitValues))
            .unwrap()
            .then(() => {
              dispatch(showMessageSuccess());
            })
            .catch(() => {
              dispatch(showMessageError());
            });
    }

    setUDO(false);
    setData('');
  };

  const validationSchema = yup.object({
   account_number:yup.string().required()
   .matches(/^[0-9]+$/, "Nhập dãy số")
   .min(9, "có độ dài trong khoảng từ 9 - 14 ký tự")
   .max(14, "có độ dài trong khoảng từ 9 - 14 ký tự")
  });

  const { values,setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      account_number: (dataEdit && dataEdit.account_number) || '',
      account_owner: (dataEdit && dataEdit.account_owner) || '',
      bank_name: (dataEdit && dataEdit.bank_name) || '',
      qr_code: (dataEdit && dataEdit.qr_code) || ''
    },
    validationSchema: validationSchema,
    onSubmit: submitForm
  });
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <div className="content">
        <div className="box-form">
          <Row>
          <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Tên ngân hàng" />
                <FormInput
                  type="text"
                  name="bank_name"
                  placeholder="Tên ngân hàng"
                  value={values.bank_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.bank_name && errors.bank_name}
                />
                <HelpText status="error">
                  {touched.bank_name && errors.bank_name ? errors.bank_name : ''}
                </HelpText>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Số tài khoản" />
                <FormInput
                  type="text"
                  name="account_number"
                  placeholder="Số tài khoản"
                  value={values.account_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.account_number && errors.account_number}
                />
                <HelpText status="error">
                  {touched.account_number && errors.account_number ? errors.account_number : ''}
                </HelpText>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Tên chủ tài khoản" />
                <FormInput
                  type="text"
                  name="account_owner"
                  placeholder="Tên chủ tài khoản"
                  value={values.account_owner}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.account_owner && errors.account_owner}
                />
                <HelpText status="error">
                  {touched.account_owner && errors.account_owner ? errors.account_owner : ''}
                </HelpText>
              </div>
            </Col>
            
            <Col xs={12}>
             
              <CustomPhotoUpload
                className={'photo-edit'}
                label="Qr Code"
                src={selectedImage ? URL.createObjectURL(selectedImage) : values.qr_code}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = logo;
                }}
                name="image"
                onChange={(e) => {
                  setFieldValue('qr_code', e.currentTarget.files[0]);
                  setSelectedImage(e.currentTarget.files[0]);
                }}
                />
            </Col>
            <Col xs={12}>
              <div className="form-input-box text-right">
                <button
                  className={`btn ${status.success === false ? 'disabled' : ''}`}
                  type="submit"
                  onClick={handleSubmit}>
                  {!dataEdit ? 'Tạo' : 'Sửa'}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Suspense>
  );
};

export default DialogAccBank;
