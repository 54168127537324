import axios from 'axios';
import { TIMEOUT,routeConstants } from '../../constants';
import { getCookieStorage, removeAllCookieStorage, setAllCookieStorage } from '../helps/storage';
import jwt_decode from 'jwt-decode';
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API}`,
  timeout: TIMEOUT,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
});

// for multiple requests
let isRefreshing = false;
let subscribers = [];

function onRefreshed({ authorisationToken }) {
  subscribers.map((cb) => cb(authorisationToken));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}



axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getCookieStorage('access_token');



    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    const originalConfig = error.config;

    if (error.response) {

      // Access Token was expired

      if (error.response.status === 401 && !originalConfig._retry) {
        const refreshToken = getCookieStorage('refresh_token');
        const expireToken = parseFloat(getCookieStorage('expire_token') || '0');
       

        if (isRefreshing) {
          //queue api 401 after refresh token
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalConfig.headers['Authorization'] = `Bearer ${token}`;
              resolve(axiosInstance(originalConfig));
            });
          });
        }
        originalConfig._retry = true;
        isRefreshing = true;
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
        // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp > now) {
          return new Promise(function (resolve, reject) {
            axios
              .post(`${process.env.REACT_APP_BASE_API}/restapi/token/refresh/`, {
                refresh: refreshToken
              })
              .then((response) => {
                const tokenDecoded = jwt_decode(response.data.access);
                const expToken = tokenDecoded.exp ? parseFloat(tokenDecoded.exp) : 0;
                setAllCookieStorage([
                  { key: 'access_token', value: response.data.access },
                  { key: 'expire_token', value: expToken }
                ]);

                onRefreshed(response.data.access);
                resolve(axiosInstance(originalConfig));
              })
              .catch((err) => {
                subscribers = [];
                
                 removeAllCookieStorage([
                   'access_token',
                   'refresh_token',
                   'expire_token',
                   'expire_refresh_token'
                 ]);
                 window.location.replace(routeConstants.SIGN_IN);
                reject(err);
               
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          removeAllCookieStorage([
            'access_token',
            'refresh_token',
            'expire_token',
            'expire_refresh_token'
          ]);
          window.location.replace(routeConstants.SIGN_IN);
        }
      }
    
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
