import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  groups: {
    list: []
  },
  detail:null,
};

const ACTION = {
  GET_GROUPS: 'group/getGroup',
  CREATE_GROUPS: 'group/createGroup',
  UPDATE_GROUPS: 'group/updateGroup',
  DEL_GROUPS: 'group/deleteGroup',
  GET_GROUPS_ID: 'group/getGroupId',
};

export const getGroup = createAsyncThunk(ACTION.GET_GROUPS, async body => {
  return axiosInstance.get('/restapi/groups', { params: body });
});

export const getGroupId = createAsyncThunk(ACTION.GET_GROUPS_ID, async body => {
  return axiosInstance.get(`/restapi/groups/${body.id}`);
});
// create group

export const createGroup = createAsyncThunk(ACTION.CREATE_GROUPS, async body => {
  return axiosInstance.post('/restapi/groups/', body);
});

// update group

export const updateGroup = createAsyncThunk(ACTION.UPDATE_GROUPS, async body => {
  return axiosInstance.put(`/restapi/groups/${body.id}/`, body);
});

// delete group

export const deleteGroup = createAsyncThunk(ACTION.DEL_GROUPS, async body => {
  return axiosInstance.delete(`/restapi/groups/${body.id}`);
});

const groupSlice = createSlice({
  name: 'group',
  initialState: initialState,
  reducers:{
    resetGroupDetail:(state, action) =>{
      state.detail = null
    }
  },
  extraReducers: {
    [getGroup.pending.toString()]: state => {
      state.loading = true;
    },
    [getGroup.rejected.toString()]: state => {
      state.loading = false;
    },
    [getGroup.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.groups = action.payload;
    },
    [createGroup.pending.toString()]: state => {
      state.success = false;
    },
    [createGroup.rejected.toString()]: state => {
      state.success = false;
    },
    [createGroup.fulfilled.toString()]: (state) => {
      state.success = true;
    },
    [updateGroup.pending.toString()]: state => {
      state.success = false;
    },
    [updateGroup.rejected.toString()]: state => {
      state.success = false;
    },
    [updateGroup.fulfilled.toString()]: (state) => {
      state.success = true;
    },
    [deleteGroup.pending.toString()]: state => {
      state.success = false;
    },
    [deleteGroup.rejected.toString()]: state => {
      state.success = false;
    },
    [deleteGroup.fulfilled.toString()]: (state) => {
      state.success = true;
    },
    [getGroupId.pending.toString()]: state => { 
        state.loadingDetail = true;
    },
    [getGroupId.rejected.toString()]: state => {
        state.loadingDetail = false;
    },
    [getGroupId.fulfilled.toString()]:( state,action) => {
      state.loadingDetail = false;
      state.detail = action.payload;
  },
  }
});
export const selectStatus = (state => state.groups)
export const {resetGroupDetail} = groupSlice.actions
const { reducer: groupReducer } = groupSlice;
export default groupReducer;
