import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  rates: {}
};

const ACTION = {
  GET_RATES: 'rate/getRate'
};

export const getRate = createAsyncThunk(ACTION.GET_RATES, async body => {
  return axiosInstance.get('/restapi/export/rate', {
    params: body
  });
});

const rateSlice = createSlice({
  name: 'rate',
  initialState: initialState,
  extraReducers: {
    [getRate.pending.toString()]: state => {
      state.loading = true;
    },
    [getRate.rejected.toString()]: state => {
      state.loading = false;
    },
    [getRate.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.rates = action.payload;
    }
  }
});

const { reducer: rateReducer } = rateSlice;
export default rateReducer;
