
import React from 'react';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
const useExcel = (props) => {

    const workSheetName = props.excelConfig && props.excelConfig.workSheetName;
    const workBookName = props.excelConfig && props.excelConfig.workBookName;
    const myInputId = props.excelConfig && props.excelConfig.myInputId;
    const workbook = new Excel.Workbook();
    const columns = props.excelConfig && props.excelConfig.columns;

    

    const saveExcel = React.useMemo(async() => {
        // const newDataCreditToExport =
        if(props.file && props.file.length > 0){
            try {
                const myInput = document.getElementById(myInputId);
                const fileName = myInput.value || workBookName;
    
                // creating one worksheet in workbook
                if (workbook !== null) {
                    const worksheet = workbook.addWorksheet(workSheetName);
    
                    // add worksheet columns
                    // each columns contains header and its mapping key from data
                    worksheet.columns = columns;
    
                    // updated the font for first row.
                    worksheet.getRow(1).font = { bold: true };
    
                    // loop through all of the columns and set the alignment with width.
                    worksheet.columns.forEach(column => {
                        column.width = column.header.length + 5;
                        column.alignment = { horizontal: 'center' };
                    });
                    // loop through data and add each one to worksheet
                    
                        props.file.forEach((v, index) => {
                            const newData = {
    
                                id: v.id,
                                user: v.user,
                                accuracy: v.accuracy,
                                client_ip: v.client_ip,
                                created_at: v.created_at,
                                endpoint: v.endpoint,
                                include_image: v.include_image
                            };
                            worksheet.addRow(newData);
                        });
    
                    // loop through all of the rows and set the outline style.
                    worksheet.eachRow({ includeEmpty: false }, row => {
                        // store each cell to currentCell
                        const currentCell = row._cells;
    
                        // loop through currentCell to apply border only for the non-empty cell of excel
                        currentCell.forEach(singleCell => {
                            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                            const cellAddress = singleCell._address;
    
                            // apply border
                            worksheet.getCell(cellAddress).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        });
                    });
    
                    // write the content using writeBuffer
                    const buf = await workbook.xlsx.writeBuffer();
    
                    // download the processed file
                    saveAs(new Blob([buf]), `${fileName}.xlsx`);
                    props.setFile([])
                }
            } catch (error) {
                console.error('<<<ERRROR>>>', error);
                console.error('Something Went Wrong', error.message);
            } finally {
                // removing worksheet's instance to create new one
                
                workbook.removeWorksheet(workSheetName);
            }
        }
    },[props.file]);

    return {
        saveExcel,
        myInputId,
        workBookName
    }

}

export default useExcel