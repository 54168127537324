import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  categoriesList: {},

  successCreate: null,
  loadingDetail: false,
  successEdit: null,
};

const ACTION = {
  GET_CATEGORIES: 'categories/getCategories',
  CREATE_CATEGORIES: 'categories/createCategories',
  EDIT_CATEGORIES: 'categories/editCategories',
};

export const getCategories = createAsyncThunk(ACTION.GET_CATEGORIES, async body => {
  return axiosInstance.get('/api/accountant/categories/', {params:body});
});

export const createCategories = createAsyncThunk(ACTION.CREATE_CATEGORIES, async body => {
  return axiosInstance.post('/api/accountant/categories', body);
});

export const editCategories = createAsyncThunk(ACTION.EDIT_CATEGORIES, async body => {
 
  return axiosInstance.put('/api/accountant/categories', body);
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialState,
  extraReducers: {
    [getCategories.pending.toString()]: state => {
      state.loading = true;
    },
    [getCategories.rejected.toString()]: state => {
      state.loading = false;
    },
    [getCategories.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successEdit = null;
      state.categoriesList = action.payload;
    },
    [createCategories.pending.toString()]: state => {
      state.loading = true;
    },
    [createCategories.rejected.toString()]: state => {
      state.loading = false;
    },
    [createCategories.fulfilled.toString()]: (state, action) => {
      state.loading = false;
    },
    // edit categories
    [editCategories.pending.toString()]: state => { },
    [editCategories.rejected.toString()]: state => {
      state.successEdit = false;
    },
    [editCategories.fulfilled.toString()]: state => {
      state.successEdit = true;
    },
  }
});


export const selectAccountCate = (state => state.categories.categoriesList)

const { reducer: categoriesReducer } = categoriesSlice;
export default categoriesReducer;
