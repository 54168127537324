import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// Layout Types
import { DefaultLayout } from '../layouts';

// Route Views

import SignIn from '../erp/view/SignIn';
import PrivateRoute from '../routes/PrivateRoute';
import Account from '../erp/view/Account';
import Rate from '../erp/view/Rate';
import RatePerDay from '../erp/view/RateDay';
import Summary from '../erp/view/Summary';
import Detail from '../erp/view/Detail';
import Compare from '../erp/view/Compare';
import Sim from '../erp/view/Sim';
import SimReg from '../erp/view/SimReg';
import SimAdmin from '../erp/view/SimAdmin';
import Control from '../erp/view/Control'
import SimRegAdmin from '../erp/view/SimRegAdmin';
import SimOrder from '../erp/view/SimOrder';
import SimOrderAdmin from '../erp/view/SimOrderAdmin';
import Ticket from '../erp/view/Ticket';
import EndUser from '../erp/view/EndUser';
import HistoryUserSim from '../erp/view/HistoryUserSim';
import F88 from '../erp/view/f88/F88';
import F88Detail from '../erp/view/f88/F88Detail';
import SimLock from '../erp/view/SimLock';
import GroupView from '../erp/view/Group';
import F88DetailPhone from '../erp/view/f88/F88DetailPhone';
import Customer from '../erp/view/Customer';
import Agency from '../erp/view/Agency';
import Permission from '../erp/view/Permission';
import User from '../erp/view/User';
import Sales from '../erp/view/Sales';
import UcWrapper from '../erp/view/expense-order/uc-warraper';
import ExpenseOrder from '../erp/view/expense-order/ExpenseOrder';
import Expenses from '../erp/view/Expenses'
import Position from '../erp/view/admin/Position';
import Department from '../erp/view/admin/Department';
import Employee from '../erp/view/admin/Employee';
import AccountantService from '../erp/view/AccountantService';
import Asset from '../erp/view/asset/Asset';
import SaleOrder from '../erp/view/sale-order/SaleOrder';
import SimLockWrapper from '../erp/view/sim-lock/Wrapper';
import AssetDocument from '../erp/view/asset-document/AssetDocument';
import AssetDocumentForm from '../erp/view/asset-document/uc-wrapper';
import AccCode from '../erp/view/acc-code/AccCode';
import Telco from '../erp/view/telco/Telco';
import GroupForm from '../erp/view/group/GroupForm';
import Contract from '../erp/view/contracts/Contract';
import ContractForm from '../erp/view/contracts/ContractForm';
import CustomerForm from '../erp/view/customer/CustomerForm';
import CmcPhone from '../erp/view/cmc-phone/CmcPhone';
import callLocal from '../erp/view/call-local/call-local';
import SetupTelco from '../erp/view/setup-telco/setupTelco';
import AccBank from '../erp/view/acc-bank/AccBank';
import Debt from '../erp/view/debt/Debt';
import DetailDebt from '../erp/view/debt/detail';
import ReportVideoCall from '../erp/view/videocall/Report';

const routes = {
  dashboard: {
    exact: true,
    layout: DefaultLayout,
    path: '/',
    component: () => <Redirect to="/tai-khoan" />,
    route: PrivateRoute
  },
  signIn: {
    path: '/login',
    exact: true,
    layout: SignIn,
    route: Route
  },
  account: {
    path: '/tai-khoan',
    layout: DefaultLayout,
    component: Account,
    route: PrivateRoute
  },
  create_profile: {
    path: '/user',
    layout: DefaultLayout,
    component: User,
    route: PrivateRoute
  },
  account_enduser: {
    path: '/tai-khoan-enduser',
    layout: DefaultLayout,
    component: EndUser,
    route: PrivateRoute
  },
  history_usersim: {
    path: '/history-user-sim',
    layout: DefaultLayout,
    component: HistoryUserSim,
    route: PrivateRoute
  },
  summary: {
    path: '/tong-hop',
    layout: DefaultLayout,
    component: Summary,
    route: PrivateRoute
  },
  detail: {
    path: '/chi-tiet',
    layout: DefaultLayout,
    component: Detail,
    route: PrivateRoute
  },
  compare: {
    path: '/so-sanh',
    layout: DefaultLayout,
    component: Compare,
    route: PrivateRoute
  },
  f88: {
    path: '/f88',
    layout: DefaultLayout,
    component: F88,
    route: PrivateRoute
  },
  f88Detail: {
    path: '/f88-detail',
    layout: DefaultLayout,
    component: F88Detail,
    route: PrivateRoute
  },
  f88DetailPhone: {
    path: '/f88-detail-phone',
    layout: DefaultLayout,
    component: F88DetailPhone,
    route: PrivateRoute
  },
  rate: {
    path: '/thong-ke-ti-le',
    layout: DefaultLayout,
    component: Rate,
    route: PrivateRoute
  },
  ratePerDay: {
    path: '/thong-ke-ti-le-theo-ngay',
    layout: DefaultLayout,
    component: RatePerDay,
    route: PrivateRoute
  },
  control: {
    path: '/xuat-doi-soat',
    layout: DefaultLayout,
    component: Control,
    route: PrivateRoute
  },

  // sim: {
  //   path: '/sim',
  //   layout: DefaultLayout,
  //   component: Sim,
  //   route: PrivateRoute
  // },
  // simreg: {
  //   path: '/sim-reg',
  //   layout: DefaultLayout,
  //   component: SimReg,
  //   route: PrivateRoute
  // },
  simadmin: {
    path: '/sim-admin',
    layout: DefaultLayout,
    component: SimAdmin,
    route: PrivateRoute
  },
  simlock: {
    path: '/sim-lock',
    exact: true,
    layout: DefaultLayout,
    component: SimLock,
    route: PrivateRoute
  },
  simlockcreate: {
    path: '/sim-lock/create',
    layout: DefaultLayout,
    exact: true,
    component: SimLockWrapper,
    route: PrivateRoute
  },
  simlockdetail: {
    path: '/sim-lock/:id',
    layout: DefaultLayout,
    exact: true,
    component: SimLockWrapper,
    route: PrivateRoute
  },
  listsimadmin: {
    path: '/sim-admin-reg',
    layout: DefaultLayout,
    component: SimRegAdmin,
    route: PrivateRoute
  },
  // simorder: {
  //   path: '/sim-order',
  //   layout: DefaultLayout,
  //   component: SimOrder,
  //   route: PrivateRoute
  // },
  simorderadmin: {
    path: '/admin-order',
    layout: DefaultLayout,
    component: SimOrderAdmin,
    route: PrivateRoute
  },
  ticket: {
    path: '/ticket',
    exact: true,
    layout: DefaultLayout,
    component: Ticket,
    route: PrivateRoute
  },
  ticketApproved: {
    path: '/ticket/:uuid',
    exact: true,
    layout: DefaultLayout,
    component: Ticket,
    route: PrivateRoute
  },
  group: {
    path: '/group',
    exact: true,
    layout: DefaultLayout,
    component: GroupView,
    route: PrivateRoute
  },
  create_group: {
    path: '/group/create',
    exact: true,
    layout: DefaultLayout,
    component: GroupForm,
    route: PrivateRoute
  },
  update_group: {
    path: '/group/update/:id',
    exact: true,
    layout: DefaultLayout,
    component: GroupForm,
    route: PrivateRoute
  },
  accCode: {
    path: '/acc-code',
    exact: true,
    layout: DefaultLayout,
    component: AccCode,
    route: PrivateRoute
  },
  customer: {
    path: '/khach-hang',
    exact: true,
    layout: DefaultLayout,
    component: Customer,
    route: PrivateRoute
  },
  create_customer: {
    path: '/khach-hang/create',
    exact: true,
    layout: DefaultLayout,
    component: CustomerForm,
    route: PrivateRoute
  },
  update_customer: {
    path: '/khach-hang/update/:id',
    exact: true,
    layout: DefaultLayout,
    component: CustomerForm,
    route: PrivateRoute
  },
  agency: {
    path: '/dai-ly',
    exact: true,
    layout: DefaultLayout,
    component: Agency,
    route: PrivateRoute
  },
  permission: {
    path: '/permission',
    exact: true,
    layout: DefaultLayout,
    component: Permission,
    route: PrivateRoute
  },
  position: {
    path: '/position',
    exact: true,
    layout: DefaultLayout,
    component: Position,
    route: PrivateRoute
  },
  department: {
    path: '/department',
    exact: true,
    layout: DefaultLayout,
    component: Department,
    route: PrivateRoute
  },
  sales: {
    path: '/accountant/sales',
    exact: true,
    layout: DefaultLayout,
    component: Sales,
    route: PrivateRoute
  },
  expenses: {
    path: '/expenses',
    exact: true,
    layout: DefaultLayout,
    component: Expenses,
    route: PrivateRoute
  },
  employee: {
    path: '/employee',
    exact: true,
    layout: DefaultLayout,
    component: Employee,
    route: PrivateRoute
  },
  accountantService: {
    path: '/accountant/services',
    exact: true,
    layout: DefaultLayout,
    component: AccountantService,
    route: PrivateRoute
  },
  asset: {
    path: '/accountant/asset',
    exact: true,
    layout: DefaultLayout,
    component: Asset,
    route: PrivateRoute
  },
  expense: {
    path: '/expense-order',
    exact: true,
    layout: DefaultLayout,
    component: ExpenseOrder,
    route: PrivateRoute
  },
  expenseUpdate: {
    path: '/expense-order/update/:id',
    exact: true,
    layout: DefaultLayout,
    component: UcWrapper,
    route: PrivateRoute
  },

  expenseCreate: {
    path: '/expense-order/create',
    exact: true,
    layout: DefaultLayout,
    component: UcWrapper,
    route: PrivateRoute
  },
  saleorder: {
    path: '/sale-order',
    exact: true,
    layout: DefaultLayout,
    component: SaleOrder,
    route: PrivateRoute
  },
  detailsaleorder: {
    path: '/sale-order/:id',
    exact: true,
    layout: DefaultLayout,
    component: SaleOrder,
    route: PrivateRoute
  },
  asset_document: {
    path: '/accountant/asset-document',
    exact: true,
    layout: DefaultLayout,
    component: AssetDocument,
    route: PrivateRoute
  },
  create_asset_document: {
    path: '/accountant/asset-document/create',
    exact: true,
    layout: DefaultLayout,
    component: AssetDocumentForm,
    route: PrivateRoute
  },
  update_asset_document: {
    path: '/accountant/asset-document/update/:id',
    exact: true,
    layout: DefaultLayout,
    component: AssetDocumentForm,
    route: PrivateRoute
  },
  telco: {
    path: '/telco',
    exact: true,
    layout: DefaultLayout,
    component: Telco,
    route: PrivateRoute
  },
  contract: {
    path: '/contracts',
    exact: true,
    layout: DefaultLayout,
    component: Contract,
    route: PrivateRoute
  },
  create_contract: {
    path: '/contracts/create',
    exact: true,
    layout: DefaultLayout,
    component: ContractForm,
    route: PrivateRoute
  },
  update_contract: {
    path: '/khach-hang/update/:id/contracts/update/:uuid',
    exact: true,
    layout: DefaultLayout,
    component: ContractForm,
    route: PrivateRoute
  },

  cmc_phone: {
    path: '/cmc-phone',
    exact: true,
    layout: DefaultLayout,
    component: CmcPhone,
    route: PrivateRoute
  },

  create_cmc_phone: {
    path: '/cmc-phone/create',
    exact: true,
    layout: DefaultLayout,
    component: CmcPhone,
    route: PrivateRoute
  },
  update_cmc_phone: {
    path: '/cmc-phone/update/:uuid',
    exact: true,
    layout: DefaultLayout,
    component: CmcPhone,
    route: PrivateRoute
  },
  callinside:{
    path: '/not-hat-lien-tinh',
    exact: true,
    layout: DefaultLayout,
    component: callLocal,
    route: PrivateRoute
  },
  setup_telco: {
    path: '/setup-telco',
    exact: true,
    layout: DefaultLayout,
    component: SetupTelco,
    route: PrivateRoute
  },
  accountBank: {
    path: '/account-bank',
    exact: true,
    layout: DefaultLayout,
    component: AccBank,
    route: PrivateRoute
  },
  debt: {
    path: '/accountant/debt',
    exact: true,
    layout: DefaultLayout,
    component: Debt,
    route: PrivateRoute
  },
  debtDetail: {
    path: '/accountant/debt/:id',
    exact: true,
    layout: DefaultLayout,
    component: DetailDebt,
    route: PrivateRoute
  },
  videoCall: {
    path: '/thong-ke-video-call',
    exact: true,
    layout: DefaultLayout,
    component: ReportVideoCall,
    route: PrivateRoute
  },
};

export default routes;
