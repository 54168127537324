import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggleSidebar: true
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.toggleSidebar = action.payload;
    }
  },
  extraReducers: {}
});

export const { toggleSidebar } = sidebarSlice.actions;
const { reducer: sidebarReducer } = sidebarSlice;
export default sidebarReducer;
