import React, { useEffect, useState } from 'react';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';

import Modal, { ModalContent } from '../../../components/common/Modal';
import { Tooltip } from 'react-tippy';
import { getAccount } from '../../redux/account';
import DialogAccCode from './DialogAccCode';

import moment from 'moment';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const AccCode = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState('');
  const [search, setSearch] = useState('');
  const [activeClear, setActiveClear] = useState(false);
  const accCode = useSelector((state) => state.accounts.accounts);
  const isLoading = useSelector((state) => state.accounts.loading);
  const status = useSelector((state) => state.accounts);
  const pagination = usePaginationState();

  // search

  useEffect(() => {
    dispatch(
      getAccount({
        offset: pagination.perPage * pagination.page - pagination.perPage,
        limit: pagination.perPage,
        name: search && search.trim()
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getAccount({
          offset: !search ? pagination.perPage * pagination.page - pagination.perPage : 0,
          limit: pagination.perPage,
          name: search && search.trim()
        })
      );
    }
  }, [status.success]);

  // close modal

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý Acc Code
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm acc code
          </span>
        </h1>
        <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              disabled={isLoading}
              type="text"
              name="name"
              placeholder="Search theo tên"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getAccount({
                      offset: pagination.perPage * pagination.page - pagination.perPage,
                      limit: pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  e.preventDefault();
                  dispatch(
                    getAccount({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      name: search && search.trim()
                    })
                  );
                }
              }}
            />
            {activeClear && (
              <span onClick={() => setSearch('')} className="clear-search">
                <i className="fa fa-times"></i>
              </span>
            )}
          </div>
        </div>
        <Modal
          changeActive={setOpenModal}
          active={openModal}
          id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent
            style={{ maxWidth: 500 }}
            heading={!data ? 'Thêm acc code' : 'Sửa'}
            onClose={handleCloseModal}>
            <DialogAccCode setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0">
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Mã Code
                  </th>
                  <th scope="col" className="border-0">
                    Tên
                  </th>
                  <th scope="col" className="border-0">
                    Mã công ty
                  </th>

                  <th scope="col" className="border-0">
                   Ngày tạo
                  </th>

                  <th scope="col" className="border-0" style={{ width: '180px' }}>
                    Hành động
                  </th>
                </tr>
              </thead>

              <tbody>
                {accCode &&
                  accCode.results &&
                  accCode.results.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.code}</td>
                        <td>{item.name}</td>
                        <td>{item.group && item.group.name}</td>
                        <td>{item.created_at && moment(item.created_at).format('DD/MM/YYYY')}</td>
                        <td>
                          <Tooltip
                            position="top"
                            title={'Cập nhập'}
                            size={'small'}
                            animation={'shift'}>
                            <button
                              onClick={() => {
                                setOpenModal(true);
                                setData(item);
                              }}
                              className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {accCode && accCode.results && accCode.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((accCode ? accCode.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(Math.ceil((accCode ? accCode.count : 0) / pagination.perPage))
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default AccCode;
