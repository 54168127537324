import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { toggleSidebar } from '../../redux/sidebar';
import { useDispatch, useSelector } from 'react-redux';

import { NavItem, NavLink } from 'shards-react';

const SidebarNavItem = ({ item, isHovered, selected, setIsHovered }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.control.loading);

  useEffect(() => {
    if (!isLoading) {
      setIsHovered(false)
    }
  }, [isLoading]);

  useEffect(() => {
    if (item.title) {
      dispatch(toggleSidebar(false));
    }
  }, [item.title]);

  return (
    <>
      {item.submenu ? (
        <>
          <NavItem
            className={isHovered && selected === item.title ? 'isActive' : ''}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {item.submenu.map((elm, indx) => {
              return (
                <NavLink key={indx} tag={props => <RouteNavLink {...props} />} to={elm.to}>
                  {elm.title}
                </NavLink>
              );
            })}
          </NavItem>
        </>
      ) : (
        <>
          <NavItem
            className={isHovered && selected === item.title ? 'isActive' : ''}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <NavLink tag={props => <RouteNavLink {...props} />} to={item.to}>
              {item.htmlBefore && (
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                />
              )}
              {item.title && <span>{item.title}</span>}
            </NavLink>
          </NavItem>
        </>
      )}
    </>
  );
};

SidebarNavItem.propTypes = {
  item: PropTypes.object
};

export default SidebarNavItem;
