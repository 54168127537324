import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { formatMoney } from '../../helps/convertMoney';
import { getCallLocal } from '../../redux/call-local';
import useDebounce from '../../../hooks/use-debounce';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { getGroup } from '../../redux/group';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const FormLabel = React.lazy(() => import('../../../components/common/FormLabel'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const callLocal = () => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: 33,
      height: 33,
      borderColor: '#e1e5eb'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 31
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: state => ({
      display: 'none'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 31
    })
  };
  const dispatch = useDispatch();
  const loading = useSelector(state => state.callLocal.loading);
  const list = useSelector(state => state.callLocal.list);

  const handleFilter = () => {
    dispatch(getCallLocal(filter));
  };

  // datatime

  var today = new Date();
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState(moment().toDate());

  const [filter, setFilter] = useState({
    group: '',
    from: moment(startDate).format('YYYY-MM-DD'),
    to: moment(endDate).format('YYYY-MM-DD')
  });

  // chang date

  const handleStartDateChange = value => {
    setStartDate(value);
    setFilter({ ...filter, from: moment(value).format('YYYY-MM-DD') });
  };

  const handleEndDateChange = value => {
    setEndDate(value);
    setFilter({ ...filter, to: moment(value).format('YYYY-MM-DD') });
  };
  const loadingGroup = useSelector(state => state.groups.loading);
  const [groupNameOptions, setGroupNameOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPageGroup, setTotalPageGroup] = useState(1);
  const [search, setSearch] = useState('');
  const searchGroupDebouce = useDebounce(search, 500);
  const addnewOption = () => {
    setPage(page + 1);
  };

  var arrGroup = [];
  const baseOption = {
    label: 'Chọn',
    value: ''
  };
  arrGroup = [...groupNameOptions, baseOption];
  useEffect(() => {
    const fetchDataGroup = async () => {
      const isSearch = search
        ? { name: search }
        : { limit: 15, offset: page === 0 ? 0 : (page - 1 + 1) * 15 };
      dispatch(getGroup(isSearch))
        .unwrap()
        .then(res => {
          if (search) {
            setTotalPageGroup(prev => prev);
          } else {
            const totalpage = Math.round(res.count / 15);
            setTotalPageGroup(totalpage);
          }

          res &&
            res.results.map(item =>
              arrGroup.push({
                value: item.name,
                label: item.name
              })
            );
          if (res.results && res.results.length > 0) {
            const unique = [...new Map(arrGroup.map(item => [item['value'], item])).values()];

            setGroupNameOptions(unique);
          }
        });
    };

    if (page <= totalPageGroup) {
      fetchDataGroup();
    }
  }, [searchGroupDebouce, page]);

  const handleChangeOption = (option, field) => {
    switch (field) {
      case 'group':
        setFilter({ ...filter, group: option.label });
        break;
      default:
        break;
    }
  };

  return (
    <React.Suspense>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">Gọi nội hạt liên tỉnh</h1>
        <div className="box-form-general">
          <Row>
            <Col xs={12} lg={3}>
              <div className="form-input-box">
                <FormLabel label="Chọn group" required />
                <Select
                  onMenuScrollToBottom={e => addnewOption(e)}
                  isSearchable={true}
                  cacheOptions
                  loadOptions={groupNameOptions}
                  options={groupNameOptions}
                  type="select"
                  id="group"
                  name="group"
                  placeholder={<div>Chọn</div>}
                  onChange={e => handleChangeOption(e, 'group')}
                  onInputChange={value => setSearch(value)}
                  styles={customStyles}
                  isLoading={loadingGroup}
                />
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <FormLabel label="Ngày tháng" />
              <DatePicker
                className="custom-datepicker"
                value={startDate}
                onChange={handleStartDateChange}
                format="d/MM/y"
                clearIcon={null}
                calendarIcon={<i className="material-icons">&#xE916;</i>}
              />
              <DatePicker
                className="custom-datepicker"
                value={endDate}
                onChange={handleEndDateChange}
                format="d/MM/y"
                clearIcon={null}
                calendarIcon={<i className="material-icons">&#xE916;</i>}
              />
              <button
                className="btn"
                type="submit"
                onClick={() => handleFilter()}
                style={{ marginLeft: 20 }}>
                Tìm kiếm
              </button>
            </Col>
          </Row>
        </div>

        {loading ? (
          <Spinner loading={loading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0" style={{ width: '40px' }}>
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    ACC
                  </th>
                  <th scope="col" className="border-0">
                    Billsec
                  </th>

                  <th scope="col" className="border-0 text-right">
                    Caller
                  </th>
                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Price
                  </th>
                </tr>
              </thead>

              <tbody>
                {list.data &&
                  list.data.length > 0 &&
                  list.data.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.account}</td>
                        <td>{formatMoney(item.billsec, '(phút)')}</td>
                        <td className="text-right">{item.caller}</td>
                        <td>{item.phone}</td>
                        <td>{formatMoney(item.price)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {list && list.data && list.data.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default callLocal;
