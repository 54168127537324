import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../redux/sidebar';

const NavbarToggle = () => {
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector(state => state.sidebar.toggleSidebar);

  const handleClick = () => {
    dispatch(toggleSidebar(!isOpenSidebar));
  };

  return (
    <nav className="nav">
      <a
        href="#!"
        onClick={handleClick}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
};

export default NavbarToggle;
