import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerId } from '../../redux/customer';

import DialogDebtUpdate from './DialogDebtUpdate';
import { getDebtDetail } from '../../redux/deb';
const FormBack = React.lazy(() => import('../../../components/common/FormBack'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));

const Detail = () => {
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector((state) => state.debt);
  const [data, setData] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(getDebtDetail({ id }))
        .unwrap()
        .then((res) => {
          setData(res);
        });
    }
  }, [id]);

  useEffect(() => {
   if(state.success === true){
    dispatch(getDebtDetail({ id }))
        .unwrap()
        .then((res) => {
          setData(res);
        });
   }
  }, [state.success])
  

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <Spinner loading={state.loading} top={150} />
        <FormBack />
        {
            data && (
                <div className="box-form-general style1">
          <Row>
            <Col xs={12}>
              <h1 className="heading">Chi tiết công nợ {data.code}</h1>
            </Col>
          </Row>
          <DialogDebtUpdate  dataEdit={data} />
        </div>
            )
        }
      </Container>
    </React.Suspense>
  );
};

export default Detail;
