import React from 'react';

const ToastItem = ({ message, type, onClose }) => {
  return (
    <div
      className={`notifi custom-toast bottom-left ${
        type === 'success' ? 'bg-success' : 'bg-error'
      }`}>
      <div className="notification-image">
        {type === 'success' ? (
          <>
            <i className="fa fa-check-circle"></i>
          </>
        ) : (
          <>
            <i className="fa fa-times-circle"></i>
          </>
        )}
      </div>
      <div className="toast-close" onClick={onClose}>
        <i className="fa fa-times-circle"></i>
      </div>
      <div>
        <p className="notification-title">{type === 'success' ? 'Thành công' : 'Lỗi'}</p>
        <p className="notification-message">{message}</p>
      </div>
    </div>
  );
};

export default ToastItem;
