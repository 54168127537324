import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  customerList: {},
  detail:null,
  customerTypeList:{},
  loading: false
};

const ACTION = {
  GET_CUSTOMERS: 'customer/getCustomer',
  CREATE_CUSTOMER: 'customer/createCustomer',
  UPDATE_CUSTOMER: 'customer/updateCustomer',
  DELETE_CUSTOMER: 'customer/deleteCustomer',

  GET_CUSTOMERS_ID: 'customers/getCustomerId',

  GET_CUSTOMER_TYPES: 'customer/getCustomerTypes',
  CREATE_CUSTOMER_TYPES: 'customer/createCustomerTypes',
  UPDATE_CUSTOMER_TYPES: 'customer/updateCustomerTypes',
  DEL_CUSTOMER_TYPES: 'customer/delCustomerTypes',
};

export const getCustomer = createAsyncThunk(ACTION.GET_CUSTOMERS, async body => {
  return axiosInstance.get('/api/customers/', { params: body });
});

export const getCustomerId = createAsyncThunk(ACTION.GET_CUSTOMERS_ID, async body => {
  return axiosInstance.get(`/api/customers/${body.id}`);
});

// create customer

export const createCustomer = createAsyncThunk(ACTION.CREATE_CUSTOMER, async body => {
  return axiosInstance.post('/api/customers/', body);
});

// update customer

export const updateCustomer = createAsyncThunk(ACTION.UPDATE_CUSTOMER, async body => {
  return axiosInstance.put(`/api/customers/${body.id}/`, body);
});
export const deleteCustomer = createAsyncThunk(ACTION.UPDATE_CUSTOMER, async body => {
  return axiosInstance.delete(`/api/customers/${body.id}/`);
});
// asset type
export const getCustomerTypes = createAsyncThunk(ACTION.GET_CUSTOMER_TYPES, async body => {
  return axiosInstance.get('/api/customer-types/', { params: body });
});
// create customer types

export const createCustomerTypes = createAsyncThunk(ACTION.CREATE_CUSTOMER_TYPES, async body => {
  return axiosInstance.post('/api/customer-types/', body);
});
// update customer types

export const updateCustomerTypes = createAsyncThunk(ACTION.UPDATE_CUSTOMER_TYPES, async body => {
  return axiosInstance.put(`/api/customer-types/${body.id}`, body);
});

// del customer types

export const delCustomerTypes = createAsyncThunk(ACTION.DEL_CUSTOMER_TYPES, async body => {
  return axiosInstance.delete(`/api/customer-types/${body.id}`);
});
const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  extraReducers: {
    [getCustomer.pending.toString()]: state => {
      state.loading = true;
    },
    [getCustomer.rejected.toString()]: state => {
      state.loading = false;
    },
    [getCustomer.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.customerList = action.payload;
    },

    [getCustomerId.pending.toString()]: state => {
      state.loadingDetail = true;
    },
    [getCustomerId.rejected.toString()]: state => {
      state.loadingDetail = false;
    },
    [getCustomerId.fulfilled.toString()]: (state, action) => {
      state.loadingDetail = false;
      state.detail = action.payload;
    },


    [createCustomer.pending.toString()]: state => {
      state.success = false;
    },
    [createCustomer.rejected.toString()]: state => {
      state.success = true;
    },
    [createCustomer.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [updateCustomer.pending.toString()]: state => {
      state.success = false;
    },
    [updateCustomer.rejected.toString()]: state => {
      state.success = true;
    },
    [updateCustomer.fulfilled.toString()]: (state, action) => {
      state.success = true
    },
    [deleteCustomer.pending.toString()]: state => {
      state.success = false;
    },
    [deleteCustomer.rejected.toString()]: state => {
      state.success = true;
    },
    [deleteCustomer.fulfilled.toString()]: (state) => {
      state.success = true
    },

    [getCustomerTypes.pending.toString()]: state => {
      state.loadingCustomerTypes = true;
    },
    [getCustomerTypes.rejected.toString()]: state => {
      state.loadingCustomerTypes = false;
    },
    [getCustomerTypes.fulfilled.toString()]: (state, action) => {
      state.loadingCustomerTypes = false;
      state.createCustomerTypesError = false;
      state.createCustomerTypesSuccess = false;
      state.updateCustomerTypesSuccess = false;
      state.updateCustomerTypesError = false;
      state.customerTypeList = action.payload;
    },
    [createCustomerTypes.pending.toString()]: state => {
    },
    [createCustomerTypes.rejected.toString()]: state => {
      state.createCustomerTypesError = true;
    },
    [createCustomerTypes.fulfilled.toString()]: (state, action) => {
      state.createCustomerTypesSuccess = true;
    },
    [updateCustomerTypes.pending.toString()]: state => {
    },
    [updateCustomerTypes.rejected.toString()]: state => {
      state.updateCustomerTypesError = true;
    },
    [updateCustomerTypes.fulfilled.toString()]: (state, action) => {
      state.updateCustomerTypesSuccess = true
    },
  }
});

export const selectCustomerList = (state => state.customers.customerList)
export const selectCustomerTypesList = (state => state.customers.customerTypeList)
export const selectStatus = (state => state.customers)
const { reducer: customertReducer } = customerSlice;
export default customertReducer;
