import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  detail: {},
  loading: false,
  downloading: false
};

const ACTION = {
  GET_DETAIL: 'detail/getDetail',
  DOWNLOAD_DETAIL: 'detail/downloadDetail'
};

export const getDetail = createAsyncThunk(ACTION.GET_DETAIL, async (body,{rejectWithValue}) => {
  return axiosInstance.get('/restapi/export/detail', {
    params: body
  })
  .then(res => res)
  .catch(error => rejectWithValue(error.response.data));
});

export const downloadDetail = createAsyncThunk(ACTION.DOWNLOAD_DETAIL, async body => {
  return await axiosInstance.post('/restapi/export/detail-download', body, {
    // headers: {
    //   'content-type': 'application/x-zip-compressed',
    //   'Content-Disposition': 'attachment; filename=account-Công ty 3T-T08-2022.zip'
    // },
    timeout: '0',
    responseType: 'blob',
    onDownloadProgress: progressEvent => Math.round((progressEvent.loaded * 100) / progressEvent.total)
  });
});

const detailSlice = createSlice({
  name: 'detail',
  initialState: initialState,
  reducers: {
    setDownloading: (state, action) => {
      state.percentCompleted = action.payload;
    }
  },
  extraReducers: {
    [getDetail.pending.toString()]: state => {
      state.loading = true;
    },
    [getDetail.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [getDetail.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.detail = action.payload;
    },

    [downloadDetail.pending.toString()]: state => {
      state.downloading = true;
    },
    [downloadDetail.rejected.toString()]: state => {
      state.downloading = false;
    },
    [downloadDetail.fulfilled.toString()]: (state, action) => {
      state.downloading = false;
      // state.detail = action.payload;
    }
  }
});
const { reducer: detailReducer } = detailSlice;
export default detailReducer;
