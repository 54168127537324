import React from 'react'
import TableRow from './TableRow';
import { formatMoney } from '../../helps/convertMoney';
import { Button } from 'shards-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DataTable = ({
    setOpenSaleOrder,
    openSaleOrder,
    productSorted,
    handleOnDragEnd,
    setUpdateRowId,
    setModalAsk,
    setDataEdit,
    totalCost,
    totalVat,
    setOpenUpdateVatModal,
    openUpdateVatModal,
    control
}) => {
    return (
        <>
            <span className="btn mb-4" onClick={() => setOpenSaleOrder(!openSaleOrder)}>
                Chỉnh sửa sản phẩm
            </span>

            <div className="overflow-x-auto">
                <div className="card card-small mb-4 overflow-x-auto">
                    <div className="table-wrapper">
                        <table className="table mb-0 overflow-auto">
                            <thead className="bg-white">
                                <tr>
                                    <th scope="col" className="border-0 text-center">
                                        STT
                                    </th>
                                    <th scope="col" className="border-0">
                                        Diễn giải
                                    </th>
                                    <th scope="col" className="border-0 text-right">
                                        Đơn giá(VNĐ)
                                    </th>
                                    <th scope="col" className="border-0 text-right">
                                        Số lượng
                                    </th>
                                    <th scope="col" className="border-0 text-right">
                                        Chiết khấu (%)
                                    </th>
                                    <th scope="col" className="border-0 text-right">
                                        Thành tiền
                                    </th>
                                    <th scope="col" className="border-0 text-center">
                                        Hành động
                                    </th>
                                </tr>
                            </thead>
                            {productSorted && (
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="products">
                                        {(provided, snapshot) => (
                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                {productSorted.map((v, index) => {
                                                    return (
                                                        <Draggable key={v.name} draggableId={v.name} index={index}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            background:
                                                                                snapshot && snapshot.isDragging
                                                                                    ? '#f7f7f7'
                                                                                    : v.qty !== 0 && v.price !== 0
                                                                                        ? ''
                                                                                        : '#eae9e9',
                                                                            position: 'static',
                                                                            fontStyle:
                                                                                v.qty !== 0 && v.price !== 0 ? '' : 'italic',
                                                                            ...provided.draggableProps.style
                                                                        }}>
                                                                        {!v.deleted && (
                                                                            <TableRow
                                                                                show={true}
                                                                                row={v}
                                                                                index={index}
                                                                                setUpdateRowId={setUpdateRowId}
                                                                                setUDO={setModalAsk}
                                                                                setDataEdit={setDataEdit}
                                                                            />
                                                                        )}
                                                                    </tr>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                                <tr>
                                                    <td></td>
                                                    <td className="font-bold">Cộng phát sinh trong kỳ</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="text-right">{formatMoney(totalCost)}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="font-bold">
                                                        Thuế GTGT ({control && control.vat}%)
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="font-bold text-right">
                                                        {formatMoney(totalVat)}
                                                    </td>
                                                    <td className="d-flex justify-center">
                                                        <Button
                                                            onClick={() => {
                                                                setOpenUpdateVatModal(!openUpdateVatModal);
                                                            }}>
                                                            Cập nhật
                                                        </Button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="font-bold">
                                                        Tổng số tiền phải thanh toán cho VNTEL
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="font-bold text-right">
                                                        {formatMoney(totalCost + totalVat)}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataTable