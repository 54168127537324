import React, { useEffect, useState } from 'react';
import { Row, Col, Container, FormSelect, Button } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getF88 } from '../../redux/f88';
import { MONTH } from '../../../constants';
import moment from 'moment';
import { formatMoney } from '../../helps/convertMoney';
import { useHistory } from 'react-router';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));

const F88 = () => {
  const dispatch = useDispatch();
  const f88List = useSelector(state => state.f88.f88);
  const isLoading = useSelector(state => state.f88.loading);
  const [filter, setFilter] = useState({});
  const [totalEst, setTotalEst] = useState(0);
  const history = useHistory();

  useEffect(() => {
    dispatch(getF88({ ...filter, group: 'F88', month: '1', year: moment().year() }));
  }, []);

  const handleSearch = () => {
    setFilter(filter);
    dispatch(getF88({ ...filter, group: 'F88', year: moment().year() }));
  };

  useEffect(() => {
    if (f88List.data && f88List.data.length > 0) {
      let total = 0;
      f88List.data.map(item => (total += item.price));
      total +=
        f88List.services.phi_kenh * f88List.services.sl_kenh +
        f88List.services.phi_thue_bao_khach * f88List.services.sl_thue_bao_khach;
      setTotalEst(total);
    }
  }, [f88List]);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">F88</h1>
        {<Spinner loading={isLoading} top="30%" />}
        <Row>
          <Col>
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">F88</h6>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item p-3">
                  <div className="row">
                    <div className="col">
                      <div className="form-row">
                        <div className="form-group col-md-3">
                          <label htmlFor="">Chọn tháng</label>
                          <FormSelect
                            id="feInputState"
                            onChange={e => {
                              filter['month'] = e.target.value;
                              setFilter({ ...filter });
                            }}>
                            {MONTH.map((item, idx) => {
                              return <option key={idx}>{item}</option>;
                            })}
                          </FormSelect>
                        </div>
                        <div className="form-group col-md-1">
                          {' '}
                          <label htmlFor="">&nbsp;</label>
                          <Button type="submit" className="form-control" onClick={handleSearch}>
                            Thống kê
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="border-0">
                      STT
                    </th>
                    <th scope="col" className="border-0">
                      Tên
                    </th>
                    <th scope="col" className="border-0">
                      Thành tiền
                    </th>
                    <th scope="col" className="border-0">
                      Số lượng
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                {f88List.data && f88List.data.length > 0 && (
                  <tbody>
                    {f88List.data.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.account}</td>
                          <td>{formatMoney(item.price)}</td>
                          <td>{formatMoney(item.billsec, '(phút)')}</td>
                          <td>
                            <Button
                              className="form-control w-1/2"
                              onClick={() => {
                                history.push(`/f88-detail?company=${item.account}`, {
                                  company: item.account,
                                  month: filter['month']
                                });
                              }}>
                              Chi tiết
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>{f88List.data.length + 1}</td>
                      <td>Kênh</td>
                      <td>{formatMoney(f88List.services.phi_kenh * f88List.services.sl_kenh)}</td>
                      <td>
                        {f88List.services.sl_kenh +
                          ' (' +
                          formatMoney(f88List.services.phi_kenh) +
                          ' / kênh)'}
                      </td>
                      <td className="w-28">
                        <Button className="form-control w-1/2" onClick={async () => {}}>
                          Cập nhật
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>{f88List.data.length + 2}</td>
                      <td>Thuê bao</td>
                      <td>
                        {formatMoney(
                          f88List.services.phi_thue_bao_khach * f88List.services.sl_thue_bao_khach
                        )}
                      </td>
                      <td>
                        {f88List.services.sl_thue_bao_khach +
                          '(' +
                          formatMoney(f88List.services.phi_thue_bao_khach) +
                          ' / thuê bao)'}
                      </td>
                      <td className="w-28">
                        <Button className="form-control" onClick={async () => {}}>
                          Cập nhật
                        </Button>
                      </td>
                    </tr>
                    <tr className="font-bold">
                      <td></td>
                      <td>Tổng tạm tính</td>
                      <td>{formatMoney(totalEst)}</td>
                    </tr>
                    <tr className="font-bold">
                      <td></td>
                      <td>Phí VAT (10%)</td>
                      <td>{formatMoney((totalEst * 10) / 100)}</td>
                    </tr>
                    <tr className="font-bold">
                      <td></td>
                      <td>Tổng tiền thực tế</td>
                      <td>{formatMoney(totalEst + (totalEst * 10) / 100)}</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default F88;
