import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  employees: {},
  loading: false,
  failure: false
};

const ACTION = {
  GET_EMPLOYEES: 'employee/getEmployees',
  CREATE_EMPLOYEE: 'employee/createEmployees',
  UPDATE_EMPLOYEE: 'employee/updateEmployee'
};

export const getEmployees = createAsyncThunk(ACTION.GET_EMPLOYEES, async body => {
  return axiosInstance.get('/api/employees/', {
    params: body
  });
});

export const createEmployee = createAsyncThunk(ACTION.CREATE_EMPLOYEE, async body => {
  return axiosInstance.post('/api/employees/', body);
});

export const updateEmployee = createAsyncThunk(ACTION.UPDATE_EMPLOYEE, async body => {
  return axiosInstance.put(`/api/employees/${body.id}/`, body);
});


const employeeSlice = createSlice({
  name: 'employees',
  initialState: initialState,
  extraReducers: {
    [getEmployees.pending.toString()]: state => {
      state.loading = true;
    },
    [getEmployees.rejected.toString()]: state => {
      state.loading = false;
    },
    [getEmployees.fulfilled.toString()]: (state, action) => {
      state.loading = false;
    
      state.employees = action.payload;
    },
    [createEmployee.pending.toString()]: state => {
      state.success = false;
    },
    [createEmployee.rejected.toString()]: state => {
      state.success = true;
    },
    [createEmployee.fulfilled.toString()]: state => {
      state.success = true;
    },
    [updateEmployee.pending.toString()]: state => {
      state.success = false;
    },
    [updateEmployee.rejected.toString()]: state => {
      state.success = true;
    },
    [updateEmployee.fulfilled.toString()]: state => {
      state.success = true;
    }
  }
});

export const selectEmploy = (state => state.employees.employees)
export const selectStatus = (state => state.employees)
const { reducer: employeeReducer } = employeeSlice;
export default employeeReducer;
