import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    detail:null,
};

const ACTION = {
    GET_ASSETDOCUMENT: 'assetdocument/getAssetDocument',
    CREATE_ASSETDOCUMENT: 'assetdocument/createAssetDocument',
    EDIT_ASSETDOCUMENT: 'assetdocument/editAssetDocument',
    DELETE_ASSETDOCUMENT: 'assetdocument/deleteAssetDocument',

    GET_ASSETDOCUMENT_BY_ID: 'assetdocument/getAssetDocumentById',
};

export const getAssetDocument = createAsyncThunk(ACTION.GET_ASSETDOCUMENT, async body => {
   
    return axiosInstance.get('/api/documents/', {params:body});
});

export const createAssetDocument = createAsyncThunk(ACTION.CREATE_ASSETDOCUMENT, async body => {
    
    return axiosInstance.post('/api/documents/', body);
});

export const editAssetDocument = createAsyncThunk(ACTION.EDIT_ASSETDOCUMENT, async body => {
    return axiosInstance.put(`/api/documents/${body.id}/`,body);
});

export const deleteAssetDocument = createAsyncThunk(ACTION.DELETE_ASSETDOCUMENT, async (body) => {
    return axiosInstance.delete(`/api/accountant/documents/${body}/`);
});

export const getAssetDocumentById = createAsyncThunk(ACTION.GET_ASSETDOCUMENT_BY_ID, async body => {
   
    return axiosInstance.get(`/api/documents/${body.id}/`);
});

const assetDocumentSlice = createSlice({
    name: 'assetdocument',
    initialState: initialState,
    extraReducers: {
        [getAssetDocument.pending.toString()]: state => {
            state.loading = true;
        },
        [getAssetDocument.rejected.toString()]: state => {
            state.loading = false;
        },
        [getAssetDocument.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            state.list = action.payload;
        },
        ///////////create assetdocument
        [createAssetDocument.pending.toString()]: state => {
        },
        [createAssetDocument.rejected.toString()]: state => {
           state.error = true;
        },
        [createAssetDocument.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },
        // edit assetdocument
        [editAssetDocument.pending.toString()]: state => { },
        [editAssetDocument.rejected.toString()]: state => {
            state.error = true;
        },
        [editAssetDocument.fulfilled.toString()]: state => {
            state.success = true;
        },
        //////////delete assetdocument
        [deleteAssetDocument.pending.toString()]: state => { },
        [deleteAssetDocument.rejected.toString()]: state => {
            state.error = true;
        },
        [deleteAssetDocument.fulfilled.toString()]: state => {
            state.success = true;
        },
        //detail

        [getAssetDocumentById.pending.toString()]: state => {
            state.loadingDetail = true;
        },
        [getAssetDocumentById.rejected.toString()]: state => {
            state.loadingDetail = false;
        },
        [getAssetDocumentById.fulfilled.toString()]: (state, action) => {
            state.loadingDetail = false;
            state.error = false;
            state.success = false;
            state.detail = action.payload;
        },
    }
});
const { reducer: assetDocumentReducer } = assetDocumentSlice;
export default assetDocumentReducer;
