import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../redux/sidebar';

const SidebarMainNavbar = () => {
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector(state => state.sidebar.toggleSidebar);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(!isOpenSidebar));
  };
  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light">
        <NavbarBrand className="w-100 mr-0" href="#" style={{ lineHeight: '25px' }}>
          <div className="d-table m-auto">
            <img
              id="main-logo"
              className="d-inline-block align-top"
              style={{ maxWidth: '90px' }}
              src={require('../../../images/vntel.png')}
              alt="Shards Dashboard"
            />
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none" onClick={handleToggleSidebar}>
          <i className="material-icons" onClick={handleToggleSidebar}>
            &#xE5C4;
          </i>
        </a>
      </Navbar>
    </div>
  );
};

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
