import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    detail:null,
    chart:[],
    
};

const ACTION = {
    GET_EXPENSES_ORDER: 'expenseOrder/getExpenseOrder',
    CREATE_EXPENSES_ORDER: 'expenseOrder/createExpenseOrder',
    EDIT_EXPENSES_ORDER: 'expenseOrder/editExpenseOrder',
    GET_DETAIL_EXPENSES_ORDER: 'expenseOrder/getExpenseOrderDetail',

    UPDATE_EXPENSE_ORDER_PRODUCT: 'expenseOrder/updateExpenseOrderPd',
    CREATE_EXPENSE_ORDER_PRODUCT: 'expenseOrder/createExpenseOrderPd',
    DEL_EXPENSE_ORDER_PRODUCT: 'expenseOrder/delExpenseOrderPd',

    GET_EXPENSE_CHART: 'expenseOrder/getExpenseReportChart',
};

export const getExpenseOrder = createAsyncThunk(ACTION.GET_EXPENSES_ORDER, async body => {
   
    return axiosInstance.get('/api/accountant/expense-orders/', {params:body});
});

export const createExpenseOrder = createAsyncThunk(ACTION.CREATE_EXPENSES_ORDER, async body => {
    
    return axiosInstance.post('/api/accountant/expense-orders/', body);
});

export const editExpenseOrder = createAsyncThunk(ACTION.EDIT_EXPENSES_ORDER, async body => {
    return axiosInstance.put(`/api/accountant/expense-orders/${body.id}/`,body);
});

export const getExpenseOrderDetail = createAsyncThunk(ACTION.GET_DETAIL_EXPENSES_ORDER, async ({ id }) => {
    return axiosInstance.get(`/api/accountant/expense-orders/${id}`);
});

export const updateExpenseOrderPd = createAsyncThunk(ACTION.UPDATE_EXPENSE_ORDER_PRODUCT, async body => {
    return axiosInstance.put(`/api/accountant/expense-details/${body.id}/`, body);
  });
export const createExpenseOrderPd = createAsyncThunk(ACTION.CREATE_EXPENSE_ORDER_PRODUCT, async body => {
return axiosInstance.post('/api/accountant/expense-details/', body);
});

export const delExpenseOrderPd = createAsyncThunk(ACTION.DEL_EXPENSE_ORDER_PRODUCT, async body => {
return axiosInstance.delete(`/api/accountant/expense-details/${body.id}/`);
});

export const getExpenseReportChart = createAsyncThunk(ACTION.GET_EXPENSE_CHART, async body => {
   
    return axiosInstance.get('/api/accountant/expense-reports/', {params:body});
});


const expenseOrderSlice = createSlice({
    name: 'expenseOrder',
    initialState: initialState,
    extraReducers: {
        [getExpenseOrder.pending.toString()]: state => {
            state.loading = true;
        },
        [getExpenseOrder.rejected.toString()]: state => {
            state.loading = false;
        },
        [getExpenseOrder.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.createError = false;
            state.createSuccess = false;
            state.updateError = false;
            state.updateSuccess = false;
            state.list = action.payload;
        },
        ///////////create expenses
        [createExpenseOrder.pending.toString()]: state => {
        },
        [createExpenseOrder.rejected.toString()]: state => {
            state.createError = true;
        },
        [createExpenseOrder.fulfilled.toString()]: (state, action) => {
            state.createSuccess = true;

        },
        // edit expenses
        [editExpenseOrder.pending.toString()]: state => { },
        [editExpenseOrder.rejected.toString()]: state => {
            state.updateError = true;
        },
        [editExpenseOrder.fulfilled.toString()]: state => {
            state.updateSuccess = true;
        },
        //////////delete expenses
        [getExpenseOrderDetail.pending.toString()]: state => { 
            state.loadingDetail = true;
        },
        [getExpenseOrderDetail.rejected.toString()]: state => {
            state.loadingDetail = false;
        },
        [getExpenseOrderDetail.fulfilled.toString()]:( state,action) => {
            state.loadingDetail = false;
            state.updatePdSuccess = false;
            state.updatePdError = false;
            state.createPdSuccess = false;
            state.createPdError = false;
            state.delPdError=false;
            state.delPdSuccess=false;
            state.detail = action.payload;
        },

        [updateExpenseOrderPd.pending.toString()]: state => {
        },
        [updateExpenseOrderPd.rejected.toString()]: state => {
          state.updatePdError = true;
        },
        [updateExpenseOrderPd.fulfilled.toString()]: (state, action) => {
          state.updatePdSuccess=true;
        },
    
        [createExpenseOrderPd.pending.toString()]: state => {
        },
        [createExpenseOrderPd.rejected.toString()]: state => {
          state.createPdError = true;
        },
        [createExpenseOrderPd.fulfilled.toString()]: (state, action) => {
          state.createPdSuccess=true;
        },

        [delExpenseOrderPd.pending.toString()]: state => {
        },
        [delExpenseOrderPd.rejected.toString()]: state => {
          state.delPdError = true;
        },
        [delExpenseOrderPd.fulfilled.toString()]: (state, action) => {
          state.delPdSuccess=true;
        },

        [getExpenseReportChart.pending.toString()]: state => {
            state.loadingChart = true;
        },
        [getExpenseReportChart.rejected.toString()]: state => {
            state.loadingChart = false;
        },
        [getExpenseReportChart.fulfilled.toString()]: (state, action) => {
            state.loadingChart = false;
          
            state.chart = action.payload;
        },
    }
});

const { reducer: expenseOrderReducer } = expenseOrderSlice;
export default expenseOrderReducer;
