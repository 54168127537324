import React from 'react'

const FormLabel = ({...props}) => {
  return (
    <div className="label-name">
        {props.label}
        {props.children}
        {props.required && <span className="required"> *</span>}
    </div>
  )
}

export default FormLabel