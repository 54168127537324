import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormInput,
  Container,
  FormCheckbox
} from 'shards-react';
import { useDispatch } from 'react-redux';
import { OPTION_FILTER } from '../../components/common/Filter';
import { getControl, updateFee, updateVat, updateOrderService } from '../redux/control';
import NumberFormat from 'react-number-format';
import { getGroupId, updateGroup } from '../redux/group';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import Modal2, { ModalContent } from '../../components/common/Modal';
import useControl from './control/useControl';
import DataTable from './control/DataTable';


const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Filter = React.lazy(() => import('../../components/common/Filter'));
const TelcoDetail = React.lazy(() => import('./group/TelcoDetail'));
const CreateServiceUsed = React.lazy(() => import('./control/create-service-used'));
// const Invoice = React.lazy(() => import('../../components/pdf/Invoice'));
const ControlEdit = React.lazy(() => import('./group/ControlEdit'));
const DialogUpload = React.lazy(() => import('./control/DialogUpload'));
const DialogCompare = React.lazy(() => import('./control/DialogCompare'));
const DialogDebtControl = React.lazy(() => import('./control/DialogDebtControl'));
const DialogAsk = React.lazy(() => import('../../components/common/DialogAsk'));

const Control = () => {
  const dispatch = useDispatch();
  const {
    updateRow,
    setUpdateRow,
    modalAsk,
    setModalAsk,
    setUpdateRowId,
    modalInvoice,
    setModalInvoice,
    modalUpload,
    setModalUpload,
    modalCompare,
    setModalCompare,
    dataEdit,
    setDataEdit,
    idCustomer,
    telcoList,
    control,
    dataAgency,
    statusControl,
    statusGroup,
    filter,
    openModalConfig,
    setOpenModalConfig,
    openUpdateSaleModal,
    setOpenUpdateSaleModal,
    openUpdateStartFeeModal,
    setOpenUpdateStartFeeModal,
    openDebtModal,
    setOpenDebtModal,
    dataDebtControl,
    openUpdateTelco,
    setOpenUpdateTelco,
    openUpdateFeeModal,
    setOpenUpdateFeeModal,
    openUpdateVatModal,
    setOpenUpdateVatModal,
    products,
    isLoading,
    totalCost,
    totalVat,
    productSorted,
    isDownloading,
    datahm,
    setDatahm,
    datatb,
    setDatatb,
    vatUpdate,
    setVatUpdate,
    openSaleOrder,
    setOpenSaleOrder,
    rowsTelco,
    setRowsTelco,
    handleSearch,
    clearProductList,
    handleDeleteRow,
    handleOnDragEnd,
    handleKeyDown
  } = useControl();
  
  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const loadControl = React.useMemo(() => {
    if (isLoading) return <Spinner loading={isLoading} top={'50%'} />;
  }, [isLoading]);

  const checkShowContent = React.useMemo(
    () => filter && filter.customer && filter.typeExport && control && control.group,
    [filter, control]
  );

  const modalVat = React.useMemo(() => {
    if (openUpdateVatModal)
      return (
        <Modal
          open={openUpdateVatModal}
          toggle={() => {
            setOpenUpdateVatModal(!openUpdateVatModal);
          }}>
          <ModalHeader className="font-bold">VAT</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="">Vat</label>
              <FormInput
                size="sm"
                className="mb-3"
                name="vat"
                type="number"
                value={vatUpdate.vat}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setVatUpdate({ ...vatUpdate, [name]: Number.parseFloat(value) });
                }}
                placeholder="Nhập Vat"
              />
            </div>
            <Button
              className={`form-control ${statusControl.success === false ? 'disabled' : ''}`}
              onClick={() => {
                dispatch(updateVat(vatUpdate))
                  .unwrap()
                  .then(() => {
                    dispatch(showMessageSuccess());
                  })
                  .catch(() => {
                    dispatch(showMessageError());
                  });
                  setOpenUpdateVatModal(false);
              }}>
              Lưu
            </Button>
          </ModalBody>
        </Modal>
      );
    return null;
  }, [openUpdateVatModal, vatUpdate,statusControl]);

  const modalFeeHm = React.useMemo(() => {
    if (openUpdateStartFeeModal)
      return (
        <Modal
          open={openUpdateStartFeeModal}
          toggle={() => {
            setOpenUpdateStartFeeModal(!openUpdateStartFeeModal);
          }}>
          <ModalHeader className="font-bold">Phí hòa mạng ban đầu</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="">Đơn giá (VNĐ)</label>
              <NumberFormat
                thousandSeparator={true}
                customInput={FormInput}
                value={datahm.phi_hoa_mang}
                placeholder="Giá"
                onValueChange={(value) => {
                  setDatahm({ ...datahm, phi_hoa_mang: value.floatValue });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Số lượng</label>
              <FormInput
                placeholder="0"
                size="sm"
                className="mb-3"
                type="number"
                value={datahm.sl_hoa_mang}
                onChange={(e) => {
                  setDatahm({ ...datahm, sl_hoa_mang: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Chiết khấu (%)</label>
              <FormInput
                size="sm"
                className="mb-3"
                type="number"
                value={datahm.ck_hoa_mang}
                onChange={(e) => {
                  setDatahm({ ...datahm, ck_hoa_mang: Number.parseFloat(e.target.value) });
                }}
                placeholder="Nhập chiết khấu"
              />
            </div>
            <Button
              className={`form-control ${statusControl.success === false ? 'disabled' : ''}`}
              onClick={async () => {
                clearProductList();
                await dispatch(
                  updateFee({
                    group: control && control.group && control.group.name,
                    data: [
                      {
                        type: 'hoa_mang',
                        qty: datahm.sl_hoa_mang || 0,
                        price: datahm.phi_hoa_mang || 0,
                        ck: datahm.ck_hoa_mang || 0
                      }
                    ]
                  })
                );
                setOpenUpdateStartFeeModal(false);
              }}>
              Lưu
            </Button>
          </ModalBody>
        </Modal>
      );
    return null;
  }, [openUpdateStartFeeModal, datahm,statusControl]);

  const modalFeeTb = React.useMemo(() => {
    if (openUpdateFeeModal)
      return (
        <Modal
          open={openUpdateFeeModal}
          toggle={() => {
            setOpenUpdateFeeModal(!openUpdateFeeModal);
          }}>
          <ModalHeader className="font-bold">Phí thuê bao</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="">Đơn giá (VNĐ)</label>
              <NumberFormat
                thousandSeparator={true}
                customInput={FormInput}
                value={datatb.phi_thue_bao}
                placeholder="Giá"
                onValueChange={(value) => {
                  setDatatb({ ...datatb, phi_thue_bao: value.floatValue });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Số lượng</label>
              <FormInput
                placeholder="0"
                size="sm"
                className="mb-3"
                type="number"
                value={datatb.sl_thue_bao}
                onChange={(e) => {
                  setDatatb({ ...datatb, sl_thue_bao: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Chiết khấu</label>
              <FormInput
                size="sm"
                className="mb-3"
                type="number"
                value={datatb.ck_thue_bao}
                onChange={(e) => {
                  setDatatb({ ...datatb, ck_thue_bao: Number.parseFloat(e.target.value) });
                }}
                placeholder="Nhập chiết khấu"
              />
            </div>
            <Button
              className={`form-control ${
                statusControl && statusControl.success === false ? 'disabled' : ''
              }`}
              onClick={async () => {
                clearProductList();
                await dispatch(
                  updateFee({
                    group: control && control.group && control.group.name,
                    data: [
                      {
                        type: 'thue_bao',
                        qty: datatb.sl_thue_bao || 0,
                        price: datatb.phi_thue_bao || 0,
                        ck: datatb.ck_thue_bao ? datatb.ck_thue_bao : 0
                      }
                    ]
                  })
                );
                // dispatch(getControl(filter));
                setOpenUpdateFeeModal(!openUpdateFeeModal);
              }}>
              Lưu
            </Button>
          </ModalBody>
        </Modal>
      );
    return null;
  }, [openUpdateFeeModal, datatb,statusControl]);

  const modalService = React.useMemo(() => {
    if (openUpdateSaleModal)
      return (
        <Modal2 changeActive={setOpenUpdateSaleModal} active={openUpdateSaleModal}>
          <ModalContent
            className="pu-block"
            heading={'Thông tin chiết khấu'}
            onClose={() => setOpenUpdateSaleModal(false)}>
            <div className="content">
              <TelcoDetail
                rows={rowsTelco}
                setRows={setRowsTelco}
                telcoList={telcoList}
                telcoDetail={control && control.group && control.group.telcos}
              />
              <Button
                style={{
                  marginTop: 10,
                  float: 'right'
                }}
                className={`btn bg-theme ${statusGroup.success === false ? 'disabled' : ''}`}
                onClick={async () => {
                  const submitValues = {
                    id: control.group.id,
                    accs: control.group.accs,
                    telcos:
                      rowsTelco &&
                      rowsTelco
                        .filter((v) => !!v.block)
                        .map((item) => {
                          return { ...item, ck: parseFloat(item.ck) };
                        }),
                    name: control && control.group && control.group.name
                  };
                  await dispatch(updateGroup(submitValues))
                    .unwrap()
                    .then(async () => {
                      await dispatch(showMessageSuccess());
                      await setOpenUpdateSaleModal(false);
                      await dispatch(getGroupId({ id: control.group.id }));
                      await dispatch(getControl(filter));
                    })
                    .catch(async () => {
                      await dispatch(showMessageError('Group chưa có Acccode'));
                      await setOpenUpdateSaleModal(false);
                    });
                }}>
                Lưu
              </Button>
            </div>
          </ModalContent>
        </Modal2>
      );
  }, [openUpdateSaleModal, rowsTelco,statusGroup]);
  const modalTelco = React.useMemo(() => {
    if (openUpdateTelco && openUpdateTelco.data) {
      <Modal
        open={openUpdateTelco.modal}
        toggle={() => {
          setOpenUpdateTelco({ ...openUpdateTelco, modal: !openUpdateTelco.modal });
        }}>
        <ModalHeader className="font-bold">{openUpdateTelco.data.name}</ModalHeader>
        <ModalBody>
          <ControlEdit
            groupDetail={openUpdateTelco.data}
            setUDO={setOpenUpdateTelco}
            handleReloadControl={() => dispatch(getControl({ ...filter }))}
          />
        </ModalBody>
      </Modal>;
    }
    return null;
  }, [openUpdateTelco]);

  const modalAskControl = React.useMemo(() => {
    if (modalAsk)
      return (
        <Modal2 changeActive={setModalAsk} active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
            }}>
            <DialogAsk
              handleCanel={setModalAsk}
              text={`Bạn có muốn xoá ${dataEdit && dataEdit.name}`}
              handleConfirm={handleDeleteRow}
              isDisabled={statusControl && statusControl.success}
            />
          </ModalContent>
        </Modal2>
      );
    return null;
  }, [modalAsk,statusControl]);

  // const modalInvoiceControl = React.useMemo(() => {
  //   if (modalInvoice)
  //     return (
  //       <Modal2 changeActive={setModalInvoice} active={modalInvoice}>
  //         <ModalContent
  //           className="pu-review"
  //           onClose={() => {
  //             setModalInvoice(false);
  //           }}>
  //           <Invoice dataInvoice={idCustomer} dataAgency={dataAgency} />
  //         </ModalContent>
  //       </Modal2>
  //     );
  //   return null;
  // }, [modalInvoice, dataAgency]);

  const modalUploadControl = React.useMemo(() => {
    if (modalUpload)
      return (
        <Modal2 changeActive={setModalUpload} active={modalUpload}>
          <ModalContent
            className="pu-review"
            onClose={() => {
              setModalUpload(false);
            }}>
            <DialogUpload
              setModalUpload={setModalUpload}
              groupId={control && control.group && control.group.id}
            />
          </ModalContent>
        </Modal2>
      );
    return null;
  }, [modalUpload]);

  const modalCompareControl = React.useMemo(() => {
    if (modalCompare)
      return (
        <Modal2 changeActive={setModalCompare} active={modalCompare}>
          <ModalContent
            className="pu-compare-ds"
            onClose={() => {
              setModalCompare(false);
            }}>
            <DialogCompare
              totalCost={totalCost}
              totalVat={totalVat}
              control={control}
              products={products}
              setUDO={setModalCompare}
              listCompare={control && control.histories}
            />
          </ModalContent>
        </Modal2>
      );
    return null;
  }, [modalCompare]);

  const modalDebcontrol = React.useMemo(() => {
    if (openDebtModal)
      return (
        <Modal2 changeActive={setOpenDebtModal} active={openDebtModal}>
          <ModalContent
            className="pu-debt"
            onClose={() => {
              setOpenDebtModal(false);
            }}>
            <DialogDebtControl dataEdit={dataDebtControl} setUDO={setOpenDebtModal} />
          </ModalContent>
        </Modal2>
      );
    return null;
  }, [openDebtModal]);
  const modalUpdateRow = React.useMemo(()=>{
    if(!!updateRow)
    return (
      <Modal
          open={!!updateRow}
          toggle={() => {
            setUpdateRow(null);
            setUpdateRowId(null);
          }}>
          <ModalHeader className="font-bold">{updateRow && updateRow.name}</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="">Đơn giá (VNĐ)</label>
              <NumberFormat
                thousandSeparator={true}
                customInput={FormInput}
                value={updateRow && updateRow.price}
                placeholder="Giá"
                onValueChange={(value) => {
                  setUpdateRow({ ...updateRow, price: value.floatValue });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Số lượng</label>
              <FormInput
                placeholder="0"
                size="sm"
                className="mb-3"
                type="number"
                value={updateRow && updateRow.qty}
                onChange={(e) => {
                  setUpdateRow({ ...updateRow, qty: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Chiết khấu</label>
              <FormInput
                size="sm"
                className="mb-3"
                type="number"
                value={updateRow && updateRow.ck}
                onChange={(e) => {
                  setUpdateRow({ ...updateRow, ck: e.target.value });
                }}
                placeholder="Nhập chiết khấu"
              />
            </div>
            <div className="form-group">
              <FormCheckbox
                checked={!!(updateRow && updateRow.minus)}
                onChange={(e) => {
                  setUpdateRow({ ...updateRow, minus: !updateRow.minus });
                }}>
                Bù trừ
              </FormCheckbox>
            </div>
            <Button
              className={`form-control ${
                statusControl && statusControl.success === false ? 'disabled' : ''
              }`}
              onClick={async () => {
                clearProductList();
                const newdata = {
                  ...updateRow,
                  qty: updateRow.qty || 0,
                  price: updateRow.price || 0,
                  ck: updateRow.ck || 0
                };
                await dispatch(
                  updateOrderService({
                    id: updateRow.id,
                    data: newdata,
                    group: control && control.group && control.group.name
                  })
                );
                setUpdateRowId(null);
              }}>
              Lưu
            </Button>
          </ModalBody>
        </Modal>
    )
    return null
  },[updateRow,statusControl])

  const dataTable = React.useMemo(()=>{
    if(!openSaleOrder)
    return (
      <DataTable 
        setOpenSaleOrder = {setOpenSaleOrder}
        openSaleOrder = {openSaleOrder}
        productSorted = {products}
        handleOnDragEnd = {handleOnDragEnd}
        setUpdateRowId={setUpdateRowId}
        setModalAsk= {setModalAsk}
        setDataEdit={setDataEdit}
        totalCost={totalCost}
        totalVat={totalVat}
        setOpenUpdateVatModal={setOpenUpdateVatModal}
        openUpdateVatModal={openUpdateVatModal}
        control={control}
      />
    )
    return (
      control && control.products && (
        <CreateServiceUsed
          setUDO={setOpenSaleOrder}
          servicesDetail={control.products}
          group={control && control.group && control.group.name}
        />
      )
    )
  },[control,openSaleOrder,products,totalCost,totalVat])


  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Xuất đối soát</h1>
        <Spinner loading={isDownloading} top="30%" />
        <Row>
          <Col>
            <Filter
              onSearch={handleSearch}
              optionFilter={[
                OPTION_FILTER.GROUP_SELECT_SEARCH_CONTROL,
                OPTION_FILTER.DATE_RANGE,
                OPTION_FILTER.TYPE_EXPORT,
                OPTION_FILTER.ACCOUNTANT_MINUS,
                filter.customer !== undefined ? OPTION_FILTER.DOWNLOAD_CONTROL : ''
                //filter.acc_minus !== undefined ? OPTION_FILTER.ACCOUNTANT_MINUS : ''
              ]}
              setModalInvoice={setModalInvoice}
              setModalUpload={setModalUpload}
              setModalCompare={setModalCompare}
              setOpenDebtModal={setOpenDebtModal}
              setOpenModalConfig={setOpenModalConfig}
              openModalConfig={openModalConfig}
              groupName={control && control.group && control.group.name}
              groupId={control && control.group && control.group.id}
            />
            {loadControl}
            {checkShowContent && (
              <>
                {filter.typeExport !== 'product' && (
                  <div className="overflow-x-auto">
                    <div className="card card-small mb-4 overflow-x-auto">
                      <div className="card-header border-bottom">
                        <span className="m-0 font-bold">Chiết khấu đang áp dụng</span>
                      </div>

                      <table className="table mb-0 overflow-auto">
                        <thead className="bg-white">
                          <tr>
                            {control.group &&
                              control.group.telcos &&
                              control.group.telcos.slice(0, 4).map((telco) => (
                                <th key={telco.telco} scope="col" className="border-0 text-center">
                                  {telco.telco}
                                </th>
                              ))}

                            <th scope="col" className="border-0"></th>
                          </tr>
                        </thead>
                        {control.group && control.group.telcos && (
                          <tbody>
                            <tr>
                              {control.group.telcos.slice(0, 4).map((telco) => (
                                <td key={telco.telco} className="text-center border-0">
                                  B:{telco.block} - G:{telco.price} - C:{telco.ck}
                                </td>
                              ))}

                              <td>
                                <Button
                                  className="form-control"
                                  onClick={() => {
                                    setOpenUpdateSaleModal(!openUpdateSaleModal);
                                  }}>
                                  Cập nhật
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}

                {/* if typeexport = all */}
                {dataTable}
              </>
            )}
          </Col>
        </Row>

        {/* modal VAT */}
        {modalVat}

        {modalService}

        {/* fee hm */}
        {modalFeeHm}
        {modalTelco}
        {modalFeeTb}

        {modalAskControl}

        {modalUpdateRow}
        {modalUpdateRow}

        {/* {modalInvoiceControl} */}

        {modalUploadControl}

        {modalCompareControl}

        {modalDebcontrol}
      </Container>
    </React.Suspense>
  );
};

export default Control;
