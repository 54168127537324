import React, { useEffect, useState } from 'react';
import { Row, Col, Container, FormSelect, Button } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../hooks/use-pagination';
import { getCompare } from '../redux/compare';
import { MONTH } from '../../../src/constants';
import moment from 'moment';
import { getYearArr } from '../helps/help';

const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));

const Compare = () => {
  const dispatch = useDispatch();
  const compare = useSelector(state => state.compare.compare);
  const [filter, setFilter] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isFilePicked, setIsFilePicked] = useState(false);
  const isLoading = useSelector(state => state.compare.loading);

  const year = moment().year()



  function range(start, end) {
    return new Array(end - start).fill().map((d, i) => i + start);
  }
const YEAR = range(2021, 2041)

  const handleSearch = () => {
    // event.preventDefault();
    const formData = new FormData();
    formData.append('files', selectedFile);
    formData.append('month', filter['month'] ? filter['month'] : MONTH[0]);
    formData.append('filter', filter['filter'] ? filter['filter'] : 0);
    formData.append('year', filter['year'] ? filter['year'] : 2022);
    formData.append('page', pagination.page);
    formData.append('perPage', pagination.perPage);
    dispatch(getCompare(formData));
  };

  const handleOnChange = event => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const pagination = usePaginationState();
  useEffect(() => {
    if (selectedFile) {
      handleSearch({ ...filter, page: pagination.page, perPage: pagination.perPage });
    }
  }, [pagination]);

  const filterMenu = [{
    value: 0,
    label: 'Tất cả'
  },
  {
    value: 1,
    label: 'Bản ghi khớp'
  },
  {
    value: 2,
    label: 'Bản ghi không khớp'
  }]

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">So sánh</h1>
        <Row>
          <Col>
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">CDR So Sánh Dữ Liệu</h6>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item p-3">
                  <div className="row">
                    <div className="col">
                      <div className="form-row">
                        <div className="form-group col-md-3">
                          <label htmlFor="">Chọn tháng</label>
                          <FormSelect
                            id="feInputState"
                            onChange={e => {
                              filter['month'] = e.target.value;
                              setFilter({ ...filter });
                            }}>
                            {MONTH.map(item => {
                              return <option key={item}>{item}</option>;
                            })}
                          </FormSelect>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="">Chọn năm</label>
                          <FormSelect
                            id="feInputStateYear"
                            onChange={e => {
                              filter['year'] = e.target.value;
                              setFilter({ ...filter });
                            }}>
                            {YEAR.map(item => {
                              return <option key={item}>{item}</option>;
                            })}
                          </FormSelect>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="">Lọc</label>
                          <FormSelect
                            id="feInputStateYear"
                            onChange={e => {
                              filter['filter'] = e.target.value;
                              setFilter({ ...filter });
                            }}>
                            {filterMenu.map((item, idx) => {
                              return <option value={item.value} key={idx}>{item.label}</option>;
                            })}
                          </FormSelect>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="">Chọn tệp so sánh</label>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            // className="opacity-0 overflow-hidden absolute"
                            style={{ zIndex: -1 }}
                            accept={'.csv'}
                            onChange={handleOnChange}
                          />
                          {/* <label
                              htmlFor="file"
                              className="font-bold text-xs bare-button-table mr-4 underline cursor-pointer">
                              Chọn file tải lên
                            </label> */}
                        </div>
                        <div className="form-group col-md-1">
                          {' '}
                          <label htmlFor="">&nbsp;</label>
                          <Button type="submit" className="form-control" onClick={handleSearch}>
                            So sánh
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {selectedFile && <Spinner loading={isLoading} />}

            <div className="table-wrapper">
              {compare.equal && (
                <div className="mt-2 ml-2">
                  <div>{`Khớp: ${compare.equal} bản ghi`}</div>
                  <div>{`Không khớp: ${compare.not_equals} bản ghi`}</div>
                </div>
              )}
              <table className="table mb-0">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="border-0">
                      STT
                    </th>
                    <th scope="col" className="border-0">
                      Mã
                    </th>
                    <th scope="col" className="border-0 text-right">
                      Tổng bản ghi hệ thống
                    </th>
                    <th scope="col" className="border-0 text-right">
                      Tổng bản ghi từ file
                    </th>
                  </tr>
                </thead>
                {compare && (
                  <tbody>
                    {compare.list.map((item, idx) => {
                      return (
                        <tr key={idx} className={item.records === item.files ? 'text-success' : 'text-danger'}>
                          <td>{idx + 1}</td>
                          <td>{item.account}</td>
                          <td className="text-right">{item.records}</td>
                          <td className="text-right">{item.files}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <Pagination
                currentPage={pagination.page}
                pageSize={pagination.perPage}
                lastPage={Math.min(
                pagination.page + 5,
                Math.ceil(compare.total / pagination.perPage)
              )}                onChangePage={pagination.setPage}
                onChangePageSize={pagination.setPerPage}
                onGoToLast={() => pagination.setPage(Math.ceil(compare.total / pagination.perPage))}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default Compare;
