import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'shards-react';
import { getEnduser } from '../redux/enduser';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const DialogCreateEndUser = React.lazy(() => import('../../components/sim/DialogCreateEndUser'));
const EndUser = () => {
  const dispatch = useDispatch();

  const { enduser, loading, successCreate } = useSelector(state => state.enduser);
  const profile = useSelector(state => state.auth.profile);
  const [openModal, setOpenModal] = useState(false);

  const wrapperRef = useRef(null);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const closeModal = e => {
    if (e.target === wrapperRef.current) setOpenModal(false);
  };

  useEffect(() => {
    dispatch(getEnduser({}));
  }, []);

  useEffect(() => {
    if (successCreate === true) {
      dispatch(getEnduser({}));
    }
  }, [successCreate]);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        {openModal && profile && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">Tạo người dùng cuối</h3>
                <div className="popup-close" onClick={() => handleCloseModal()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogCreateEndUser setUDO={setOpenModal} dataProfile={profile} />
              </div>
            </div>
          </>
        )}

        <h1 className="heading">
          Danh sách người dùng cuối
          <Tooltip title={'Tạo mới'} position="right" size={'small'} animation={'shift'}>
            <span
              style={{top:-5}}
              className="btn-create"
              onClick={() => {
                setOpenModal(true);
              }}>
              <i className="fa fa-plus"></i>
            </span>
          </Tooltip>
        </h1>
        <Row>
          <Col>
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        Tên
                      </th>
                      <th scope="col" className="border-0">
                        Mã Code
                      </th>
                      <th scope="col" className="border-0">
                        Địa chỉ
                      </th>
                      <th scope="col" className="border-0">
                        SĐT
                      </th>
                      <th scope="col" className="border-0">
                        Người quản lý
                      </th>

                      <th scope="col" className="border-0">
                        Tax code
                      </th>
                      <th scope="col" className="border-0">
                        Ngày tạo
                      </th>
                      <th scope="col" className="border-0 text-center">
                        Trạng thái
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {enduser.info &&
                      enduser.info.length > 0 &&
                      enduser.info.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.code}</td>
                            <td>{item.address}</td>
                            <td>{item.phone}</td>
                            <td>{item.manager_org && item.manager_org.username}</td>
                            <td>{item.tax_code}</td>
                            <td>{moment(item.create_on).format('YYYY-MM-DD')}</td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.end_user_status === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default EndUser;
