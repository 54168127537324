import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getReportRarAll, getReportVideocall } from '../../redux/videocall';
import { usePaginationState } from '../../../hooks/use-pagination';
import moment from 'moment';
import useExcel from './useExcel';
const useVideocall = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.videocall)
  const [file,setFile] = React.useState([])
  const pagination = usePaginationState();
  const [filterChart, setFilterChart] = React.useState({
    from: moment()
      .add(-7, 'days')
      .toDate(),
    to: moment().toDate()
  });
  const excelConfig = {
    workSheetName : 'Danh sách RAR',
    workBookName : 'DanhSachRar',
    myInputId : 'myInput',
    columns : [
      { header: 'ID', key: 'id' },
      { header: 'Tài khoản', key: 'user' },
      { header: 'Kết quả xác thực', key: 'accuracy' },
      { header: 'client_ip', key: 'client_ip' },
      { header: 'Ngày gọi', key: 'created_at' },
      { header: 'endpoint', key: 'endpoint' },
      { header: 'include_image', key: 'include_image' },
    ],
  }

   
  useExcel({excelConfig,file, setFile})
    

  React.useEffect(() => {
    dispatch(getReportVideocall({
      limit: pagination.perPage,
      offset: pagination.perPage * pagination.page - pagination.perPage,
      from: moment(filterChart.from).format('YYYY-MM-DD'),
      to: moment(filterChart.to).format('YYYY-MM-DD')
    }))
  }, [pagination])

  const getReport = () => {
    dispatch(getReportVideocall({
      limit: pagination.perPage,
      offset: pagination.perPage * pagination.page - pagination.perPage,
      from: moment(filterChart.from).format('YYYY-MM-DD'),
      to: moment(filterChart.to).format('YYYY-MM-DD')
    }))
  }

  const getExcelAll = () => {
    dispatch(getReportRarAll({
      from: moment(filterChart.from).format('YYYY-MM-DD'),
      to: moment(filterChart.to).format('YYYY-MM-DD'),
      export:`true`
    })).unwrap().then((res)=>{
      setFile(res && res.results)
    })
  }

  return React.useMemo(
    () => ({
      state,
      loading: state && state.loading,
      success:state && state.success,
      list: state && state.list,
      results: state && state.list && state.list.results || [],
      pagination,
      setFilterChart,
      filterChart,
      getReport,
      getExcelAll,
      myInputId:excelConfig && excelConfig.myInputId,
      workBookName:excelConfig && excelConfig.workBookName,
    }),
    [state, pagination]
  );
}

export default useVideocall