import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  loading: false,
  compare: {
    list: []
  }
};

const ACTION = {
  GET_COMPARE: 'compare/getCompare'
};

export const getCompare = createAsyncThunk(ACTION.GET_COMPARE, async body => {
  return axiosInstance.post('/restapi/export/compare', body, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
});

const compareSlice = createSlice({
  name: 'compare',
  initialState: initialState,
  extraReducers: {
    [getCompare.pending.toString()]: state => {
      state.loading = true;
    },
    [getCompare.rejected.toString()]: state => {
      state.loading = false;
    },
    [getCompare.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.compare = action.payload;
    }
  }
});

const { reducer: compareReducer } = compareSlice;
export default compareReducer;
