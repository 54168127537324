import React, { useEffect, useState, useRef } from 'react';
import { Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../hooks/use-pagination';
import { getPermission } from '../redux/permission';

const DialogCreatePermission = React.lazy(() =>
  import('../../components/permission/DialogCreatePermission')
);
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));

const Permission = () => {
  const dispatch = useDispatch();
  const permissionList = useSelector(state => state.permission.permissionList);
  const isLoading = useSelector(state => state.permission.loading);
  const status = useSelector(state => state.permission)
  const [filter, setFilter] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const wrapperRef = useRef(null);

  const handleSearch = filter => {
    setFilter({ ...filter });
    dispatch(getPermission({ ...filter }));
  };

  const pagination = usePaginationState();

  useEffect(() => {
    handleSearch({ ...filter });
  }, [pagination]);


  useEffect(() => {
    if(status.success === true){
      handleSearch({ ...filter });
    }
  }, [status.success])
  

  const closeModal = e => {
    if (e.target === wrapperRef.current) setOpenModal(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  })

  const handleKeyDown = e => {
    if (openModal && e.keyCode == 27) {
      setOpenModal(false)
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý phân quyền
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm permission
          </span>
        </h1>
        {openModal && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">Thêm mới permisson</h3>
                <div className="popup-close" onClick={() => handleCloseModal()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogCreatePermission setUDO={setOpenModal} />
              </div>
            </div>
          </>
        )}
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0">
                    ID
                  </th>
                  <th scope="col" className="border-0">
                    Mã
                  </th>
                  <th scope="col" className="border-0">
                    Quyền
                  </th>
                </tr>
              </thead>
              {permissionList.list && (
                <tbody>
                  {permissionList.list.map((per, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{per.id}</td>
                        <td>{per.per}</td>
                        <td>{per.vn}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.min(
                pagination.page + 5,
                Math.ceil(permissionList.total / pagination.perPage)
              )}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(Math.ceil(permissionList.total / pagination.perPage))
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Permission;
