import React,{useState} from 'react';
import { Tooltip } from 'shards-react';
import Empty from '../../../components/common/Empty';
import Modal, { ModalContent } from '../../../components/common/Modal';
import { formatMoney } from '../../helps/convertMoney';
import DialogTelco from './DialogTelco';

const TableTelco = ({ dataTelco, groupId}) => {
    const [data, setData] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [nameTelco, setNameTelco] = useState({
      viettel: '',
      vinaphone: '',
      mobifone: ''
    });
  return (
<>
    <Modal changeActive={setOpenModal} active={openModal} id={`${!data ? '' : `modal_${data.id}`}`}>
          <ModalContent heading={'Sửa'} onClose={()=>{
            setOpenModal(false)
            setData('')
            setNameTelco({
              viettel: '',
              vinaphone: '',
              mobifone: ''
            })
          }}>
            <DialogTelco setNameTelco={setNameTelco} nameTelco={nameTelco} groupId={groupId} setUDO={setOpenModal} setData={setData} dataEdit={data} />
          </ModalContent>
        </Modal>


    <div className="table-wrapper">
      <table className="table mb-0">
        <thead className="bg-white">
          <tr>
            <th scope="col" className="border-0" style={{ width: '40px' }}>
              STT
            </th>
            <th scope="col" className="border-0">
              Tên nhà mạng
            </th>
            <th scope="col" className="border-0">
              Số phút
            </th>
            <th scope="col" className="border-0">
              Giá
            </th>
            <th scope="col" className="border-0">
              Chiết khấu
            </th>
            <th scope="col" className="border-0 text-center">
              Tính tất cả thời gian cuộc gọi
            </th>

            <th scope="col" className="border-0">
              Hành động
            </th>
          </tr>
        </thead>

        <tbody>
          {dataTelco &&
            dataTelco.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.limit}</td>
                  <td>{formatMoney(item.price)}</td>
                  <td>{item.ck}</td>
                  <td className="text-center">
                    <span className={`fa ${item.all === true ? 'fa-check' : 'fa-times'}`}></span>
                  </td>
                  <td>
                    <button
                         onClick={() => {
                           setOpenModal(true);
                           setData(item);
                         }}
                      className="btn btn-edit">
                      <i className="fa fa-edit"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {dataTelco && dataTelco.length === 0 && <Empty description="Không có dữ liệu" />}
    </div>
    </>
  );
};

export default TableTelco;
