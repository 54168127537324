import React, { useEffect, useRef } from "react";
import ToastItem from "./ToastItem";

const ToastList = ({ data, removeToast }) => {
  const listRef = useRef(null);

   const handleScrolling = (el) => {
    el?.scrollTo(0, 0);
   };

   useEffect(() => {
     handleScrolling(listRef.current);
   }, [data]);

  const sortedData = data.length > 0 && [...data].reverse()

  return (
    sortedData.length > 0 && (
      <div
        className={`toast-list toast-list--`}
        aria-live="assertive"
        ref={listRef}
      >
        {sortedData.map((toast) => (
          <ToastItem
            key={toast.id}
            message={toast.message}
            type={toast.type}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div>
    )
  );
};



export default ToastList;
