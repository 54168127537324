import React from 'react';
import { FormInput, Row, Col } from 'shards-react';
import { HelpText } from '../../../components/components-overview/Help-text';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch,useSelector } from 'react-redux';
import { createAccCode, updateAccCode } from '../../redux/account';
import { showMessageSuccess, showMessageError } from '../../redux/alert-message';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const FormLabel = React.lazy(() => import('../../../components/common/FormLabel'));
const DialogAccCode = ({ dataEdit = null, setUDO, setData }) => {
  const status = useSelector(state => state.accounts)
  const dispatch = useDispatch();

  const submitForm = values => {
    if (!dataEdit) {
      dispatch(createAccCode(values))
        .unwrap()
        .then(() => {
          dispatch(showMessageSuccess());
        })
        .catch(() => {
          dispatch(showMessageError());
        });
    } else {
      const submitValues = {
        ...values,
        id: dataEdit.id
      };
      dispatch(updateAccCode(submitValues))
        .unwrap()
        .then(() => {
          dispatch(showMessageSuccess());
        })
        .catch(() => {
          dispatch(showMessageError());
        });
    }

    setUDO(false);
    setData('');
  };

  const validationSchema = yup.object({
    code: yup.string().required('Không được bỏ trống'),
  
  });

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched
  } = useFormik({
    initialValues: {
      code: (dataEdit && dataEdit.code) || '',
   
    },
    validationSchema: validationSchema,
    onSubmit: submitForm
  });
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <div className="content">
        <div className="box-form">
          <Row>
          <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Code" required />
                <FormInput
                  type="text"
                  name="code"
                  placeholder="Mã"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.code && errors.code}
                />
                <HelpText status="error">{touched.code && errors.code ? errors.code : ''}</HelpText>
              </div>
            </Col>

            <Col xs={12}>
              <div className="form-input-box text-right">
                <button className={`btn ${status.success === false ? 'disabled' : ''}`} type="submit" onClick={handleSubmit}>
                  {!dataEdit ? 'Tạo' : 'Sửa'}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Suspense>
  );
};

export default DialogAccCode;
