import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  cityList: {},
  districtList: {},
  wardList: {},
  wardDetail:{},
  loading: false
};

const ACTION = {
  GET_CITY: 'city/getCity',
  GET_DISTRICT: 'district/getDistrict',
  GET_WARD: 'ward/getWard',
  GET_DETAIL_WARD: 'ward/getDetailWard',
};

export const getCity = createAsyncThunk(ACTION.GET_CITY, async body => {
  return axiosInstance.get('/api/address/cities/', { params: body });
});

export const getDistrict = createAsyncThunk(ACTION.GET_DISTRICT, async body => {
  return axiosInstance.get(`/api/address/cities/${body.cityId}`);
});

export const getWard = createAsyncThunk(ACTION.GET_WARD, async body => {
  return axiosInstance.get(`/api/address/districts/${body.districtId}`);
});

export const getDetailWard = createAsyncThunk(ACTION.GET_DETAIL_WARD, async body => {
  return axiosInstance.get(`/api/address/wards/${body.wardId}/`);
  
});

const addressSlice = createSlice({
  name: 'address',
  initialState: initialState,
  extraReducers: {
    [getCity.pending.toString()]: state => {
      state.loading = true;
    },
    [getCity.rejected.toString()]: state => {
      state.loading = false;
    },
    [getCity.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.cityList = action.payload;
    },
    [getDistrict.pending.toString()]: state => {
      state.loadingDistrict = true;
    },
    [getDistrict.rejected.toString()]: state => {
      state.loadingDistrict = false;
    },
    [getDistrict.fulfilled.toString()]: (state, action) => {
      state.loadingDistrict = false;
      state.districtList = action.payload;
    },
    [getWard.pending.toString()]: state => {
      state.loadingWard = true;
    },
    [getWard.rejected.toString()]: state => {
      state.loadingWard = false;
    },
    [getWard.fulfilled.toString()]: (state, action) => {
      state.loadingWard = false;
      state.wardList = action.payload;
    },
    [getDetailWard.pending.toString()]: state => {
      state.loading = true;
    },
    [getDetailWard.rejected.toString()]: state => {
      state.loading = false;
    },
    [getDetailWard.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.wardDetail = action.payload;
    }
  }
});

const { reducer: addresstReducer } = addressSlice;
export default addresstReducer;
