import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  f88Detail: {},
  f88: {},
  loading: false,
  f88DetailPhone: {}
};

const ACTION = {
  GET_F88: 'f88/getF88',
  GET_F88_DETAIL: 'f88/getF88Detail',
  GET_F88_DETAIL_PHONE: 'f88/getF88DetailPhone',
  DOWNLOAD_F88_PHONE_DETAIL: 'f88/getF88DetailPhone'
};

export const getF88 = createAsyncThunk(ACTION.GET_F88, async body => {
  return axiosInstance.get('/restapi/export/f88', { params: body });
});

export const getF88ByCode = createAsyncThunk(ACTION.GET_F88_DETAIL, async body => {
  return axiosInstance.get(`/restapi/export/f88/${body.code}`, { params: body });
});

export const getF88ByPhone = createAsyncThunk(ACTION.GET_F88_DETAIL_PHONE, async body => {
  return axiosInstance.get(`/restapi/export/f88/${body.code}/${body.phone}`, { params: body });
});

export const downloadF88PhoneDetail = createAsyncThunk(
  ACTION.DOWNLOAD_F88_PHONE_DETAIL,
  async body => {
    return axiosInstance.get(`/restapi/export/f88/${body.code}/${body.phone}/download`, {
      params: body
    });
  }
);

const f88Slice = createSlice({
  name: 'f88',
  initialState: initialState,
  extraReducers: {
    [getF88.pending.toString()]: state => {
      state.loading = true;
    },
    [getF88.rejected.toString()]: state => {
      state.loading = false;
    },
    [getF88.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.f88 = action.payload;
    },
    [getF88ByCode.pending.toString()]: state => {
      state.loading = true;
    },
    [getF88ByCode.rejected.toString()]: state => {
      state.loading = false;
    },
    [getF88ByCode.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.f88Detail = action.payload;
    },
    [getF88ByPhone.pending.toString()]: state => {
      state.loading = true;
    },
    [getF88ByPhone.rejected.toString()]: state => {
      state.loading = false;
    },
    [getF88ByPhone.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.f88DetailPhone = action.payload;
    }
  }
});

const { reducer: f88Reducer } = f88Slice;
export default f88Reducer;
