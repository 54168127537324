import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

// cookie
export const getCookieStorage = key => Cookies.get(key);

export const setOneCookieStorage = (key, data) => {
  const domain = process.env.REACT_APP_COOKIE_DOMAIN || '';
  Cookies.set(key, typeof data === 'object' ? JSON.stringify(data) : data, { domain });
};

export const setAllCookieStorage = data =>
  data.forEach(item => setOneCookieStorage(item.key, item.value));

export const removeOneCookieStorage = key => {
  const domain = process.env.REACT_APP_COOKIE_DOMAIN || '';
  Cookies.remove(key, { domain });
};

export const removeAllCookieStorage = data => data.forEach(item => removeOneCookieStorage(item));

export const setTokenCookie = (access_token, refresh_token) => {
  try {
    const tokenDecoded = jwt_decode(access_token);
    const refreshTokenDecoded = jwt_decode(refresh_token);
    const expToken = tokenDecoded.exp ? parseFloat(tokenDecoded.exp) : 0;
    const expRefreshToken = refreshTokenDecoded.exp
      ? parseFloat(refreshTokenDecoded.exp)
      : 0;

    setAllCookieStorage([
      { key: 'access_token', value: access_token },
      { key: 'refresh_token', value: refresh_token },
      { key: 'expire_refresh_token', value: expRefreshToken },
      { key: 'expire_token', value: expToken }
    ]);
  } catch (error) {}
};
