import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  loading: false,

};

const ACTION = {
  GET_REPORT_VIDEOCALL: 'videocall/getReportVideocall',
  GET_REPORT_RAR: 'videocall/getReportRarAll',
};

export const getReportVideocall = createAsyncThunk(ACTION.GET_REPORT_VIDEOCALL, async body => {
  return axiosInstance.get('/restapi/export/report-history', {params: body});
});

export const getReportRarAll = createAsyncThunk(ACTION.GET_REPORT_RAR, async body => {
  return axiosInstance.get('/restapi/export/report-history', {params: body});
});

const videocallSlice = createSlice({
  name: 'videocall',
  initialState: initialState,
  extraReducers: {
    [getReportVideocall.pending.toString()]: state => {
      state.loading = true;
    },
    [getReportVideocall.rejected.toString()]: state => {
      state.loading = false;
    },
    [getReportVideocall.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [getReportRarAll.pending.toString()]: state => {
      state.success = false;
    },
    [getReportRarAll.rejected.toString()]: state => {
      state.success = true;
    },
    [getReportRarAll.fulfilled.toString()]: (state, action) => {
      state.success = true;
      state.file = action.payload;
    }
  }
});

const { reducer: videocallReducer } = videocallSlice;
export default videocallReducer;
