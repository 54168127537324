import React from 'react';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import moment from 'moment';
import localization from 'moment/locale/vi';
import to_vietnamese from '../../helps/transNumText';
import logoFile from '../../../images/vntel.png';
import axios from 'axios';
import { splitLastOccurrence } from '../../helps/help';

const ControlExcel = (props) => {
  const [workBookName, setWorkBookName] = React.useState('')
  const data = useSelector((state) => state.control.invoice);
  const dataAccount = useSelector((state) => state.control.dataAccountControl);
  const control = useSelector((state) => state.control.control);
  moment.locale('vi', localization);
  const date = new Date();
  const dateInvoice = moment(date).format('LL');
  const contractName = props.contractName || data && data.annexContract && data.annexContract.length > 0 && data.annexContract.map((v) => v.name) || ''

  const invoiceName =
    (data && data.nameInvoice) || (control && control.group && control.group.contract) || '';
  const type_of_control = (data && data.type_of_control) ? data.type_of_control : 'Contact Center'
  const customerName = data && (data.nameCustomer || data.value);
  const customerS1 = 'NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN PHƯƠNG ĐÔNG (OCB)';
  const customerS2 = 'CÔNG TY CỔ PHẦN TOKIKIN';
  const customerS3 = 'NGÂN HÀNG TMCP BẢN VIỆT';

  // phuong dong gtel

  const arrGtel = ['GTEL cố định gọi di động', 'GTEL cố định gọi cố định'];

  // phuong dong vnpt
  const arrVnpt = [
    'VNPT cố định gọi cố định nội hạt',
    'VNPT cố định gọi cố định liên tỉnh',
    'VNPT cố định gọi di động'
  ];

  // TOKIKIN 1800
  const arr1800 = ['1800-Mobile','1800-Landline',];

  // phi vuot phut
  const arrFilter = [
    'Phí vượt phút Viettel',
    'Phí vượt phút Vinaphone',
    'Phí vượt phút Mobifone',
    'Phí vượt phút mobifone',
    'Phí vượt phút vinaphone',
    'Phí vượt phút viettel'
  ];

  // product base
  const dataProduct = (type) => {
    let dataFilter = [];
    if ([1,2].includes(type)) {
      if (customerS1 === (data && data.agency)) {
        return (dataFilter =
          data &&
          data.productControl &&
          data.productControl.filter(
            (m) =>
              m.qty !== 0 &&
              m.price !== 0 &&
              !arrFilter.includes(m.name) &&
              !arrGtel.includes(m.name) &&
              !arrVnpt.includes(m.name) &&
              !arr1800.includes(m.code)
          ));
      }
      if (customerS2 === (data && data.agency)) {
        return (dataFilter =
          data &&
          data.productControl &&
          data.productControl.filter(
            (m) =>
              m.qty !== 0 &&
              m.price !== 0 &&
              !arrFilter.includes(m.name) &&
              !arr1800.includes(m.code)
          ));
      } else {
        return (dataFilter =
          data &&
          data.productControl &&
          data.productControl.filter(
            (m) => m.qty !== 0 && m.price !== 0 && !arrFilter.includes(m.name)
          ));
      }
    }
  };
  // product base telco
  const dataProductTelco = (type) => {
    let dataFilter = [];
    if ([1,2].includes(type)) {
      return (dataFilter =
        data &&
        data.productControl &&
        data.productControl.filter(
          (m) => m.qty !== 0 && m.price !== 0 && arrFilter.includes(m.name)
        ));
    }
  };
  // product base cuoc phi dau so co dinh vnpt - NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN PHƯƠNG ĐÔNG
  const dataProductVnpt = (type) => {
    let dataFilter = [];
    if ([1,2].includes(type)) {
      return (dataFilter =
        data &&
        data.productControl &&
        data.productControl.filter(
          (m) => m.qty !== 0 && m.price !== 0 && arrVnpt.includes(m.name)
        ));
    }
  };
  // gtel phuong dong
  const dataProductGtel = (type) => {
    let dataFilter = [];
    if ([1,2].includes(type)) {
      const newData =
        data &&
        data.productControl &&
        data.productControl.filter((m) => m.qty !== 0 && m.price !== 0 && arrGtel.includes(m.name));

      const mergeArr = (arr1, arr2) => {
        const obj = {};
        let qty = 0;
        let price = 0;
        let ck = 0;
        arr1.forEach((item) => {
          obj[item] = { name: item,qty: qty, price: price, ck:ck };
        });

        arr2.forEach((item) => {
          obj[item.name] ? (obj[item.name] = { ...obj[item], ...item }) : (obj[item.name] = item);
        });
        return Object.values(obj);
      };

      if (newData) {
        return (dataFilter = mergeArr(arrGtel, newData));
      }
    }
  };
  // 1800 tokiki
  const dataProduct1800 = (type) => {
    let dataFilter = [];
    if ([1,2].includes(type)) {
      const newData =
        data &&
        data.productControl &&
        data.productControl.filter((m) => m.qty !== 0 && m.price !== 0 && arr1800.includes(m.code));

      const mergeArr = (arr1, arr2) => {
        const obj = {};
        let rename = '';
        let qty = 0;
        let price = 0;
        let ck = 0;
        arr1.forEach((item) => {
          if (item === '1800-Mobile') {
            rename = 'Cước phí gọi từ di động trong nước';
          }
          if (item === '1800-Landline') {
            
            rename = 'Cước phí gọi từ số cố định';
          }
          obj[item] = { code: item, rename: rename, qty: qty, price: price, ck:ck };
        });

        arr2.forEach((item) => {
          if (item.code === '1800-Mobile') {
            rename = 'Cước phí gọi từ di động trong nước';
          }
          if (item.code === '1800-Landline') {
            rename = 'Cước phí gọi từ số cố định';
          }
          obj[item.code]
            ? (obj[item.code] = { ...obj[item], ...item, rename })
            : (obj[item.code] = item);
        });
        return Object.values(obj);
      };
      if (newData) {
        return (dataFilter = mergeArr(arr1800, newData));
      }
    }
  };

  const sum = (arr) => {
    if (arr) {
      let total = 0;
      arr.map((item) => {
        let cost = Math.round(item.price * Math.round(item.qty) * ((100 - item.ck) / 100));
        if (Boolean(item.hasOwnProperty('minus') && item.minus)) {
          cost = -Math.abs(cost);
        }
        return (total += cost);
      });
      return total;
    }
  };

  const keyColumn = (type) => {
    let columns = [];
    if (type === 1) {
      return (columns = [
        { key: 'stt', width: 10 },
        { key: 'product', width: 25 },
        { key: 'productmerge', width: 30 },
        { key: 'price', width: 15 },
        { key: 'qty', width: 15 },
        { key: 'ck', width: 15 },
        { key: 'total', width: 15 },
        { key: 'minus', width: 30 }
      ]);
    }
    if (type === 2) {
      return (columns = [
        { key: 'stt', width: 10 },
        { key: 'product', width: 25 },
        { key: 'productmerge', width: 30 },
        { key: 'price', width: 15 },
        { key: 'qty', width: 15 },
        { key: 'total', width: 15 },
        { key: 'minus', width: 30 }
      ]);
    }
  };

  const keyHeader = (type) => {
    let header = [];
    if (type === 1) {
      return (header = [
        'STT',
        'Diễn giải',
        'Diễn giải',
        'Đơn giá(VNĐ)',
        'Số lượng',
        'Chiết khấu(%)',
        'Thành tiền',
        ''
      ]);
    }
    if (type === 2) {
      return (header = ['STT', 'Diễn giải', 'Diễn giải', 'Đơn giá(VNĐ)', 'Số lượng', 'Thành tiền']);
    }
  };

  const posColumn = (type) => {
    if (type === 1) {
      return `G`;
    }
    if (type === 2) {
      return `F`;
    }
  };

  const posColumnSplit = (type) => {
    if (type === 1) {
      return `F`;
    }
    if (type === 2) {
      return `E`;
    }
  };

  const calculateTotal = (qty, price, ck) => {
    if (!(qty && price)) {
      return 0;
    }
    let total = price * qty * ((100 - ck) / 100);
    // if (v.minus) {
    //   return Math.abs(total) * -1
    // }
    return total;
  };

  // Calculate is today

  const todayHTML = () =>{
    const getToday = new Date()
    
    if(getToday){
    const day = moment(getToday).format('D')
     const month = moment(getToday).format('M')
     const year = moment(getToday).format('YYYY')
     return `Ngày ${day} tháng ${month} năm ${year}`
    }
    
  }

  const getFileName = (p1, p2, to, typeExport) => {
    let fileName = 'Default';
    if (props.filter[p2] || (Array.isArray(props.filter[p2]) && props.filter[p2].length > 0)) {
      fileName = props.filter[p2];
    } else if (props.filter[p1]) {
      fileName = props.filter[p1];
    }
    fileName = fileName + '_T' + to.format('M');
    if (typeExport) {
      fileName = fileName + '_' + typeExport;
    }
    return fileName;
  };
  
  React.useEffect(() => {
    if(props.filter){
      setWorkBookName('Xuat_doi_soat_' +
      getFileName('customer', 'customer', moment(props.filter['to']), props.filter['typeExport']))
    }
  }, [props.filter])
  

  const workSheetName = 'report';
  const workSheetName2 = 'accounts';

  const myInputId = 'myInput';
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    // const newDataCreditToExport =
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      const line1 = 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM';

      const line2 = 'Độc Lập - Tự Do - Hạnh Phúc';
      
      const line3 = ''

      const line4 = `BIÊN BẢN ĐỐI SOÁT DỊCH VỤ ${type_of_control && type_of_control.toUpperCase()}`;

      const line5 = `GIỮA CÔNG TY CP VIỄN THÔNG TIN HỌC VIỆT NAM và ${customerName}`;

      const line6 = contractName ? `${contractName} - ${invoiceName && `Hợp đồng số: ${invoiceName}`}` : `${invoiceName && `Hợp đồng số: ${invoiceName}`}`
      const line7 = `Tháng ${
        props.filter && props.filter.from && moment(props.filter.from).format('MM/YYYY')
      }`;
      const line8 = `- Hôm nay, ${dateInvoice}, CÔNG TY CỔ PHẦN VIỄN THÔNG TIN HỌC VIỆT NAM và ${customerName} cùng ký biên bản xác nhận số liệu sản lượng - doanh thu dịch vụ ${type_of_control}. Cụ thể như sau:`;

      const line9 = `Tổng số tiền ${customerName} phải thanh toán cho VNTEL`;

      const line10 = 'Lưu ý:';
      const line11 =
        'Biên bản này được lập thành 02 bản có giá trị pháp lý như nhau, mỗi bên giữ 01 bản.';
      const line12 = (data && data.isToday ) ? todayHTML() : 'Ngày   tháng    năm 2023';
      const line13 = customerName;
      const line14 = 'CÔNG TY CỔ PHẦN VIỄN THÔNG TIN HỌC VIỆT NAM';

      const line15 = `Số tiền bằng chữ: ${data && to_vietnamese(data.totalControl)} đồng./.`;

      // creating one worksheet in workbook
      if (workbook !== null) {
        const worksheet = workbook.addWorksheet(workSheetName, {
          pageSetup: {
            // Print size set to A4
            paperSize: 9,
            orientation: 'landscape',
            fitToPage: true,
          }
        });

        



        


        //font base
        const fontBase = { name: 'Times New Roman', family: 4, size: 11, bold: true };
        const fontBase2 = { name: 'Times New Roman', family: 4, size: 11 };

        //format cell number

        const numFormat = '#,##0';
        const numFormatCk = '#,##0.00';
        const numFormatCkBase = '#,##0';

        const numFormatAccount = '#0.00';

        // area table A-G
        const areaTable = ['A', 'B', 'D', 'E', 'F'];

        //fill bg

        const fill = {
          type: 'pattern',
          pattern: 'darkVertical',
          fgColor: { argb: '92CDDC' },
          bgColor: { argb: '92CDDC' }
        };

        const fillMinus = {
          type: 'pattern',
          pattern: 'darkVertical',
          fgColor: { argb: 'FF6666' },
          bgColor: { argb: 'FF6666' }
        };

        const alignmentCenter = {
          vertical: 'middle',
          horizontal: 'center'
        };

        const alignmentRight = {
          vertical: 'middle',
          horizontal: 'right'
        };
        //border

        const border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        //merge cell A1 - A7

        const column = posColumn(props.type);
        if (column) {
          for (let i = 1; i < 9; i++) {
            worksheet.mergeCells(`A${i}:${column}${i}`);
            worksheet.getCell(`A${i}`).value = eval(`line${i}`);
            worksheet.getCell(`A${i}`).font = fontBase;
            worksheet.getCell(`A${i}`).alignment = alignmentCenter;
            worksheet.getRow(i).height = 16;
            if (i === 8) {
              worksheet.getRow(i).height = 30;
              worksheet.getCell(`A${i}`).font = fontBase2;
              worksheet.getCell(`A${i}`).alignment = {
                vertical: 'middle',
                wrapText: true
              };
            }
          }
        }

        //Add Image
        const imageBuffer = await axios.get(logoFile, { responseType: 'arraybuffer' });
        const imageId2 = workbook.addImage({
          buffer: imageBuffer.data,
          extension: 'png'
        });

        worksheet.addImage(imageId2, 'A1');
        worksheet.addImage(imageId2, {
          tl: { col: 0, row: 0 },
          ext: { width: 125.76, height: 54.72 }
        });

        // updated the font for first row.

        worksheet.columns = keyColumn(props.type);

        // header table
        worksheet.addRow(keyHeader(props.type).map((v) => v));
        worksheet.mergeCells('B9:C9');

        worksheet.getRow(9).font = fontBase;

        worksheet.getRow(9).alignment = alignmentCenter;
        worksheet.getRow(9).height = 16;
        worksheet.getRow(9).eachCell({ includeEmpty: true }, function (cell) {
          worksheet.getCell(cell.address).fill = fill;
          worksheet.getCell(cell.address).border = border;
        });



        // loop through data and add each one to worksheet
        let currentRow = 10;
        // product
        if (dataProduct(props.type) && dataProduct(props.type).length > 0) {
          dataProduct(props.type).forEach((v, index) => {
            
            let row = worksheet.addRow({
              stt: index + 1,
              product: v.name,
              productmerge: v.name,
              price: v.price,
              qty: v.qty,
              ck: v.ck,
              total: calculateTotal(v.qty, v.price, v.ck),
              minus:v.minus && 'x',
            });
            row.eachCell(function (cell) {
              cell.font = fontBase2;
              cell.border = border;
              cell.alignment = { wrapText: true };
            });
          });

          
            for (let index = 0; index < dataProduct(props.type).length; index++) {
              
              ['A', 'E', 'G', 'D','F','H'].map((v) => {
                const isMinus = ['H', 'G'].includes(v)
                if(isMinus) {
                  if( worksheet.getCell(`${v}${currentRow + index}`).value === 'x'){
                  if(props.type === 1 ){
                    worksheet.getCell(`G${currentRow + index}`).fill = fillMinus
                  }
                  if(props.type === 2 ){
                    worksheet.getCell(`F${currentRow + index}`).fill = fillMinus
                  }
                  }
                }
                if (v === 'A') {
                  worksheet.getCell(`${v}${currentRow + index}`).alignment = alignmentCenter;
                } else {
                  worksheet.getCell(`${v}${currentRow + index}`).alignment = alignmentRight;
                }
                if (['D', 'G'].includes(v)) {
                  worksheet.getCell(`${v}${currentRow + index}`).numFmt = numFormat;
                }
                if (v === 'F') {
                  let data = worksheet.getCell(`${v}${currentRow + index}`).value
                  if(data === parseInt(data, 10)){
                    worksheet.getCell(`${v}${currentRow + index}`).numFmt = numFormatCkBase;
                  } else {
                    worksheet.getCell(`${v}${currentRow + index}`).numFmt = numFormatCk;
                  }
                }
              });
              worksheet.mergeCells(`B${currentRow + index}:C${currentRow + index}`);
              const value = worksheet.getCell(`B${currentRow + index}`).value
              if(value.length > 70){
                worksheet.getRow(currentRow + index).height = 30
                worksheet.getCell(`B${currentRow + index}`).alignment = {
                  vertical: 'middle',
                  horizontal: 'left',
                  wrapText: true
                };
              }
            }
            
          
        }
        // product phi vuot phut
        if (dataProductTelco(props.type) && dataProductTelco(props.type).length > 0) {
          let currentRowTelco = currentRow + dataProduct(props.type).length;
          dataProductTelco(props.type).forEach((v, index) => {
            const [before, after] = v.name && splitLastOccurrence(v.name, 'Phí vượt phút ');
            let row = worksheet.addRow({
              stt: dataProduct(props.type).length + 1 + index,
              product: before,
              productmerge: after,
              price: v.price,
              qty: v.qty,
              ck: v.ck,
              total: calculateTotal(v.qty, v.price, v.ck)
            });
            row.eachCell(function (cell) {
              cell.font = fontBase2;
              cell.border = border;
              
            });
          });
          for (let index = 0; index < dataProductTelco(props.type).length; index++) {
            ['A', 'E', 'G', 'D','F'].map((v) => {
              if (v === 'A') {
                worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentCenter;
              } else {
                worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentRight;
              }
              if (['D', 'G'].includes(v)) {
                worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormat;
              }
              if (v === 'F') {
                let data = worksheet.getCell(`${v}${currentRowTelco + index}`).value
                if(data === parseInt(data, 10)){
                  worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCkBase;
                } else {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCk;
                }
              }
            });
          }
          if (dataProductTelco(props.type).length > 1) {
            worksheet.getCell(
              `B${currentRowTelco}:B${currentRowTelco + dataProductTelco(props.type).length - 1}`
            ).value = 'Phí vượt phút';
            worksheet.getCell(
              `B${currentRowTelco}:B${currentRowTelco + dataProductTelco(props.type).length - 1}`
            ).alignment = alignmentCenter;
            worksheet.mergeCells(
              `B${currentRowTelco}:B${currentRowTelco + dataProductTelco(props.type).length - 1}`
            );
          } else {
            worksheet.getCell(`B${currentRowTelco}`).value = 'Phí vượt phút';
            worksheet.getCell(`B${currentRowTelco}`).alignment = alignmentCenter;
          }
        }
        if (customerS1 === (data && data.agency)) {
          if (dataProductGtel(props.type) && dataProductGtel(props.type).length > 0) {
            let currentRowTelco = currentRow + dataProduct(props.type).length;
            dataProductGtel(props.type).forEach((v, index) => {
              const [before, after] = v.name && splitLastOccurrence(v.name, 'GTEL cố định ');
              let row = worksheet.addRow({
                stt: dataProduct(props.type).length + 1 + index,
                product: before,
                productmerge: after,
                price: v.price,
                qty: v.qty,
                ck: v.ck,
                total: calculateTotal(v.qty, v.price, v.ck)
              });
              row.eachCell(function (cell) {
                cell.font = fontBase2;
                cell.border = border;
                
              });
            });
            for (let index = 0; index < dataProductGtel(props.type).length; index++) {
              ['A', 'E', 'G', 'D','F'].map((v) => {
                if (v === 'A') {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentCenter;
                } else {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentRight;
                }
                if (['D', 'G'].includes(v)) {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormat;
                }
                if (v === 'F') {
                  let data = worksheet.getCell(`${v}${currentRowTelco + index}`).value
                  if(data === parseInt(data, 10)){
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCkBase;
                  } else {
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCk;
                }
                }
              });
            }

            if (dataProductGtel(props.type).length > 1) {
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProductGtel(props.type).length - 1}`
              ).value = 'Cước phí đầu số cố định Gtel';
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProductGtel(props.type).length - 1}`
              ).alignment = alignmentCenter;
              worksheet.mergeCells(
                `B${currentRowTelco}:B${currentRowTelco + dataProductGtel(props.type).length - 1}`
              );
            } else {
              worksheet.getCell(`B${currentRowTelco}`).value = 'Cước phí đầu số cố định Gtel';
              worksheet.getCell(`B${currentRowTelco}`).alignment = alignmentCenter;
            }
          }
          if (dataProductVnpt(props.type) && dataProductVnpt(props.type).length > 0) {
            let currentRowTelco =
              currentRow + dataProduct(props.type).length + dataProductGtel(props.type).length;

            dataProductVnpt(props.type).forEach((v, index) => {
              const [before, after] = v.name && splitLastOccurrence(v.name, 'VNPT cố định ');
              let row = worksheet.addRow({
                stt:
                  dataProduct(props.type).length + dataProductGtel(props.type).length + 1 + index,
                product: before,
                productmerge: after,
                price: v.price,
                qty: v.qty,
                ck: v.ck,
                total: calculateTotal(v.qty, v.price, v.ck)
              });
              row.eachCell(function (cell) {
                cell.font = fontBase2;
                cell.border = border;
                
              });
            });
            for (let index = 0; index < dataProductVnpt(props.type).length; index++) {
              ['A', 'E', 'G', 'D','F'].map((v) => {
                if (v === 'A') {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentCenter;
                } else {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentRight;
                }
                if (['D', 'G'].includes(v)) {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormat;
                }
                if (v === 'F') {
                  let data = worksheet.getCell(`${v}${currentRowTelco + index}`).value
                  if(data === parseInt(data, 10)){
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCkBase;
                  } else {
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCk;
                  }
                }
              });
            }

            if (dataProductVnpt(props.type).length > 1) {
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProductVnpt(props.type).length - 1}`
              ).value = 'Cước phí đầu số cố định VNPT';
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProductVnpt(props.type).length - 1}`
              ).alignment = alignmentCenter;
              worksheet.mergeCells(
                `B${currentRowTelco}:B${currentRowTelco + dataProductVnpt(props.type).length - 1}`
              );
            } else {
              worksheet.getCell(`B${currentRowTelco}`).value = 'Cước phí đầu số cố định VNPT';
              worksheet.getCell(`B${currentRowTelco}`).alignment = alignmentCenter;
            }
          }
          if (
            dataProduct1800(props.type) && dataProduct1800(props.type).length > 0
          ) {
            let currentRowTelco =
              currentRow +
              dataProduct(props.type).length +
              dataProductGtel(props.type).length +
              dataProductVnpt(props.type).length;
            dataProduct1800(props.type).forEach((v, index) => {
              let row = worksheet.addRow({
                stt:
                  dataProduct(props.type).length +
                  dataProductGtel(props.type).length +
                  dataProductVnpt(props.type).length +
                  1 +
                  index,

                product: v.rename,
                productmerge: v.rename,
                price: v.price,
                qty: v.qty,
                ck: v.ck,
                total: calculateTotal(v.qty, v.price, v.ck)
              });
              row.eachCell(function (cell) {
                
                cell.font = fontBase2;
                cell.border = border;
                
              });
            });
            for (let index = 0; index < dataProduct1800(props.type).length; index++) {
              ['A', 'E', 'G', 'D','F'].map((v) => {
                if (v === 'A') {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentCenter;
                } else {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentRight;
                }
                if (['D', 'G'].includes(v)) {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormat;
                }
                if (v === 'F') {
                  let data = worksheet.getCell(`${v}${currentRowTelco + index}`).value
                  if(data === parseInt(data, 10)){
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCkBase;
                  } else {
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCk;
                  }
                }
              });
            }

            if (dataProduct1800(props.type).length > 1) {
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProduct1800(props.type).length - 1}`
              ).value = 'Cước phí phải trả cho thuê bao gọi vào số 1800';
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProduct1800(props.type).length - 1}`
              ).alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true
              };
              worksheet.mergeCells(
                `B${currentRowTelco}:B${currentRowTelco + dataProduct1800(props.type).length - 1}`
              );
            } else {
              worksheet.getCell(`B${currentRowTelco}`).value =
                'Cước phí phải trả cho thuê bao gọi vào số 1800';
              worksheet.getCell(`B${currentRowTelco}`).alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true
              };

            }
          }
        }
        if (customerS2 === (data && data.agency)) {
          if (dataProduct1800(props.type) && dataProduct1800(props.type).length > 0) {
            let currentRowTelco = currentRow + dataProduct(props.type).length;
            dataProduct1800(props.type).forEach((v, index) => {
              let row = worksheet.addRow({
                stt: dataProduct(props.type).length + 1 + index,
                product: v.rename,
                productmerge: v.rename,
                price: v.price,
                qty: v.qty,
                ck: v.ck,
                total: calculateTotal(v.qty, v.price, v.ck)
              });
              row.eachCell(function (cell) {
                cell.font = fontBase2;
                cell.border = border;
                
              });
            });
            for (let index = 0; index < dataProduct1800(props.type).length; index++) {
              ['A', 'E', 'G', 'D','F'].map((v) => {
                if (v === 'A') {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentCenter;
                } else {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).alignment = alignmentRight;
                }
                if (['D', 'G'].includes(v)) {
                  worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormat;
                }
                if (v === 'F') {
                  let data = worksheet.getCell(`${v}${currentRowTelco + index}`).value
                  if(data === parseInt(data, 10)){
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCkBase;
                  } else {
                    worksheet.getCell(`${v}${currentRowTelco + index}`).numFmt = numFormatCk;
                  }
                }
              });
            }

            if (dataProduct1800(props.type).length > 1) {
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProduct1800(props.type).length - 1}`
              ).value = 'Cước phí phải trả cho thuê bao gọi vào số 1800';
              worksheet.getCell(
                `B${currentRowTelco}:B${currentRowTelco + dataProduct1800(props.type).length - 1}`
              ).alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true
              };
              worksheet.mergeCells(
                `B${currentRowTelco}:B${currentRowTelco + dataProduct1800(props.type).length - 1}`
              );
            } else {
              worksheet.getCell(`B${currentRowTelco}`).value =
                'Cước phí phải trả cho thuê bao gọi vào số 1800';
              worksheet.getCell(`B${currentRowTelco}`).alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true
              };
            }
          }
        }
        // Iterate over all rows that have values in a worksheet
        // sum
        const endRow = worksheet.lastRow._number + 1;


        worksheet.mergeCells(`A${endRow}:${posColumnSplit(props.type)}${endRow}`);
        worksheet.getRow(`A${endRow}`).border = border;
        worksheet.getCell(`${posColumn(props.type)}${endRow}`).border = border;
        worksheet.getCell(`A${endRow}`).value = 'Cộng phát sinh trong kỳ';
        worksheet.getCell(`A${endRow}`).alignment = alignmentCenter;
        worksheet.getCell(`A${endRow}`).font = fontBase2;
        worksheet.getCell(`${posColumn(props.type)}${endRow}`).font = fontBase;
        worksheet.getCell(`${posColumn(props.type)}${endRow}`).numFmt = numFormat;
        worksheet.getCell(`${posColumn(props.type)}${endRow}`).value =
          data && sum(data.productControl);

        //vat
        if (control && control.vat > 0) {
          const endRow2 = worksheet.lastRow._number + 1;
          worksheet.getCell(`A${endRow}`).border = border;
          worksheet.getCell(`${posColumnSplit(props.type)}${endRow}`).border = border;
          worksheet.mergeCells(`A${endRow2}:${posColumnSplit(props.type)}${endRow2}`);
          worksheet.getCell(`A${endRow2}`).border = border;
          worksheet.getCell(`${posColumn(props.type)}${endRow2}`).border = border;
          worksheet.getCell(`A${endRow2}`).value = `Thuế GTGT (${control && control.vat}%)`;
          worksheet.getCell(`A${endRow2}`).alignment = alignmentCenter;
          worksheet.getCell(`A${endRow2}`).font = fontBase2;
          worksheet.getCell(`${posColumn(props.type)}${endRow2}`).font = fontBase;

          worksheet.getCell(`${posColumn(props.type)}${endRow2}`).numFmt = numFormat;
          worksheet.getCell(`${posColumn(props.type)}${endRow2}`).value = {
            formula: `${posColumn(props.type)}${endRow2 - 1}*${(control && control.vat) / 100}`
          };
        }

        //total

        const endRow3 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow3}:${posColumnSplit(props.type)}${endRow3}`);
        worksheet.getCell(`A${endRow3}`).border = border;
        worksheet.getCell(`${posColumn(props.type)}${endRow3}`).border = border;
        worksheet.getCell(`A${endRow3}`).font = {
          bold: true
        };
        worksheet.getCell(`A${endRow3}`).alignment = alignmentCenter;
        worksheet.getCell(`A${endRow3}`).value = line9;
        worksheet.getRow(endRow3).font = fontBase;
        worksheet.getRow(endRow3).height = 16;
        worksheet.getCell(`A${endRow3}`).fill = fill;
        worksheet.getCell(`${posColumn(props.type)}${endRow3}`).fill = fill;
        (worksheet.getRow(endRow3).height = 15);
        worksheet.getCell(`${posColumn(props.type)}${endRow3}`).alignment = alignmentRight;
        worksheet.getCell(`${posColumn(props.type)}${endRow3}`).numFmt = numFormat;
        worksheet.getCell(`${posColumn(props.type)}${endRow3}`).value =
          control && control.vat > 0
            ? {
                formula: `SUM(${posColumn(props.type)}${endRow3 - 2}:${posColumn(props.type)}${
                  endRow3 - 1
                })`
              }
            : { formula: `${posColumn(props.type)}${endRow3 - 1}` };

        // so tien bang chu
        const endRow4 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow4}:${posColumn(props.type)}${endRow4}`);
        worksheet.getRow(endRow4).font = fontBase;
        worksheet.getRow(endRow4).height = 16;
        worksheet.getCell(`A${endRow4}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`A${endRow4}`).value = line15;
        //note
        const endRow5 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow5}:${posColumn(props.type)}${endRow5}`);
        worksheet.getRow(endRow5).font = fontBase;
        worksheet.getRow(endRow5).height = 16;
        worksheet.getCell(`A${endRow5}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`A${endRow5}`).value = line10;
        
        //note
        const endRow6 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow6}:${posColumn(props.type)}${endRow6}`);
        worksheet.getRow(endRow6).font = fontBase2;
        worksheet.getRow(endRow6).height = 16;
        worksheet.getCell(`A${endRow6}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`A${endRow6}`).value = line11;

        //date sign
        const endRow7 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`F${endRow7}:${posColumn(props.type)}${endRow7}`);
        worksheet.getRow(endRow7).font = fontBase;
        worksheet.getRow(endRow7).height = 16;
        worksheet.getCell(`F${endRow7}`).value = line12;

        worksheet.getRow(endRow7).alignment = alignmentCenter;
        //sign A
        const endRow8 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow8}:C${endRow8}`);
        worksheet.mergeCells(`D${endRow8}:${posColumn(props.type)}${endRow8}`);
        worksheet.getRow(endRow8).font = fontBase;

        worksheet.getRow(endRow8).alignment = alignmentCenter;
        worksheet.getRow(endRow8).height = 30;

        worksheet.getCell(`A${endRow8}`).value = line13;
        worksheet.getCell(`D${endRow8}`).value = line14;


        //delete last column

        const lastColumn = worksheet.columnCount;
        if(props.type === 1){
          worksheet.spliceColumns(lastColumn, 1);
        } else {
          const newLastColumn = lastColumn - 1
          worksheet.spliceColumns(newLastColumn , 1);
        }
        // Set Print Area for a sheet
        const rowCount = worksheet.rowCount;
        worksheet.pageSetup.printArea = `A1:${posColumn(props.type)}${rowCount}`;
                // adjust pageSetup settings afterwards
                // worksheet.pageSetup.margins = {
                //   left: 0.5,
                //   right: rowCount > 24 ? 0.7 : 1,
                //   top: rowCount > 24 ? 0.5 : 1,
                //   bottom: rowCount > 24 ? 0.75 : 1,
                //   header: 0.5,
                //   footer: 0.5
                // };
                worksheet.pageSetup.margins = {
                  left:rowCount > 24 ? 0.5 : 0.75,
                  right:rowCount > 24 ? 0.5 : 0.75,
                  top: rowCount > 24 ? 0.7 : 1,
                  bottom: rowCount > 24 ? 1.5 : 1,
                  header: 0.5,
                  footer: 0.5
                };
                worksheet.pageSetup.horizontalCentered = true;
                worksheet.views = [{}]
        // header table 2
        if(dataAccount && dataAccount.headers &&  dataAccount.headers.length > 0  && dataAccount.body && dataAccount.body.length > 0){
          const worksheet2 = workbook.addWorksheet(workSheetName2,{
            pageSetup: {
              // Print size set to A4
              paperSize: 9,
              orientation: 'landscape',
              fitToPage: true,
            }
          });
          // adjust pageSetup settings afterwards
          worksheet2.pageSetup.margins = {
            left: 0.75,
            right: 0.75,
            top: 0.75,
            bottom: 0.75,
            header: 0.5,
            footer: 0.5
          };
          worksheet2.pageSetup.horizontalCentered = true;
  
          // Set Print Area for a sheet
          worksheet2.addRow(dataAccount.headers
            .map((v) => v));
  
  
            worksheet2.getRow(1).font = fontBase;
  
            worksheet2.getRow(1).alignment = alignmentCenter;
            worksheet2.getRow(1).eachCell({ includeEmpty: true }, function (cell) {
              worksheet2.getCell(cell.address).fill = fill;
              worksheet2.getCell(cell.address).border = border;
            });
            dataAccount.body.forEach((v) => {
              
              let row = worksheet2.addRow(v);
              row.eachCell(function (cell) {
                cell.font = fontBase2;
                cell.border = border;
                cell.numFmt = cell.value > 0 ? numFormatAccount : 0
              });
            });
  
            worksheet2.columns.forEach(function (column, i) {
              let maxLength = 0;
              column["eachCell"]({ includeEmpty: true }, function (cell) {
                  var columnLength = cell.value ? cell.value.toString().length : 10;
                  if (columnLength > maxLength ) {
                      maxLength = columnLength;
                  }
              });
              column.width = maxLength < 10 ? 10 : maxLength;
          });

           // Set Print Area for a sheet
        const rowCount = worksheet2.rowCount;
        worksheet2.pageSetup.printArea = `A1:${posColumn(props.type)}${rowCount}`;
        worksheet2.views = [{}]

            // end sheet 2
        }




        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();
        
        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
      workbook.removeWorksheet(workSheetName2);
    }
  };
  return (
   
      <div className="text-right">
      <input style={{ display: 'none' }} id={myInputId} defaultValue={workBookName} />
      <button className={`btn`} 
        onClick={()=>saveExcel()}
        style={{ minWidth:'inherit', marginLeft: 10 }}>
        Tải file Excel
      </button>
      </div>
    
  );
};

export default ControlExcel;
