import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  profile: {},

  isSuccess: false,
  isError: false,
  isFetching: false,
  errorMessage: ''
};

const ACTION = {
  SIGN_UP: 'profile/signUp'
};



export const signUp = createAsyncThunk(ACTION.SIGN_UP, async (body, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/restapi/profile/', body);
    return response;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  extraReducers: {

    [signUp.pending.toString()]: state => {
      state.isFetching = true;
    },
    [signUp.rejected.toString()]: (state, { payload }) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [signUp.fulfilled.toString()]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = payload.message;
    }
  }
});

const { reducer: profileReducer } = profileSlice;
export default profileReducer;
