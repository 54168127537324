import React, { useEffect, useState } from 'react';
import { Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';
import moment from 'moment';
import { delContracts, getContracts } from '../../redux/contract';
import { showMessageError, showMessageSuccess } from '../../redux/alert-message';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import Modal, { ModalContent } from '../../../components/common/Modal';
import DialogAsk from '../../../components/common/DialogAsk';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const ContractForm = React.lazy(() => import('./ContractForm'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const Contract = ({ url }) => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.contracts);
  const customerId = useSelector((state) => state.contracts.customerId);
  const [contractList, setContractList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalAsk, setModalAsk] = useState(false);
  const [data, setData] = useState(null);
  const pagination = usePaginationState();

  useEffect(() => {
    if (customerId) {
      dispatch(
        getContracts({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage,
          customer: customerId
        })
      ).then((res) => {
        setContractList(res && res.payload);
      });
    }
  }, [pagination, customerId]);

  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getContracts({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage,
          customer: customerId
        })
      ).then((res) => {
        setContractList(res && res.payload);
      });
    }
  }, [status.success]);
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container
        fluid
        className={!customerId ? `main-content-container px-4` : `px-0 mt-4`}
        style={{ borderTop: '1px solid #dedede' }}>
        <h1 className="heading">
          Quản lý hợp đồng
          <span className="btn" onClick={() => setOpenModal(true)}>
            <span className="fa fa-plus-circle mr-2"></span>
            Thêm hợp đồng
          </span>
        </h1>
        <Modal active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
              setData(null);
            }}>
            <DialogAsk
              handleCanel={setModalAsk}
              dataEdit={data}
              text={`Bạn có muốn xoá hợp đồng ${data && data.name}`}
              handleConfirm={() => {
                dispatch(delContracts({ uuid: data.unique_id }))
                  .unwrap()
                  .then(() => {
                    dispatch(showMessageSuccess());
                    setModalAsk(false);
                    setData(null);
                  })
                  .catch(() => {
                    dispatch(showMessageError());
                  });
              }}
            />
          </ModalContent>
        </Modal>
        <>
          {openModal ? (
            <ContractForm customer={customerId} setUDO={setOpenModal} />
          ) : (
            <>
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        Tên
                      </th>
                      <th scope="col" className="border-0">
                        Khách hàng
                      </th>
                      <th scope="col" className="border-0">
                        Status
                      </th>
                      <th scope="col" className="border-0">
                        Giá trị hợp đồng
                      </th>
                      <th scope="col" className="border-0">
                        Ngày tạo
                      </th>

                      <th scope="col" className="border-0 text-center">
                        Đã duyệt
                      </th>
                      <th scope="col" className="border-0">
                        File gán
                      </th>
                      <th scope="col" className="border-0" style={{ width: 120 }}>
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerId &&
                      contractList &&
                      contractList.results &&
                      contractList.results.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.customer && item.customer.name}</td>
                            <td>{item.status && item.status.name}</td>
                            <td>{item.amount}</td>
                            <td>{moment(item.create_at).format('YYYY-MM-DD')}</td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.approve === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                            <td>
                              {item.attachments &&
                                item.attachments.map((attach, idx) => {
                                  return (
                                    <Tooltip
                                      key={idx}
                                      title={attach.filename}
                                      position="top"
                                      size={'small'}
                                      animation={'shift'}>
                                      <Link
                                        to={{
                                          pathname: `${process.env.REACT_APP_BASE_API}${attach.file_path}`
                                        }}
                                        className="btn btn-edit bg-theme mt-2"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <i className="fa fa-file"></i>
                                      </Link>
                                    </Tooltip>
                                  );
                                })}
                            </td>
                            <td>
                              <Tooltip
                                position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                                <Link
                                  to={`${url}/contracts/update/${item.unique_id}`}
                                  className="btn btn-edit">
                                  <i className="fa fa-edit"></i>
                                </Link>
                              </Tooltip>
                              <Tooltip
                                position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-del"
                                  onClick={() => {
                                    setModalAsk(true);
                                    setData(item);
                                  }}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {contractList && contractList.results && contractList.results.length === 0 && (
                  <Empty description="Không có dữ liệu" />
                )}

                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.ceil((contractList ? contractList.count : 0) / pagination.perPage)}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(
                      Math.ceil((contractList ? contractList.count : 0) / pagination.perPage)
                    )
                  }
                />
              </div>
            </>
          )}
        </>
      </Container>
    </React.Suspense>
  );
};

export default Contract;
