import { noop, useStableCallback } from '@reach/utils';
import * as React from 'react';



// export interface UsePaginationStateOptions {
//   /**
//    * @default 1
//    */
//   initialPage?: number;
//   /**
//    * @default 20
//    */
//   initialPerPage?: number;
//   initialIsLastPage?: boolean;
//   onChange?: (latestState: PaginationState) => void;
// }

export const usePaginationState = ({
  initialPage,
  initialPerPage,
  initialIsLastPage,
  onChange = noop,
} = {}) => {
  const [state, setState] = React.useState(() => ({
    page: initialPage ? initialPage : 1,
    perPage: initialPerPage ? initialPerPage : 15,
    isLastPage: initialIsLastPage ? initialIsLastPage : false,
  }));

  const setPage = useStableCallback(function setPage(page) {
    const latestState = {
      ...state,
      page,
      isLastPage: false,
    };

    setState(latestState);
    onChange(latestState);
  });

  const setPerPage = useStableCallback(function setPerPage(perPage) {
    const latestState = {
      ...state,
      page: 1,
      perPage,
    };

    setState(latestState);
    onChange(latestState);
  });

  const setIsLastPage = useStableCallback(function setIsLastPage(
    isLastPage
  ) {
    const latestState = {
      ...state,
      isLastPage,
    };
    setState(latestState);
    onChange(latestState);
  });

  return React.useMemo(
    () => ({
      page: state.page,
      perPage: state.perPage,
      isLastPage: state.isLastPage,
      setPage,
      setPerPage,
      setIsLastPage,
    }),
    [state, setPage, setPerPage, setIsLastPage]
  );
};
