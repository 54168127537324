import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  agencyList: {},
  loading: false
};

const ACTION = {
  GET_AGENCY: 'agency/getAgency',
  CREATE_AGENCY: 'agency/createAgency',
  UPDATE_AGENCY: 'agency/createAgency',
  DELETE_AGENCY: 'agency/deleteAgency'
};

export const getAgency = createAsyncThunk(ACTION.GET_AGENCY, async body => {
  return axiosInstance.get('/api/agencies/', { params: body });
});

// create agency

export const createAgency = createAsyncThunk(ACTION.CREATE_AGENCY, async body => {
  return axiosInstance.post('/api/agencies/', body);
});

// update agency

export const updateAgency = createAsyncThunk(ACTION.UPDATE_AGENCY, async ({id,body}) => {
  return axiosInstance.put(`/api/agencies/${id}/`, body);
});

export const deleteAgency = createAsyncThunk(ACTION.DELETE_AGENCY, async body => {
  return axiosInstance.delete(`/api/agencies/${body.id}/`);
})

const agencySlice = createSlice({
  name: 'agency',
  initialState: initialState,
  extraReducers: {
    [getAgency.pending.toString()]: state => {
      state.loading = true;
    },
    [getAgency.rejected.toString()]: state => {
      state.loading = false;
    },
    [getAgency.fulfilled.toString()]: (state, action) => {
      state.loading = false;
     
      state.agencyList = action.payload;
    },
    [createAgency.pending.toString()]: state => {
      state.success = false;
    },
    [createAgency.rejected.toString()]: state => {
      state.success = true;
    },
    [createAgency.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [updateAgency.pending.toString()]: state => {
      state.success = false;
    },
    [updateAgency.rejected.toString()]: state => {
      state.success = true;
    },
    [updateAgency.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [deleteAgency.pending.toString()]: state => {
      state.success = false;
    },
    [deleteAgency.rejected.toString()]: state => {
      state.success = true;
    },
    [deleteAgency.fulfilled.toString()]: (state, action) => {
      state.success = true;
    }
  }
});
export const selectStatus = (state => state.agency)
const { reducer: agencyReducer } = agencySlice;
export default agencyReducer;
