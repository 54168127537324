import React, { useEffect, useState } from 'react';
import { Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';
import { getDepartments, selectStatus } from '../../redux/department';
import DialogDepartment from '../../../components/admin/DialogDepartment';
import { ModalContent } from '../../../components/common/Modal';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Modal = React.lazy(() => import('../../../components/common/Modal'));

const Department = () => {
  const dispatch = useDispatch();
  const departments = useSelector(state => state.department.departments);
  const isLoading = useSelector(state => state.department.loading);
  const status = useSelector(selectStatus);
  const [filter, setFilter] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [departmentUpdate, setDepartmentUpdate] = useState();

  const handleSearch = filter => {
    setFilter({ ...filter });
    dispatch(getDepartments({ ...filter }));
  };

  const pagination = usePaginationState();

  useEffect(() => {
    handleSearch({ ...filter });
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      handleSearch({ ...filter });
    }
  }, [status.success]);


  const handleCloseModal = () => {
    setOpenModal(false);
    setDepartmentUpdate();
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Phòng ban
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm phòng ban
          </span>
        </h1>

        <Modal changeActive={setOpenModal} active={openModal} id={`${!departmentUpdate ? '' : `modal_${departmentUpdate.id}`}`}>
          <ModalContent
            style={{ maxWidth: 500 }}
            heading={!departmentUpdate ? 'Thêm mới phòng ban' : 'Cập nhật phòng ban'}
            onClose={handleCloseModal}>
            <DialogDepartment
              setUDO={setOpenModal}
              departmentDetail={departmentUpdate}
              setData={setDepartmentUpdate}
            />
          </ModalContent>
        </Modal>
        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    STT
                  </th>
                  <th scope="col" className="border-0">
                    Vị trí
                  </th>
                  <th scope="col" className="border-0">
                    Hành động
                  </th>
                </tr>
              </thead>
              {departments && departments.results && (
                <tbody>
                  {departments.results.map((department, idx) => {
                    return (
                      <tr key={idx + 1}>
                        <td>{idx + 1}</td>
                        <td>{department.name}</td>
                        <td>
                          <button
                            onClick={() => {
                              setOpenModal(true);
                              setDepartmentUpdate(department);
                            }}
                            className="btn btn-buy mr-2">
                            Cập nhật
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {/* <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    pagination.page + 5,
                    Math.ceil(accountList.total / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(Math.ceil(accountList.total / pagination.perPage))
                  }
                /> */}
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Department;
