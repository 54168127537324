import React from 'react';
import { FormInput, Col, Row } from 'shards-react';
import { HelpText } from '../components-overview/Help-text';
import FormLabel from '../common/FormLabel';
import { useDispatch,useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createDepartment, selectStatus, updateDepartment } from '../../erp/redux/department';
import { showMessageError, showMessageSuccess } from '../../erp/redux/alert-message';
const DialogDepartment = ({ setUDO, departmentDetail = null, style, setData }) => {
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    name: yup.string().required('Tên phòng ban bắt buộc')
  });

  const submitForm = async values => {
    if (departmentDetail) {
      const submitValues = {
        ...values,
        id: departmentDetail.id
      };
      await dispatch(updateDepartment(submitValues))
        .unwrap()
        .then(() => {
          dispatch(showMessageSuccess());
        })
        .catch(() => {
          dispatch(showMessageError());
        });
    } else {
      await dispatch(createDepartment(values))
        .unwrap()
        .then(() => {
          dispatch(showMessageSuccess());
        })
        .catch(() => {
          dispatch(showMessageError());
        });
    }
    setUDO(false);
    setData();
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched
  } = useFormik({
    initialValues: {
      name: (departmentDetail && departmentDetail.name) || ''
    },
    validationSchema: validationSchema,
    onSubmit: submitForm
  });

  return (
    <div className="content" style={style}>
      <form onSubmit={handleSubmit}>
        <div className="box-form">
          <Row>
            <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Tên phòng ban" required />
                <FormInput
                  className="tags-input"
                  type="text"
                  name="name"
                  value={values.name}
                  placeholder="Tên phòng ban"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <HelpText status="error">{touched.name && errors.name ? errors.name : ''}</HelpText>
              </div>{' '}
            </Col>
            <Col xs={12}>
              <div className="form-input-box text-right" style={{ marginTop: 10 }}>
                <button className={`btn ${status.success === false ? 'disabled' : ''}`} type="submit">
                  {departmentDetail ? 'Cập nhập' : 'Tạo'}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default DialogDepartment;
