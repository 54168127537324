import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import localization from 'moment/locale/vi';
import to_vietnamese from '../../helps/transNumText';
import { formatMoney } from '../../helps/convertMoney';
import { splitLastOccurrence } from '../../helps/help';
import ControlExcel from './excel';
const DialogPreview = (props) => {
  const [totalCost, setTotalCost] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const data = useSelector((state) => state.control.invoice);
  const control = useSelector((state) => state.control.control);
  moment.locale('vi', localization);
  const date = new Date();
  const dateInvoice = moment(date).format('LL');
  const type_of_control = (data && data.type_of_control) ? data.type_of_control : 'Contact Center'

  const contractName = props.contractName || data && data.annexContract && data.annexContract.length > 0 && data.annexContract.map((v) => v.name) || ''

  const products = data && data.productControl;
  const invoiceName =
    (data && data.nameInvoice) || (control && control.group && control.group.contract) || '';
    const customerName = data && (data.nameCustomer || data.value )

       // Calculate is today

       const todayHTML = () =>{
        const getToday = new Date()
        
        if(getToday){
        const day = moment(getToday).format('D')
         const month = moment(getToday).format('M')
         const year = moment(getToday).format('YYYY')
         return `Ngày ${day} tháng ${month} năm ${year}`
        }
        
      }
      
    
  const customerS1 = 'NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN PHƯƠNG ĐÔNG (OCB)';
  const customerS2 = 'CÔNG TY CỔ PHẦN TOKIKIN';
  const customerS3 = 'NGÂN HÀNG TMCP BẢN VIỆT';

        // custom banviet - type ds
  // custom banviet - type ds

  const line1 = 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM';

  const line2 = 'Độc Lập - Tự Do - Hạnh Phúc';
  const line3 = `BIÊN BẢN ĐỐI SOÁT DỊCH VỤ ${type_of_control && type_of_control.toUpperCase()}`;

  const line4 = `GIỮA CÔNG TY CP VIỄN THÔNG TIN HỌC VIỆT NAM và ${customerName}`;

  const line5 = contractName ? `${contractName} - ${invoiceName && `Hợp đồng số: ${invoiceName}`}` : `${invoiceName && `Hợp đồng số: ${invoiceName}`}`

  const line6 = `Tháng ${
    props.filter && props.filter.from && moment(props.filter.from).format('MM/YYYY')
  }`;
  const line7 = `- Hôm nay, ${dateInvoice}, CÔNG TY CỔ PHẦN VIỄN THÔNG TIN HỌC VIỆT NAM và ${customerName} cùng ký biên bản xác nhận số liệu sản lượng - doanh thu dịch vụ ${type_of_control}. Cụ thể như sau:`;

  const line8 = `Tổng số tiền ${customerName} phải thanh toán cho VNTEL`;

  const line9 = 'Lưu ý:';
  const line10 =
    'Biên bản này được lập thành 02 bản có giá trị pháp lý như nhau, mỗi bên giữ 01 bản.';
  const line11 = (data && data.isToday ) ? todayHTML() : 'Ngày   tháng    năm 2023	';
  const line12 = customerName;
  const line13 = 'CÔNG TY CỔ PHẦN VIỄN THÔNG TIN HỌC VIỆT NAM		';

  const line14 = `Số tiền bằng chữ: ${data && to_vietnamese(data.totalControl)} đồng./.`;

  // phuong dong gtel

  const arrGtel = ['GTEL cố định gọi di động', 'GTEL cố định gọi cố định'];

  // phuong dong vnpt
  const arrVnpt = [
    'VNPT cố định gọi cố định nội hạt',
    'VNPT cố định gọi cố định liên tỉnh',
    'VNPT cố định gọi di động'
  ];

  // TOKIKIN 1800
  const arr1800 = ['1800-Mobile','1800-Landline'];

  // phi vuot phut
  const arrFilter = [
    'Phí vượt phút Viettel',
    'Phí vượt phút Vinaphone',
    'Phí vượt phút Mobifone',
    'Phí vượt phút mobifone',
    'Phí vượt phút vinaphone',
    'Phí vượt phút viettel'
  ];

  // product base
  const dataProduct = (type) => {
    let dataFilter = [];
    if (type === 1 || type === 2) {
      if (customerS1 === (data && data.agency)) {
        return (dataFilter =
          data &&
          data.productControl &&
          data.productControl.filter(
            (m) =>
              m.qty !== 0 &&
              m.price !== 0 &&
              !arrFilter.includes(m.name) &&
              !arrGtel.includes(m.name) &&
              !arrVnpt.includes(m.name) && 
              !arr1800.includes(m.code)
          ));
      }
      if (customerS2 === (data && data.agency)) {
        return (dataFilter =
          data &&
          data.productControl &&
          data.productControl.filter(
            (m) =>
              m.qty !== 0 &&
              m.price !== 0 &&
              !arrFilter.includes(m.name) &&
              !arr1800.includes(m.code)
          ));
      } else {
        return (dataFilter =
          data &&
          data.productControl &&
          data.productControl.filter(
            (m) => m.qty !== 0 && m.price !== 0 && !arrFilter.includes(m.name)
          ));
      }
    }
  };
  
  // product base telco
  const dataProductTelco = (type) => {
    let dataFilter = [];
    if (type === 1 || type === 2) {
      return (dataFilter =
        data &&
        data.productControl &&
        data.productControl.filter(
          (m) => m.qty !== 0 && m.price !== 0 && arrFilter.includes(m.name)
        ));
    }
  };
  // product base cuoc phi dau so co dinh vnpt - NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN PHƯƠNG ĐÔNG
  const dataProductVnpt = (type) => {
    let dataFilter = [];
    if (type === 1 || type === 2) {
      return (dataFilter =
        data &&
        data.productControl &&
        data.productControl.filter(
          (m) => m.qty !== 0 && m.price !== 0 && arrVnpt.includes(m.name)
        ));
    }
  };
  // gtel phuong dong
  const dataProductGtel = (type) => {
    let dataFilter = [];
    if (type === 1 || type === 2) {
      const newData =
        data &&
        data.productControl &&
        data.productControl.filter((m) => m.qty !== 0 && m.price !== 0 && arrGtel.includes(m.name));

      const mergeArr = (arr1, arr2) => {
        const obj = {};

        arr1.forEach((item) => {
          obj[item] = { name: item };
        });

        arr2.forEach((item) => {
          obj[item.name] ? (obj[item.name] = { ...obj[item], ...item }) : (obj[item.name] = item);
        });
        return Object.values(obj);
      };

      if (newData) {
        return (dataFilter = mergeArr(arrGtel, newData));
      }
    }
  };
  // 1800 tokiki
  const dataProduct1800 = (type) => {
    let dataFilter = [];
    if (type === 1 || type === 2) {
      const newData =
        data &&
        data.productControl &&
        data.productControl.filter((m) => m.qty !== 0 && m.price !== 0 && arr1800.includes(m.code));

        const mergeArr = (arr1, arr2) => {
          const obj = {};
          let rename = '';
          let qty = 0;
          let price = 0;
          let ck = 0;
          arr1.forEach((item) => {
            if (item === '1800-Mobile') {
              rename = 'Cước phí gọi từ di động trong nước';
            }
            if (item === '1800-Landline') {
              
              rename = 'Cước phí gọi từ số cố định';
            }
            obj[item] = { code: item, rename: rename, qty: qty, price: price, ck:ck };
          });

          arr2.forEach((item) => {
            if (item.code === '1800-Mobile') {
              rename = 'Cước phí gọi từ di động trong nước';
            }
            if (item.code === '1800-Landline') {
              rename = 'Cước phí gọi từ số cố định';
            }
            obj[item.code]
              ? (obj[item.code] = { ...obj[item], ...item, rename })
              : (obj[item.code] = item);
          });
          return Object.values(obj);
        };
      if (newData) {
        return (dataFilter = mergeArr(arr1800, newData));
      }
    }
  };




  const hideCk = (type) => {
    if (type === 1) {
      return true;
    }
    if (type === 2) {
      return false;
    }
  };


  const calculateTotal = (qty, price, ck) => {
    if (!(qty && price)) {
      return 0;
    }
    let total = price * qty * ((100 - ck) / 100);
    // if (v.minus) {
    //   return Math.abs(total) * -1
    // }
    return formatMoney(total, '');
  };




  useEffect(() => {
    if (products) {
      let total = 0;
      products.map((item) => {
        let cost = Math.round(item.price * Math.round(item.qty) * ((100 - item.ck) / 100));
        if (Boolean(item.hasOwnProperty('minus') && item.minus)) {
          cost = -Math.abs(cost);
        }
        return (total += cost);
      });

      setTotalCost(total);
    } else {
      setTotalCost(0);
      setTotalVat(0);
    }
  }, [products]);

  useEffect(() => {
    if (control && control.vat) {
      setTotalVat(Math.round(totalCost * (control && control.vat / 100)));
    }
  }, [totalCost, control]);

  return (
    
    <div className="content">
    <ControlExcel filter={props.filter} type={props.type} contractName={props.contractName} />
      <div className="text-center fbold">{line1}</div>
      <div className="text-center fbold">{line2}</div>
      <div className="text-center fbold">{line3}</div>
      <div className="text-center fbold">{line4}</div>
      <div className="text-center fbold">{line5}</div>
      <div className="text-center fbold">{line6}</div>
      <div>{line7}</div>
      <div className="bg-header">
        <div className="view w-17 p-4-8 textCenter">
          <div className="fbold">STT</div>
        </div>
        <div className="view w-48 p-4-8 textCenter">
          <div className="fbold">Diễn giải</div>
        </div>
        <div className="view w-20 p-4-8 textCenter">
          <div className="fbold">Đơn giá(VNĐ)</div>
        </div>
        <div className="view w-20 p-4-8 textCenter">
          <div className="fbold">Số lượng</div>
        </div>
        {hideCk(props.type) && (
          <div className="view w-20 p-4-8 textCenter">
            <div className="fbold">Chiết khấu(%)</div>
          </div>
        )}
        <div className="view w-20 p-4-8 textCenter">
          <div className="fbold">Thành tiền</div>
        </div>
      </div>
      {dataProduct(props.type) &&
        dataProduct(props.type).map((v, idx) => {
          return (
            <div className="bg-header rowChild">
              <div className="view w-17 p-4-8 textCenter noneBrTop">{idx + 1}</div>

              <div className="view w-48 p-4-8 noneBrTop">{v.name}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>
              {hideCk(props.type) && (
                <div className="view w-20 p-4-8 textRight noneBrTop">{v.ck}</div>
              )}
              <div className="view w-20 p-4-8 textRight noneBrTop">
                {calculateTotal(v.qty, v.price, v.ck)}
              </div>
            </div>
          );
        })}
      {dataProductTelco(props.type) &&
        dataProductTelco(props.type).length > 0 &&
        dataProductTelco(props.type).map((v, idx) => {
          let currentRowTelco = dataProduct(props.type).length;
          const [before, after] = v.name && splitLastOccurrence(v.name, 'Phí vượt phút ');
          const render = () => {
            return (
              <>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap'
                  }}>
                  {idx === 0 && 'Phí vượt phút'}
                </div>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    borderLeft: '1px solid'
                  }}>
                  <div style={{ flex: '0 0 100%' }}>{after}</div>
                </div>
              </>
            );
          };
          return (
            <div className="bg-header rowChild">
              <div className="view w-17 p-4-8 textCenter noneBrTop">
                {currentRowTelco + idx + 1}
              </div>

              <div className="view w-48 p-4-8 noneBrTop view-flex">{render()}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>
              {hideCk(props.type) && (
                <div className="view w-20 p-4-8 textRight noneBrTop">{v.ck}</div>
              )}
              <div className="view w-20 p-4-8 textRight noneBrTop">
                {calculateTotal(v.qty, v.price, v.ck)}
              </div>
            </div>
          );
        })}
      {customerS1 === (data && data.agency) &&
        dataProductGtel(props.type) &&
        dataProductGtel(props.type).length > 0 &&
        dataProductGtel(props.type).map((v, idx) => {
          let currentRowTelco = dataProduct(props.type).length + (dataProductTelco(props.type) &&
        dataProductTelco(props.type).length) ;
          const [before, after] = v.name && splitLastOccurrence(v.name, 'GTEL cố định ');
          const render = () => {
            return (
              <>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap'
                  }}>
                  {idx === 0 && 'GTEL cố định'}
                </div>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    borderLeft: '1px solid'
                  }}>
                  <div style={{ flex: '0 0 100%' }}>{after}</div>
                </div>
              </>
            );
          };
          return (
            <div className="bg-header rowChild">
              <div className="view w-17 p-4-8 textCenter noneBrTop">
                {currentRowTelco + idx + 1}
              </div>

              <div className="view w-48 p-4-8 noneBrTop view-flex">{render()}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>
              {hideCk(props.type) && (
                <div className="view w-20 p-4-8 textRight noneBrTop">{v.ck}</div>
              )}
              <div className="view w-20 p-4-8 textRight noneBrTop">
                {calculateTotal(v.qty, v.price, v.ck)}
              </div>
            </div>
          );
        })}
      {customerS1 === (data && data.agency) &&
        dataProductVnpt(props.type) &&
        dataProductVnpt(props.type).length > 0 &&
        dataProductVnpt(props.type).map((v, idx) => {
          let currentRowTelco = dataProduct(props.type).length;
          const [before, after] = v.name && splitLastOccurrence(v.name, 'VNPT cố định ');
          const render = () => {
            return (
              <>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap'
                  }}>
                  {idx === 0 && 'Cước phí đầu số cố định VNPT'}
                </div>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    borderLeft: '1px solid'
                  }}>
                  <div style={{ flex: '0 0 100%' }}>{after}</div>
                </div>
              </>
            );
          };
          return (
            <div className="bg-header rowChild">
              <div className="view w-17 p-4-8 textCenter noneBrTop">
                {currentRowTelco + idx + 1}
              </div>

              <div className="view w-48 p-4-8 noneBrTop view-flex">{render()}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>
              {hideCk(props.type) && (
                <div className="view w-20 p-4-8 textRight noneBrTop">{v.ck}</div>
              )}
              <div className="view w-20 p-4-8 textRight noneBrTop">
                {calculateTotal(v.qty, v.price, v.ck)}
              </div>
            </div>
          );
        })}
        {customerS1 === (data && data.agency) &&
        dataProduct1800(props.type) &&
        dataProduct1800(props.type).length > 0 &&
        dataProduct1800(props.type).map((v, idx) => {
          let currentRowTelco = dataProduct(props.type).length + (dataProductTelco(props.type) &&
        dataProductTelco(props.type).length) + (dataProductVnpt(props.type) &&
        dataProductVnpt(props.type).length) ;
          const render = () => {
            return (
              <>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap'
                  }}>
                  {idx === 0 && 'Cước phí phải trả cho thuê bao gọi vào số 1800'}
                </div>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    borderLeft: '1px solid'
                  }}>
                  <div style={{ flex: '0 0 100%' }}>{v.rename}</div>
                </div>
              </>
            );
          };
          return (
            <div className="bg-header rowChild">
              <div className="view w-17 p-4-8 textCenter noneBrTop">
                {currentRowTelco + idx + 1}
              </div>

              <div className="view w-48 p-4-8 noneBrTop view-flex">{render()}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>
              {hideCk(props.type) && (
                <div className="view w-20 p-4-8 textRight noneBrTop">{v.ck}</div>
              )}
              <div className="view w-20 p-4-8 textRight noneBrTop">
                {calculateTotal(v.qty, v.price, v.ck)}
              </div>
            </div>
          );
        })}
      {customerS2 === (data && data.agency) &&
        dataProduct1800(props.type) &&
        dataProduct1800(props.type).length > 0 &&
        dataProduct1800(props.type).map((v, idx) => {
          let currentRowTelco = dataProduct(props.type).length;
          const render = () => {
            return (
              <>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap'
                  }}>
                  {idx === 0 && 'Cước phí phải trả cho thuê bao gọi vào số 1800'}
                </div>
                <div
                  style={{
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    borderLeft: '1px solid'
                  }}>
                  <div style={{ flex: '0 0 100%' }}>{v.rename}</div>
                </div>
              </>
            );
          };
          return (
            <div className="bg-header rowChild">
              <div className="view w-17 p-4-8 textCenter noneBrTop">
                {currentRowTelco + idx + 1}
              </div>

              <div className="view w-48 p-4-8 noneBrTop view-flex">{render()}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{formatMoney(v.price, '')}</div>
              <div className="view w-20 p-4-8 textRight noneBrTop">{v.qty}</div>
              {hideCk(props.type) && (
                <div className="view w-20 p-4-8 textRight noneBrTop">{v.ck}</div>
              )}
              <div className="view w-20 p-4-8 textRight noneBrTop">
                {calculateTotal(v.qty, v.price, v.ck)}
              </div>
            </div>
          );
        })}
      <div className="bg-header rowChild">
        <div className="w-bottom view p-4-8 textCenter noneBrTop">Cộng phát sinh trong kỳ</div>
        <div className="w-bottom-2 view p-4-8 textRight noneBrTop fbold">
          {formatMoney(totalCost, '')}
        </div>
      </div>
      <div className="bg-header rowChild">
        <div className="w-bottom view p-4-8 textCenter noneBrTop">
          Thuế GTGT ({control && control.vat}%)
        </div>
        <div className="w-bottom-2 view p-4-8 textRight noneBrTop fbold">
          {formatMoney(totalVat, '')}
        </div>
      </div>
      <div className="bg-header rowChild">
        <div className="w-bottom view p-4-8 textCenter noneBrTop">{line8}</div>
        <div className="w-bottom-2 view p-4-8 textRight noneBrTop fbold">
          {formatMoney(totalCost + totalVat, '')}
        </div>
      </div>
      <div className="fbold">{line14}</div>
      <div className="fbold">{line9}</div>
      <div className="">{line10}</div>

      <div className="bg-header rowChild">
        <div className="w-50 textCenter fbold"></div>
        <div className="w-50 textCenter fbold">{line11}</div>
      </div>
      <div className="bg-header rowChild">
        <div className="w-50 textCenter fbold">{line12}</div>
        <div className="w-50 textCenter fbold">{line13}</div>
      </div>
    </div>
  );
};

export default DialogPreview;
