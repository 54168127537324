import React, { useState } from 'react';
import Modal, { ModalContent } from '../../../components/common/Modal';
import DialogPreview from './DialogPreview';

const PreviewDs = (props) => {
  const [modalPreview, setModalPreview] = useState(false);

  return (
    <>
      <button className="btn" style={{minWidth:'inherit',width:180, marginLeft:props.marginLeft}} onClick={() => setModalPreview(true)}>
       {
        props.contractName ? <><span className='fa fa-eye'></span> <span>{props.contractName}</span></> : 'Xem trước đối soát'
       }
      </button>
      <Modal changeActive={setModalPreview} active={modalPreview}>
        <ModalContent
          className="pu-review-ds"
          onClose={() => {
            setModalPreview(false);
          }}>
          <DialogPreview contractName={props.contractName} filter={props.filter} type={props.type} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewDs;
