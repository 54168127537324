import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  data: {},
  dataDetail: {},
};

const ACTION = {
  GET_SALE_ORDER: 'saleorder/getSaleOrder',
  GET_DETAIL_SALE_ORDER: 'saleorder/getDetailId',
  CREATE_SALE_ORDER: 'saleorder/createSaleOrder',
  UPDATE_SALE_ORDER: 'saleorder/updateSaleOrder',
  UPDATE_SALE_ORDER_PRODUCT: 'saleorder/updateSaleOrderPd',
  CREATE_SALE_ORDER_PRODUCT: 'saleorder/createSaleOrderPd',
  DEL_SALE_ORDER_PRODUCT: 'saleorder/delSaleOrderPd',
};

export const getSaleOrder = createAsyncThunk(ACTION.GET_SALE_ORDER, async body => {
  
  return axiosInstance.get('/api/accountant/sale-orders/', {params:body});
});

export const getDetailId = createAsyncThunk(ACTION.GET_DETAIL_SALE_ORDER, async body => {
  
  return axiosInstance.get(`api/accountant/sale-orders/${body.id}`);
});

export const createSaleOrder = createAsyncThunk(ACTION.CREATE_SALE_ORDER, async (body,{rejectWithValue}) => {
  try {
    const response = await axiosInstance.post('/api/accountant/sale-orders/', body);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateSaleOrder = createAsyncThunk(ACTION.UPDATE_SALE_ORDER, async body => {
  return axiosInstance.put(`/api/accountant/sale-orders/${body.id}/`, body);
});

export const updateSaleOrderPd = createAsyncThunk(ACTION.UPDATE_SALE_ORDER_PRODUCT, async body => {
  return axiosInstance.put(`/api/accountant/sale-details/${body.id}/`, body);
});
export const createSaleOrderPd = createAsyncThunk(ACTION.CREATE_SALE_ORDER_PRODUCT, async body => {
  return axiosInstance.post('/api/accountant/sale-details/', body);
});

export const delSaleOrderPd = createAsyncThunk(ACTION.DEL_SALE_ORDER_PRODUCT, async body => {
  return axiosInstance.delete(`/api/accountant/sale-details/${body.id}/`);
});
const saleOrderSlice = createSlice({
  name: 'saleorder',
  initialState: initialState,
  extraReducers: {
    [getSaleOrder.pending.toString()]: state => {
      state.loading = true;
    },
    [getSaleOrder.rejected.toString()]: state => {
      state.loading = false;
    },
    [getSaleOrder.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.data = action.payload;
    },

    [getDetailId.pending.toString()]: state => {
      state.loadingDetail = true;
    },
    [getDetailId.rejected.toString()]: state => {
      state.loadingDetail = false;
    },
    [getDetailId.fulfilled.toString()]: (state, action) => {
      state.loadingDetail = false;
      state.error = false;
      state.success = false;
      
      state.dataDetail = action.payload;
    },

    [createSaleOrder.pending.toString()]: state => {
    },
    [createSaleOrder.rejected.toString()]: state => {
      state.error = true;
    },
    [createSaleOrder.fulfilled.toString()]: (state, action) => {
      state.success=true;
    },

    [updateSaleOrder.pending.toString()]: state => {
    },
    [updateSaleOrder.rejected.toString()]: state => {
      state.error = true;
    },
    [updateSaleOrder.fulfilled.toString()]: (state, action) => {
      state.success=true;
    },

    [updateSaleOrderPd.pending.toString()]: state => {
    },
    [updateSaleOrderPd.rejected.toString()]: state => {
      state.error = true;
    },
    [updateSaleOrderPd.fulfilled.toString()]: (state, action) => {
      state.success=true;
    },

    [createSaleOrderPd.pending.toString()]: state => {
    },
    [createSaleOrderPd.rejected.toString()]: state => {
      state.error = true;
    },
    [createSaleOrderPd.fulfilled.toString()]: (state, action) => {
      state.success=true;
    },

    [delSaleOrderPd.pending.toString()]: state => {
    },
    [delSaleOrderPd.rejected.toString()]: state => {
      state.error = true;
    },
    [delSaleOrderPd.fulfilled.toString()]: (state, action) => {
      state.success=true;
    },

  }
});
export const selectDetail = (state => state.saleOrder.dataDetail)
export const selectStatus = (state => state.saleOrder)
const { reducer: saleOrderReducer } = saleOrderSlice;
export default saleOrderReducer;
