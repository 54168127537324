import React, { useState } from 'react';
import { Container } from 'shards-react';
import { expenseTabs } from '../../../constants';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Table = React.lazy(() => import('./Table'));
const Chart = React.lazy(() => import('./Chart'));
const ExpenseOrder = () => {
  // Tab
  const [activeTab, setActiveTab] = useState(0);
  const dataInformation = [<Table />, <Chart />];
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <div className="tabs-list">
          {expenseTabs.map((v, i) => (
            <div
              key={i}
              className={`tab-item ${activeTab === v.key ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(v.key);
              }}>
              <p>{v.name.toUpperCase()}</p>
            </div>
          ))}
        </div>
        {dataInformation[activeTab]}
      </Container>
    </React.Suspense>
  );
};

export default ExpenseOrder;
