import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  permission: {
    list: []
  },
  permissionList: {},
  loading: false
};

const ACTION = {
  GET_PERMISSIONS: 'permission/getPermission',
  CREATE_PERMISSIONS: 'permission/createPermission'
};

export const getPermission = createAsyncThunk(ACTION.GET_PERMISSIONS, async body => {
  return axiosInstance.get('/restapi/permissions', { params: body });
});

// create customer

export const createPermission = createAsyncThunk(ACTION.CREATE_PERMISSIONS, async body => {
  return axiosInstance.post('/restapi/permissions/', body);
});

const permissionSlice = createSlice({
  name: 'permission',
  initialState: initialState,
  extraReducers: {
    [getPermission.pending.toString()]: state => {
      state.loading = true;
    },
    [getPermission.rejected.toString()]: state => {
      state.loading = false;
    },
    [getPermission.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.permissionList = action.payload;
    },
    [createPermission.pending.toString()]: state => {
      
      state.success = false;
    },
    [createPermission.rejected.toString()]: state => {
      state.success = true;
    },
    [createPermission.fulfilled.toString()]: (state, action) => {
      state.success = true;
    }
  }
});

const { reducer: permissionReducer } = permissionSlice;
export default permissionReducer;
