import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookieStorage } from '../erp/helps/storage';

const PrivateRoute = ({
  component: Component,
  auth = !!getCookieStorage('access_token'),
  ...rest
}) => (
   <Route
     {...rest}
     render={props => 
     auth ? 
     (<Component {...props} /> ) : (
     <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />)}
   />
);

export default PrivateRoute;
