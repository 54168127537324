import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getSim, getSimStock, getSimStatus, delSim, getHistorySim } from '../redux/sim';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import { hotkeyList } from '../../constants';
import { usePaginationState } from '../../hooks/use-pagination';
import { saveAs } from 'file-saver';
import Excel from 'exceljs/dist/exceljs';
import { Tooltip } from 'react-tippy';

const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const DialogUpload = React.lazy(() => import('../../components/sim/DialogUpload'));
const DialogCreateSim = React.lazy(() => import('../../components/sim/DialogCreateSim'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const DialogHistorySim = React.lazy(() => import('../../components/sim/DialogHistorySim'));

const SimAdmin = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [activeClear, setActiveClear] = useState(false);

  const simsList = useSelector(state => state.sims.simList);
  const simsStockList = useSelector(state => state.sims.simStockList);
  const simsStatusList = useSelector(state => state.sims.simStatusList);
  const { simHistory, loadinghistory } = useSelector(state => state.sims);

  const { successCreate, successDel, successEdit } = useSelector(state => state.sims);

  const loadingState = useSelector(state => state.sims.loading);

  const initialValue = {
    search: search,
    limit: 15,
    offset: 0
  };

  const [openModal, setOpenModal] = useState(false);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [data, setData] = useState('');

  const handleDel = id => {
    dispatch(delSim({ id }))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
      })
      .catch(() => {
        dispatch(showMessageError());
      });
  };

  const handleChangeSearch = e => {
    setSearch(e.target.value);
  };
  const onFocus = e => {
    setActiveClear(true);
  };
  const wrapperRef = useRef(null);
  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  const closeModal = e => {
    if (e.target === wrapperRef.current) setOpenModal(false);
  };

  const closeModalUpload = e => {
    if (e.target === wrapperRef.current) setOpenModalUpload(false);
  };

  const pagination = usePaginationState();

  useEffect(() => {
    if (search) {
      let regexPhone = search.match(/\d+/g);
      let newSearch = {
        ...initialValue,
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage,
        search: regexPhone
      };
      if (regexPhone) {
        dispatch(getSim(newSearch));
      }
    } else {
      dispatch(
        getSim({
          ...initialValue,
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage
        })
      );
    }
  }, [search, pagination]);

  useEffect(() => {
    dispatch(getSimStock({}));
    dispatch(getSimStatus({}));
  }, []);

  useEffect(() => {
    if (successCreate === true) {
      setTimeout(() => {
        dispatch(getSim(initialValue));
      }, 1000);
    }
  }, [successCreate]);

  useEffect(() => {
    if (successDel === true) {
      setTimeout(() => {
        dispatch(getSim(initialValue));
      }, 1000);
    }
  }, [successDel]);

  useEffect(() => {
    if (successEdit === true) {
      setTimeout(() => {
        dispatch(getSim(initialValue));
      }, 1000);
    }
  }, [successEdit]);

  // get History Sim

  const handleSimHistory = async number => {
    await dispatch(getHistorySim({ number }));

    await setOpenModalHistory(true);
  };

  const handleCloseModalHistory = () => {
    setOpenModalHistory(false);
  };

  const closeModalHistory = e => {
    if (e.target === wrapperRef.current) setOpenModalHistory(false);
  };

  // upload

  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [dataImp, setDataImp] = useState({
    cols: [],
    rows: [],
    errorMessage: null
  });
  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
    setDataImp({
      cols: [],
      rows: [],
      errorMessage: null
    });
  };

  // save file

  const workSheetName = 'Danh sách Sim';
  const workBookName = 'DanhSachSim';
  const myInputId = 'myInput';
  const workbook = new Excel.Workbook();
  const columns = [
    { header: 'STT', key: 'stt' },
    { header: 'SĐT', key: 'phone' },
    { header: 'Nhà mạng', key: 'telco' },
    { header: 'Số lượng	', key: 'stocks' },
    { header: 'Giá', key: 'price' },
    { header: 'Đã đăng ký	', key: 'is_register' },
    { header: 'Loại số sipNumber', key: 'is_sipNumber' },
    { header: 'Trạng thái	', key: 'simstatus' }
  ];

  const saveExcel = async () => {
    // const newDataCreditToExport =
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      // creating one worksheet in workbook
      if (workbook !== null) {
        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach(column => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: 'center' };
        });

        // loop through data and add each one to worksheet
        simsList.sims &&
          simsList.sims.forEach((sim, index) => {
            const newData = {
              stt: index,
              phone: sim.number,
              telco: sim.telco,
              stocks: sim.simstocks,
              price: sim.price,
              is_register: sim.is_register === true ? 'Đã đăng ký' : 'Chưa đăng ký',
              is_sipNumber: sim.is_sipNumber === true ? 'Đang hoạt động' : 'Chờ phê duyệt',
              simstatus: sim.simstatus === null ? '' : sim.simstatus.name
            };
            worksheet.addRow(newData);
          });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach(singleCell => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            const cellAddress = singleCell._address;

            // apply border
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <input style={{ display: 'none' }} id={myInputId} defaultValue={workBookName} />
        <h1 className="heading">
          Danh sách Sim
          <Tooltip title={'Tạo mới'} position="right" size={'small'} animation={'shift'}>
            <span
              className="btn-create"
              style={{ top: -5 }}
              onClick={() => {
                setOpenModal(true);
                setData('');
              }}>
              <i className="fa fa-plus"></i>
            </span>
          </Tooltip>
          <div className="btn-g">
            <Tooltip size={'small'} position="bottom" title={'Tải lên'}>
              <span
                className="btn"
                onClick={() => setOpenModalUpload(true)}
                style={{ marginRight: 5 }}>
                <i className="fa fa-upload"></i>
              </span>
            </Tooltip>
            <Tooltip size={'small'} position="bottom" title={'Tải xuống'}>
              <span className="btn" onClick={saveExcel}>
                <i className="fa fa-download"></i>
              </span>
            </Tooltip>
          </div>
        </h1>

        {openModalUpload && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModalUpload} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">Upload</h3>
                <div className="popup-close" onClick={() => handleCloseModalUpload()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogUpload
                  dataImp={dataImp}
                  setDataImp={setDataImp}
                  setDialogImp={setOpenModalUpload}
                  dialogImp={openModalUpload}
                />
              </div>
            </div>
          </>
        )}
        {openModal && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">{!data ? 'Nhập Sim' : 'Sửa'}</h3>
                <div className="popup-close" onClick={() => handleCloseModal()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogCreateSim
                  simsStockList={simsStockList}
                  simsStatusList={simsStatusList}
                  setUDO={setOpenModal}
                  setData={setData}
                  dataEdit={data}
                />
              </div>
            </div>
          </>
        )}
        {openModalHistory && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModalHistory} className="modal-popup_style2 open">
              <div className="popup-container pu-general pu-simhistory">
                <h3 className="pu-heading">Lịch sử</h3>
                <div className="popup-close" onClick={() => handleCloseModalHistory()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogHistorySim
                  setUDO={setOpenModalHistory}
                  data={simHistory}
                  loading={loadinghistory}
                />
              </div>
            </div>
          </>
        )}
        <Row>
          <Col>
            <div className="d-flex box-top">
              <div className="text-left">
                <div className="hotkey-menu">
                  <span className="text">Hot key</span>
                  {hotkeyList.map((item, idx) => {
                    return (
                      <span key={idx} onClick={() => setSearch(item)}>
                        {item}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="text-right ml-auto">
                <div className="form-search">
                  <FormInput
                    type="text"
                    name="search"
                    placeholder="Search theo SĐT"
                    value={search}
                    onChange={handleChangeSearch}
                    onFocus={onFocus}
                  />
                  {activeClear && (
                    <span onClick={() => setSearch('')} className="clear-search">
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
            {loadingState ? (
              <Spinner loading={loadingState} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        SĐT
                      </th>
                      <th scope="col" className="border-0">
                        Nhà mạng
                      </th>
                      <th scope="col" className="border-0 text-center">
                        Đã đăng ký
                      </th>
                      <th scope="col" className="border-0 text-center">
                        Loại số (sipnumber))
                      </th>

                      <th scope="col" className="border-0">
                        Trạng thái
                      </th>

                      <th scope="col" className="border-0">
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {simsList.sims &&
                      simsList.sims.length > 0 &&
                      simsList.sims.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              <Tooltip text="Xem thêm">
                                <span
                                  className="phone-history"
                                  onClick={() => handleSimHistory(item.number)}>
                                  {item.number}
                                </span>
                              </Tooltip>
                            </td>
                            <td>{item.telco}</td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.is_register === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                            <td className="text-center">
                              <span
                                className={`fa ${
                                  item.is_sipNumber === true ? 'fa-check' : 'fa-times'
                                }`}></span>
                            </td>
                            <td>{item.simstatus === null ? '' : item.simstatus.name}</td>

                            <td>
                              <Tooltip position="top" title={'Cập nhập'} size={'small'}>
                                <span
                                  className="btn btn-edit"
                                  onClick={() => {
                                    setOpenModal(true);
                                    setData(item);
                                  }}>
                                  <i className="fa fa-edit"></i>{' '}
                                </span>
                              </Tooltip>
                              <Tooltip position="top" title={'Xoá'} size={'small'}>
                                <span className="btn btn-del" onClick={() => handleDel(item.id)}>
                                  <i className="fa fa-trash"></i>{' '}
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    Math.ceil((simsList ? simsList.sims_count : 0) / pagination.perPage),
                    Math.ceil((simsList ? simsList.sims_count : 0) / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(
                      Math.ceil((simsList ? simsList.sims_count : 0) / pagination.perPage)
                    )
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default SimAdmin;
