import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getServices, selectStatus } from '../../redux/accountantService';
import { getContracts } from '../../redux/contract';
import moment from 'moment';
import {
  delService,
  getControl,
  getInvoice,
  getPosControl,
  postDataAccountControl,
  resetPos,
  setProductControlToDownload
} from '../../redux/control';
import { showMessageError, showMessageSuccess } from '../../redux/alert-message';
import { splitLastOccurrence } from '../../helps/help';
import { Button } from 'shards-react';
import { getDirectionTelco } from '../../redux/direction-telco';
const useControl = () => {
  const dispatch = useDispatch();
  const [updateRowId, setUpdateRowId] = React.useState(null);
  const [updateRow, setUpdateRow] = React.useState(null);
  const [modalAsk, setModalAsk] = React.useState(false);
  const [modalInvoice, setModalInvoice] = React.useState(false);
  const [modalUpload, setModalUpload] = React.useState(false);
  const [modalCompare, setModalCompare] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState(null);

  const telcoList = useSelector((state) => state.directionTelco.list);
  const control = useSelector((state) => state.control.control);
  const posProducts = useSelector((state) => state.control.posProducts);
  const dataAgency = useSelector((state) => state.agency.agencyList);
  const statusControl = useSelector((state) => state.control);
  const status = useSelector(selectStatus);
  const statusPd = useSelector((state) => state.saleOrder);
  const statusGroup = useSelector((state) => state.groups);

  const [filter, setFilter] = React.useState({});
  const [openModalConfig, setOpenModalConfig] = React.useState(false);
  const [openUpdateSaleModal, setOpenUpdateSaleModal] = React.useState(false);
  const [openUpdateStartFeeModal, setOpenUpdateStartFeeModal] = React.useState(false);
  const [openDebtModal, setOpenDebtModal] = React.useState(false);
  const [dataDebtControl, setDataDebtControl] = React.useState('');
  const [openUpdateTelco, setOpenUpdateTelco] = React.useState({
    modal: false,
    data: ''
  });

  const [openUpdateFeeModal, setOpenUpdateFeeModal] = React.useState(false);
  const [openUpdateVatModal, setOpenUpdateVatModal] = React.useState(false);
  const [products, setProducts] = React.useState([]);

  const isLoading = useSelector((state) => state.control.loading);
  const [totalCost, setTotalCost] = React.useState(0);
  const [totalVat, setTotalVat] = React.useState(0);
  const [productSorted, setProductSorted] = React.useState([]);
  const isDownloading = useSelector((state) => state.control.downloading);
  const idCustomer = useSelector((state) => state.control.invoice);
  const [datahm, setDatahm] = React.useState({
    phi_hoa_mang: 0,
    ck_hoa_mang: 0,
    sl_hoa_mang: 0
  });

  const [datatb, setDatatb] = React.useState({
    phi_thue_bao: 0,
    ck_thue_bao: 0,
    sl_thue_bao: 0
  });
  // VAT
  const [vatUpdate, setVatUpdate] = React.useState({
    id: '',
    vat: ''
  });

  const [openSaleOrder, setOpenSaleOrder] = React.useState(false);

  const [rowsTelco, setRowsTelco] = React.useState([]);
  const [dataContract, setDataContract] = React.useState();
  const handleSearch = (filter) => {
    setTotalCost(0)
    setTotalVat(0)
    dispatch(resetPos())
    setProducts([]);
    setFilter({ ...filter });
    dispatch(getControl({ ...filter }))
      .unwrap()
      .then((res) => {
        setVatUpdate({
          ...vatUpdate,
          id: res && res.group && res.group.id,
          vat: res && res.group && res.group.vat,
          name: res && res.group && res.group.name,
          accs: res && res.group && res.group.accs
        });
        dispatch(
          getContracts({
            customer: res && res.customer && res.customer.id
          })
        )
          .unwrap()
          .then((res) => {
            const nameInvoice = res && res.results && res.results[0] && res.results[0].name;
            const agency =
              res &&
              res.results &&
              res.results[0] &&
              res.results[0].customer &&
              res.results[0].customer.name;
            const annexContract =
              res && res.results && res.results[0] && res.results[0].annexContract;
            const newDataInvoice = {
              nameInvoice,
              annexContract,
              agency,
              date: moment(filter.from).format('YYYY-MM-01'),
              signInvoice: res && res.results && res.results[0] && res.results[0].startdate && 
              moment(res.results[0].startdate).format('DD/MM/YYYY')
            };


            setDataContract(newDataInvoice);
          });
        dispatch(
          postDataAccountControl({
            from: filter.from,
            to: filter.to,
            group: res && res.group && res.group.name
          })
        );
        dispatch(
          getPosControl({
            id: res && res.group && res.group.id
          })
        ).unwrap().then((res) => {
          // setPosProducts(res && res.indexs)
        })
      });

  };

  React.useEffect(() => {
    dispatch(getDirectionTelco());
  }, []);

  function clearProductList() {
    setProducts((products) => []);
  }

  React.useEffect(() => {
    if (updateRowId || updateRowId === 0) {
      setUpdateRow(products[updateRowId]);
    } else {
      setUpdateRow(null);
    }
  }, [updateRowId]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setOpenModalConfig(false);
      setOpenUpdateStartFeeModal(false);
      setOpenUpdateFeeModal(false);
      setOpenUpdateVatModal(false);
      setUpdateRowId(null);
      setDataEdit(null);
    }
  };

  const handleDeleteRow = () => {
    let row = dataEdit;
    let productTemp = [...products];
    const index = productTemp.indexOf(dataEdit);
    if (row.hasOwnProperty('type') && row.type === 'product') {
      dispatch(delService({ id: row.id, group: control.group.id }))
        .unwrap()
        .then((res) => {
          //clearProductList();
          dispatch(showMessageSuccess());
          setModalAsk(false);
        })
        .catch((err) => {
          dispatch(showMessageError());
        });
    } else {
      productTemp.splice(index, 1);
      setProducts(productTemp);
    }
  };

  const addProductToTemp = (product, productListInput, unshift = false) => {
    const index = productListInput.findIndex((item) => item.name === product.name);
    if (index !== -1) {
      productListInput[index] = product;
    } else if (unshift) {
      productListInput.unshift(product);
    } else {
      productListInput.push(product);
    }
    return productListInput;
  };


  const getListProducts = (list,position) => {
    let productTemp = [...products];
    let total = 0
    let vat = 0
    let pos = position && position.indexs
    const arrFilter = [
      'Phí vượt phút Viettel',
      'Phí vượt phút Vinaphone',
      'Phí vượt phút Mobifone',
      'Phí vượt phút mobifone',
      'Phí vượt phút vinaphone',
      'Phí vượt phút viettel'
    ];
    if (list && list.totals) {
      list.totals.map((item) => {
        const [before, after] = item.name && splitLastOccurrence(item.name, 'Phí vượt phút ');

        return (productTemp = addProductToTemp(
          {
            ...item,
            telcoExcel: after,
            canDelete: false,
            action: arrFilter.includes(item.name) && (
              <Button
                onClick={() => {
                  setOpenUpdateTelco({
                    modal: true,
                    data: {
                      dataGroup: list && list.customer,
                      telcoList: [
                        { ...(list && list.group && list.group.viettel), name: 'Viettel' },
                        {
                          ...(list && list.group && list.group.mobifone),
                          name: 'Mobifone'
                        },
                        {
                          ...(list && list.group && list.group.vinaphone),
                          name: 'Vinaphone'
                        }
                      ],
                      telco: after,
                      ...item
                    }
                  });
                }}>
                Cập nhật
              </Button>
            )
          },

          productTemp,
          false
        ));
      });
    }
    if (list && list.group) {
      if (filter.typeExport !== 'product' && filter.typeExport !== 'voiceBranch') {
        setDatahm({
          ck_hoa_mang: list.ck_hoa_mang,
          phi_hoa_mang: list.phi_hoa_mang,
          sl_hoa_mang: list.sl_hoa_mang
        });
        setDatatb({
          ck_thue_bao: list.ck_thue_bao,
          phi_thue_bao: list.phi_thue_bao,
          sl_thue_bao: list.sl_thue_bao
        });
        productTemp = addProductToTemp(
          {
            name: 'Phí hòa mạng ban đầu',
            ck: list.group.ck_hoa_mang,
            price: list.group.phi_hoa_mang,
            qty: list.group.sl_hoa_mang,
            action: (
              <Button
                // className="form-control"
                onClick={() => {
                  setOpenUpdateStartFeeModal(!openUpdateStartFeeModal);
                }}>
                Cập nhật
              </Button>
            ),
            canDelete: false
          },
          productTemp,
          false
        );
        productTemp = addProductToTemp(
          {
            name: 'Phí thuê bao',
            ck: list.group.ck_thue_bao,
            price: list.group.phi_thue_bao,
            qty: list.group.sl_thue_bao,
            action: (
              <Button
                // className="form-control"
                onClick={() => {
                  setOpenUpdateFeeModal(!openUpdateFeeModal);
                }}>
                Cập nhật
              </Button>
            ),
            canDelete: false
          },
          productTemp,
          false
        );
      }
    }

    if (list && list.products) {
      list.products.map((item) => {
        return (productTemp = addProductToTemp(
          { ...item, canDelete: true, type: 'product', canUpdate: true },
          productTemp,
          false
        ));
      });
    }
    if (productTemp) {
      productTemp.map((item) => {
        let cost = Math.round(item.price * Math.round(item.qty) * ((100 - item.ck) / 100));
        if (Boolean(item.hasOwnProperty('minus') && item.minus)) {
          cost = -Math.abs(cost);
        }
        return (total += cost);
      });
      if (list && list.vat && list.vat > 0) {
        setTotalVat(Math.round(total * (list && list.vat / 100)));
        vat = Math.round(total * (list && list.vat / 100));
      }
      if (list && list.customer) {
        setDataDebtControl({
          ...dataDebtControl,
          customer: list.customer,
          month: moment(filter.to).format('M'),
          year: moment(filter.to).format('YYYY'),
          total: total + vat
        });
      }
      setTotalCost(total);
      const pdSorted = getPostProduct(productTemp,pos)
      setProducts(pdSorted);
      dispatch(setProductControlToDownload(pdSorted));
      dispatch(
        getInvoice({
          ...idCustomer,
          type_of_control: list && list.customer && list.customer.type_of_control,
          products: [{ name: 'Phí dịch vụ', price: total + vat }],
          totalControl: total + vat,
          productControl:pdSorted
        })
      );
      
    }
    
  }

  React.useEffect(() => {
    if(control && posProducts){
      getListProducts(control,posProducts)
    }    
  }, [control,posProducts]);
  
  console.log('product when render', products);

  console.log('posporudct', posProducts);



  const mergeArr = (arr1, arr2) => {
    const obj = {};

    arr1.forEach((item) => {
      obj[item] = item;
    });

    arr2.forEach((item) => {
      obj[item.name] ? (obj[item.name] = { ...obj[item], ...item }) : (obj[item.name] = item);
    });
    return Object.values(obj);
  };

  const getPostProduct = (controlProduct, posProduct) => {


    const output = mergeArr(posProduct, controlProduct);
    if (output) {
      const newOutput = output.filter((v) => v.name);
      return [...newOutput]
    } 
  }

  React.useEffect(() => {
    if (dataContract) {
      dispatch(getInvoice({ ...idCustomer, ...dataContract }));
    }
  }, [dataContract]);

  //update control
  React.useEffect(() => {
    if (filter.customer && statusControl.success === true) {
      setProducts([]);
      setTotalCost(0)
      setTotalVat(0)
      dispatch(getControl({ ...filter }))
    }
  }, [statusControl.success]);
//savepos control
React.useEffect(() => {
  if (statusControl.successPos === true) {
   
    dispatch(getPosControl({
      id: statusControl.control.group.id
    }))
    
  }
}, [statusControl.successPos]);
  // product

  React.useEffect(() => {
    if (statusPd.success === true) {
      setProducts([])
      // setPosProducts([])
      dispatch(getControl({ ...filter }))
      
    }
  }, [statusPd.success]);

  React.useEffect(() => {
    if (status.createSuccess === true) {
      dispatch(getServices({}));
    }
  }, [status.createSuccess]);

  // drag

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(products);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setProducts(items);
    dispatch(
      getInvoice({
        ...idCustomer,
        productControl: items
      })
    );
    dispatch(setProductControlToDownload(items));
  }
  return {
    updateRow,
    setUpdateRow,
    modalAsk,
    setModalAsk,
    setUpdateRowId,
    modalInvoice,
    setModalInvoice,
    modalUpload,
    setModalUpload,
    modalCompare,
    setModalCompare,
    dataEdit,
    setDataEdit,
    idCustomer,
    telcoList,
    control,
    posProducts,
    dataAgency,
    statusControl,
    statusGroup,
    filter,
    setFilter,
    openModalConfig,
    setOpenModalConfig,
    openUpdateSaleModal,
    setOpenUpdateSaleModal,
    openUpdateStartFeeModal,
    setOpenUpdateStartFeeModal,
    openDebtModal,
    setOpenDebtModal,
    dataDebtControl,
    setDataDebtControl,
    openUpdateTelco,
    setOpenUpdateTelco,
    openUpdateFeeModal,
    setOpenUpdateFeeModal,
    openUpdateVatModal,
    setOpenUpdateVatModal,
    products,
    setProducts,
    isLoading,
    totalCost,
    totalVat,
    productSorted,
    isDownloading,
    datahm,
    setDatahm,
    datatb,
    setDatatb,
    vatUpdate,
    setVatUpdate,
    openSaleOrder,
    setOpenSaleOrder,
    rowsTelco,
    setRowsTelco,
    handleSearch,
    clearProductList,
    handleDeleteRow,
    handleOnDragEnd,
    handleKeyDown
  };
};

export default useControl;


