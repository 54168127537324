import React from 'react'

const DialogAsk = (props) => {
  return (
    <div className='content text-center'>
       <span className='fas fa-exclamation-circle'></span>
        <p>{props.text}</p>
       
        <div className='flex' style={{justifyContent:'flex-end',marginTop:10, paddingTop:15}}>
        <div className="btn-outline br-theme" onClick={()=>props.handleCanel(false)}>{props.textCanel || 'Thoát'} </div>
        <div style={{
            height:36,
            lineHeight:'36px',
            marginLeft:10
        }} className={`btn ${props.isDisabled === false ? 'disabled' : ''}`} onClick={props.handleConfirm}>{props.textConfirm || 'Xóa' } </div>
        </div>
      
    </div>
  )
}

export default DialogAsk