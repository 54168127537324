import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../hooks/use-pagination';
import { deleteGroup, getGroup } from '../redux/group';
import { Tooltip } from 'react-tippy';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import Modal, { ModalContent } from '../../components/common/Modal';
import DialogAsk from '../../components/common/DialogAsk';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../components/common/Empty'));
const GroupView = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const [modalAsk, setModalAsk] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);

  const groupList = useSelector(state => state.groups.groups);
  const statusGroup = useSelector(state => state.groups);
  const isLoading = useSelector(state => state.groups.loading);

  const pagination = usePaginationState();

  const handleClearSearch = () => {
    setSearch();
  };

  useEffect(() => {
    
      dispatch(
        getGroup({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage,
          name: search && search.trim()
        })
      );
    
  }, [ pagination]);



  useEffect(() => {
    if (statusGroup.success === true) {
      dispatch(
        getGroup({
          limit: pagination.perPage,
          offset: !search ? pagination.perPage * pagination.page - pagination.perPage : 0,
          name: search && search.trim()
        })
      );
    }
  }, [statusGroup.success]);

  const handleDel = () => {
    if(dataEdit){
      const id = dataEdit && dataEdit.id
      dispatch(deleteGroup({id}))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
        setModalAsk(false);
        setDataEdit(null)
      })
      .catch(() => {
        dispatch(showMessageError());
      });
      
    }

  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
          <Modal active={modalAsk}>
        <ModalContent
          className="modal-warning"
          onClose={() => {
            setModalAsk(false);
            
          }}
        >
          <DialogAsk
            handleCanel={setModalAsk}
            dataEdit={dataEdit}
            text={`Bạn có muốn xoá group ${dataEdit && dataEdit.name}`}
            handleConfirm={handleDel}
            isDisabled={statusGroup.success}
          />
        </ModalContent>
      </Modal>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý groups
          <Link to={'/group/create'} className="btn">
            <span className="fa fa-plus-circle mr-2"></span>
            Thêm group
          </Link>
        </h1>
        <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              disabled={isLoading}
              type="text"
              name="name"
              placeholder="Search theo tên nhóm"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getGroup({
                      limit: pagination.perPage,
                      offset: pagination.perPage * pagination.page - pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Enter'|| e.keyCode === 13) {
                  e.preventDefault();
                  dispatch(
                    getGroup({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      name: search && search.trim()
                    })
                  );
                }
              }}
            />
            <span
              className="clear-search"
              onClick={() => {
                handleClearSearch()
              }}>
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>

        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="border-0" style={{ width: 150 }}>
                    Tên group
                  </th>
                  <th scope="col" className="border-0">
                    AccCode
                  </th>
                  <th scope="col" className="border-0" style={{ width: '180px' }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupList &&
                  groupList.results &&
                  groupList.results.map((group, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{group.name}</td>
                        <td>
                          {group.accs &&
                            group.accs.length > 0 &&
                            group.accs
                              .filter(item => item.code !== null)
                              .map(item => item.code)
                              .join(', ')}
                        </td>
                        <td>
                          <Tooltip position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                            <Link to={`/group/update/${group.id}`} className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </Link>
                          </Tooltip>
                          <Tooltip position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                            <span className="btn btn-del" onClick={() => {
                              setDataEdit(group);
                              setModalAsk(true)
                            }}>
                              <i className="fa fa-trash"></i>
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {groupList && groupList.results && groupList.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((groupList ? groupList.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(
                  Math.ceil((groupList ? groupList.count : 0) / pagination.perPage)
                )
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default GroupView;
