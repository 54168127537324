import React from 'react'
import Heading from './Heading';
import img from '../../images/empty.jpg'
const Empty = (props) => {
    return (
      <div className='empty text-center'>
        <img src={img} alt="" />
        <Heading type='h2' className={'heading'}>{props.description}</Heading>
      </div>
    );
  };

  export default Empty;
