import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetState } from '../../../erp/redux/alert-message';
import ToastList from './ToastList';

const Toast = () => {
  const [toasts, setToasts] = React.useState([]);
  const autoCloseDuration = 5;
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    if (alert && ['success', 'error'].includes(alert.type)) {
      setToasts([...toasts, alert]);

      setTimeout(() => {
        removeToast(alert.id);
      }, autoCloseDuration * 1000);
    }
  }, [alert]);

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetState());
    }, autoCloseDuration * 1000);
  }, [alert.showStatus]);

  return (
    <>
      <ToastList data={toasts} removeToast={removeToast} />
    </>
  );
};

export default Toast;
