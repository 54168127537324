import React, { useState, useEffect } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast_type, assetDocumentTabs } from '../../../constants';
import { getAssetDocumentType } from '../../redux/asset-document-type';
import { getAssetDocumentStatus } from '../../redux/asset-document-status';
import { getAssetDocumentById } from '../../redux/asset-document';

const AssetDocumentType = React.lazy(() => import('./AssetDocumentType'));
const UcAssetDocument = React.lazy(() => import('./UcAssetDocument'));
const AssetDocumentStatus = React.lazy(() => import('./AssetDocumentStatus'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));

const Wrapper = () => {
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(state => state.assetDocument);
  const statusDocumentType = useSelector(state => state.assetDocumentType);
  const detail = useSelector(state => state.assetDocument.detail);
  const statusDocumentStatus = useSelector(state => state.assetDocumentStatus);
  useEffect(() => {
    dispatch(getAssetDocumentType());
    dispatch(getAssetDocumentStatus());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getAssetDocumentById({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (status.success === true && id) {
      dispatch(getAssetDocumentById({ id }));
    }
  }, [status.success, id]);

  useEffect(() => {
    if (statusDocumentType.success === true) {
      dispatch(getAssetDocumentType());
    }
  }, [statusDocumentType.success]);

  useEffect(() => {
    if (statusDocumentStatus.success === true) {
      dispatch(getAssetDocumentStatus());
    }
  }, [statusDocumentStatus.success]);

  // Tab
  const [activeTab, setActiveTab] = useState('');
  const dataInformation = [<AssetDocumentType />, <AssetDocumentStatus />];

  return (
    <React.Suspense>
      <Container fluid className="main-content-container px-4">
        <Spinner loading={status.loadingDetail} top={200} />
        
        <span
          className="btn"
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 'inherit',
            width: 40,
            height: 40,
            lineHeight: 40,
            marginTop: 20
          }}>
          <i className="fa fa-chevron-left"></i>
        </span>

      {!id ? <UcAssetDocument /> : <UcAssetDocument dataEdit={detail} />}
      <div className="tabs-list">
        {assetDocumentTabs.map((v, i) => (
          <div
            key={i}
            className={`tab-item ${activeTab === v.key ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(v.key);
            }}>
            <p> <span className='fa fa-plus-circle'></span> {v.name.toUpperCase()}</p>
          </div>
        ))}
      </div>
      <div className='box-form-general'>
         {dataInformation[activeTab]}
      </div>
    </Container>
        
    </React.Suspense>
  );
};

export default Wrapper;
