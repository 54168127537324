export default function () {
  return [
    // {
    //   title: 'Tổng quan',
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: '/analytic',
    //   symbol: 'ACCOUNT'
    // },
    {
      title: 'Cấu hình',
      htmlBefore: '<i class="fa fa-cog"></i>',
      to: '/tao-tai-khoan',
      symbol: 'ADMIN',
      submenu: [
        {
          title: 'Quản lý group',
          to: '/group'
        },
        {
          title: 'Acc Code',
          to: '/acc-code'
        },
        {
          title: 'Khách hàng',
          to: '/khach-hang'
        },
        {
          title: 'Đại lý',
          to: '/dai-ly'
        },
        {
          title: 'Nhà mạng',
          to: '/telco'
        },
        {
          title: 'Quản lý gói cước',
          to: '/setup-telco'
        },
        {
          title: 'Quản lý đầu số ',
          to: '/cmc-phone'
        },
        {
          title: 'Quyền hạn',
          to: '/permission'
        },
        {
          title: 'Vị trí',
          to: '/position'
        },
        {
          title: 'Phòng ban',
          to: '/department'
        },
        {
          title: 'Nhân viên',
          to: '/employee'
        },
        {
          title: 'User',
          to: '/user'
        }
      ]
    },
    {
      title: 'Tài khoản',
      htmlBefore: '<i class="material-icons">person</i>',
      to: '/tai-khoan',
      symbol: 'ACCOUNT',
      submenu: [
        {
          title: 'Thống kê',
          to: '/tai-khoan'
        },
        {
          title: 'Quản lý tài khoản',
          to: '/tai-khoan-enduser'
        },
        {
          title: 'Quản lý tài khoản ngân hàng',
          to: '/account-bank'
        },
        {
          title: 'Lịch sử dùng số',
          to: '/history-user-sim'
        }
      ]
    },
    {
      title: 'Đối soát',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/tong-hop',
      symbol:['SUM','DETAIL','REPORT','RAR'],
      submenu: [
        {
          title: 'Thống kê RAR',
          htmlBefore: '<i class="material-icons">view_module</i>',
          to: '/thong-ke-video-call',
          symbol: 'RAR'
        },
        {
          title: 'Tổng hợp',
          htmlBefore: '<i class="material-icons">view_module</i>',
          to: '/tong-hop',
          symbol: 'SUM'
        },
        {
          title: 'Chi tiết',
          htmlBefore: '<i class="material-icons">view_module</i>',
          to: '/chi-tiet',
          symbol: 'DETAIL'
        },
        {
          title: 'So sánh',
          htmlBefore: '<i class="material-icons">view_module</i>',
          to: '/so-sanh',
          symbol: 'COMPARE'
        },
        {
          title: 'Xuất đối soát',
          htmlBefore: '<i class="material-icons">view_module</i>',
          to: '/xuat-doi-soat',
          symbol: 'REPORT'
        },
        {
          title: 'Thống kê tỉ lệ',
          htmlBefore: '<i class="material-icons">table_chart</i>',
          to: '/thong-ke-ti-le',
          symbol: 'THONG-KE'
        },
        {
          title: 'Thống kê tỉ lệ theo ngày',
          htmlBefore: '<i class="material-icons">table_chart</i>',
          to: '/thong-ke-ti-le-theo-ngay',
          symbol: 'THONG-KE-THEO-NGAY'
        },
        {
          title: 'Gọi nội hạt liên tỉnh',
          htmlBefore: '<i class="material-icons">table_chart</i>',
          to: '/not-hat-lien-tinh',
          symbol: 'NOI-HAT-LIEN-TINH'
        }
      ]
    },

    // {
    //   title: 'Kho Sim (User)',
    //   htmlBefore: '<i class="fa fa-shopping-basket"></i>',
    //   to: '/sim',
    //   submenu: [
    //     {
    //       title: 'Đăng ký số Sip',
    //       to: '/sim'
    //     },
    //     {
    //       title: 'Danh sách số đăng ký',
    //       to: '/sim-reg'
    //     },
    //     {
    //       title: 'Danh sách đặt số ngoài',
    //       to: '/sim-order'
    //     }
    //   ],
    //   symbol: 'SIMSTORE-USER'
    // },
    {
      title: 'Quản lý kho',
      htmlBefore: '<i class="fa fa-database"></i>',
      htmlAfter: '',
      to: '/sim',
      submenu: [
        {
          title: 'Danh sách sim',
          to: '/sim-admin'
        },
        {
          title: 'Khóa sim/sip',
          to: '/sim-lock'
        },
        {
          title: 'Danh sách số KH đã đăng ký',
          to: '/sim-admin-reg'
        },
        {
          title: 'Danh sách order ngoài',
          to: '/admin-order'
        }
      ],
      symbol: 'SIMSTORE-STAFF'
    },
    {
      title: 'Ticket',
      htmlBefore: '<i class="fa fa-ticket-alt"></i>',
      htmlAfter: '',
      to: '/ticket',
      symbol: 'TICKET',
      submenu: [
        {
          title: 'Danh sách Ticket',
          to: '/ticket'
        }
      ]
    },
    {
      title: 'F88',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/f88',
      symbol: 'F88'
    },
    {
      title: 'Kế Toán',
      htmlBefore: '<i class="material-icons">paid</i>',
      to: '/accountant',
      submenu: [
        {
          title: 'Quản lý công nợ',
          to: '/accountant/debt'
        },
        {
          title: 'Danh sách sản phẩm',
          to: '/accountant/services',
        },
        // {
        //   title: 'Hợp đồng',
        //   to: '/contracts'
        // },
        {
          title: 'Doanh thu',
          to: '/accountant/sales'
        },
        {
          title: 'Chi phí',
          to: '/expense-order'
        },
        // {
        //   title: 'Quản lý tài sản',
        //   to: '/accountant/asset'
        // },
        {
          title: 'Quản lý tài sản (Asset Document)',
          to: '/accountant/asset-document'
        }
      ],
      symbol: 'MANAGE-SERVICE',
    }
  ];
}
