import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  list:{}
};

const ACTION = {
  GET_CALL_LOCAL: 'call-local/getCallLocal',
};

export const getCallLocal = createAsyncThunk(ACTION.GET_CALL_LOCAL, async body => {
  return axiosInstance.get('restapi/export/call-local', { params: body });
});



const callLocalSlice = createSlice({
  name: 'call-local',
  initialState: initialState,
  extraReducers: {
    [getCallLocal.pending.toString()]: state => {
      state.loading = true;
    },
    [getCallLocal.rejected.toString()]: state => {
      state.loading = false;
    },
    [getCallLocal.fulfilled.toString()]: (state, action) => {
      state.loading = false;
   
      state.list = action.payload;
    },
  }
});

const { reducer: callLocalReducer } = callLocalSlice;
export default callLocalReducer;
