export function formatMoney(num, tag = ' VND') {

  var money = Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '';
  let moneySplits = money.split('.');
  var results = '';
  if (moneySplits.slice(-1)[0].length === 2) {
    moneySplits.map((item, idx) => {
      if (idx !== moneySplits.length - 1) {
        results += item + ',';
      }
      return item;
    });
    if (moneySplits.slice(-1)[0] === '00') {
      results = results.substring(0, results.length - 1);
    } else {
      results = results.substring(0, results.length - 1) + '.' + moneySplits.slice(-1)[0];
    }
  } else {
    results = moneySplits.map(item => {
      results += item + '.';
      return results
    });
    results = results.toString().substring(0, results.length - 1);
  }
  return results + tag;
}

export const formatMoneyInvoice = (num) =>{
  var money = Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '';
  
return money  
  

}
