import React, { useEffect, useState } from 'react';
import { Container, FormInput } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../hooks/use-pagination';
import { getCustomer, selectStatus, deleteCustomer } from '../redux/customer';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import { Tooltip } from 'react-tippy';
import { Link } from 'react-router-dom';
import Modal, { ModalContent } from '../../components/common/Modal';
import DialogAsk from '../../components/common/DialogAsk';
import { resetCustomerId } from '../redux/contract';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Empty = React.lazy(() => import('../../components/common/Empty'));

const Customer = () => {
  const dispatch = useDispatch();
  const [modalAsk, setModalAsk] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const customerList = useSelector((state) => state.customers.customerList);
  const isLoading = useSelector((state) => state.customers.loading);
  const status = useSelector(selectStatus);
  const [search, setSearch] = useState('');

  // search
  const handleClearSearch = () => {
    setSearch();
  };

  const pagination = usePaginationState();




  useEffect(() => {
    dispatch(
      getCustomer({
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage,
        name: search && search.trim()
      })
    );
    
  }, [pagination]);



  const getAddressString = (customer) => {
    var address = '';
    if (customer.ward && customer.ward.name) {
      address += customer.ward.name + ', ';
    }
    if (customer.district && customer.district.name) {
      address += customer.district.name + ', ';
    }
    if (customer.city && customer.city.name) {
      address += customer.city.name + ', ';
    }
    if (address.length) {
      address = address.substring(0, address.length - 2);
    }
    return address;
  };

  const getAgencyString = (customer) => {
    if (customer.agency) {
      return customer.agency.name;
    }
    return '';
  };

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomer(dataEdit))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
        setModalAsk(false);
        setDataEdit(null);
      })
      .catch(() => {
        dispatch(showMessageError());
      });
  };
  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getCustomer({
          limit: pagination.perPage,
          offset: !search ? pagination.perPage * pagination.page - pagination.perPage : 0,
          name: search && search.trim()
        })
      );
    }
  }, [status.success]);
useEffect(() => {
  dispatch(resetCustomerId())
}, [])
  return (
    <React.Suspense>
      <Container fluid className="main-content-container border-t">
        <Modal active={modalAsk}>
          <ModalContent
            className="modal-warning"
            onClose={() => {
              setModalAsk(false);
            }}>
            <DialogAsk
              dataEdit={dataEdit}
              text={`Bạn có muốn xoá khách hàng ${dataEdit && dataEdit.name} ?`}
              handleCanel={setModalAsk}
              handleConfirm={handleDeleteCustomer}
              isDisabled={status.success}
            />
          </ModalContent>
        </Modal>

        <h1 className="heading">
          Quản lý khách hàng
          <Tooltip position="right" title={'Tạo mới'} size={'small'} animation={'shift'}>
            <Link to={'/khach-hang/create'} className="btn" style={{ marginLeft: 10 }}>
              <span className="fa fa-plus-circle mr-2"></span>
              Thêm khách hàng
            </Link>
          </Tooltip>
        </h1>
        <div className="text-right ml-auto">
          <div className="form-search">
            <FormInput
              disabled={isLoading}
              type="text"
              name="name"
              placeholder="Search theo tên"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.trim().length === 0) {
                  setSearch();
                  dispatch(
                    getCustomer({
                      limit: pagination.perPage,
                      offset: pagination.perPage * pagination.page - pagination.perPage
                    })
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  e.preventDefault();
                  dispatch(
                    getCustomer({
                      limit: pagination.perPage,
                      offset: !search
                        ? pagination.perPage * pagination.page - pagination.perPage
                        : 0,
                      name: search && search.trim()
                    })
                  );
                }
              }}
            />
            <span
              className="clear-search"
              onClick={() => {
                handleClearSearch();
              }}>
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>

        {isLoading ? (
          <Spinner loading={isLoading} top={100} />
        ) : (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Tên
                  </th>
                  <th scope="col" className="border-0">
                    Sdt
                  </th>
                  <th scope="col" className="border-0">
                    Đại lý
                  </th>
                  <th scope="col" className="border-0">
                    Nhóm mã
                  </th>

                  <th scope="col" className="border-0 text-center" style={{ width: 120 }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerList &&
                  customerList.results &&
                  customerList.results.map((cus, idx) => {
                    return (
                      <tr key={idx + 1}>
                        <td>{cus.name}</td>
                        <td>{cus.phone}</td>
                        <td>{getAgencyString(cus)}</td>
                        <td>{cus.group ? cus.group.name : 'Chưa cập nhật'}</td>
                        <td className="text-center">
                          <Tooltip
                            position="top"
                            title={'Cập nhập'}
                            size={'small'}
                            animation={'shift'}>
                            <Link to={`/khach-hang/update/${cus.id}`} className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </Link>
                          </Tooltip>

                          <Tooltip position="top" title={'Xoá'} size={'small'} animation={'shift'}>
                            <span
                              className="btn btn-del"
                              onClick={() => {
                                setDataEdit(cus);
                                setModalAsk(true);
                              }}>
                              <i className="fa fa-trash"></i>
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {customerList && customerList.results && customerList.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((customerList ? customerList.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(
                  Math.ceil((customerList ? customerList.count : 0) / pagination.perPage)
                )
              }
            />
          </div>
        )}
      </Container>
    </React.Suspense>
  );
};

export default Customer;
