import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getTicket } from '../redux/ticket';
import { getEnduser } from '../redux/enduser';
import { usePaginationState } from '../../hooks/use-pagination';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tippy';

const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const DialogUCTicket = React.lazy(() => import('../../components/sim/DialogUCTicket'));
const TicketDetail = React.lazy(() => import('../../components/ticket/TicketDetail'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));

const Ticket = () => {
  const { uuid } = useParams();

  const dispatch = useDispatch();

  const enduserList = useSelector(state => state.enduser.enduser.info);

  const ticketList = useSelector(state => state.ticket.ticketList);

  const { loading, successEdit, successUpdateApproved } = useSelector(state => state.ticket);

  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getTicket({
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage
      })
    );
  }, [pagination]);

  useEffect(() => {
    dispatch(getEnduser());
  }, []);

  useEffect(() => {
    if (successEdit === true) {
      setTimeout(() => {
        dispatch(getTicket({}));
      }, 1000);
    }
  }, [successEdit]);

  useEffect(() => {
    if (successUpdateApproved === true) {
      setTimeout(() => {
        dispatch(getTicket({}));
      }, 1000);
    }
  }, [successUpdateApproved]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalTicket, setOpenModalTicket] = useState(false);

  const [data, setData] = useState('');

  const wrapperRef = useRef(null);

  const closeModal = e => {
    if (e.target === wrapperRef.current) setOpenModal(false);
    setOpenModalTicket(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  const handleCloseModalTicket = () => {
    setOpenModalTicket(false);
    setData('');
  };

  // open ticket

  useEffect(() => {
    if (loading === false && uuid) {
      let filterDatabyuuid = ticketList.open_Ticket.find(item => item.unique_id === uuid);
      setOpenModalTicket(true);
      setData(filterDatabyuuid);
    }
  }, [uuid, loading]);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Danh sách Ticket</h1>
        {openModal && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">{!data ? 'Tạo' : 'Sửa'}</h3>
                <div className="popup-close" onClick={() => handleCloseModal()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogUCTicket
                  enduserList={enduserList}
                  uuid={uuid}
                  setUDO={setOpenModal}
                  setData={setData}
                  dataEdit={data}
                />
              </div>
            </div>
          </>
        )}

        {openModalTicket && (
          <>
            <div className="modal-overlay"></div>
            <div className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">Chi tiết Ticket</h3>
                <div className="popup-close" onClick={() => handleCloseModalTicket()}>
                  <span className="fa fa-times"></span>
                </div>
                <TicketDetail uuid={uuid} setUDO={setOpenModal} />
              </div>
            </div>
          </>
        )}
        <Row>
          <Col>
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        Tên
                      </th>
                      <th scope="col" className="border-0">
                        SĐT
                      </th>
                      <th scope="col" className="border-0">
                        Tên người tạo
                      </th>

                      <th scope="col" className="border-0">
                        Nội dung
                      </th>

                      <th scope="col" className="border-0">
                        Ngày tạo
                      </th>
                      <th scope="col" className="border-0">
                        Trạng Thái
                      </th>
                      <th scope="col" className="border-0">
                        Gán cho
                      </th>
                      <th scope="col" className="border-0">
                        Mức độ
                      </th>
                      <th scope="col" className="border-0">
                        Nhóm duyệt
                      </th>
                      <th scope="col" className="border-0">
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketList && ticketList.open_Ticket &&
                      ticketList.open_Ticket.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.phone}</td>
                            <td>{item.cus_name}</td>

                            <td>{item.content}</td>
                            <td>{moment(item.create_date).format('YYYY-MM-DD')}</td>

                            <td>{item.trangthai}</td>
                            <td>{item.assigned_to !== null ? item.assigned_to.username : ''}</td>
                            <td>{item.priority}</td>
                            <td>{item.ticketApprove.name}</td>
                            <td>
                              <Tooltip position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-edit"
                                  onClick={() => {
                                    setOpenModal(true);
                                    setData(item);
                                  }}>
                                  <i className="fa fa-edit"></i>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    Math.ceil((ticketList ? ticketList.Ticket_count : 0) / pagination.perPage),
                    Math.ceil((ticketList ? ticketList.Ticket_count : 0) / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(
                      Math.ceil((ticketList ? ticketList.Ticket_count : 0) / pagination.perPage)
                    )
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default Ticket;
