import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    expensesList: [],
    expensesListChart: [],
    
    successCreate: null,
    successEdit: null,
    successDelete: null,
    loadingDetail: false,

};

const ACTION = {
    GET_EXPENSES: 'expenses/getExpenses',
    CREATE_EXPENSES: 'expenses/createExpenses',
    EDIT_EXPENSES: 'expenses/editExpenses',
    DELETE_EXPENSES: 'expenses/deleteExpenses',

    GET_REPORT_BUY_CHART: 'expenses/getReportBuy',
    GET_REPORT_SALE_CHART: 'expenses/getReportSale'
};

export const getExpenses = createAsyncThunk(ACTION.GET_EXPENSES, async body => {
   
    return axiosInstance.get('/api/accountant/expenses/', {params:body});
});

export const createExpenses = createAsyncThunk(ACTION.CREATE_EXPENSES, async body => {
    
    return axiosInstance.post('/api/accountant/expenses/', body);
});

export const editExpenses = createAsyncThunk(ACTION.EDIT_EXPENSES, async body => {
    return axiosInstance.put(`/api/accountant/expenses/${body.id}/`,body);
});

export const deleteExpenses = createAsyncThunk(ACTION.DELETE_EXPENSES, async ({ id }) => {
    return axiosInstance.delete(`/api/accountant/expenses/${id}/?`);
});

export const getReportBuy = createAsyncThunk(ACTION.GET_REPORT_BUY_CHART, async body => {
    return axiosInstance.get('/api/accountant/buy-reports/', { params: body });
});

export const getReportSale = createAsyncThunk(ACTION.GET_REPORT_SALE_CHART, async body => {
    return axiosInstance.get('/api/accountant/sale-reports/', { params: body });
});

const expensesSlice = createSlice({
    name: 'expenses',
    initialState: initialState,
    extraReducers: {
        [getExpenses.pending.toString()]: state => {
            state.loading = true;
        },
        [getExpenses.rejected.toString()]: state => {
            state.loading = false;
        },
        [getExpenses.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.successCreate = null;
            state.successEdit = null;
            state.successDelete = null;
            state.expensesList = action.payload;
        },
        ///////////create expenses
        [createExpenses.pending.toString()]: state => {
            state.loading = true;
        },
        [createExpenses.rejected.toString()]: state => {
            state.loading = false;
            state.successCreate = false;
        },
        [createExpenses.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.successCreate = true;
        },
        // edit expenses
        [editExpenses.pending.toString()]: state => { },
        [editExpenses.rejected.toString()]: state => {
            state.successEdit = false;
        },
        [editExpenses.fulfilled.toString()]: state => {
            state.successEdit = true;
        },
        //////////delete expenses
        [deleteExpenses.pending.toString()]: state => { },
        [deleteExpenses.rejected.toString()]: state => {
            state.successDelete = false;
        },
        [deleteExpenses.fulfilled.toString()]: state => {
            state.successDelete = true;
        },

        /////////////chart expensese
        [getReportBuy.pending.toString()]: state => {
            state.loadingExpensesChart = true;
        },
        [getReportBuy.rejected.toString()]: state => {
            state.loadingExpensesChart = false;
        },
        [getReportBuy.fulfilled.toString()]: (state, action) => {
            state.loadingExpensesChart = false;
            state.expensesChart = action.payload;
        },
        // report sale
        [getReportSale.pending.toString()]: state => {
            state.loadingSaleChart = true;
        },
        [getReportSale.rejected.toString()]: state => {
            state.loadingSaleChart = false;
        },
        [getReportSale.fulfilled.toString()]: (state, action) => {
            state.loadingSaleChart = false;
            state.saleChart = action.payload;
        },
    }
});
const { reducer: expensesReducer } = expensesSlice;
export default expensesReducer;
