import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';
import { useSelector } from 'react-redux';

const MainSidebar = () => {
  const isOpenSidebar = useSelector(state => state.sidebar.toggleSidebar);

  const classes = classNames('main-sidebar', 'px-0', 'col-12', isOpenSidebar && 'open');

  return (
    <Col tag="aside" className={classes}>
      <SidebarMainNavbar hideLogoText={true} />
      {/* <SidebarSearch /> */}
      <SidebarNavItems />
    </Col>
  );
};

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
