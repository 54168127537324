import React from 'react';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import moment from 'moment';
import to_vietnamese from '../../helps/transNumText';
const ControlExcelNewCustomer = (props) => {
  const [workBookName, setWorkBookName] = React.useState('')
  const data = useSelector((state) => state.control.invoice);
  const control = useSelector((state) => state.control.control);

  const fromDate = data && data.date && moment(data.date).format('DD/MM/YYYY')
  const toDate = data && data.date && moment(data.date).add(5, 'days').format('DD/MM/YYYY')
  const signInvoice = data.signInvoice
  const contractName = props.contractName || data && data.annexContract && data.annexContract.length > 0 && data.annexContract.map((v) => v.name) || ''

  const invoiceName =
    (data && data.nameInvoice) || (control && control.group && control.group.contract) || '';
  const type_of_control = (data && data.type_of_control) ? data.type_of_control : 'Contact Center'
  const customerName = data && (data.nameCustomer || data.value);




  const keyColumn = () => {
    let columns = [];
    return (columns = [
      { key: 'stt', width: 10 },
      { key: 'product', width: 50 },
      { key: 'price', width: 15 },
      { key: 'qty', width: 15 },
      { key: 'total', width: 15 },
    ]);

  };

  const keyHeader = () => {
    let header = [];
    return (header = [
      'STT',
      'Diễn giải',
      'Đơn giá',
      'Số lượng',
      'Thành tiền',
    ]);

  };
  // product base
  const dataProduct = () => {
    return (
      data &&
      data.productControl &&
      data.productControl.filter(
        (m) => m.qty !== 0 && m.price !== 0)
    )
  };
  const calculateTotal = (qty, price, ck) => {
    if (!(qty && price)) {
      return 0;
    }
    let total = price * qty * ((100 - ck) / 100);
    // if (v.minus) {
    //   return Math.abs(total) * -1
    // }
    return total;
  };
  const sum = (arr) => {
    if (arr) {
      let total = 0;
      arr.map((item) => {
        let cost = Math.round(item.price * Math.round(item.qty) * ((100 - item.ck) / 100));
        if (Boolean(item.hasOwnProperty('minus') && item.minus)) {
          cost = -Math.abs(cost);
        }
        return (total += cost);
      });
      return total;
    }
  };
  const getFileName = (p1, p2, to, typeExport) => {
    let fileName = 'Default';
    if (props.filter[p2] || (Array.isArray(props.filter[p2]) && props.filter[p2].length > 0)) {
      fileName = props.filter[p2];
    } else if (props.filter[p1]) {
      fileName = props.filter[p1];
    }
    fileName = fileName + '_T' + to.format('M');
    if (typeExport) {
      fileName = fileName + '_' + typeExport;
    }
    return fileName;
  };
  
  React.useEffect(() => {
    if(props.filter){
      setWorkBookName('Xuat_doi_soat_' +
      getFileName('customer', 'customer', moment(props.filter['to']), props.filter['typeExport']))
    }
  }, [props.filter])
  

  const workSheetName = 'report';


  const myInputId2 = 'myInput';
  const workbook = new Excel.Workbook();
  console.log('============================data INVOICE', data);
  const saveExcel = async () => {
    // const newDataCreditToExport =
    try {
      const myInput = document.getElementById(myInputId2);
      const fileName = myInput.value || workBookName;

      const line1 = 'THÔNG BÁO PHÍ SỬ DỤNG DỊCH VỤ THÁNG …';

      const line2 = `Dịch vụ: ${type_of_control}`;
      // skipeed line 3 + 4
      const line5 = 'CÔNG TY CỔ PHẦN VIỄN THÔNG TIN HỌC VIỆT NAM'

      const line6 = 'Địa chỉ: Số 74 ngõ 297 Đường Trần Cung, Cổ Nhuế 1, Bắc Từ Liêm, Hà Nội';

      const line7 = 'Sô tài khoản: 0581161668888 Tại: CN Đống Đa – Ngân hàng TMCP Quân Đội';
      // skipped line 8
      const line9 = `Kính gửi: ${customerName}`;
      const line10 = 'Địa chỉ: Số …';

      const line11 = `Số hợp đồng : ${contractName ? `PL số ${contractName} - ` : ''} HĐ: ${invoiceName}         Ngày ký hợp đồng : ${signInvoice ? signInvoice : ''}`
      const line12 = 'Thời điểm chốt công nợ :';
      const line13 = `Từ: ${fromDate}`;
      const line14 = `Đến ngày: ${toDate}`
      const line15 = 'Các khoản chi tiết như sau:';
      //skipped line 16

      const lastLine = [
        'Lưu ý :','Thông báo này không được coi là thông báo chứng tỏ Quý khách đã thanh toán cước',
        'Thông báo cước được gửi tới khách hàng qua email, sau 2 ngày kể từ ngày VNTEL gửi TBC nếu khách hàng không có khiếu nại, thì đây sẽ là căn cứ để VNTEL xuất hóa đơn cho khách hàng.','Quý khách vui lòng thanh toán đầy đủ số tiền cước để đảm bảo liên lạc được thông suốt.',
        'Để biết chi tiết cước sử dụng, xin vui lòng liên hệ hot line: 19000102 .'
      ]
      // creating one worksheet in workbook
      if (workbook !== null) {
        const worksheet = workbook.addWorksheet(workSheetName, {
          pageSetup: {
            // Print size set to A4
            paperSize: 9,
            orientation: 'landscape',
            fitToPage: true,
          }
        });








        //font base
        const fontBase = { name: 'Times New Roman', family: 4, size: 10, bold: true };
        const fontBase2 = { name: 'Times New Roman', family: 4, size: 10 };

        //format cell number

        const numFormat = '#,##0';
        const numFormatCk = '#,##0.00';
        const numFormatCkBase = '#,##0';

        const numFormatAccount = '#0.00';

        // area table A-G
        const areaTable = ['A', 'B', 'D', 'E'];

        //fill bg

        const fill = {
          type: 'pattern',
          pattern: 'darkVertical',
          fgColor: { argb: '92CDDC' },
          bgColor: { argb: '92CDDC' }
        };

        const fillMinus = {
          type: 'pattern',
          pattern: 'darkVertical',
          fgColor: { argb: 'FF6666' },
          bgColor: { argb: 'FF6666' }
        };

        const alignmentCenter = {
          vertical: 'middle',
          horizontal: 'center'
        };

        const alignmentRight = {
          vertical: 'middle',
          horizontal: 'right'
        };
        //border

        const border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        //merge cell A1 - A7

        const column = 'E';
        if (column) {
          for (let i = 1; i < 17; i++) {
            worksheet.mergeCells(`A${i}:${column}${i}`);

            worksheet.getCell(`A${i}`).font = fontBase2;
            worksheet.getRow(i).height = 14;
            if ([3, 4, 16,8].includes(i)) {
              worksheet.getCell(`A${i}`).value = ''
            } else {
              worksheet.getCell(`A${i}`).value = eval(`line${i}`);
            }
            if ([1, 5, 9, 15].includes(i)) {
              worksheet.getCell(`A${i}`).font = fontBase;
            }
            if ([1, 2].includes(i)) {
              worksheet.getCell(`A${i}`).alignment = alignmentCenter;
            }
          }
        }

        //Add Image
        // const imageBuffer = await axios.get(logoFile, { responseType: 'arraybuffer' });
        // const imageId2 = workbook.addImage({
        //   buffer: imageBuffer.data,
        //   extension: 'png'
        // });

        // worksheet.addImage(imageId2, 'A1');
        // worksheet.addImage(imageId2, {
        //   tl: { col: 0, row: 0 },
        //   ext: { width: 125.76, height: 54.72 }
        // });

        // updated the font for first row.

        worksheet.columns = keyColumn();

        // header table
        worksheet.addRow(keyHeader().map((v) => v));

        worksheet.getRow(17).font = fontBase;

        worksheet.getRow(17).alignment = alignmentCenter;
        worksheet.getRow(17).height = 14;
        worksheet.getRow(17).eachCell({ includeEmpty: true }, function (cell) {
          worksheet.getCell(cell.address).fill = fill;
          worksheet.getCell(cell.address).border = border;
        });



        // loop through data and add each one to worksheet
        let currentRow = 18;
        // product
        if (dataProduct().length > 0) {
          dataProduct().forEach((v, index) => {

            let row = worksheet.addRow({
              stt: index + 1,
              product: v.name === 'Phí thuê bao' ? 'Phí duy trì đầu số' : v.name,
              price: v.price,
              qty: v.qty,
              total: calculateTotal(v.qty, v.price, v.ck),
            });
            row.eachCell(function (cell, index) {
              cell.font = fontBase2;
              cell.border = border;
              cell.alignment = { wrapText: true };

            });
          });
          for (let index = 0; index < dataProduct().length; index++) {
            ['A', 'B', 'C', 'D', 'E'].map((v) => {
              if (v === 'A') {
                worksheet.getCell(`${v}${currentRow + index}`).alignment = alignmentCenter;
              }
              if (['C', 'E'].includes(v)) {
                worksheet.getCell(`${v}${currentRow + index}`).numFmt = numFormat;
              }
            })
          }
        }
        // cong cuoc dich vu
        const endRowTable = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`B${endRowTable}:D${endRowTable}`);
        worksheet.getCell(`A${endRowTable}`).border = border;
        worksheet.getCell(`C${endRowTable}`).border = border;
        worksheet.getCell(`E${endRowTable}`).border = border;
        worksheet.getRow(`${endRowTable}`).font = fontBase2;
        worksheet.getCell(`A${endRowTable}`).value = dataProduct().length + 1;
        worksheet.getCell(`B${endRowTable}`).value = 'Cộng cước dịch vụ';
        worksheet.getCell(`E${endRowTable}`).value = data && sum(data.productControl);
        worksheet.getCell(`E${endRowTable}`).numFmt = numFormat;
        worksheet.getCell(`A${endRowTable}`).alignment = alignmentCenter
        worksheet.getCell(`B${endRowTable}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        // vat
        const endRowTable2 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`B${endRowTable2}:D${endRowTable2}`);
        worksheet.getCell(`A${endRowTable2}`).border = border;
        worksheet.getCell(`C${endRowTable2}`).border = border;
        worksheet.getCell(`E${endRowTable2}`).border = border;
        worksheet.getRow(`${endRowTable2}`).font = fontBase2;
        worksheet.getCell(`A${endRowTable2}`).value = dataProduct().length + 2;
        worksheet.getCell(`B${endRowTable2}`).value = `Thuế GTGT (${control && control.vat}%)`;
        worksheet.getCell(`E${endRowTable2}`).value = {
          formula: `E${endRowTable2 - 1}*${(control && control.vat) / 100}`
        };

        worksheet.getCell(`E${endRowTable2}`).numFmt = numFormat;
        worksheet.getCell(`A${endRowTable2}`).alignment = alignmentCenter
        worksheet.getCell(`B${endRowTable2}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };


        // sum
        const endRowTable3 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`B${endRowTable3}:D${endRowTable3}`);
        worksheet.getCell(`A${endRowTable3}`).border = border;
        worksheet.getCell(`C${endRowTable3}`).border = border;
        worksheet.getCell(`E${endRowTable3}`).border = border;
        worksheet.getRow(`${endRowTable3}`).font = fontBase2;
        worksheet.getCell(`A${endRowTable3}`).value = dataProduct().length + 3;
        worksheet.getCell(`B${endRowTable3}`).value = 'Tổng cước';
        worksheet.getCell(`E${endRowTable3}`).value =
          control && control.vat > 0
            ? {
              formula: `SUM(E${endRowTable3 - 2}:E${endRowTable3 - 1
                })`
            }
            : { formula: `E${endRowTable3 - 2}` };
        worksheet.getCell(`E${endRowTable3}`).numFmt = numFormat;
        worksheet.getCell(`A${endRowTable3}`).alignment = alignmentCenter
        worksheet.getCell(`B${endRowTable3}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        //so tien
        const endRow = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow}:B}${endRow}`);
        worksheet.mergeCells(`C${endRow}:E}${endRow}`);
        worksheet.getCell(`A${endRow}`).border = border;
        worksheet.getCell(`C${endRow}`).border = border;
        worksheet.getRow(`${endRow}`).font = fontBase2;
        worksheet.getRow(`${endRow}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`C${endRow}`).alignment = alignmentCenter;
        worksheet.getCell(`A${endRow}`).value = 'Số tiền bằng chữ	';
        worksheet.getCell(`C${endRow}`).value = data && data.totalControl && to_vietnamese(data.totalControl);
        // so tien nap
        const endRow2 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow2}:D}${endRow2}`);
        worksheet.getCell(`A${endRow2}`).border = border;
        worksheet.getCell(`E${endRow2}`).border = border;
        worksheet.getRow(`${endRow2}`).font = fontBase2;
        worksheet.getRow(`${endRow2}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`E${endRow2}`).alignment = alignmentRight;
        worksheet.getCell(`A${endRow2}`).font = {name: 'Times New Roman',bold:true, italic:true,size:11};
        worksheet.getCell(`A${endRow2}`).value = 'Số tiền nạp phát sinh trong tháng';
        worksheet.getCell(`E${endRow2}`).numFmt = numFormat;
        // so tien con lai cuoi ky
        const endRow3 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow3}:D}${endRow3}`);
        worksheet.getCell(`A${endRow3}`).border = border;
        worksheet.getCell(`E${endRow3}`).border = border;
        worksheet.getRow(`${endRow3}`).font = fontBase2;
        worksheet.getRow(`${endRow3}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`E${endRow3}`).alignment = alignmentRight;
        worksheet.getCell(`A${endRow3}`).value = 'Số tiền còn lại cuối kỳ';
        worksheet.getCell(`E${endRow3}`).numFmt = numFormat;
        // worksheet.getCell(`E${endRow3}`).value = {
        //   formula: `SUM(E${endRow3 - 1},-E${endRow3 - 3})` };
        //de nghi thanh toan
        const endRow4 = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRow4}:E}${endRow4}`);
        worksheet.getCell(`A${endRow4}`).border = border;
        worksheet.getRow(`${endRow4}`).height = 18;
        worksheet.getRow(`${endRow4}`).font = fontBase2;
        worksheet.getRow(`${endRow4}`).alignment = {
          vertical: 'middle',
          wrapText: true
        };
        worksheet.getCell(`A${endRow4}`).value = 'Đề nghị quý khách thanh toán trước ngày: …';

        // last line
        const totalLine = worksheet.lastRow._number + 1 + lastLine.length
        for (let i = worksheet.lastRow._number + 1; i < totalLine; i++) {
          worksheet.mergeCells(`A${i}:${column}${i}`);
          
          worksheet.getCell(`A${i}`).font = fontBase2;
          
          
          worksheet.getCell(`A${i}`).alignment = {
            vertical: 'middle',
            wrapText: true
          };
          if (i === totalLine - 3 ) {
            worksheet.getRow(i).height = 27.75;
          }
          
          worksheet.getCell(`A${i}`).value = lastLine[i - totalLine + lastLine.length];
        }


         //sign
        const endRowSign = worksheet.lastRow._number + 1;
        worksheet.mergeCells(`A${endRowSign}:B${endRowSign}`);
        worksheet.mergeCells(`C${endRowSign}:E${endRowSign}`);
        worksheet.getRow(endRowSign).font = fontBase;

        worksheet.getRow(endRowSign).alignment = alignmentCenter;
        worksheet.getRow(endRowSign).height = 20.5;
        worksheet.getCell(`D${endRowSign}`).value = line5;
        // Set Print Area for a sheet
        const rowCount = worksheet.rowCount;
        worksheet.pageSetup.printArea = `A1:E}${rowCount}`;
        worksheet.pageSetup.margins = {
          left: rowCount > 24 ? 0.5 : 0.75,
          right: rowCount > 24 ? 0.5 : 0.75,
          top: rowCount > 24 ? 0.5 : 1,
          bottom: rowCount > 24 ? 0.5 : 1,
          header: 0.5,
          footer: 0.5
        };
        worksheet.pageSetup.horizontalCentered = true;
        worksheet.views = [{}]




        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };
  return (
    <div className="text-right">
      <input style={{ display: 'none' }} id={myInputId2} defaultValue={workBookName} />
      <button className={`btn`}
        onClick={() => saveExcel()}
        style={{ minWidth: 'inherit', marginLeft: 10 }}>
        Excel (OneConnect)
      </button>
    </div>
  )
}

export default ControlExcelNewCustomer