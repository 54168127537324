import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';
import { setTokenCookie } from '../../erp/helps/storage';

const initialState = {
  profile: {},
};

const ACTION = {
  SIGN_IN: 'auth/authSlice',
  PROFILE: 'auth/profile'
};

export const signIn = createAsyncThunk(ACTION.SIGN_IN, async (body,{rejectWithValue}) => {
  try {
    const response = await axiosInstance.post('/restapi/login/', body);
    if(response){
      setTokenCookie(response.access, response.refresh);
    }
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
  // return axiosInstance.post('/restapi/login/', body);
});

export const getProfile = createAsyncThunk(ACTION.GET_PROFILE, async () => {
  return axiosInstance.get('/restapi/profile');
});

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    authLogout: (state, action) => {
      state.profile = action.payload;
    }
  },
  extraReducers: {
    [signIn.pending.toString()]: state => {
      state.loading = true;
    },
    [signIn.rejected.toString()]: state => {
      state.loading = false;
      state.error = true
    },
    [signIn.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = false
      //setTokenCookie(action.payload.access, action.payload.refresh);
      // state.profile = action.payload.user;
    },

    [getProfile.pending.toString()]: state => {
      state.loading = true;
    },
    [getProfile.rejected.toString()]: state => {
      state.loading = false;
    },
    [getProfile.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    }
  }
});
export const authMsg = (state => state.auth.error)
export const { authLogout } = authSlice.actions;
const { reducer: authReducer } = authSlice;
export default authReducer;
