import React, { useEffect } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getRate } from '../redux/rate';
import { OPTION_FILTER } from '../../components/common/Filter';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Filter = React.lazy(() => import('../../components/common/Filter'));

const Rate = () => {
  const dispatch = useDispatch();
  const rateList = useSelector(state => state.rates.rates);
  const isLoading = useSelector(state => state.rates.loading);

  useEffect(() => {
    dispatch(getRate({}));
  }, []);

  const handleSearch = filter => {
    dispatch(getRate(filter));
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Thống kê tỷ lệ</h1>
        <Row>
          <Col>
            <Filter
              onSearch={handleSearch}
              optionFilter={[
                OPTION_FILTER.ACC_CODE_SELECT_SEARCH,
                OPTION_FILTER.GROUP_SELECT_SEARCH,
                OPTION_FILTER.DATE_RANGE
              ]}
            />
            <Spinner loading={isLoading} />
            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      STT
                    </th>
                    <th scope="col" className="border-0">
                      Mã
                    </th>
                    <th scope="col" className="border-0">
                      Số cuộc gọi
                    </th>
                    <th scope="col" className="border-0">
                      Số cuộc gọi thành công
                    </th>
                    <th scope="col" className="border-0">
                      Số cuộc gọi thất bại
                    </th>
                    <th scope="col" className="border-0">
                      Tỉ lệ thành công
                    </th>
                    <th scope="col" className="border-0">
                      Tỉ lệ thất bại
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {rateList.map((acc, idx) => {
                    return ( */}
                  <tr key={1}>
                    <td>1</td>
                    <td>Tổng</td>
                    <td>{rateList.totals}</td>
                    <td>{rateList.totals_success}</td>
                    <td>{rateList.totals_fail}</td>
                    <td>{rateList.totals_success_rate}</td>
                    <td>{rateList.totals_fail_rate}</td>
                  </tr>
                  {/* );
                  })} */}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default Rate;
