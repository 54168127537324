import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  ticketList: [],

  ticketDetail: [],
  ticketHistoryApproved: [],
  ticketHistoryGroupApproved: [],

  ticketMessage: '',

  loadingDetail:false,

  successEdit: null,
  successUpdateApproved: null,

  loadingApproved:false,
};

const ACTION = {
  GET_TICKET: 'ticket/getTicket',
  CREATE_TICKET: 'ticket/createTicket',
  EDIT_TICKET: 'ticket/editTicket',

  UPDATE_TICKETAPPROVED: 'ticket/updateApprovedTicket',
  GET_TICKETAPPROVED: 'ticket/getApprovedTicket',
  GET_TICKETHISTORY: 'ticket/getTicketHistoryApproved',
  GET_TICKETGROUPHISTORY: 'ticket/getTicketGroupApproved'
};

export const getTicket = createAsyncThunk(ACTION.GET_TICKET, async body => {
  let params = "";
  const { limit,offset} = body;
  params += limit !== "" && limit !== undefined ? `limit=${limit}&`: ""
  params += offset !== "" && offset !== undefined ? `offset=${offset}&`: ""
  return axiosInstance.get('/api/ticket?' + params);
});

export const createTicket = createAsyncThunk(ACTION.CREATE_TICKET, async body => {
  return axiosInstance.post('/api/ticket', body);
});

export const editTicket = createAsyncThunk(ACTION.EDIT_TICKET, async body => {
  return axiosInstance.put('/api/ticket', body);
});

export const getTicketApproved = createAsyncThunk(ACTION.GET_TICKETAPPROVED, async uuid => {
  return axiosInstance.get(`/api/ticket-approved/${uuid}`, {});
});

export const updateApprovedTicket = createAsyncThunk(
  ACTION.UPDATE_TICKETAPPROVED,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/api/ticket-approved/${uuid}/`);

      return response.info;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTicketHistoryApproved = createAsyncThunk(ACTION.GET_TICKETHISTORY, async id => {
  return axiosInstance.get(`/api/approve-history/${id}`, {});
});

export const getTicketGroupApproved = createAsyncThunk(ACTION.GET_TICKETGROUPHISTORY, async id => {
  return axiosInstance.get(`/api/group-approve/${id}`, {});
});

const ticketSlice = createSlice({
  name: 'ticket',
  initialState: initialState,
  extraReducers: {
    [getTicket.pending.toString()]: state => {
      state.loading = true;
    },
    [getTicket.rejected.toString()]: state => {
      state.loading = false;
    },
    [getTicket.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successEdit = null;
      state.ticketList = action.payload.Ticket;
      state.successUpdateApproved = null;
    },
    // edit ticket
    [editTicket.pending.toString()]: state => {},
    [editTicket.rejected.toString()]: state => {
      state.successEdit = false;
    },
    [editTicket.fulfilled.toString()]: state => {
      state.successEdit = true;
    },

    //update approved ticket
    [updateApprovedTicket.pending.toString()]: state => {},
    [updateApprovedTicket.rejected.toString()]: (state, { payload }) => {
      state.successUpdateApproved = false;
      state.ticketMessage = payload.message;
    },
    [updateApprovedTicket.fulfilled.toString()]: (state, { payload }) => {
      state.successUpdateApproved = true;
      state.ticketMessage = payload;
    },

    [getTicketApproved.pending.toString()]: state => {
      state.loadingDetail = true;
    },
    [getTicketApproved.rejected.toString()]: state => {
      state.loadingDetail = false;
    },
    [getTicketApproved.fulfilled.toString()]: (state, action) => {
      state.loadingDetail = false;
      state.ticketDetail = action.payload;
    },

    [getTicketHistoryApproved.pending.toString()]: state => {
      state.loadingApproved = true;

    },
    [getTicketHistoryApproved.rejected.toString()]: state => {
      state.loadingApproved = false;
    },
    [getTicketHistoryApproved.fulfilled.toString()]: (state, action) => {
      state.loadingApproved = false;

      state.ticketHistoryApproved = action.payload;
    },

    [getTicketGroupApproved.pending.toString()]: state => {
    },
    [getTicketGroupApproved.rejected.toString()]: state => {
    },
    [getTicketGroupApproved.fulfilled.toString()]: (state, action) => {
      state.ticketHistoryGroupApproved = action.payload;
    }
  }
});

const { reducer: ticketReducer } = ticketSlice;
export default ticketReducer;
