import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    
};

const ACTION = {
    GET_EXPENSES_TYPE: 'expenses/getExpenseType',
    CREATE_EXPENSES_TYPE: 'expenses/createExpenseType',
    EDIT_EXPENSES_TYPE: 'expenses/editExpenseType',
    DELETE_EXPENSES_TYPE: 'expenses/deleteExpenseType',

};

export const getExpenseType = createAsyncThunk(ACTION.GET_EXPENSES_TYPE, async body => {
   
    return axiosInstance.get('/api/accountant/expense-types/', {params:body});
});

export const createExpenseType = createAsyncThunk(ACTION.CREATE_EXPENSES_TYPE, async body => {
    
    return axiosInstance.post('/api/accountant/expense-types/', body);
});

export const editExpenseType = createAsyncThunk(ACTION.EDIT_EXPENSES_TYPE, async body => {
    return axiosInstance.put(`/api/accountant/expense-types/${body.id}/`,body);
});

export const deleteExpenseType = createAsyncThunk(ACTION.DELETE_EXPENSES_TYPE, async ({ id }) => {
    return axiosInstance.delete(`/api/accountant/expenses/${id}/?`);
});



const expenseTypeSlice = createSlice({
    name: 'expenseType',
    initialState: initialState,
    extraReducers: {
        [getExpenseType.pending.toString()]: state => {
            state.loading = true;
        },
        [getExpenseType.rejected.toString()]: state => {
            state.loading = false;
        },
        [getExpenseType.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            state.list = action.payload;
        },
        ///////////create expenses
        [createExpenseType.pending.toString()]: state => {
        },
        [createExpenseType.rejected.toString()]: state => {
           state.error = true;
        },
        [createExpenseType.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },
        // edit expenses
        [editExpenseType.pending.toString()]: state => { },
        [editExpenseType.rejected.toString()]: state => {
        },
        [editExpenseType.fulfilled.toString()]: state => {
        },
        //////////delete expenses
        [deleteExpenseType.pending.toString()]: state => { },
        [deleteExpenseType.rejected.toString()]: state => {
        },
        [deleteExpenseType.fulfilled.toString()]: state => {
        },

    }
});
const { reducer: expenseTypeReducer } = expenseTypeSlice;
export default expenseTypeReducer;
