import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  list: {},
  detail:{},
  listPrice:{}
};

const ACTION = {
  GET_CMC_PHONE: 'cmcphone/getCmcPhone',
  GET_CMC_PHONE_BY_ID: 'cmcphone/getCmcPhoneById',
  CREATE_CMC_PHONE: 'cmcphone/createCmcPhone',
  UPDATE_CMC_PHONE: 'cmcphone/updateCmcPhone',
  DEL_CMC_PHONE: 'cmcphone/delCmcPhone',

  UPLOAD_CMC_PHONE:'cmcphone/uploadCmcPhone',

  GET_CMC_PHONE_PRICE: 'cmcphone/getCmcPhonePrice',
  CREATE_CMC_PHONE_PRICE: 'cmcphone/createCmcPhonePrice',
  UPDATE_CMC_PHONE_PRICE: 'cmcphone/updateCmcPhonePrice',
  DEL_CMC_PHONE_PRICE: 'cmcphone/delCmcPhonePrice',
};

export const getCmcPhone = createAsyncThunk(ACTION.GET_CMC_PHONE, async body => {
  return axiosInstance.get('/restapi/cmc-phones/', { params: body });
});

export const getCmcPhoneById = createAsyncThunk(ACTION.GET_CMC_PHONE_BY_ID, async body => {
  return axiosInstance.get(`/restapi/cmc-phones/${body.id}/`);
});

// create cmcphone

export const createCmcPhone = createAsyncThunk(ACTION.CREATE_CMC_PHONE, async body => {
  return axiosInstance.post('/restapi/cmc-phones/', body);
});

// update cmcphone

export const updateCmcPhone = createAsyncThunk(ACTION.UPDATE_CMC_PHONE, async body => {
  return axiosInstance.put(`/restapi/cmc-phones/${body.id}/`, body);
});

export const delCmcPhone = createAsyncThunk(ACTION.DEL_CMC_PHONE, async body => {
  return axiosInstance.delete(`/restapi/cmc-phones/${body.id}/`);
});


export const uploadCmcPhone = createAsyncThunk(ACTION.UPLOAD_CMC_PHONE, async body => {
  return axiosInstance.post(`/restapi/upload/cmc-phones/`,body)
})

export const getCmcPhonePrice = createAsyncThunk(ACTION.GET_CMC_PHONE_PRICE, async body => {
  return axiosInstance.get('/restapi/cmc-prices/', { params: body });
});

export const createCmcPhonePrice = createAsyncThunk(ACTION.CREATE_CMC_PHONE_PRICE, async body => {
  return axiosInstance.post('/restapi/cmc-prices/', body);
});

export const updateCmcPhonePrice = createAsyncThunk(ACTION.UPDATE_CMC_PHONE_PRICE, async body => {
  return axiosInstance.put(`/restapi/cmc-prices/${body.id}/`, body);
});

export const delCmcPhonePrice = createAsyncThunk(ACTION.DEL_CMC_PHONE_PRICE, async body => {
  return axiosInstance.delete(`/restapi/cmc-prices/${body.id}/`);
});

const cmcphoneSlice = createSlice({
  name: 'cmcphone',
  initialState: initialState,
  extraReducers: {
    [getCmcPhone.pending.toString()]: state => {
      state.loading = true;
    },
    [getCmcPhone.rejected.toString()]: state => {
      state.loading = false;
    },
    [getCmcPhone.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },

    [getCmcPhoneById.pending.toString()]: state => {
      state.loadingDetail = true;
    },
    [getCmcPhoneById.rejected.toString()]: state => {
      state.loadingDetail = false;
    },
    [getCmcPhoneById.fulfilled.toString()]: (state, action) => {
      state.loadingDetail = false;
      state.detail = action.payload;
    },


    [createCmcPhone.pending.toString()]: state => {
      state.success = false;
    },
    [createCmcPhone.rejected.toString()]: state => {
      state.success = false;
    },
    [createCmcPhone.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [updateCmcPhone.pending.toString()]: state => {
      state.success = false;
    },
    [updateCmcPhone.rejected.toString()]: state => {
      state.success = false;
    },
    [updateCmcPhone.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [delCmcPhone.pending.toString()]: state => {
      state.success = false;
    },
    [delCmcPhone.rejected.toString()]: state => {
      state.success = false;
    },
    [delCmcPhone.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },
    [uploadCmcPhone.pending.toString()]: state => {
      state.success = false;
    },
    [uploadCmcPhone.rejected.toString()]: state => {
      state.success = false;
    },
    [uploadCmcPhone.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },

    [getCmcPhonePrice.pending.toString()]: state => {
      state.loadingCmcPrice = true;
    },
    [getCmcPhonePrice.rejected.toString()]: state => {
      state.loadingCmcPrice = false;
    },
    [getCmcPhonePrice.fulfilled.toString()]: (state, action) => {
      state.loadingCmcPrice = false;
      state.listPrice = action.payload;
    },
    [createCmcPhonePrice.pending.toString()]: state => {
      state.successCmcPrice = false;
    },
    [createCmcPhonePrice.rejected.toString()]: state => {
      state.successCmcPrice = false;
    },
    [createCmcPhonePrice.fulfilled.toString()]: (state, action) => {
      state.successCmcPrice = true;
    },
  }
});
export const selectStatus = (state => state.cmcphone)
const { reducer: cmcphoneReducer } = cmcphoneSlice;
export default cmcphoneReducer;
