import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
    list: [],
    statusList: [],
    detail:null,
    customerId:null,
};

const ACTION = {
    GET_CONTRACTS: 'contracts/getContracts',
    CREATE_CONTRACTS: 'contracts/createContracts',
    UPDATE_CONTRACTS: 'contracts/updateContracts',
    DEL_CONTRACTS: 'contracts/delContracts',

    GET_CONTRACTS_DETAIL: 'contracts/getContractsDetail',
    GET_CONTRACTS_STATUS: 'contracts/getContractsStatus',
    

};

export const getContracts = createAsyncThunk(ACTION.GET_CONTRACTS, async body => {
    return axiosInstance.get('/api/contracts/', {params:body});
});

export const getContractsStatus = createAsyncThunk(ACTION.GET_CONTRACTS_STATUS, async body => {
    return axiosInstance.get('/api/contracts/status', {params:body});
});

export const createContracts = createAsyncThunk(ACTION.CREATE_CONTRACTS, async body => {
    return axiosInstance.post('/api/contracts/', body);
});


export const getContractsDetail = createAsyncThunk(ACTION.GET_CONTRACTS_DETAIL, async body => {
    return axiosInstance.get(`/api/contracts/detail/${body}/`);
});

export const updateContracts = createAsyncThunk(ACTION.UPDATE_CONTRACTS, async ({uuid, body}) => {
    return axiosInstance.put(`/api/contracts/detail/${uuid}/`, body);
});

export const delContracts = createAsyncThunk(ACTION.DEL_CONTRACTS, async (body) => {
    return axiosInstance.delete(`/api/contracts/detail/${body.uuid}/`);
});


const contractsSlice = createSlice({
    name: 'contracts',
    initialState: initialState,
    reducers: {
        saveCustomerId: (state, action) => {
            state.customerId = action.payload;
        },
        resetCustomerId: (state, action) => {
            state.customerId = null;
        }
      },
    extraReducers: {
        [getContracts.pending.toString()]: state => {
            state.loading = true;
        },
        [getContracts.rejected.toString()]: state => {
            state.loading = false;
        },
        [getContracts.fulfilled.toString()]: (state, action) => {
            state.loading = false;
           
            state.list = action.payload;
        },

        [getContractsStatus.pending.toString()]: state => {
            state.loading = true;
        },
        [getContractsStatus.rejected.toString()]: state => {
            state.loading = false;
        },
        [getContractsStatus.fulfilled.toString()]: (state, action) => {
            state.loading = false;
            state.statusList = action.payload;
        },

        [getContractsDetail.pending.toString()]: state => {
            state.loading = true;
        },
        [getContractsDetail.rejected.toString()]: state => {
            state.loading = false;
        },
        [getContractsDetail.fulfilled.toString()]: (state, action) => {
            state.loading = false;
           
            state.detail = action.payload;
        },

        [updateContracts.pending.toString()]: state => {
            state.success = false;
           
        },
        [updateContracts.rejected.toString()]: state => {
            state.success = true;
        },
        [updateContracts.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },

        [createContracts.pending.toString()]: state => {
            state.success = false;
           
        },
        [createContracts.rejected.toString()]: state => {
            state.success = true;
        },
        [createContracts.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },


        [delContracts.pending.toString()]: state => {
            state.success = false;
           
        },
        [delContracts.rejected.toString()]: state => {
            state.success = true;
        },
        [delContracts.fulfilled.toString()]: (state, action) => {
            state.success = true;
        },

    }
});

export const {saveCustomerId, resetCustomerId} =  contractsSlice.actions;
const { reducer: contractsReducer } = contractsSlice;
export default contractsReducer;
