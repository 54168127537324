import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getF88ByPhone } from '../../redux/f88';
import moment from 'moment';
import { formatMoney } from '../../helps/convertMoney';
import { useLocation } from 'react-router';

const Spinner = React.lazy(() => import('../../../components/common/Spinner'));

const F88DetailPhone = () => {
  const dispatch = useDispatch();
  const f88DetailPhone = useSelector(state => state.f88.f88DetailPhone);
  const isLoading = useSelector(state => state.f88.loading);
  const [filter] = useState({});
  const location = useLocation();

  useEffect(() => {
    dispatch(
      getF88ByPhone({
        ...filter,
        month: location.state.month,
        code: location.state.company,
        phone: location.state.phone,
        year: moment().year()
      })
    );
  }, []);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">F88 chi tiết cuộc gọi</h1>
        {<Spinner loading={isLoading} top="30%" />}
        <Row>
          <Col>
            <div class="card card-small mb-4">
              <div class="card-header border-bottom">
                <h6 class="m-0">{`F88/${location.state.company}`}</h6>
              </div>
            </div>

            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Ngày
                    </th>
                    <th scope="col" className="border-0">
                      Giờ
                    </th>
                    <th scope="col" className="border-0">
                      Đích
                    </th>
                    <th scope="col" className="border-0">
                      Mạng
                    </th>
                    <th scope="col" className="border-0">
                      Giây
                    </th>
                    <th scope="col" className="border-0">
                      Giá
                    </th>
                  </tr>
                </thead>
                {f88DetailPhone.data && f88DetailPhone.data.length > 0 && (
                  <tbody>
                    {f88DetailPhone.data.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.date}</td>
                          <td>{item.time}</td>
                          <td>{item.dst}</td>
                          <td>{item.telco}</td>
                          <td>{item.sec}</td>
                          <td>{formatMoney(item.price)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default F88DetailPhone;
