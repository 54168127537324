import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../erp/layout/MainNavbar/MainNavbar";
import MainSidebar from "../erp/layout/MainSidebar/MainSidebar";
import MainFooter from "../erp/layout/MainFooter";

import { useDispatch,useSelector } from 'react-redux';
import { getProfile } from "../erp/redux/auth";
import { getCookieStorage } from "../erp/helps/storage";
import { useLocation } from 'react-router';
import Toast from "../components/common/toast/Toast";

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  const dispatch = useDispatch();
  const accessToken = getCookieStorage('access_token');
  const profile = useSelector(state => state.auth.profile);
  const location = useLocation()
  useEffect(() => {
    if (accessToken) {
      if (profile && Object.keys(profile).length === 0 && location.pathname !== '/' ) {
        dispatch(getProfile());
      }
    }
  }, [accessToken])
  return (
    <Container fluid>
    <Row>
      <Toast />
      <MainSidebar />
      <Col
        className="main-content p-0"
        // lg={{ size: 10, offset: 2 }}
        // md={{ size: 9, offset: 3 }}
        sm="12"
        tag="main"
      >
        {!noNavbar && <MainNavbar />}
        {children}
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
  </Container>
  )
}

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
