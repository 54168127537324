import React from 'react';
import { FormInput, Row, Col, FormSelect, FormCheckbox } from 'shards-react';
import { HelpText } from '../../../components/components-overview/Help-text';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageSuccess, showMessageError } from '../../redux/alert-message';
import { getAccountBank } from '../../redux/accountBank';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { getCustomer } from '../../redux/customer';
import DatePicker from 'react-date-picker';
import { MONTH } from '../../../constants';
import { parseISO } from 'date-fns';

import moment from 'moment';
import { putDebt } from '../../redux/deb';
import NumberFormat from 'react-number-format';
import Note from './Note';
import { getAllUser } from '../../redux/user';
import { getYearArr } from '../../helps/help';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const FormLabel = React.lazy(() => import('../../../components/common/FormLabel'));
const DialogDebtUpdate = ({ dataEdit = null }) => {
  const timeoutTitle = React.useRef();
  const stateUser = useSelector((state) => state.user);
  const stateAccount = useSelector((state) => state.accountBank);
  const status = useSelector((state) => state.debt);
  const dispatch = useDispatch();

  const [users, setUsers] = React.useState([]);
  const [accountOpt, setAccountOpt] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [customerName, setCustomerName] = React.useState('Chọn');
  const [accountName, setAccountName] = React.useState('Chọn');
  const [followersList, setFollowerList] = React.useState([]);

  const year = moment().year();

  const YEAR = getYearArr(5, year);

  React.useEffect(() => {
    if (dataEdit && dataEdit.customer) {
      setCustomerName(dataEdit.customer.name);
    }
    if (dataEdit && dataEdit.followers) {
      setFollowerList(
        dataEdit.followers.map((item) => {
          return { value: item.id, label: item.username, id: item.id, key: 'followers' };
        })
      );
    }
    if (dataEdit && dataEdit.transaction_account) {
      setAccountName(
        `${dataEdit.transaction_account.account_number} - ${dataEdit.transaction_account.account_owner} - ${dataEdit.transaction_account.bank_name}`
      );
    }
    if (dataEdit && dataEdit.attachments) {
      setRows(dataEdit.attachments.map((v) => v));
    }
  }, [dataEdit]);

  React.useEffect(() => {
    dispatch(getAllUser())
      .unwrap()
      .then((res) => {
        setUsers(
          res &&
            res.list &&
            res.list.map((v) => {
              return {
                key: 'followers',
                id: v.id,
                value: v.id,
                label: `${v.username}`
              };
            })
        );
      });
    dispatch(getAccountBank())
      .unwrap()
      .then((res) => {
        setAccountOpt(
          res &&
            res.results &&
            res.results.map((v) => {
              return {
                key: 'transaction_account',
                id: v.id,
                value: v.id,
                label: `${v.account_number} - ${v.account_owner} - ${v.bank_name}`
              };
            })
        );
      });
  }, []);

  // react-select search

  const filterTypingName = (inputValue, res) => {
    const contactSelect = res?.results?.map((v) => {
      return {
        id: v.id,
        label: `${v.name}`,
        value: `${v.name} (${v.group && v.group.name})`,
        key: 'customer'
      };
    });

    return contactSelect;
  };

  const loadOptions = (inputValue, callback) => {
    clearTimeout(timeoutTitle.current);
    timeoutTitle.current = setTimeout(() => {
      dispatch(getCustomer({ name: inputValue }))
        .unwrap()
        .then((res) => {
          callback(filterTypingName(inputValue, res));
        });
    }, 400);
  };

  const handleChangeOption = (option, field) => {
    setFieldValue(field, option.value);
    switch (field) {
      case 'followers':
        setFollowerList([...option]);
        const arrId = option && option.map((v) => v.id);
        setFieldValue(field, arrId);

        break;
      case 'transaction_account':
        setAccountName(option.label);
        break;
      default:
        break;
    }
  };

  //submitform
  const convertToFormData = () => {
    const submitValues = {
      ...values,
      reminder_day: values.reminder_day ? moment(values.reminder_day).format('YYYY-MM-DD') : '',
      attachments: rows
    };
    const formData = new FormData();
    if (rows.length > 0) {
      for (const [key, value] of Object.entries(submitValues)) {
        switch (key) {
          case 'followers':
            value.map((item, idx) => {
              return formData.append(`followers[${idx}]`, item);
            });
            break;
          case 'attachments':
            value.map((item, idx) => {
              if (typeof item.file === 'object') {
                formData.append(`attachments[${idx}][file]`, item.file);
                formData.append(`attachments[${idx}][description]`, item.description);
              } else {
                formData.append(`attachments[${idx}][file]`, item.id);
                formData.append(`attachments[${idx}][description]`, item.description);
              }
            });
            break;

          default:
            formData.append(key, value);
            break;
        }
      }
      return formData;
    } else {
      return submitValues;
    }
  };
  const submitForm = (values) => {
    const submitValues = {
      body: convertToFormData(),
      id: dataEdit.id
    };
    dispatch(putDebt(submitValues))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
      })
      .catch(() => {
        dispatch(showMessageError());
      });

    setRows([]);
  };

  const validationSchema = yup.object({
    reminder_day: yup.date().required('Không được bỏ trống'),
    customer: yup.number().required('Không được bỏ trống'),
    code: yup.string().required('Không được bỏ trống'),
    total: yup.number().required('Không được bỏ trống')
  });

  const { values, setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        customer: (dataEdit && dataEdit.customer && dataEdit.customer.id) || '',
        reminder_day: (dataEdit && dataEdit.reminder_day && parseISO(dataEdit.reminder_day)) || '',
        followers: (dataEdit && dataEdit.followers && dataEdit.followers.map((v) => v.id)) || [],
        total: (dataEdit && dataEdit.total) || '',
        month: (dataEdit && dataEdit.month) || (MONTH && MONTH[0] && parseInt(MONTH[0])),
        year: (dataEdit && dataEdit.year) || (YEAR && YEAR[0]),
        code: (dataEdit && dataEdit.code) || '',
        transaction_account:
          (dataEdit && dataEdit.transaction_account && dataEdit.transaction_account.id) || '',
        attachments: (dataEdit && dataEdit.attachments) || '',
        status: dataEdit && !!dataEdit.status
      },
      validationSchema: validationSchema,
      onSubmit: submitForm
    });

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <div className="content">
        <div className="box-form">
          <Row>
            <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Chọn khách hàng" />
                <AsyncSelect
                  value={{ label: customerName }}
                  placeholder={<div>Chọn</div>}
                  className="basic-single"
                  onChange={(value) => {
                    setFieldValue('customer', value.id);
                    setCustomerName(value.label);
                  }}
                  loadOptions={loadOptions}
                  name="customer"
                />
                <HelpText status="error">
                  {touched.customer && errors.customer ? errors.customer : ''}
                </HelpText>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box">
                <FormLabel label="Người theo dõi" />
                <Select
                  isLoading={stateUser && stateUser.loading}
                  isDisabled={stateUser && stateUser.loading}
                  isMulti
                  defaultValue={followersList}
                  value={followersList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={<div>Chọn</div>}
                  onChange={(e) => handleChangeOption(e, 'followers')}
                  name="followers"
                  options={users}
                  closeMenuOnSelect={false}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Ngày nhắc nợ" />
                <DatePicker
                  className={'custom-datepicker style3'}
                  value={values.reminder_day}
                  onChange={(value) => setFieldValue('reminder_day', value)}
                  format="d/MM/y"
                  clearIcon={null}
                  calendarIcon={<i className="material-icons">&#xE916;</i>}
                />
                <HelpText status="error">
                  {touched.reminder_day && errors.reminder_day ? errors.reminder_day : ''}
                </HelpText>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Tổng tiền" />
                <NumberFormat
                  thousandSeparator={true}
                  customInput={FormInput}
                  name="total"
                  value={values['total']}
                  placeholder="Nhập tổng tiền"
                  onValueChange={(value) => {
                    setFieldValue('total', value.floatValue);
                  }}
                  onBlur={handleBlur}
                />
                <HelpText status="error">
                  {touched.total && errors.total ? errors.total : ''}
                </HelpText>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form-input-box">
                <FormLabel label="Mã công nợ" />
                <FormInput
                  type="text"
                  name="code"
                  placeholder="Nhập mã công nợ"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <HelpText status="error">{touched.code && errors.code ? errors.code : ''}</HelpText>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form-input-box">
                <FormLabel label="Chọn tháng" />
                <FormSelect
                  value={values.month}
                  id="feInputState"
                  onChange={(e) => {
                    setFieldValue('month', parseInt(e.target.value));
                  }}>
                  {MONTH.map((item, idx) => {
                    return <option key={idx}>{item}</option>;
                  })}
                </FormSelect>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form-input-box">
                <FormLabel label="Chọn năm" />
                <FormSelect
                  value={values.year}
                  id="feInputState"
                  onChange={(e) => {
                    setFieldValue('year', parseInt(e.target.value));
                  }}>
                  {YEAR.map((item, idx) => {
                    return <option key={idx}>{item}</option>;
                  })}
                </FormSelect>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Chọn tài khoản thanh toán" />
                <Select
                  value={{ label: accountName }}
                  isLoading={stateAccount && stateAccount.loading}
                  isDisabled={stateAccount && stateAccount.loading}
                  type="select"
                  name="transaction_account"
                  id="transaction_account"
                  placeholder={<div>Chọn </div>}
                  onChange={(e) => handleChangeOption(e, 'transaction_account')}
                  options={accountOpt}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Trạng thái công nợ" />
                <FormCheckbox
                  className="mb-1"
                  id="status"
                  name="status"
                  checked={values.status}
                  onChange={(e) => {
                    setFieldValue('status', e.currentTarget.checked);
                  }}>
                  Đã thanh toán
                </FormCheckbox>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box">
                <Note rows={rows} setRows={setRows} />
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box text-right">
                <button
                  className={`btn ${status.success === false ? 'disabled' : ''}`}
                  type="submit"
                  onClick={handleSubmit}>
                  {!dataEdit ? 'Tạo' : 'Sửa'}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Suspense>
  );
};

export default DialogDebtUpdate;
