import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from 'shards-react';
import { authLogout } from '../../../redux/auth';
import { removeAllCookieStorage } from '../../../helps/storage';

const UserActions = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const profile = useSelector(state => state.auth.profile);
  const toggleUserActions = () => {
    setVisible(!visible);
  };

  const logout = () => {
    removeAllCookieStorage(['access_token', 'refresh_token','expire_refresh_token','expire_token']);
    dispatch(authLogout({}));
  };

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>

      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3 d-flex align-items-center">

        <img
          className="user-avatar rounded-circle mr-2"
          src={require('./../../../../images/avatars/0.jpg')}
          alt="User Avatar"
        />{' '}
        <span className="d-none d-md-inline-block">{profile.username}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider /> */}
        <DropdownItem tag={(props) => <Link {...props} />}  to="/" className="text-danger" onClick={logout}>
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

export default UserActions;
