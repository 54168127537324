import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  accounts: {
    list: []
  },
  accountList: {},
  percentCompleted:0,
  loading: false
};

const ACTION = {
  GET_ACCOUNTS: 'account/getAccount',
  GET_ACCOUNTS_EXPORT: 'account/getAccountExport',
  DOWNLOAD_ACCOUNTS_LIST: 'account/downloadAccountList',

  CREATE_ACCCODE: 'account/createAccCode',
  UPDATE_ACCCODE: 'account/updateAccCode',
};

export const getAccount = createAsyncThunk(ACTION.GET_ACCOUNTS, async body => {
  return axiosInstance.get('/restapi/accs', { params: body });
});

export const createAccCode = createAsyncThunk(ACTION.CREATE_ACCCODE, async body => {
  return axiosInstance.post('/restapi/accs', body);
});

export const updateAccCode = createAsyncThunk(ACTION.UPDATE_ACCCODE, async body => {
  return axiosInstance.put('/restapi/accs',body);
});

export const getAccountList = createAsyncThunk(ACTION.GET_ACCOUNTS_EXPORT, async body => {
  return axiosInstance.get('/restapi/export/account', { params: body });
});

export const downloadAccountList = createAsyncThunk(ACTION.DOWNLOAD_ACCOUNTS_LIST, async ({body,config}) => {
  return axiosInstance.get('/restapi/export/xls-total', {params:body},config);
});

const accountSlice = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    setDownloadingAccount: (state, action) => {
      state.percentCompleted = action.payload;
    }
  },
  extraReducers: {
    [getAccount.pending.toString()]: state => {
      state.loading = true;
    },
    [getAccount.rejected.toString()]: state => {
      state.loading = false;
    },
    [getAccount.fulfilled.toString()]: (state, action) => {
      state.loading = false;
    
      state.accounts = action.payload;
    },

    [getAccountList.pending.toString()]: state => {
      state.loading = true;
    },
    [getAccountList.rejected.toString()]: state => {
      state.loading = false;
    },
    [getAccountList.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.accountList = action.payload;
    },

    [createAccCode.pending.toString()]: state => {
      state.success = false;
    },
    [createAccCode.rejected.toString()]: state => {
      state.success = false;
    },
    [createAccCode.fulfilled.toString()]: (state) => {
      state.success = true;
    },
    [updateAccCode.pending.toString()]: state => {
      state.success = false;
    },
    [updateAccCode.rejected.toString()]: state => {
      state.success = false;
    },
    [updateAccCode.fulfilled.toString()]: (state) => {
      state.success = true;
    },
  }
});
export const {setDownloadingAccount} = accountSlice.actions
const { reducer: accountReducer } = accountSlice;
export default accountReducer;
