import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  
  loading: false
};

const ACTION = {
  GET_DEBT: 'debt/getDebt',
  
  POST_DEBT: 'debt/postDebt',
  PUT_DEBT: 'debt/putDebt',
  GET_DEBT_DETAIL:'debt/getDebtDetail',

  DEL_DEBT: 'debt/delDebt',
  
};
export const delDebt = createAsyncThunk(ACTION.DEL_DEBT, async body => {
  return axiosInstance.delete(`/api/debts/${body.id}`);
});
export const getDebtDetail = createAsyncThunk(ACTION.GET_DEBT_DETAIL, async body => {
  return axiosInstance.get(`/api/debts/${body.id}`);
});

export const getDebt = createAsyncThunk(ACTION.GET_DEBT, async body => {
  return axiosInstance.get('/api/debts', { params: body });
});

export const postDebt = createAsyncThunk(
    ACTION.POST_DEBT,
    async (body, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`/api/debts`, body,{
        });
  
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  export const putDebt = createAsyncThunk(
    ACTION.PUT_DEBT,
    async ( {id,body, rejectWithValue }) => {
      try {
        const response = await axiosInstance.put(`/api/debts/${id}`, body,{
        });
  
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );


const debtSlice = createSlice({
  name: 'debt',
  initialState: initialState,

  extraReducers: {
    [getDebt.pending.toString()]: state => {
      state.loading = true;
    },
    [getDebt.rejected.toString()]: state => {
      state.loading = false;
    },
    [getDebt.fulfilled.toString()]: (state, action) => {
      state.loading = false;
    
      state.debts = action.payload;
    },

    [getDebtDetail.pending.toString()]: state => {
      state.loading = true;
    },
    [getDebtDetail.rejected.toString()]: state => {
      state.loading = false;
    },
    [getDebtDetail.fulfilled.toString()]: (state, action) => {
      state.loading = false;
    
    },

      [postDebt.pending.toString()]: state => {
        state.success = false;
      },
      [postDebt.rejected.toString()]: state => {
        state.success = true;
      },
      [postDebt.fulfilled.toString()]: (state, action) => {
        state.success = true;
      },
      [putDebt.pending.toString()]: state => {
        state.success = false;
      },
      [putDebt.rejected.toString()]: state => {
        state.success = true;
      },
      [putDebt.fulfilled.toString()]: (state, action) => {
        state.success = true;
      },
      [delDebt.pending.toString()]: state => {
        state.success = false;
      },
      [delDebt.rejected.toString()]: state => {
        state.success = true;
      },
      [delDebt.fulfilled.toString()]: (state, action) => {
        state.success = true;
      },
    
  }
});
const { reducer: debtReducer } = debtSlice;
export default debtReducer;
