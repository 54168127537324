import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  successVat: null,
  ck_hoa_mang: 0,
  phi_hoa_mang: 0,
  sl_hoa_mang: 0,
  ck_thue_bao: 0,
  phi_thue_bao: 0,
  sl_thue_bao: 0,
  invoice:{},
};

const ACTION = {
  GET_CONTROL: 'control/getControl',
  UPDATE_SALE: 'control/updateSale',
  UPDATE_ROW_CONTROL: 'control/updateRowControl',
  UPDATE_FEE: 'control/updateFee',
  GET_SERVICE: 'control/getService',
  UPDATE_SERVICE: 'control/updateService',
  DEL_SERVICE: 'control/delService',
  UPDATE_VAT: 'control/updateVat',
  DOWNLOAD_CONTROL: 'control/downloadControl',

  SAVE_POS_CONTROL: 'control/savePosControl',

  GET_POS_CONTROL: 'control/getPosControl',

  SAVE_LOG_CONTROL: 'control/saveLogControl',

  POST_DATA_ACCOUNT_CONTROL: 'control/postDataAccountControl',
};


export const getControl = createAsyncThunk(ACTION.GET_CONTROL, async body => {
  return axiosInstance.get('/restapi/export/control-v2', { params: body });
});

export const postDataAccountControl = createAsyncThunk(ACTION.POST_DATA_ACCOUNT_CONTROL, async body => {
  return axiosInstance.post('/restapi/export/xds-download-account-sheet/', body);
});

export const updateSale = createAsyncThunk(ACTION.UPDATE_SALE, async body => {
  return axiosInstance.post('/api/update/sale', body);
});

export const updateVat = createAsyncThunk(ACTION.UPDATE_VAT, async body => {
  return axiosInstance.put(`/restapi/groups/${body.id}/`, body);
});

export const updateFee = createAsyncThunk(ACTION.UPDATE_SALE, async body => {
  return axiosInstance.post('/api/update/group-code', body);
});

export const updateOrderService = createAsyncThunk(ACTION.UPDATE_ROW_CONTROL, async body => {
  return axiosInstance.put(`/report/service-used/?id=${body.id}&group=${body.group}`, body.data);
});

export const getServiceUsed = createAsyncThunk(ACTION.GET_SERVICE, async body => {
  return axiosInstance.get('/report/service-used/', { params: body });
});

export const updateService = createAsyncThunk(ACTION.UPDATE_SERVICE, async body => {
  return axiosInstance.post('/report/service-used/', body);
});

export const delService = createAsyncThunk(ACTION.DEL_SERVICE, async body => {
  return axiosInstance.delete(`/report/service-used/`, { params: body });
});

export const savePosControl = createAsyncThunk(ACTION.SAVE_POS_CONTROL, async( {id,products} )=> {
  return axiosInstance.post(`/restapi/group-index/${id}/`, {products});
});

export const getPosControl = createAsyncThunk(ACTION.GET_POS_CONTROL, async(body)=> {
  return axiosInstance.get(`/restapi/group-index/${body.id}/`);
});

export const saveLogControl = createAsyncThunk(ACTION.SAVE_LOG_CONTROL, async(body, thunkAPI)=> {
  try {
    const response = await axiosInstance.post(`/restapi/save-log-control/`,body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
      return response.data;
  } catch (err) {
    
      return thunkAPI.rejectWithValue(err.response.data);
  }
});


export const downloadControl = createAsyncThunk(ACTION.DOWNLOAD_CONTROL, async body => {
  return await axiosInstance.post(
    `/restapi/export/xds-download-v2/?group=${body.group}&from=${body.from}&to=${body.to}&typeExport=${body.typeExport}`,
    body,
    {
      responseType: 'blob',
      timeout: '0',
      onDownloadProgress: progressEvent =>
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }
  );
});

const controlSlice = createSlice({
  name: 'control',
  initialState: initialState,
  reducers: {
    setProductControlToDownload: (state, action) => {
      state.productDownload = action.payload;
    },
    getInvoice:(state,action) =>{
      state.invoice = action.payload;
    },
    resetPos:(state,action) =>{
      state.posProducts = action.payload;
    }
  },
  extraReducers: {
    [getControl.pending.toString()]: state => {
      state.loading = true;
    },
    [getControl.rejected.toString()]: state => {
      state.loading = false;
      state.control = null;
    },
    [getControl.fulfilled.toString()]: (state, action) => {
      state.loading = false;   
      state.control = action.payload;
      state.ck_hoa_mang = action.payload.ck_hoa_mang;
      state.phi_hoa_mang = action.payload.phi_hoa_mang;
      state.sl_hoa_mang = action.payload.sl_hoa_mang;
      state.ck_thue_bao = action.payload.ck_thue_bao;
      state.phi_thue_bao = action.payload.phi_thue_bao;
      state.sl_thue_bao = action.payload.sl_thue_bao;
    },
    [getServiceUsed.pending.toString()]: state => {
      state.loadingService = true;
    },
    [getServiceUsed.rejected.toString()]: state => {
      state.loadingService = false;
      state.products = null;
    },
    [getServiceUsed.fulfilled.toString()]: (state, action) => {
      state.loadingService = false;
    
      state.products = action.payload;
    },

    [updateSale.pending.toString()]: state => {},
    [updateSale.rejected.toString()]: state => {
      state.loading = false;
    },
    [updateSale.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      // state.control = action.payload;
    },

    [updateVat.pending.toString()]: state => {
      state.success = false;
    },
    [updateVat.rejected.toString()]: state => {
      state.success = true;
    },
    [updateVat.fulfilled.toString()]: (state, action) => {
      state.success = true;
      // state.control = action.payload;
    },

    [updateFee.pending.toString()]: state => {
      state.success = false;
    },
    [updateFee.rejected.toString()]: state => {
      state.success = true;
    },
    [updateFee.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },

    [updateOrderService.pending.toString()]: state => {
      state.success = false;
    },
    [updateOrderService.rejected.toString()]: state => {
      state.success = true;
    },
    [updateOrderService.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },

    [updateService.pending.toString()]: state => {
      state.loadingService = true;
      state.success = false;
    },
    [updateService.rejected.toString()]: state => {
      state.loadingService = false;
      state.success = true;
    },
    [updateService.fulfilled.toString()]: (state, action) => {
     
      state.loadingService = false;
      state.success = true;
      // state.control = action.payload;
    },

    [delService.pending.toString()]: state => {
      state.success = false;
    },
    [delService.rejected.toString()]: state => {
      state.success = true;
    },
    [delService.fulfilled.toString()]: (state, action) => {
      state.success = true;
      // state.control = action.payload;
    },

    [downloadControl.pending.toString()]: state => {
      state.downloading = true;
    },
    [downloadControl.rejected.toString()]: state => {
      state.downloading = false;
    },
    [downloadControl.fulfilled.toString()]: (state, action) => {
      state.downloading = false;
      // state.control = action.payload;
    },

    [getPosControl.pending.toString()]: state => {
    },
    [getPosControl.rejected.toString()]: state => {
      
    },
    [getPosControl.fulfilled.toString()]: (state, action) => {
      state.posProducts = action.payload
    },
    [savePosControl.pending.toString()]: state => {
      state.successPos= false;
    },
    [savePosControl.rejected.toString()]: state => {
      state.successPos = true;
    },
    [savePosControl.fulfilled.toString()]: (state, action) => {
      state.successPos = true;
      // state.control = action.payload;
    },
    [postDataAccountControl.pending.toString()]: state => {
      
    },
    [postDataAccountControl.rejected.toString()]: state => {
      
    },
    [postDataAccountControl.fulfilled.toString()]: (state, action) => {
      state.dataAccountControl = action.payload;
    },
  }
});

const { reducer: controlReducer } = controlSlice;
export const { setProductControlToDownload, getInvoice,resetPos } = controlSlice.actions;
export default controlReducer;
