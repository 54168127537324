import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {

  loading: false,

};

const ACTION = {
  GET_SETUP_TELCO: 'setuptelco/getSetupTelco',

  GET_SETUP_TELCO_ID: 'setuptelco/getSetupTelcoId',

  CREATE_SETUP_TELCO: 'setuptelco/createSetupTelco',

  EDIT_SETUP_TELCO: 'setuptelco/editSetupTelco',

  DEL_SETUP_TELCO: 'setuptelco/delSetupTelco',


  PUT_TELCO_CUSTOMER: 'setuptelco/putTelcoCustomer',
  
};

export const getSetupTelco = createAsyncThunk(ACTION.GET_SETUP_TELCO, async body => {
  return axiosInstance.get('/restapi/setup-telco/', { params: body });
});

export const getSetupTelcoId = createAsyncThunk(ACTION.GET_SETUP_TELCO_ID, async body => {
  return axiosInstance.get(`/restapi/setup-telco/${body.id}/`);
});

export const createSetupTelco = createAsyncThunk(ACTION.CREATE_SETUP_TELCO, async (body, rejectWithValue) => {
  try {
    const response = await axiosInstance.post(`/restapi/setup-telco/`, body);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
}
);

export const editSetupTelco = createAsyncThunk(ACTION.EDIT_SETUP_TELCO, async (body, rejectWithValue) => {
  try {
    const response = await axiosInstance.put(`/restapi/setup-telco/${body.id}/`, body);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }

});

export const delSetupTelco = createAsyncThunk(ACTION.EDIT_SETUP_TELCO, async (body, rejectWithValue) => {
  try {
    const response = await axiosInstance.delete(`/restapi/setup-telco/${body.id}/`, body);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }

});


export const putTelcoCustomer = createAsyncThunk(ACTION.EDIT_SETUP_TELCO, async ( {id, body, rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/restapi/group-telco/${id}/`, body);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }

});



const setupTelcoSlice = createSlice({
  name: 'setup-telco',
  initialState: initialState,
  reducers:{
    getTelco: (state, action) => {
      Object.keys(action.payload).map((key,value) =>{
        state[key] = (action.payload)[key]
      })
    }
  },
  extraReducers: {
    [getSetupTelco.pending.toString()]: state => {
      state.loading = true;
    },
    [getSetupTelco.rejected.toString()]: state => {
      state.loading = false;
      state.list = null;
    },
    [getSetupTelco.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },

    [createSetupTelco.pending.toString()]: state => {
      state.success = false;
    },
    [createSetupTelco.rejected.toString()]: state => {
      state.success = false;
    },
    [createSetupTelco.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },

    [editSetupTelco.pending.toString()]: state => {
      state.success = false;
    },
    [editSetupTelco.rejected.toString()]: state => {
      state.success = false;
    },
    [editSetupTelco.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },

    [putTelcoCustomer.pending.toString()]: state => {
      state.success = false;
    },
    [putTelcoCustomer.rejected.toString()]: state => {
      state.success = false;
    },
    [putTelcoCustomer.fulfilled.toString()]: (state, action) => {
      state.success = true;
    },

    
   
  }
});
export const {getTelco} = setupTelcoSlice.actions
const { reducer: setupTelcoReducer } = setupTelcoSlice;
export default setupTelcoReducer;
