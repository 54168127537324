import React, { useState, useEffect } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { expenseOrderTabs } from '../../../constants';
import { getExpenseType } from '../../redux/expense-type';
import { getExpenseOrderDetail } from '../../redux/expense-order';

const UcExpenseOrder = React.lazy(() => import('./UcExpenseOrder'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const DetailOrder = React.lazy(() => import('./detail'));

const AssetDocumentForm = () => {
  const [rows, setRows] = useState([]);
  const status = useSelector(state => state.expenseOrder);
  const statusExpenseType = useSelector(state => state.expenseOrder);
  const expensesTypeList = useSelector(state => state.expenseType.list);
  const dataEdit = useSelector(state => state.expenseOrder.detail);
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExpenseType({}));
  }, []);

  useEffect(() => {
    if (statusExpenseType.success) {
      dispatch(getExpenseType({}));
    }
  }, [statusExpenseType.success]);

  useEffect(() => {
    if (id) {
      dispatch(getExpenseOrderDetail({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (status.updatePdSuccess === true) {
      setTimeout(() => {
        dispatch(getExpenseOrderDetail({ id }));
      }, 1000);
    }
  }, [status.updatePdSuccess]);

  useEffect(() => {
    if (status.createPdSuccess === true) {
      setTimeout(() => {
        dispatch(getExpenseOrderDetail({ id }));
      }, 1000);
    }
  }, [status.createPdSuccess]);

  useEffect(() => {
    if (status.delPdSuccess === true) {
      setTimeout(() => {
        dispatch(getExpenseOrderDetail({ id }));
      }, 1000);
    }
  }, [status.delPdSuccess]);

  // Tab
  const [activeTab, setActiveTab] = useState(0);
  const dataInformation = [
    <DetailOrder rows={rows} setRows={setRows} expensesList={expensesTypeList} />
  ];

  const dataInformationEdit = [
    <DetailOrder
      rows={rows}
      setRows={setRows}
      expensesList={expensesTypeList}
      dataEdit={dataEdit}
    />
  ];

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <Spinner loading={status.loadingDetail} top={200} />

        <span
          className="btn"
          onClick={() => history.goBack()}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 'inherit',
            width: 40,
            height: 40,
            lineHeight: 40,
            marginTop: 20
          }}>
          <i className="fa fa-chevron-left"></i>
        </span>

      {id ? <UcExpenseOrder detail={rows} dataEdit={dataEdit} /> : <UcExpenseOrder detail={rows}/>}
      <div className="tabs-list">
        {expenseOrderTabs.map((v, i) => (
          <div
            key={i}
            className={`tab-item ${activeTab === v.key ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(v.key);
            }}>
            <p>{v.name.toUpperCase()}</p>
          </div>
        ))}
      </div>
      <div className='box-form-general'>
         {id ? dataInformationEdit[activeTab] : dataInformation[activeTab]}
      </div>
     
    </Container>
    </React.Suspense>
  );
};

export default AssetDocumentForm;
