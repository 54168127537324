import React, { forwardRef } from 'react';
import FormLabel from './FormLabel';

const CustomPhotoUpload = forwardRef(
  ({ className, onBlur, handleChange, error, label, type, required, ...otherProps }, ref) => {
    return (
      <div className={`form-input-box ${className}`} style={otherProps.style}>
        {label ? (
          <FormLabel label={label} />
        ) : null}
        <div className='photo'>
        
        <img
              src={otherProps.src}
              alt='avatar'
              onError={otherProps.onError}
            />
            <span className='edit'>
              <i className='fas fa-edit'></i>
            </span>
        <input
              accept='image/png, image/jpeg'
              type='file'
              onChange={handleChange}
              {...otherProps}
              />
        </div>
      </div>
    );
  }
);

export default CustomPhotoUpload;
