/* eslint-disable max-len */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from '../erp/redux/auth';
import accountReducer from '../erp/redux/account';
import rateReducer from '../erp/redux/rate';
import groupReducer from '../erp/redux/group';
import summaryReducer from '../erp/redux/summary';
import ratePerDayReducer from '../erp/redux/rate-per-day';
import detailReducer from '../erp/redux/detail';
import compareReducer from '../erp/redux/compare';
import sidebarReducer from '../erp/redux/sidebar';
import simReducer from '../erp/redux/sim';
import controlReducer from '../erp/redux/control';

import enduserReducer from '../erp/redux/enduser';
import ticketReducer from '../erp/redux/ticket';

import profileReducer from '../erp/redux/profile';

import userReducer from '../erp/redux/user';
import f88Reducer from '../erp/redux/f88';
import customertReducer from '../erp/redux/customer';
import addresstReducer from '../erp/redux/address';
import agencyReducer from '../erp/redux/agency';
import permissionReducer from '../erp/redux/permission';
import Sales from '../erp/redux/sales';
import Categories from '../erp/redux/categories';
import Expenses from '../erp/redux/expenses';
import positionReducer from '../erp/redux/position';
import departmentReducer from '../erp/redux/department';
import employeeReducer from '../erp/redux/employee';
import accountantServiceReducer from '../erp/redux/accountantService';
import assetReducer from '../erp/redux/asset';
import saleOrderReducer from '../erp/redux/sale-order';
import expenseOrderReducer from '../erp/redux/expense-order';
import expenseTypeReducer from '../erp/redux/expense-type';
import assetDocumentReducer from '../erp/redux/asset-document';
import assetDocumentTypeReducer from '../erp/redux/asset-document-type';
import assetDocumentStatusReducer from '../erp/redux/asset-document-status';
import directionTelcoReducer from '../erp/redux/direction-telco';
import contractsReducer from '../erp/redux/contract';
import alertReducer from '../erp/redux/alert-message';
import annextContractsReducer from '../erp/redux/annex-contract';
import cmcphoneReducer from '../erp/redux/cmc-phone'
import callLocalReducer from '../erp/redux/call-local';
import setupTelcoReducer from '../erp/redux/setup-telco';
import accountBankReducer from '../erp/redux/accountBank';
import debtReducer from '../erp/redux/deb';
import videocallReducer from '../erp/redux/videocall';
const store = configureStore({
  reducer: {
    auth: authReducer,
    accounts: accountReducer,
    rates: rateReducer,
    groups: groupReducer,
    summary: summaryReducer,
    ratePerDay: ratePerDayReducer,
    detail: detailReducer,
    compare: compareReducer,
    sidebar: sidebarReducer,
    sims: simReducer,
    control: controlReducer,
    enduser: enduserReducer,
    ticket: ticketReducer,
    profile: profileReducer,
    user: userReducer,
    f88: f88Reducer,
    customers: customertReducer,
    address: addresstReducer,
    agency: agencyReducer,
    permission: permissionReducer,
    sales: Sales,
    categories: Categories,
    expenses: Expenses,
    position: positionReducer,
    department: departmentReducer,
    employees: employeeReducer,
    accountantService: accountantServiceReducer,
    asset:assetReducer,
    saleOrder:saleOrderReducer,
    expenseType: expenseTypeReducer,
    expenseOrder: expenseOrderReducer,
    assetDocumentType: assetDocumentTypeReducer,
    assetDocumentStatus: assetDocumentStatusReducer,
    assetDocument: assetDocumentReducer,
    directionTelco:directionTelcoReducer,
    contracts:contractsReducer,
    alert:alertReducer,
    annextContracts:annextContractsReducer,
    cmcphone:cmcphoneReducer,
    callLocal:callLocalReducer,
    setupTelco:setupTelcoReducer,
    accountBank:accountBankReducer,
    debt:debtReducer,
    videocall:videocallReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: true
});
export default store;
