import React, { useEffect, useState } from 'react';
import { Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaginationState } from '../../../hooks/use-pagination';
import { ModalContent } from '../../../components/common/Modal';
import { Tooltip } from 'react-tippy';
import { getDirectionTelco } from '../../redux/direction-telco';

const DialogTelco = React.lazy(() => import('./DialogTelco'));
const Modal = React.lazy(() => import('../../../components/common/Modal'));
const Pagination = React.lazy(() => import('../../../components/common/Pagination'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const Empty = React.lazy(() => import('../../../components/common/Empty'));
const Telco = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState('');
  const telcoList = useSelector(state => state.directionTelco.list);
  const isLoading = useSelector(state => state.directionTelco.loading);
  const status = useSelector(state => state.directionTelco);
  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getDirectionTelco({
        offset: pagination.perPage * pagination.page - pagination.perPage,
        limit: pagination.perPage
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (status.success === true) {
      dispatch(
        getDirectionTelco({
          offset: pagination.perPage * pagination.page - pagination.perPage,
          limit: pagination.perPage
        })
      );
    }
  }, [status.success]);

  // close modal

  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };
  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container border-t">
        <h1 className="heading">
          Quản lý nhà mạng
          <span
            className="btn"
            onClick={() => {
              setOpenModal(true);
            }}>
            <i className="fa fa-plus-circle mr-2"></i>
            Thêm nhà mạng
          </span>
        </h1>

      <Modal changeActive={setOpenModal} active={openModal} id={`${!data ? '' : `modal_${data.id}`}`}>
        <ModalContent style={{ maxWidth: 700 }} heading={!data ? 'Thêm nhà mạng' : 'Sửa'} onClose={handleCloseModal}>
          <DialogTelco
            setUDO={setOpenModal}
            setData={setData}
            dataEdit={data}
          />
        </ModalContent>
      </Modal>
      {isLoading ? (
        <Spinner loading={isLoading} top={100} />
      ) : (
        <div className="table-wrapper">
          <table className="table mb-0">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="border-0" style={{ width: '40px' }}>
                  STT
                </th>
                <th scope="col" className="border-0" style={{ width: '80px' }}>
                  Tên nhà mạng
                </th>
                <th scope="col" className="border-0" style={{ width: '80px' }}>
                  Mô tả
                </th>
                <th scope="col" className="border-0">
                  Mô tả xuất đối soát theo phút
                </th>
                <th scope="col" className="border-0">
                  Mô tả xuất đối soát theo Voice Branch
                </th>
                <th scope="col" className="border-0" style={{ width: '100px' }}>
                  Hoạt động
                </th>
                <th scope="col" className="border-0" style={{ width: '120px' }}>
                  Hành động
                </th>
              </tr>
            </thead>

              <tbody>
                {telcoList && telcoList.results &&
                  telcoList.results.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>{item.description_in_minutes}</td>
                        <td>{item.description_in_channel}</td>
                        <td className="text-center">
                          <span
                            className={`fa ${item.show === true ? 'fa-check' : 'fa-times'}`}></span>
                        </td>
                        <td>
                          <Tooltip  position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                            <button
                              onClick={() => {
                                setOpenModal(true);
                                setData(item);
                              }}
                              className="btn btn-edit">
                              <i className="fa fa-edit"></i>
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {telcoList && telcoList.results && telcoList.results.length === 0 && (
              <Empty description="Không có dữ liệu" />
            )}
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.perPage}
              lastPage={Math.ceil((telcoList ? telcoList.count : 0) / pagination.perPage)}
              onChangePage={pagination.setPage}
              onChangePageSize={pagination.setPerPage}
              onGoToLast={() =>
                pagination.setPage(
                  Math.ceil((telcoList ? telcoList.count : 0) / pagination.perPage)
                )
              }
            />

        </div>
      )}
    </Container>
    </React.Suspense>
  );
};

export default Telco;
