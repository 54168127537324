import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'shards-react';

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex align-items-center">
    <Container fluid={contained}>
      
        {/* <Nav>
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={Link} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav> */}
        <div className="text-center">
        <span className="copyright">{copyright}</span>
        </div>
      
    </Container>
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: 'Copyright © 2022 Vntel',
  menuItems: [
    // {
    //   title: "Home",
    //   to: "#"
    // },
    // {
    //   title: "Services",
    //   to: "#"
    // },
    // {
    //   title: "About",
    //   to: "#"
    // },
    // {
    //   title: "Products",
    //   to: "#"
    // },
    // {
    //   title: "Blog",
    //   to: "#"
    // }
  ]
};

export default MainFooter;
