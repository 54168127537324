import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { delRegSim, getRegSim, getServiceSim } from '../redux/sim';
import { showMessageError, showMessageSuccess } from '../redux/alert-message';
import { usePaginationState } from '../../hooks/use-pagination';
import { Tooltip } from 'react-tippy';
import moment from 'moment';
import { getEnduser } from '../redux/enduser';

const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const DialogEditReg = React.lazy(() => import('../../components/sim/DialogEditReg'));
const Empty = React.lazy(() => import('../../components/common/Empty'));
const SimReg = () => {
  const dispatch = useDispatch();
  const simsRegList = useSelector(state => state.sims.simRegList);
  const simServiceList = useSelector(state => state.sims.simServiceList);
  const enduserList = useSelector(state => state.enduser.enduser.info);
  const loadingState = useSelector(state => state.sims.loading);

  const { successEdit } = useSelector(state => state.sims);

  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getRegSim({
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage
      })
    );
  }, [pagination]);

  useEffect(() => {
    if (successEdit === true) {
      dispatch(
        getRegSim({
          limit: pagination.perPage,
          offset: pagination.perPage * pagination.page - pagination.perPage
        })
      );
    }
  }, [successEdit]);

  useEffect(() => {
    dispatch(getServiceSim({}));
    dispatch(getEnduser({}));
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState('');

  const closeModal = e => {
    if (e.target === wrapperRef.current) setOpenModal(false);
  };

  const wrapperRef = useRef(null);
  const handleCloseModal = () => {
    setOpenModal(false);
    setData('');
  };

  const handleDelRegSim = id => {
    dispatch(delRegSim({ id }))
      .unwrap()
      .then(() => {
        dispatch(showMessageSuccess());
        dispatch(
          getRegSim({
            limit: pagination.perPage,
            offset: pagination.perPage * pagination.page - pagination.perPage
          })
        );
      })
      .catch(() => {
        dispatch(showMessageError('Bạn không thể hủy hợp đồng đã ký.'));
      });
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Danh sách số đăng ký</h1>
        {openModal && (
          <>
            <div className="modal-overlay"></div>
            <div ref={wrapperRef} onClick={closeModal} className="modal-popup_style2 open">
              <div className="popup-container pu-general">
                <h3 className="pu-heading">Sửa</h3>
                <div className="popup-close" onClick={() => handleCloseModal()}>
                  <span className="fa fa-times"></span>
                </div>
                <DialogEditReg
                  enduserList={enduserList}
                  simServiceList={simServiceList}
                  setUDO={setOpenModal}
                  setData={setData}
                  dataEdit={data}
                />
              </div>
            </div>
          </>
        )}
        <Row>
          <Col>
            {loadingState ? (
              <Spinner loading={loadingState} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        SĐT
                      </th>
                      <th scope="col" className="border-0">
                        Nhà mạng
                      </th>

                      <th scope="col" className="border-0">
                        Trạng thái
                      </th>

                      <th scope="col" className="border-0">
                        Ngày đăng ký
                      </th>

                      <th scope="col" className="border-0">
                        Tạo bởi
                      </th>
                      <th scope="col" className="border-0">
                        Trạng Thái
                      </th>
                      <th scope="col" className="border-0">
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {simsRegList && simsRegList.Reg &&
                      simsRegList.Reg.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.sim.number}</td>
                            <td>{item.sim.telco}</td>
                            <td>{item.sim.simstatus === null ? '' : item.sim.simstatus.name}</td>

                            <td>{moment(item.sim.date_create).format('YYYY-MM-DD')}</td>

                            <td>{item.sim.create_by.username}</td>
                            <td>{item.status === true ? 'Đang hoạt động' : 'Chờ duyệt'}</td>
                            <td>
                              <Tooltip position="top"
                                title={'Cập nhập'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-edit"
                                  onClick={() => {
                                    setOpenModal(true);
                                    setData(item);
                                  }}>
                                  <i className="fa fa-edit"></i>
                                </span>
                              </Tooltip>
                              <Tooltip position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                                <span
                                  className="btn btn-del"
                                  onClick={() => handleDelRegSim(item.id)}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {simsRegList && simsRegList.Reg &&
                      simsRegList.Reg.length === 0 && (
                  <Empty description="Không có dữ liệu" />
                )}
                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    Math.ceil((simsRegList ? simsRegList.reg_count : 0) / pagination.perPage),
                    Math.ceil((simsRegList ? simsRegList.reg_count : 0) / pagination.perPage)
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(
                      Math.ceil((simsRegList ? simsRegList.reg_count : 0) / pagination.perPage)
                    )
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default SimReg;
