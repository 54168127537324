import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { OPTION_FILTER } from '../../components/common/Filter';
import { getRatePerDay } from '../redux/rate-per-day';
import { usePaginationState } from '../../hooks/use-pagination';

const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Filter = React.lazy(() => import('../../components/common/Filter'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));

const RatePerDay = () => {
  const dispatch = useDispatch();
  const ratePerDayList = useSelector(state => state.ratePerDay.ratePerDay);
  const [filter, setFilter] = useState({});
  const isLoading = useSelector(state => state.ratePerDay.loading);

  const handleSearch = filter => {
    setFilter({ ...filter, page: pagination.page, perPage: pagination.perPage });
    dispatch(getRatePerDay({ ...filter, page: pagination.page, perPage: pagination.perPage }));
  };

  const pagination = usePaginationState();

  useEffect(() => {
    if (filter.acc)
      dispatch(getRatePerDay({ ...filter, page: pagination.page, perPage: pagination.perPage }));
  }, [pagination]);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Thống kê tỷ lệ theo ngày</h1>
        <Row>
          <Col>
            <Filter
              onSearch={handleSearch}
              optionFilter={[OPTION_FILTER.ACC_CODE_SELECT_SEARCH, OPTION_FILTER.DATE_RANGE]}
            />
            {filter.acc && <Spinner loading={isLoading} />}

            <div className="table-wrapper">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      STT
                    </th>
                    <th scope="col" className="border-0">
                      Ngày
                    </th>
                    <th scope="col" className="border-0">
                      Số cuộc gọi
                    </th>
                    <th scope="col" className="border-0">
                      Số cuộc gọi thành công
                    </th>
                    <th scope="col" className="border-0">
                      Số cuộc gọi thất bại
                    </th>
                    <th scope="col" className="border-0">
                      Tỉ lệ thành công
                    </th>
                    <th scope="col" className="border-0">
                      Tỉ lệ thất bại
                    </th>
                  </tr>
                </thead>
                {ratePerDayList.data && (
                  <tbody>
                    <tr key={0}>
                      <td className="font-bold">Tổng</td>
                      <td className="font-bold"></td>
                      <td className="font-bold">{ratePerDayList.totals}</td>
                      <td className="font-bold">{ratePerDayList.totals_success}</td>
                      <td className="font-bold">{ratePerDayList.totals_fail}</td>
                      <td className="font-bold">{ratePerDayList.totals_success_rate}</td>
                      <td className="font-bold">{ratePerDayList.totals_fail_rate}</td>
                    </tr>
                    {ratePerDayList.data.list.map((item, idx) => {
                      return (
                        <tr key={idx + 1}>
                          <td>{idx + 1}</td>
                          <td>{item.date}</td>
                          <td>{item.totals}</td>
                          <td>{item.success}</td>
                          <td>{item.fail}</td>
                          <td>{item.success_rate}</td>
                          <td>{item.fail_rate}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <Pagination
                currentPage={pagination.page}
                pageSize={pagination.perPage}
                lastPage={Math.min(
                  pagination.page + 5,
                  Math.ceil(
                    ratePerDayList.data ? ratePerDayList.data.total : 0 / pagination.perPage
                  )
                )}
                onChangePage={pagination.setPage}
                onChangePageSize={pagination.setPerPage}
                onGoToLast={() => pagination.setPage(1111)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default RatePerDay;
