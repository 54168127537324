import React from 'react';
import { FormInput, Row, Col, FormSelect, FormCheckbox } from 'shards-react';
import { HelpText } from '../../../components/components-overview/Help-text';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageSuccess, showMessageError } from '../../redux/alert-message';
import { getAccountBank } from '../../redux/accountBank';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { getCustomer } from '../../redux/customer';
import DatePicker from 'react-date-picker';
import { MONTH } from '../../../constants';

import moment from 'moment';
import { postDebt } from '../../redux/deb';
import NumberFormat from 'react-number-format';
import Note from './Note';
import { getAllUser } from '../../redux/user';
import { getYearArr } from '../../helps/help';
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const FormLabel = React.lazy(() => import('../../../components/common/FormLabel'));
const DialogDebt = ({ dataEdit = null, setUDO, setData }) => {
  const timeoutTitle = React.useRef();
  const stateUser = useSelector((state) => state.user);
  const stateCustomer = useSelector((state) => state.customers);
  const stateAccount = useSelector((state) => state.accountBank);
  const status = useSelector((state) => state.debt);
  const dispatch = useDispatch();

  const [defaultOptions, setDefaultOption] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [accountOpt, setAccountOpt] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [customerName, setCustomerName] = React.useState('');


  
  const year = moment().year();

  const YEAR = getYearArr(5, year);

  React.useEffect(() => {
    if (dataEdit && dataEdit.customer) {
      setCustomerName(`${dataEdit.customer.name} (${dataEdit.group && dataEdit.group.name})`);
    }
  }, [dataEdit]);

  React.useEffect(() => {
    dispatch(getCustomer({ limit: 10, offset: 0 }))
      .unwrap()
      .then((res) => {
        setDefaultOption(
          res?.results?.map((v) => ({
            id: v.id,
            label: `${v.name} (${v.group && v.group.name})`,
            value: `${v.name} (${v.group && v.group.name})`,
            key: 'customer'
          }))
        );
      });
    dispatch(getAllUser())
      .unwrap()
      .then((res) => {
        setUsers(
          res &&
            res.list &&
            res.list.map((v) => {
              return {
                key: 'followers',
                id: v.id,
                value: v.id,
                label: `${v.username}`
              };
            })
        );
      });
    dispatch(getAccountBank())
      .unwrap()
      .then((res) => {
        setAccountOpt(
          res &&
            res.results &&
            res.results.map((v) => {
              return {
                key: 'transaction_account',
                id: v.id,
                value: v.id,
                label: `${v.account_number} - ${v.account_owner} - ${v.bank_name}`
              };
            })
        );
      });
  }, []);

  // react-select search
  const filterTypingName = (inputValue, res) => {
    const contactSelect = res?.results?.map((v) => {
      return {
        id: v.id,
        label: `${v.name} (${v.group && v.group.name})`,
        value: `${v.name} (${v.group && v.group.name})`,
        key: 'customer'
      };
    });

    return contactSelect;
  };

  const loadOptions = (inputValue, callback) => {
    clearTimeout(timeoutTitle.current);
    timeoutTitle.current = setTimeout(() => {
      dispatch(getCustomer({ name: inputValue }))
        .unwrap()
        .then((res) => {
          callback(filterTypingName(inputValue, res));
        });
    }, 400);
  };

  const handleChangeOption = (option, field) => {
    setFieldValue(field, option.value);
    switch (field) {
      case 'followers':
        const arrId = option && option.map((v) => v.id);
        setFieldValue(field, arrId);
        break;

      default:
        break;
    }
  };

  //submitform
  const convertToFormData = () => {
    const submitValues = {
      ...values,
      reminder_day: values.reminder_day ? moment(values.reminder_day).format('YYYY-MM-DD') : '',
      attachments: rows
    };
    const formData = new FormData();
    //|| (values.followers && values.followers.length > 0)
    if (rows.length > 0) {
      for (const [key, value] of Object.entries(submitValues)) {
        switch (key) {
          case 'followers':
            value.map((item, idx) => {
              return formData.append(`followers[${idx}]`, item);
            });
            break;
          case 'attachments':
            value.map((item, idx) => {
              formData.append(`attachments[${idx}][file]`, item.file);
              formData.append(`attachments[${idx}][description]`, item.description);
            });
            break;

          default:
            formData.append(key, value);
            break;
        }
      }
      return formData;
    } else {
      return submitValues;
    }
  };
  const submitForm = (values) => {
    if (!dataEdit) {
      const submitValues = {
        ...values,
        reminder_day: values.reminder_day ? moment(values.reminder_day).format('YYYY-MM-DD') : '',
        attachments: rows
      };

      dispatch(postDebt(convertToFormData()))
        .unwrap()
        .then(() => {
          dispatch(showMessageSuccess());
        })
        .catch(() => {
          dispatch(showMessageError());
        });
    } else {
      // const submitValues = {
      //     body: convertToFormData(),
      //     id: dataEdit.id
      //   };
      //    dispatch(putAccountBank(submitValues))
      //     .unwrap()
      //     .then(() => {
      //       dispatch(showMessageSuccess());
      //     })
      //     .catch(() => {
      //       dispatch(showMessageError());
      //     });
    }

    setUDO(false);
    setData('');
    setRows([]);
  };

  const validationSchema = yup.object({
    reminder_day: yup.date().required('Không được bỏ trống'),
    customer: yup.number().required('Không được bỏ trống'),
    code: yup.string().required('Không được bỏ trống'),
    total: yup.number().required('Không được bỏ trống')
  });

  const { values, setFieldValue, handleSubmit, handleChange, handleBlur, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        customer: (dataEdit && dataEdit.customer && dataEdit.customer.id) || '',
        reminder_day: (dataEdit && dataEdit.reminder_day) || '',
        followers: (dataEdit && dataEdit.followers) || [],
        total: (dataEdit && dataEdit.total) || '',
        month: (dataEdit && dataEdit.month) || (MONTH && MONTH[0] && parseInt(MONTH[0])),
        year: (dataEdit && dataEdit.year) || (YEAR && YEAR[0]),
        code: (dataEdit && dataEdit.code) || '',
        transaction_account: (dataEdit && dataEdit.transaction_account) || '',
        attachments: (dataEdit && dataEdit.attachments) || '',
        status: false
      },
      validationSchema: validationSchema,
      onSubmit: submitForm
    });

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <div className="content">
        <div className="box-form">
          <Row>
            <Col xs={12}>
              <div className="form-input-box" style={{ position: 'relative', zIndex: 10 }}>
                <FormLabel label="Chọn khách hàng" />
                <AsyncSelect
                  isLoading={stateCustomer && stateCustomer.loading}
                  //isDisabled={stateCustomer && stateCustomer.loading}
                  placeholder={<div>Chọn</div>}
                  className="basic-single"
                  onChange={(value) => {
                    setFieldValue('customer', value.id);
                  }}
                  loadOptions={loadOptions}
                  defaultOptions={defaultOptions}
                  loadingMessage={() => 'Đang tìm kiếm...'}
                  noOptionsMessage={() => 'Không có dữ liệu'}
                  name="customer"
                />
                <HelpText status="error">
                  {touched.customer && errors.customer ? errors.customer : ''}
                </HelpText>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box" style={{ position: 'relative', zIndex: 5 }}>
                <FormLabel label="Người theo dõi" />
                <Select
                  isLoading={stateUser && stateUser.loading}
                  //isDisabled={stateUser && stateUser.loading}
                  isMulti
                  // defaultValue={followersList}
                  // value={followersList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={<div>Chọn</div>}
                  onChange={(e) => handleChangeOption(e, 'followers')}
                  name="followers"
                  options={users}
                  loadingMessage={() => 'Đang tìm kiếm...'}
                  noOptionsMessage={() => 'Không có dữ liệu'}
                  closeMenuOnSelect={false}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Ngày nhắc nợ" />
                <DatePicker
                  className={'custom-datepicker style3'}
                  value={values.reminder_day}
                  onChange={(value) => setFieldValue('reminder_day', value)}
                  format="d/MM/y"
                  clearIcon={null}
                  calendarIcon={<i className="material-icons">&#xE916;</i>}
                />
                <HelpText status="error">
                  {touched.reminder_day && errors.reminder_day ? errors.reminder_day : ''}
                </HelpText>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Tổng tiền" />
                <NumberFormat
                  thousandSeparator={true}
                  customInput={FormInput}
                  name="total"
                  value={values['total']}
                  placeholder="Nhập tổng tiền"
                  onValueChange={(value) => {
                    setFieldValue('total', value.floatValue);
                  }}
                  onBlur={handleBlur}
                />
                <HelpText status="error">
                  {touched.total && errors.total ? errors.total : ''}
                </HelpText>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form-input-box">
                <FormLabel label="Mã công nợ" />
                <FormInput
                  type="text"
                  name="code"
                  placeholder="Nhập mã công nợ"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <HelpText status="error">{touched.code && errors.code ? errors.code : ''}</HelpText>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form-input-box">
                <FormLabel label="Chọn tháng" />
                <FormSelect
                  id="feInputState"
                  onChange={(e) => {
                    setFieldValue('month', parseInt(e.target.value));
                  }}>
                  {MONTH.map((item, idx) => {
                    return <option key={idx}>{item}</option>;
                  })}
                </FormSelect>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="form-input-box">
                <FormLabel label="Chọn năm" />
                <FormSelect
                  id="feInputState"
                  onChange={(e) => {
                    setFieldValue('year', parseInt(e.target.value));
                  }}>
                  {YEAR.map((item, idx) => {
                    return <option key={idx}>{item}</option>;
                  })}
                </FormSelect>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Chọn tài khoản thanh toán" />
                <Select
                  isLoading={stateAccount && stateAccount.loading}
                  isDisabled={stateAccount && stateAccount.loading}
                  type="select"
                  name="transaction_account"
                  id="transaction_account"
                  placeholder={<div>Chọn </div>}
                  onChange={(e) => handleChangeOption(e, 'transaction_account')}
                  options={accountOpt}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-input-box">
                <FormLabel label="Trạng thái công nợ" />
                <FormCheckbox
                  className="mb-1"
                  id="status"
                  name="status"
                  checked={values.status}
                  onChange={(e) => {
                    setFieldValue('status', e.currentTarget.checked);
                  }}>
                  Đã thanh toán
                </FormCheckbox>
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box">
                <Note rows={rows} setRows={setRows} />
              </div>
            </Col>
            <Col xs={12}>
              <div className="form-input-box text-right">
                <button
                  className={`btn ${status.success === false ? 'disabled' : ''}`}
                  type="submit"
                  onClick={handleSubmit}>
                  {!dataEdit ? 'Tạo' : 'Sửa'}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Suspense>
  );
};

export default DialogDebt;
