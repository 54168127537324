import React, { useEffect, useState, Fragment } from 'react';

import getSidebarNavItems from '../../../data/sidebar-nav-items';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SidebarLeft from './SidebarLeft';

const SidebarNavItems = () => {
  const profile = useSelector(state => state.auth.profile);
  const navItems = getSidebarNavItems();
  const [firstMenu, setFirstMenu] = useState(undefined);
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const [selected, setSelected] = useState('');

  let isSetFirstMenu = false;

  useEffect(() => {
    if (firstMenu && isSetFirstMenu) {
      history.push(firstMenu.to);
    }
  }, [firstMenu]);

  useEffect(() => {
    if (!profile.permissions) {
      setFirstMenu(undefined);
    }
  }, [profile]);

  return (
    <div className={`nav-wrapper ${isHovered ? 'isActive' : ''}`}>
      <ul className="navigation-left">
        {navItems.map((item, idx) => {
          let check = false;
          const isArr = typeof item.symbol;
          if (isArr === 'object') {
            const result =
              profile.permissions &&
              profile.permissions.map(e => {
                let match = item.symbol.find(s => s === e.per);
                return match;
              });
            check = result && result.length;
          } else {
            let permisionByper = profile.permissions && profile.permissions.map(e => e.per)
            check = profile.permissions && (profile.permissions.includes(item.symbol) || permisionByper.includes(item.symbol));
          }
          
          if (check || profile.is_superuser || profile.is_staff) {
            if (!firstMenu && !isSetFirstMenu) {
              isSetFirstMenu = true;
              setFirstMenu(item);
            }
            return (
              <SidebarLeft
                key={idx}
                item={item}
                setIsHovered={setIsHovered}
                setSelected={setSelected}
                selected={selected}
                isHovered={isHovered}
              />
            );
          } else {
            return <Fragment key={idx}></Fragment>;
          }
        })}
      </ul>
    </div>
  );
};

export default SidebarNavItems;
