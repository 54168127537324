import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  data: {},
  successCreate: null,
  loadingDetail: false,
  successEdit: null,
  successDel: null,
};

const ACTION = {
  GET_SALES: 'categories/getSales',
  CREATE_SALES: 'categories/createSales',
  EDIT_SALES: 'categories/editSales',
  DELETE_SALES: 'categories/delSales',
};

export const getSales = createAsyncThunk(ACTION.GET_SALES, async body => {
  let params = "";
  const limit = body.limit || null;
  const offset = body.offset || null;
  params += limit !== null ? `limit=${limit}&` : ""
  params += offset !== null ? `offset=${offset}&` : ""
  return axiosInstance.get('/api/accountant/sales/?' + params);
});

export const createSales = createAsyncThunk(ACTION.CREATE_SALES, async body => {
  return axiosInstance.post('/api/accountant/sales/', body);
});

export const editSales = createAsyncThunk(ACTION.EDIT_SALES, async body => {
  return axiosInstance.put(`/api/accountant/sales/${body.id}/`, body);
});

export const delSales = createAsyncThunk(ACTION.DELETE_SALES, async body => {
  return axiosInstance.delete(`api/accountant/sales/${body.id}/`);
});

const salesSlice = createSlice({
  name: 'sales',
  initialState: initialState,
  extraReducers: {
    [getSales.pending.toString()]: state => {
      state.loading = true;
    },
    [getSales.rejected.toString()]: state => {
      state.loading = false;
    },
    [getSales.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successEdit = null;
      state.successDel = null;
      state.data = action.payload;
    },
    [createSales.pending.toString()]: state => {
    },
    [createSales.rejected.toString()]: state => {
      state.successCreate=false;
    },
    [createSales.fulfilled.toString()]: (state, action) => {
      state.successCreate=true;
    },
    // edit sales
    [editSales.pending.toString()]: state => { },
    [editSales.rejected.toString()]: state => {
      state.successEdit = false;
    },
    [editSales.fulfilled.toString()]: state => {
      state.successEdit = true;
    },

    [delSales.pending.toString()]: (state) => {
    },
    [delSales.rejected.toString()]: (state) => {
      state.successDel = false;
    },
    [delSales.fulfilled.toString()]: (state) => {
      state.successDel = true;
    }

  }
});

const { reducer: salesReducer } = salesSlice;
export default salesReducer;
