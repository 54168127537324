import React, { useEffect } from 'react';
import { Row, Col, Container } from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderSim } from '../redux/sim';
import moment from 'moment';
import { usePaginationState } from '../../hooks/use-pagination';
import { Tooltip } from 'react-tippy';

const Pagination = React.lazy(() => import('../../components/common/Pagination'));
const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const Empty = React.lazy(() => import('../../components/common/Empty'));
const SimOrder = () => {
  const dispatch = useDispatch();
  const simsOrderList = useSelector(state => state.sims.simOrderList);
  const loadingState = useSelector(state => state.sims.loading);

  const pagination = usePaginationState();

  useEffect(() => {
    dispatch(
      getOrderSim({
        limit: pagination.perPage,
        offset: pagination.perPage * pagination.page - pagination.perPage
      })
    );
  }, [pagination]);

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <h1 className="heading">Danh sách Order của KH</h1>

        <Row>
          <Col>
            {loadingState ? (
              <Spinner loading={loadingState} />
            ) : (
              <div className="table-wrapper">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        STT
                      </th>
                      <th scope="col" className="border-0">
                        SĐT
                      </th>
                      <th scope="col" className="border-0">
                        Nhà mạng
                      </th>

                      <th scope="col" className="border-0">
                        Tạo bởi
                      </th>
                      <th scope="col" className="border-0">
                        Ngày đăng ký
                      </th>

                      <th scope="col" className="border-0">
                        Ngày chạy
                      </th>
                      <th scope="col" className="border-0">
                        Trạng Thái
                      </th>
                      <th scope="col" className="border-0">
                        Hành động
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {simsOrderList && simsOrderList.simOrder &&
                      simsOrderList.simOrder.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.number}</td>
                            <td>{item.telco}</td>
                            <td>{item.order_by.username}</td>
                            <td>{moment(item.date_create).format('YYYY-MM-DD')}</td>
                            <td>
                              {item.date_run === null
                                ? ''
                                : moment(item.date_run).format('YYYY-MM-DD')}
                            </td>
                            <td>{item.simstatus.name}</td>
                            <td>
                              <Tooltip position="top"
                                title={'Xoá'}
                                size={'small'}
                                animation={'shift'}>
                                <span className="btn btn-del">
                                  <i className="fa fa-trash"></i>
                                </span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {simsOrderList && simsOrderList.simOrder  && simsOrderList.simOrder.length === 0 && (
                  <Empty description="Không có dữ liệu" />
                )}
                <Pagination
                  currentPage={pagination.page}
                  pageSize={pagination.perPage}
                  lastPage={Math.min(
                    Math.ceil(
                      (simsOrderList ? simsOrderList.simOrder_count : 0) / pagination.perPage
                    ),
                    Math.ceil(
                      (simsOrderList ? simsOrderList.simOrder_count : 0) / pagination.perPage
                    )
                  )}
                  onChangePage={pagination.setPage}
                  onChangePageSize={pagination.setPerPage}
                  onGoToLast={() =>
                    pagination.setPage(
                      Math.ceil(
                        (simsOrderList ? simsOrderList.simOrder_count : 0) / pagination.perPage
                      )
                    )
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Suspense>
  );
};

export default SimOrder;
