import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  enduser: {},
  successCreate:null,
};

const ACTION = {
  GET_ENDUSER: 'enduser/getEnduser',
  CREATE_ENDUSER:'enduser/createEnduser'
};

export const getEnduser = createAsyncThunk(ACTION.GET_ENDUSER, async body => {
  return axiosInstance.get('/api/enduser', {
    params: body
  });
});

export const createEnduser = createAsyncThunk(ACTION.CREATE_ENDUSER, async body => {
 
  return axiosInstance.post('/api/enduser', body);
});

const enduserSlice = createSlice({
  name: 'enduser',
  initialState: initialState,
  extraReducers: {
    [getEnduser.pending.toString()]: state => {
      state.loading = true;
    },
    [getEnduser.rejected.toString()]: state => {
      state.loading = false;
    },
    [getEnduser.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successCreate = null;
      state.enduser = action.payload;
    },
    // create
    [createEnduser.pending.toString()]: state => {
    },
    [createEnduser.rejected.toString()]: (state,action) => {
      state.successCreate = false;
    },
    [createEnduser.fulfilled.toString()]: (state, action) => {
      state.successCreate = true;

    },
  }
});

const { reducer: enduserReducer } = enduserSlice;
export default enduserReducer;
