import React from 'react';
import { ListGroup, ListGroupItem, Row, Col, FormInput, Button, Card, Alert } from 'shards-react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HelpText } from '../../components/components-overview/Help-text';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, authMsg } from '../redux/auth';
import { routeConstants } from '../../constants';
import { useHistory } from 'react-router';
const Spinner = React.lazy(() => import('../../components/common/Spinner'));
const SignIn = () => {
  const dispatch = useDispatch();
  const error = useSelector(authMsg);
  const history = useHistory();
  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      //   .email('Email không hợp lệ')
      .required('Nhập tên đăng nhập'),
    password: yup.string().required('Bạn chưa nhập mật khẩu')
  });

  const submitForm = () => {
    dispatch(
      signIn({
        username: values.email,
        password: values.password
      })
    )
      .unwrap()
      .then(res => {
        history.push(routeConstants.DASHBOARD);
      })
      .catch(err => {});
  };
  const { values, errors, handleChange, handleBlur, handleSubmit, touched } = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: submitForm
  });

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <div className="center-block w-2/6">
        <Card small>
          {error && <Alert theme="danger">Tài khoản hoặc mật khẩu không chính xác</Alert>}
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Row form>
                      <Col className="form-group">
                        <label htmlFor="feEmailAddress">Tên đăng nhập</label>
                        <FormInput
                          id="feEmailAddress"
                          name="email"
                          placeholder="Tên đăng nhập"
                          values={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          status={touched.email && errors.email ? 'error' : undefined}
                        />
                        <HelpText status="error">
                          {touched.email && errors.email ? errors.email : ''}
                        </HelpText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label htmlFor="fePassword">Mật khẩu</label>
                        <FormInput
                          id="fePassword"
                          name="password"
                          type="password"
                          placeholder="Mật khẩu"
                          values={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          status={touched.password && errors.password ? 'error' : undefined}
                          //   helpText={touched.password && errors.password ? errors.password : ''}
                        />
                        <HelpText status="error">
                          {touched.password && errors.password ? errors.password : ''}
                        </HelpText>
                      </Col>
                    </Row>
                    <Button type="submit" className="mt-4">
                      Đăng nhập
                    </Button>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    </React.Suspense>
  );
};

export default SignIn;
