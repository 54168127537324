import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  data: {},
  loading: false,
};

const ACTION = {
  GET_SERVICES: 'accountantService/getServices',
  CREATE_SERVICE: 'accountantService/createService',
  UPDATE_SERVICE: 'accountantService/updateService',
  DEL_SERVICE: 'accountantService/delService'
};

export const getServices = createAsyncThunk(ACTION.GET_SERVICES, async body => {
  return axiosInstance.get('/api/accountant/services/', { params: body });
});

export const updateService = createAsyncThunk(ACTION.UPDATE_SERVICE, async body => {
  return axiosInstance.put(`/api/accountant/services/${body.id}/`, body);
});

export const createService = createAsyncThunk(ACTION.CREATE_SERVICE, async body => {
  return axiosInstance.post('/api/accountant/services/', body);
});

export const delService = createAsyncThunk(ACTION.DEL_SERVICE, async body => {
  return axiosInstance.delete(`/api/accountant/services/${body}`);
});

const accountantServiceSlice = createSlice({
  name: 'accountantService',
  initialState: initialState,
  extraReducers: {
    [getServices.pending.toString()]: state => {
      state.loading = true;
    },
    [getServices.rejected.toString()]: state => {
      state.loading = false;
    },
    [getServices.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.success = false;
      state.data = action.payload;
    },
    [createService.pending.toString()]: state => {
    },
    [createService.rejected.toString()]: state => {
      state.error = true;
    },
    [createService.fulfilled.toString()]: (state) => {
      state.success = true;
    },
    [updateService.pending.toString()]: state => {

    },
    [updateService.rejected.toString()]: state => {
      state.error = true;
    },
    [updateService.fulfilled.toString()]: (state) => {
      state.success = true;
    },
    [delService.pending.toString()]: state => {

    },
    [delService.rejected.toString()]: state => {
      state.error = true;
    },
    [delService.fulfilled.toString()]: (state) => {
      state.success = true;
    }
  }
});
export const selectStatus = (state => state.accountantService)
export const selectAccountService = (state => state.accountantService.data)
const { reducer: accountantServiceReducer } = accountantServiceSlice;
export default accountantServiceReducer;
