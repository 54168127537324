import React from 'react';
import { formatMoney } from '../../helps/convertMoney';
import { Button } from 'shards-react';


const TableRow = ({row,
  show,
  setUpdateRowId,
  index,
  setUDO,
  setDataEdit})=>{


  const calculateTotal = () => {
    if (!(row.qty && row.price)) {
      return '0 VND';
    }
    let total = row.price * row.qty * ((100 - row.ck) / 100);
    if (row.minus) {
      return '-' + formatMoney(total);
    }
    return formatMoney(total);
  };
  return (
    <>
      <td className="text-center">{index + 1}</td>
      <td>{row.name}</td>
      <td className="text-right" style={{height:67}}>{formatMoney(row.price)}</td>
      <td className="text-right">{row.qty}</td>
      <td className="text-right">{row.ck}</td>
      <td className="text-right">{calculateTotal()}</td>
      {
        show && (
          <td className="d-flex justify-center" style={{ gap: '5px' }}>
        {row.action}
        {/* {row.action2} */}
        {row.canUpdate && (
          <Button
            onClick={() => {
              setUpdateRowId(index);
            }}>
            Cập nhật
          </Button>
        )}
        {row.canDelete && (
          <Button onClick={()=>{
            setUDO(true)
            setDataEdit(row)
          }} active theme="danger">
            Xóa dòng
          </Button>
        )}
      </td>
        )
      }
    </>
);
};

export default React.memo(TableRow);
