import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../service/config';

const initialState = {
  simList: [],
  simStockList: [],
  simStatusList: [],
  simRegList: [],
  simOrderList: [],
  simServiceList: [],
  simHistory: [],
  simLockDetail: {},
  successReg: null,
  successDel: null,
  successEdit: null,
  successCreate: null,
  loadinghistory: null,
  error: null
};

const ACTION = {
  GET_SIMS: 'sim/getSim',
  GET_SIMSTOCK: 'sim/getSimStock',
  GET_SIMSTATUS: 'sim/getSimStatus',

  CREATE_SIM: 'sim/createSim',
  DELETE_SIM: 'sim/delSim',
  EDIT_SIM: 'sim/editSim',

  CREATE_REGSIM: 'sim/createRegSim',
  GET_REGSIM: 'sim/getRegSim',
  EDIT_REGSIM: 'sim/regsim',
  DEL_REGSIM: 'sim/delRegSim',

  CREATE_ORDERSIM: 'sim/createOrderSim',
  CREATE_LOCK_SIM: 'sim/createLockSim',
  UPDATE_LOCK_SIM: 'sim/updateLockSim',
  UPDATE_LOCK_SIM_DETAIL: 'sim/updateLockSimDetail',
  DELETE_LOCK_SIM: 'sim/deleteLockSim',
  GET_LOCK_SIM: 'sim/getLockSim',
  GET_ORDERSIM: 'sim/getOrderSim',

  GET_SERVICESIM: 'sim/getServiceSim',

  GET_HISTORYSIM: 'sim/getHistorySim',
  GET_LOCK_SIM_CHART: 'sim/getLockSimChart',
  GET_LOCK_SIM_DETAIL: 'sim/getLockSimDetail'
};

export const getSim = createAsyncThunk(ACTION.GET_SIMS, async body => {
  let params = '';
  const { search, limit, offset } = body;
  params += search !== '' && search !== undefined ? `number=${search.join('')}&` : '';
  params += limit !== '' && limit !== undefined ? `limit=${limit}&` : '';
  params += offset !== '' && offset !== undefined ? `offset=${offset}&` : '';
  return axiosInstance.get('/api/store?' + params);
});

export const createSim = createAsyncThunk(ACTION.CREATE_SIM, async body => {
  let params = '';
  const { number, simstocks, date_run, is_activated, simstatus } = body;
  params += number !== '' && number !== undefined ? `number=${number}&` : '';
  params += simstocks !== '' && simstocks !== undefined ? `simstocks=${simstocks}&` : '';
  params += date_run !== '' && date_run !== undefined ? `date_run=${date_run}&` : '';
  params +=
    is_activated !== '' && is_activated !== undefined ? `is_activated=${is_activated}&` : '';
  params += simstatus !== '' && simstatus !== undefined ? `simstatus=${simstatus}&` : '';
  return axiosInstance.post('/api/store?' + params);
});

export const editSim = createAsyncThunk(ACTION.EDIT_SIM, async body => {
  let params = '';
  const { id, number, simstocks, date_run, is_activated, simstatus } = body;
  params += id !== '' && id !== undefined ? `id=${id}&` : '';
  params += number !== '' && number !== undefined ? `number=${number}&` : '';
  params += simstocks !== '' && simstocks !== undefined ? `simstocks=${simstocks}&` : '';
  params += date_run !== '' && date_run !== undefined ? `date_run=${date_run}&` : '';
  params +=
    is_activated !== '' && is_activated !== undefined ? `is_activated=${is_activated}&` : '';
  params += simstatus !== '' && simstatus !== undefined ? `simstatus=${simstatus}&` : '';
  return axiosInstance.put('/api/store?' + params);
});

export const getSimStock = createAsyncThunk(ACTION.GET_SIMSTOCK, async body => {
  return axiosInstance.get('/api/stocks', body);
});

export const getSimStatus = createAsyncThunk(ACTION.GET_SIMSTATUS, async body => {
  return axiosInstance.get('/api/status', body);
});

export const delSim = createAsyncThunk(ACTION.DELETE_SIM, async ({ id }) => {
  return axiosInstance.delete(`/api/store?id=${id}`);
});

// sim reg

export const createRegSim = createAsyncThunk(
  ACTION.CREATE_REGSIM,
  async (id, { rejectWithValue }) => {
    try {
     await axiosInstance.post(`/api/regsim?sim_id=${id}`);
    } catch (error) {}
  }
);

export const getRegSim = createAsyncThunk(ACTION.GET_REGSIM, async body => {
  let params = '';
  const { limit, offset } = body;
  params += limit !== '' && limit !== undefined ? `limit=${limit}&` : '';
  params += offset !== '' && offset !== undefined ? `offset=${offset}&` : '';
  return axiosInstance.get('/api/regsim?' + params);
});

export const editRegSim = createAsyncThunk(ACTION.EDIT_REGSIM, async body => {
  const { id, name, service, org, date_start, date_end, content, status } = body;
  let params = '';
  params += id !== '' && id !== undefined ? `id=${id}&` : '';
  params += name !== '' && name !== undefined ? `name=${name}&` : '';
  params += service !== '' && service !== undefined ? `service=${service}&` : '';
  params += org !== '' && org !== undefined ? `org=${org}&` : '';
  params += date_start !== '' && date_start !== undefined ? `date_start=${date_start}&` : '';
  params += date_end !== '' && date_end !== undefined ? `date_end=${date_end}&` : '';
  params += content !== '' && content !== undefined ? `content=${content}&` : '';
  params += status !== '' && status !== undefined ? `status=${status}&` : '';

  return axiosInstance.put('/api/regsim?' + params);
});

export const delRegSim = createAsyncThunk(ACTION.DELETE_SIM, async ({ id }) => {
  return axiosInstance.delete(`/api/regsim?id=${id}`);
});

// Order Sim

export const createOrderSim = createAsyncThunk(ACTION.CREATE_ORDERSIM, async body => {
  let params = '';
  let number_convert = `{"listdata":${JSON.stringify(body.numberlist)}}`;
  const { numberlist, date_run } = body;
  params += numberlist !== '' && numberlist !== undefined ? `numberlist=${number_convert}&` : '';
  params += date_run !== '' && date_run !== undefined ? `date_run=${date_run}&` : '';
  return axiosInstance.post('/api/order-sim/?' + params);
});

export const getOrderSim = createAsyncThunk(ACTION.GET_ORDERSIM, async body => {
  let params = '';
  const { limit, offset } = body;
  params += limit !== '' && limit !== undefined ? `limit=${limit}&` : '';
  params += offset !== '' && offset !== undefined ? `offset=${offset}&` : '';
  return axiosInstance.get('/api/order-sim/?' + params);
});

// service sim

export const getServiceSim = createAsyncThunk(ACTION.GET_SERVICESIM, async body => {
  return axiosInstance.get('/api/service-sim/', body);
});

// History sim

export const getHistorySim = createAsyncThunk(ACTION.GET_HISTORYSIM, async ({ number }) => {
  return axiosInstance.get(`/api/his-sim/${number}`);
});

// create lock Sim

export const createLockSim = createAsyncThunk(ACTION.CREATE_LOCK_SIM, async body => {
  return axiosInstance.post('/api/lock-sim/table/', body);
});

// update lock Sim

export const updateLockSim = createAsyncThunk(ACTION.UPDATE_LOCK_SIM, async body => {
  return axiosInstance.put('/api/lock-sim/table/', body);
});

// update lock Sim Detail

export const updateLockSimDetail = createAsyncThunk(ACTION.UPDATE_LOCK_SIM_DETAIL, async body => {
  return axiosInstance.put(`/api/lock-sim/detail/${body.id}/`, body);
});

// lock sim

export const getLockSim = createAsyncThunk(ACTION.GET_LOCK_SIM, async body => {
  return axiosInstance.get(`/api/lock-sim/table/`, { params: body });
});
// lock sim chart

export const getLockSimChart = createAsyncThunk(ACTION.GET_LOCK_SIM_CHART, async body => {
  return axiosInstance.get(`/api/lock-sim/charts`, { params: body });
});

//lock sim detail
export const getLockSimDetail = createAsyncThunk(ACTION.GET_LOCK_SIM_DETAIL, async body => {
  return axiosInstance.get(`/api/lock-sim/detail/${body.id}/`);
});

// delete record by date

export const delLockSim = createAsyncThunk(ACTION.DELETE_LOCK_SIM, async ({ id }) => {
  return axiosInstance.delete(`/api/lock-sim/detail/${id}`);
});

const simSlice = createSlice({
  name: 'sim',
  initialState: initialState,
  extraReducers: {
    [createSim.pending.toString()]: state => {},
    [createSim.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.successCreate = false;
    },
    [createSim.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successCreate = true;
    },
    [editSim.pending.toString()]: state => {},
    [editSim.rejected.toString()]: state => {
      state.successEdit = false;
    },
    [editSim.fulfilled.toString()]: state => {
      state.successEdit = true;
    },
    [getSim.pending.toString()]: state => {
      state.loading = true;
    },
    [getSim.rejected.toString()]: state => {
      state.loading = false;
    },
    [getSim.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successCreate = null;
      state.successDel = null;
      state.successEdit = null;
      state.successReg = null;
      state.simList = action.payload.sim;
    },
    [getSimStock.pending.toString()]: state => {
      state.loading = true;
    },
    [getSimStock.rejected.toString()]: state => {
      state.loading = false;
    },
    [getSimStock.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.simStockList = action.payload.info;
    },
    [getSimStatus.pending.toString()]: state => {
      state.loading = true;
    },
    [getSimStatus.rejected.toString()]: state => {
      state.loading = false;
    },
    [getSimStatus.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.simStatusList = action.payload.info;
    },
    [delSim.pending.toString()]: state => {},
    [delSim.rejected.toString()]: state => {
      state.successDel = false;
    },
    [delSim.fulfilled.toString()]: state => {
      state.successDel = true;
    },

    [createRegSim.pending.toString()]: state => {},
    [createRegSim.rejected.toString()]: state => {
      state.successReg = false;
    },
    [createRegSim.fulfilled.toString()]: state => {
      state.successReg = true;
    },
    [getRegSim.pending.toString()]: state => {
      state.loading = true;
    },
    [getRegSim.rejected.toString()]: state => {
      state.loading = false;
    },
    [getRegSim.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.successEdit = null;
      state.successDel = null;
      state.simRegList = action.payload.Regsim;
    },

    [editRegSim.pending.toString()]: state => {},
    [editRegSim.rejected.toString()]: state => {
      state.successEdit = false;
    },
    [editRegSim.fulfilled.toString()]: state => {
      state.successEdit = true;
    },
    [delRegSim.pending.toString()]: state => {},
    [delRegSim.rejected.toString()]: state => {
      state.successDel = false;
    },
    [delRegSim.fulfilled.toString()]: state => {
      state.successDel = true;
    },
    //order sim

    [getOrderSim.pending.toString()]: state => {
      state.loading = true;
    },
    [getOrderSim.rejected.toString()]: state => {
      state.loading = false;
    },
    [getOrderSim.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.simOrderList = action.payload.simOrder;
    },
    //service sim

    [getServiceSim.pending.toString()]: state => {
      state.loading = true;
    },
    [getServiceSim.rejected.toString()]: state => {
      state.loading = false;
    },
    [getServiceSim.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.simServiceList = action.payload.data;
    },
    // sim history

    [getHistorySim.pending.toString()]: state => {
      state.loadinghistory = true;
    },
    [getHistorySim.rejected.toString()]: state => {
      state.loadinghistory = false;
    },
    [getHistorySim.fulfilled.toString()]: (state, action) => {
      state.loadinghistory = false;
      state.simHistory = action.payload.data;
    },
    // get lock sim

    [getLockSim.pending.toString()]: state => {
      state.loadinglock = true;
    },
    [getLockSim.rejected.toString()]: state => {
      state.loadinglock = false;
    },
    [getLockSim.fulfilled.toString()]: (state, action) => {
      state.loadinglock = false;
      state.updateLockSimSuccess = false;
      state.delLockSimSuccess = false;
      state.createLockSimSuccess = false;
      state.locksim = action.payload;
    },
    // get lock sim for chart

    [getLockSimChart.pending.toString()]: state => {
      state.loadinglockChart = true;
    },
    [getLockSimChart.rejected.toString()]: state => {
      state.loadinglockChart = false;
    },
    [getLockSimChart.fulfilled.toString()]: (state, action) => {
      state.loadinglockChart = false;
      state.locksimChart = action.payload;
    },

    // get lock sim detail

    [getLockSimDetail.pending.toString()]: state => {
      state.loadingSimLockDetail = true;
    },
    [getLockSimDetail.rejected.toString()]: state => {
      state.loadingSimLockDetail = false;
    },
    [getLockSimDetail.fulfilled.toString()]: (state, action) => {
      state.loadingSimLockDetail = false;
      state.simLockDetail = action.payload;
    },
    // create lock sim

    [createLockSim.pending.toString()]: state => {
      // state.createLockSimSuccess = false;
    },
    [createLockSim.rejected.toString()]: state => {
      state.createLockSimSuccess = false;
    },
    [createLockSim.fulfilled.toString()]: (state, action) => {
      state.createLockSimSuccess = true;
      state.locksim = action.payload.data;
    },
    // update lock sim

    [updateLockSim.pending.toString()]: state => {
      state.createLockSimSuccess = false;
    },
    [updateLockSim.rejected.toString()]: state => {
      state.updateLockSimSuccess = false;
      state.updateLockSimError = true;
    },
    [updateLockSim.fulfilled.toString()]: (state, action) => {
      state.updateLockSimSuccess = true;
      state.updateLockSimError = false;
      state.locksim = action.payload.data;
    },
    //update locksim detail
    [updateLockSimDetail.pending.toString()]: state => {
      state.createLockSimSuccess = false;
    },
    [updateLockSimDetail.rejected.toString()]: state => {
      state.updateLockSimSuccess = false;
      state.updateLockSimError = true;
    },
    [updateLockSimDetail.fulfilled.toString()]: (state, action) => {
      state.updateLockSimSuccess = true;
      state.updateLockSimError = false;
      state.locksim = action.payload.data;
    },
    // delete lock sim

    [delLockSim.pending.toString()]: state => {
      state.delLockSimSuccess = false;
    },
    [delLockSim.rejected.toString()]: state => {
      state.delLockSimSuccess = false;
      state.delLockSimError = true;
    },
    [delLockSim.fulfilled.toString()]: (state, action) => {
      state.delLockSimSuccess = true;
      state.delLockSimError = false;
      // state.locksim = action.payload.data;
    }
  }
});
export const selectLockSimDetail = (state => state.sims.simLockDetail)
const { reducer: simReducer } = simSlice;
export default simReducer;
