import React, { useState, useEffect } from 'react';
import { Container } from 'shards-react';
import { useParams, useHistory } from 'react-router-dom';
import { saleOrderTabs } from '../../../constants';
import { getServices } from '../../redux/accountantService';
import { useDispatch, useSelector } from 'react-redux';
import { selectDetail, getDetailId, selectStatus } from '../../redux/sale-order';

const UcSaleOrder = React.lazy(() => import('./UcSaleOrder'));
const DetailOrder = React.lazy(() => import('./DetailOrder'));
const Spinner = React.lazy(() => import('../../../components/common/Spinner'));
const DialogCreateService = React.lazy(() => import('../control/DialogCreateService'));

const SaleOrder = ({
  setUDO,
  dataEditControl = null,
  detailControl = null,
  dataCustomer = null,
  month = null
}) => {
  const [rows, setRows] = useState([]);

  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const dataEdit = useSelector(selectDetail);
  const status = useSelector(selectStatus);
  const statusService = useSelector(state => state.accountantService);

  // control

  // end control

  useEffect(() => {
    dispatch(getServices({}));
  }, []);

  useEffect(() => {
    if (statusService.createSuccess === true) {
      dispatch(getServices({}));
    }
  }, [statusService.createSuccess]);

  //  get id detail
  useEffect(() => {
    if (id) {
      dispatch(getDetailId({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (status.successPd === true && id) {
      setTimeout(() => {
        dispatch(getDetailId({ id }));
      }, 1000);
    }
  }, [status.successPd]);

  useEffect(() => {
    if (status.success === true && id) {
      setTimeout(() => {
        dispatch(getDetailId({ id }));
      }, 1000);
    }
  }, [status.success]);

  // Tab
  const [activeTab, setActiveTab] = useState();
  const dataInformation = [<DialogCreateService />];

  const handleBack = () => {
    history.goBack();
  };

  const renderSaleOrder = () => {
    if (id) {
      return <UcSaleOrder detail={rows} dataEdit={dataEdit} handleBack={handleBack} />;
    } else {
      return <UcSaleOrder detail={rows} handleBack={handleBack} />;
    }
  };

  const renderDetail = () => {
    if (id) {
      return (
        <DetailOrder rows={rows} setRows={setRows} dataEdit={dataEdit} handleBack={handleBack} />
      );
    } else {
      return <DetailOrder rows={rows} setRows={setRows} handleBack={handleBack} />;
    }
  };

  return (
    <React.Suspense fallback={<Spinner></Spinner>}>
      <Container fluid className="main-content-container px-4">
        <Spinner loading={status.loadingDetail} top={200} />
        <span
          className="btn"
          onClick={() => handleBack()}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 'inherit',
            width: 40,
            height: 40,
            lineHeight: 40,
            marginTop: 20
          }}>
          <i className="fa fa-chevron-left"></i>
        </span>

        {renderSaleOrder()}
        <h1 className="heading">Danh sách sản phẩm</h1>
        {renderDetail()}

        <div className="tabs-list">
          {saleOrderTabs.map((v, i) => (
            <div
              key={i}
              className={`tab-item ${activeTab === v.key ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(v.key);
              }}>
              <p>{v.name.toUpperCase()}</p>
            </div>
          ))}
        </div>
        <div className="box-form-general">{dataInformation[activeTab]}</div>
      </Container>
    </React.Suspense>
  );
};

export default SaleOrder;
