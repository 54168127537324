import React from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch,useSelector } from 'react-redux';
import FormLabel from '../../../components/common/FormLabel';
import { putTelcoCustomer } from '../../redux/setup-telco';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Col, FormSelect, FormInput, Row, FormCheckbox } from 'shards-react';
import { showMessageError, showMessageSuccess } from '../../redux/alert-message';

const DialogTelco = ({ groupId, setUDO, setData, dataEdit, setNameTelco, }) => {
  const status = useSelector(state => state.setupTelco)
  const BLOCK_OPTIONS = ['Chọn', 1, 6, 60];

  const dispatch = useDispatch();





  const validationSchema = yup.object({});

  function clean(obj) {
    for (var propName in obj) {

      if (
        propName === 'block' &&
        (obj[propName] === null ||
          obj[propName] === undefined ||
          isNaN(obj[propName])) ||
          obj[propName] === '')
       {
        obj[propName] = 60
      }
    }
    return obj;
  }

  const submitForm = (values) => {
    clean(values);

    dispatch(putTelcoCustomer({
      id: groupId,
      body:values
    }))
      .then((res) => {
        dispatch(showMessageSuccess());
        setUDO(false);
        setData('');
        setNameTelco({
          viettel: '',
          vinaphone: '',
          mobifone: ''
        });
      })
      .catch((error) => {
        dispatch(showMessageError());
      });
  };
  const { values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        telco: (dataEdit && dataEdit.name) || '',
        minutes: (dataEdit && dataEdit.minutes) || 0,
        price: (dataEdit && dataEdit.price) || 0,
        ck: (dataEdit && dataEdit.ck) || 0,
        block: (dataEdit && dataEdit.block) || 0,
        all: !!dataEdit.all
      },
      enableReinitialize: true,

      validationSchema: validationSchema,
      onSubmit: submitForm
    });
  return (
    <div className="content">
      <div className="box-form">
        <Row>
          <Col xs={12} sm={6}>
            <div className="form-input-box">
              <FormLabel label="Chọn nhà mạng" />
              <FormInput
                  type="text"
                  name="telco"
                  value={values.telco}
                  placeholder={'Nhà mạng'}
                  disabled
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="form-input-box">
              <FormLabel label="Block"></FormLabel>
              <FormSelect
                name="block"
                value={values.block}
                onChange={(e) => setFieldValue('block', parseFloat(e.target.value))}>
                {BLOCK_OPTIONS.map((item, idx) => {
                  return <option key={idx}>{item}</option>;
                })}
              </FormSelect>
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <div className="form-input-box">
            <FormLabel label={'Số phút'} />
        <FormInput
          type="number"
          name="minutes"
          value={values.minutes}
          placeholder={'Số phút'}
          onChange={handleChange}
          onBlur={handleBlur}
        />
            </div>
          </Col>


          <Col xs={12} sm={4}>
            <FormLabel label="Giá tiền"></FormLabel>
            <NumberFormat
              thousandSeparator={true}
              customInput={FormInput}
              name="price"
              value={values['price']}
              placeholder="Giá tiền"
              onValueChange={(value) => {
                setFieldValue('price', value.floatValue);
              }}
              onBlur={handleBlur}
            />
          </Col>
          <Col xs={12} sm={4}>
            <FormLabel label="Chiết khấu"></FormLabel>
            <FormInput
          type="number"
          name="ck"
          value={values['ck']}
          placeholder="ck"
          onChange={handleChange}
          onBlur={handleBlur}
        />
          </Col>
          <Col xs={12}>
            <div className="form-input-box">
              <FormCheckbox
                className="mb-1"
                id="all"
                name="all"
                checked={values.all}
                onChange={(e) => {
                  setFieldValue('all', e.currentTarget.checked);
                }}>
                Tính tất cả thời gian cuộc gọi
              </FormCheckbox>
            </div>
          </Col>
          <Col xs={12}>
            <div className="form-input-box text-right">
              <button className={`btn ${status.success === false ? 'disabled' : ''}`} type="submit" onClick={handleSubmit}>
                Lưu
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DialogTelco;
